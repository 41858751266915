import React, { Component } from 'react';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-yaml";
import {Button} from "react-bootstrap";
import {Play, Code} from "react-feather";


class CodeView extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(value, event) {
        // manually create change event
        this.props.handleChangeFunc({
            target: {
                dataset: {
                    applyUdf:     false,
                    attributeId:  this.props.attributeId,
                    property:     'actionValue',
                    sortPosition: this.props.sortPosition,
                    yamlError:    this.props.yamlError,
                    value:        value
                }
            }
        });
    }

    render() {
        const canEditPipeline = true;
        const code = this.props.code;
        const yamlError = this.props.yamlError;

        if (code === undefined && yamlError === undefined) {
            return (
                <div className='loading-pipeline-designer-wrapper'>
                    <div className='loading-pipeline-designer'>
                        <div className='loader-text'>
                            <div className='spinner-border text-success' role='status'>
                                <span className='sr-only'>Loading...</span>
                            </div>
                            <div className='loader-label'>
                                Loading  YAML editor
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (yamlError) {
            return (
                <div className='container mt-4'>
                    <div className='row'>
                        <div className='col'>
                            <div className='alert alert-danger mb-0 d-flex align-items-center'>
                                Loading YAML description of Pipeline failed
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className='container mt-4'>
              <div className='row'>
                <div className='col'>
                  <div className='card'>
                    <div className='card-header'>
                      <h4 className='card-header-title d-flex align-items-center'>
                        <Code className='feather-icon mr-2' />
                        YAML editor
                      </h4>
                      <button
                        className='btn btn-white'
                        disabled={true}>
                        Read-only mode
                      </button>
                    </div>
                    <div className='card-body p-0'>
                      <AceEditor
                          placeholder=''
                          mode='yaml'
                          theme='xcode'
                          className=''
                          readOnly={canEditPipeline}
                          onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                          onChange={this.handleChange}
                          fontSize={13}
                          height='646px'
                          width='100%'
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={code}
                          enableLiveAutocompletion={false}
                          tabSize={2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
    }
}

export default CodeView;
