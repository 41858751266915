const dataSources = (state, action) => {
  const initialState = {
    checkingHealth:          false,
    checkingHealthJoined:    false,
    columns:                 [],
    connectionWorking:       undefined,
    connectionTestError:     undefined,
    creatingDataSource:      false,
    dataSource:              undefined,
    joinedDataSource:        undefined,
    dataSources:             [],
    fetchingColumns:         false,
    fetchingColumnsFailed:   false,
    fetchingDataSources:     false,
    fetchingTables:          false,
    fetchingTablesFailed:    false,
    numberOfRecords:         undefined,
    numberOfRecordsJoined:   undefined,
    tables:                  [],
    testingConnection:       false,
    testingJoinedConnection: false,
    updatingDataSource:      false
  };

  switch (action.type) {
    case 'REQUEST_DATA_SOURCES':
      return {
        ...state,
        creatingDataSource:  false,
        fetchingDataSources: true,
        flatFiles:           [],
        updatingDataSource:  false
      };
    case 'RECEIVE_DATA_SOURCES':
      return {
        ...state,
        creatingDataSource:  false,
        fetchingDataSources: false,
        dataSources:         action.dataSources,
        updatingDataSource:  false
      };
    case 'REQUEST_DATA_SOURCE':
      return {
        ...state,
        connectionWorking:   undefined,
        creatingDataSource:  false,
        fetchingDataSources: true,
        flatFiles:           [],
        dataSource:          undefined,
        joinedDataSource:    undefined,
        testingConnection:   false,
        updatingDataSource:  false
      };
    case 'RECEIVE_DATA_SOURCE':
      return {
        ...state,
        creatingDataSource:  false,
        fetchingDataSources: false,
        dataSource:          action.dataSource,
        updatingDataSource:  false
      };
    case 'REQUEST_COUNT_DATA_SOURCE':
      return {
        ...state,
      };
    case 'RECEIVE_COUNT_DATA_SOURCE':
      return {
        ...state,
        numberOfRecords: action.numberOfRecords
      };
    case 'REQUEST_COUNT_JOINED_DATA_SOURCE':
      return {
        ...state,
      };
    case 'RECEIVE_COUNT_JOINED_DATA_SOURCE':
      return {
        ...state,
        numberOfRecordsJoined: action.numberOfRecords
      };
    case 'REQUEST_DATA_SOURCE_CHECK_HEALTH':
      return {
        ...state,
        checkingHealth: true
      };
    case 'RECEIVE_DATA_SOURCE_CHECK_HEALTH':
      return {
        ...state,
        checkingHealth: false,
        dataSource:     action.dataSource
      };
    case 'REQUEST_JOINED_DATA_SOURCE_CHECK_HEALTH':
      return {
        ...state,
        checkingHealthJoined: true
      };
    case 'RECEIVE_JOINED_DATA_SOURCE_CHECK_HEALTH':
      return {
        ...state,
        checkingHealthJoined: false,
        joinedDataSource:     action.joinedDataSource
      };
    case 'REQUEST_ADD_DATA_SOURCE':
      return {
        ...state,
        creatingDataSource:  true,
        fetchingDataSources: true,
        dataSource:          undefined
      };
    case 'RECEIVE_ADD_DATA_SOURCE':
      return {
        ...state,
        creatingDataSource:  false,
        fetchingDataSources: false,
        dataSource:          action.dataSource
      };
    case 'REQUEST_UPDATE_DATA_SOURCE':
      return {
        ...state,
        updatingDataSource: true
      };
    case 'RECEIVE_UPDATE_DATA_SOURCE':
      return {
        ...state,
        updatingDataSource: false
      };
    case 'REQUEST_TRANSFER_DATA_SOURCE_TO_PROJECT':
      return {
        ...state,
        updatingDataSource: true
      };
    case 'RECEIVE_TRANSFER_DATA_SOURCE_TO_PROJECT':
      return {
        ...state,
        dataSource:         action.dataSource,
        updatingDataSource: false
      };
    case 'REQUEST_SOURCE_TEST_CONNECTION':
      return {
        ...state,
        testingConnection:   true,
        connectionWorking:   undefined,
        connectionTestError: undefined
      };
    case 'RECEIVE_SOURCE_TEST_CONNECTION':
      return {
        ...state,
        testingConnection:   false,
        connectionWorking:   (action.responseCode === 202),
        connectionTestError: action.connectionTestError
      };
    case 'RESET_SOURCE_TEST_CONNECTION':
      return {
        ...state,
        testingConnection:   false,
        connectionWorking:   undefined,
        connectionTestError: undefined
      };
    case 'REQUEST_DATA_SOURCE_TABLES':
      return {
        ...state,
        connectionTestError:  undefined,
        connectionWorking:    undefined,
        fetchingTables:       true,
        fetchingTablesFailed: false,
        tables:               []
      };
    case 'RECEIVE_DATA_SOURCE_TABLES':
      return {
        ...state,
        fetchingTables:       false,
        fetchingTablesFailed: false,
        tables:               action.tables.sort()
      };
    case 'RECEIVE_DATA_SOURCE_TABLES_FAILED':
      return {
        ...state,
        connectionTestError:  action.connectionError,
        connectionWorking:    false,
        fetchingTables:       false,
        fetchingTablesFailed: true,
        tables:               []
      };
    case 'RESET_SOURCE_TABLES':
      return {
        ...state,
        fetchingTables:       false,
        fetchingTablesFailed: false,
        tables:               []
      };
    case 'REQUEST_DATA_SOURCE_COLUMNS':
      return {
        ...state,
        columns:               [],
        connectionTestError:   undefined,
        connectionWorking:     undefined,
        fetchingColumns:       true,
        fetchingColumnsFailed: false
      };
    case 'RECEIVE_DATA_SOURCE_COLUMNS':
      return {
        ...state,
        columns:               action.columns.sort(),
        fetchingColumns:       false,
        fetchingColumnsFailed: false
      };
    case 'RECEIVE_DATA_SOURCE_COLUMNS_FAILED':
      return {
        ...state,
        columns:               [],
        connectionTestError:   action.connectionError,
        connectionWorking:     false,
        fetchingColumns:       false,
        fetchingColumnsFailed: true
      };
    case 'RESET_SOURCE_COLUMNS':
      return {
        ...state,
        columns:               [],
        fetchingColumns:       false,
        fetchingColumnsFailed: false
      };
    default:
      return (state || initialState);
  }
};

export default dataSources;
