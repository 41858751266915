import React, { Component } from 'react';
import { Link }             from 'react-router-dom';

class Navigation extends Component {
  render() {
    let navItems = [
      {
        isActive: (window.location.pathname.includes('/settings/user')),
        label:    'Account',
        link:     '/settings/user'
      },
      {
        isActive: (window.location.pathname.includes('/settings/password')),
        label:    'Password',
        link:     '/settings/password'
      }
    ];

    const currentUser = this.props.currentUser;
    const info = this.props.info;

    if (
      (currentUser !== undefined) &&
      currentUser.isSuperUser
    ) {
      navItems = navItems.concat(
        [
          {
            isActive: (window.location.pathname.includes('/admin/projects')),
            label:    'Projects',
            link:     '/admin/projects'
          },
          {
            isActive: (window.location.pathname.includes('/admin/users')),
            label:    'Users',
            link:     '/admin/users'
          },
          {
            isActive: (['/admin', '/admin/'].includes(window.location.pathname)),
            label:    'Admin',
            link:     '/admin'
          }
        ]
      );
    }

    if (
      (currentUser !== undefined) &&
      (info !== undefined) &&
      info.enableApi
    ) {
      navItems = navItems.concat(
        [
          {
            isActive: (window.location.pathname.includes('/settings/api')),
            label:    'API',
            link:     '/settings/api'
          },
        ]
      );
    }

    return (
      <ul className='nav nav-tabs'>
        {navItems.map((navItem, index) => (
          <li className='nav-item' key={index}>
            {(navItem.isActive === true) &&
              <Link className='nav-link text-center active' to={navItem.link}>
                {navItem.label}
              </Link>
            }
            {(navItem.isActive !== true) &&
              <Link className='nav-link text-center' to={navItem.link}>
                {navItem.label}
              </Link>
            }
          </li>
        ))}
      </ul>
    );
  }
}

export default Navigation;
