export function restartAllConnectors() {
  const requestRestartAllConnectors = () => ({
    type: 'REQUEST_RESTART_ALL_CONNECTORS'
  });

  const receiveRestartAllConnectors = () => ({
    type: 'RECEIVE_RESTART_ALL_CONNECTORS'
  });

  return function (dispatch) {
    dispatch(requestRestartAllConnectors());

    return fetch(
      '/api/kafka_connectors/restart',
      {
        method:      'POST',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'X-Auth-token': localStorage.getItem('userToken')
        }
      })
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.text();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((emptyText) => {
        dispatch(receiveRestartAllConnectors());
      });
  };
}
