import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProjectWidgetListItem extends Component {
  getPathToProject(project) {
    return (project.id != null) ? ('/projects/' + project.id) : '/projects';
  }

  getProjectShortName(project) {
    let shortName = '?';

    if (project.name === undefined) {
      return shortName;
    }

    const words = project.name.split(' ');

    if (words[0] !== undefined && words[0].length > 0) {
      shortName = words[0][0].toUpperCase()[0];

      if (words[1] !== undefined && words[1].length > 0) {
        shortName += words[1][0].toUpperCase()[0];
      }
    }

    return shortName;
  }

  render() {
    const {
      project
    } = this.props;

    return (
      <li className='list-group-item py-2'>
        <div className='row align-items-center py-2'>
          <div className='col-auto'>
            <div className='row align-items-center p-0'>
              <div className='col-auto'>
                <Link
                  to={this.getPathToProject(project)}
                  className='avatar'>
                  <div className='avatar-title font-size-x-lg bg-primary-soft rounded text-primary'>
                    {this.getProjectShortName(project)}
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className='col ml-2 overflow-hidden text-nowrap'>
            <h4 className='mb-0 name'>
              <Link to={this.getPathToProject(project)}>
                #{project.id}: {project.name}
              </Link>
            </h4>
          </div>
        </div>
      </li>
    );
  }
}

export default ProjectWidgetListItem;
