import { deepCopy } from '../helpers/deepCopy';

const dataSourceProfiles = (state, action) => {
  const initialState = {
    failedLoadingJoinedSampleRecords: false,
    failedLoadingSampleRecords:       false,
    fetchingDataSourceProfiles:       false,
    fetchingJoinedDataSourceProfile:  false,
    fetchingJoinedSampleRecords:      false,
    fetchingSampleRecords:            false,
    dataSourceProfile:                undefined,
    dataSourceProfileAttribute:       undefined,
    joinedDataSourceProfile:          undefined,
    profilingDataSourceProfile:       false,
    sampleRecords:                    [],
    joinedSampleRecords:              []
  };

  switch (action.type) {
    case 'REQUEST_DATA_SOURCE_PROFILE_SAMPLE_RECORDS':
      return {
        ...state,
        failedLoadingSampleRecords: false,
        fetchingSampleRecords:      true,
        sampleRecords:              []
      };
    case 'RECEIVE_DATA_SOURCE_PROFILE_SAMPLE_RECORDS':
      return {
        ...state,
        failedLoadingSampleRecords: false,
        fetchingSampleRecords:      false,
        sampleRecords:              action.sampleRecords
      };
    case 'RECEIVE_DATA_SOURCE_PROFILE_SAMPLE_RECORDS_FAILED':
      return {
        ...state,
        failedLoadingSampleRecords: true,
        fetchingSampleRecords:      false,
        sampleRecords:              []
      };
    case 'REQUEST_JOINED_DATA_SOURCE_PROFILE_SAMPLE_RECORDS':
      return {
        ...state,
        failedLoadingJoinedSampleRecords: false,
        fetchingJoinedSampleRecords:      true,
        joinedSampleRecords:              []
      };
    case 'RECEIVE_JOINED_DATA_SOURCE_PROFILE_SAMPLE_RECORDS':
      return {
        ...state,
        failedLoadingJoinedSampleRecords: false,
        fetchingJoinedSampleRecords:      false,
        joinedSampleRecords:              action.sampleRecords
      };
    case 'RECEIVE_JOINED_DATA_SOURCE_PROFILE_SAMPLE_RECORDS_FAILED':
      return {
        ...state,
        failedLoadingJoiendSampleRecords: true,
        fetchingJoinedSampleRecords:      false,
        joinedSampleRecords:              []
      };
    case 'RESET_DATA_SOURCE_PROFILE':
      return {
        ...state,
        dataSourceProfile:          undefined,
        fetchingDataSourceProfiles: false
      };
    case 'RESET_JOINED_DATA_SOURCE_PROFILE':
      return {
        ...state,
        joinedDataSourceProfile:         undefined,
        fetchingJoinedDataSourceProfile: false
      };
    case 'REQUEST_CREATE_DATA_SOURCE_PROFILE':
      return {
        ...state,
        dataSourceProfile:          undefined,
        fetchingDataSourceProfiles: true
      };
    case 'REQUEST_ADD_DATA_SOURCE_PROFILE_ATTRIBUTE':
      return {
        ...state,
        dataSourceProfileAttribute: undefined
      };
    case 'RECEIVE_DATA_SOURCE_PROFILE_ATTRIBUTE':
      return {
        ...state,
        dataSourceProfileAttribute: action.dataSourceProfileAttribute,
      };
    case 'REQUEST_DATA_SOURCE_PROFILE':
      return {
        ...state,
        fetchingDataSourceProfiles: true
      };
    case 'RECEIVE_DATA_SOURCE_PROFILE':
      return {
        ...state,
        dataSourceProfile:          action.dataSourceProfile,
        fetchingDataSourceProfiles: false
      };
    case 'REQUEST_JOINED_DATA_SOURCE_PROFILE':
      return {
        ...state,
        fetchingJoinedDataSourceProfile: true
      };
    case 'RECEIVE_JOINED_DATA_SOURCE_PROFILE':
      let joinedDataSourceProfile = deepCopy(action.joinedDataSourceProfile);
      // hide isKey property for attributes from joined data source profile
      joinedDataSourceProfile.attributes = joinedDataSourceProfile
        .attributes
        .map(attribute => Object.assign({}, attribute, { isKey: false }));
      return {
        ...state,
        joinedDataSourceProfile:         joinedDataSourceProfile,
        fetchingJoinedDataSourceProfile: false
      };
    case 'RECEIVE_REPLACE_DATA_SOURCE_PROFILE_ATTRIBUTES':
      // merge updated attributes into data source profile
      let dataSourceProfile = deepCopy(state.dataSourceProfile);
      dataSourceProfile.attributes = action.attributes;
      return {
        dataSourceProfile: dataSourceProfile
      };
    case 'REQUEST_UPDATE_REPROFILE_DATA_SOURCE_PROFILE':
      return {
        ...state,
        profilingDataSourceProfile: true
      };
    case 'RECEIVE_UPDATE_REPROFILE_DATA_SOURCE_PROFILE':
      return {
        ...state,
        dataSourceProfile:          action.dataSourceProfile,
        profilingDataSourceProfile: false
      };
    default:
      return (state || initialState);
  }
}

export default dataSourceProfiles
