import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { daysInTrialLeft } from '../../helpers/daysInTrialLeft';
import { humanizeFormattedTimestamp } from '../../helpers/humanizeFormattedTimestamp';

class UserListItem extends Component {
  constructor(props) {
    super(props);
    this.resetPassword = this.resetPassword.bind(this);
  }

  getPathToUser(user, path = '') {
    if (user.id != null) {
      let url = '/admin/users/' + user.id;
      if (path.length > 0) {
        url += '/' + path
      }
      return url;
    } else {
      return '/admin/users';
    }
  }

  resetPassword(event) {
    event.preventDefault();
    this.props.resetPasswordFunc(this.props.user);
  }

  render() {
    const user = this.props.user;

    let isActiveTrial = false;
    const isTrialUser = (user.trialEndsAt !== undefined);
    if (isTrialUser) {
      isActiveTrial = (daysInTrialLeft(user.trialEndsAt) >= 0);
    }

    return (
      <tr>
        <td>
          {user.isActivated && isTrialUser && isActiveTrial &&
            <React.Fragment>
              <span className='text-success mr-2'>●</span>
              <span>Active Trial</span>
            </React.Fragment>
          }
          {user.isActivated && isTrialUser && !isActiveTrial &&
            <React.Fragment>
              <span className='text-warning mr-2'>●</span>
              <span>Overdue Trial</span>
            </React.Fragment>
          }
          {user.isActivated && !isTrialUser &&
            <React.Fragment>
              <span className='text-success mr-2'>●</span>
              <span>Active</span>
            </React.Fragment>
          }
          {!user.isActivated &&
            <React.Fragment>
              <span className='text-danger mr-2'>●</span>
              <span>Inactive</span>
            </React.Fragment>
          }
        </td>
        <td>
          <Link className='text-black' to={this.getPathToUser(user, 'edit')}>
            #{user.id}: {user.name}
          </Link>
        </td>
        <td>
          {humanizeFormattedTimestamp(user.lastSignInAt)}
        </td>
        <td className='text-right pr-0'>
          <Link className='btn btn-white btn-sm' to={this.getPathToUser(user, 'edit')}>
            Edit
          </Link>
          <Link className='btn btn-white btn-sm ml-2' to={this.getPathToUser(user, 'reset_password')} onClick={this.resetPassword}>
            Reset password
          </Link>
        </td>
      </tr>
    );
  }
}

export default UserListItem;
