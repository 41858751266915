const kafkaConnectors = (state, action) => {
  const initialState = {
    isRestartingConnectors: false
  };

  switch (action.type) {
    case 'REQUEST_RESTART_ALL_CONNECTORS':
      return {
        ...state,
        isRestartingConnectors: true
      };
    case 'RECEIVE_RESTART_ALL_CONNECTORS':
      return {
        ...state,
        isRestartingConnectors: false
      };
    default:
      return (state || initialState);
  }
};

export default kafkaConnectors;
