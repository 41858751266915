export function fetchDataSinks() {
  const requestDataSinks = () => ({
    type: 'REQUEST_DATA_SINKS'
  });

  const receivedDataSinks = response => ({
    type:        'RECEIVE_DATA_SINKS',
    dataSinks: response
  });

  return function (dispatch) {
    dispatch(requestDataSinks());

    return fetch('/api/data_sinks',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedDataSinks(json));
      });
  };
}

export function fetchDataSink(id) {
  const requestDataSink = () => ({
    type: 'REQUEST_DATA_SINK'
  });

  const receivedDataSink = response => ({
    type:       'RECEIVE_DATA_SINK',
    dataSink: response
  });

  return function (dispatch) {
    dispatch(requestDataSink());

    return fetch('/api/data_sinks/' + id,
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else if (response.status === 404) {
            window.location = '/404';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedDataSink(json));
      });
  };
}

export function addDataSink(dataSink) {
  const requestAddDataSink = () => ({
    type: 'REQUEST_ADD_DATA_SINK'
  });

  const receivedAddDataSink = response => ({
    type:     'RECEIVE_ADD_DATA_SINK',
    dataSink: response
  });

  return function (dispatch) {
    dispatch(requestAddDataSink());

    let cleanDataSink = dataSink;
    if (cleanDataSink.port !== undefined) {
      cleanDataSink.port = parseInt(cleanDataSink.port);
    }

    return fetch(
        '/api/data_sinks',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSink),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        dispatch(receivedAddDataSink(json));
      });
  };
}

export function updateDataSink(dataSink) {
  const requestUpdateDataSink = () => ({
    type: 'REQUEST_UPDATE_DATA_SINK'
  });
  const receivedUpdateDataSink = () => ({
    type: 'RECEIVE_UPDATE_DATA_SINK'
  });

  return function (dispatch) {
    dispatch(requestUpdateDataSink());

    // remove timestamps
    let cleanDataSink = dataSink;
    delete cleanDataSink.createdAt;
    delete cleanDataSink.updatedAt;
    delete cleanDataSink.connectionHeartbeat;
    if (cleanDataSink.port !== undefined) {
      cleanDataSink.port = parseInt(cleanDataSink.port);
    }

    return fetch(
        '/api/data_sinks/' + dataSink.id,
        {
          method:      'PUT',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSink),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then(() => {
        dispatch(receivedUpdateDataSink());
      });
  };
}

export function deleteDataSink(id) {
  const requestDeleteDataSink = () => ({
    type: 'REQUEST_DELETE_DATA_SINK'
  });
  const receivedDeleteDataSink = () => ({
    type: 'RECEIVE_DELETE_DATA_SINK'
  });

  return function (dispatch) {
    dispatch(requestDeleteDataSink());

    return fetch(
        '/api/data_sinks/' + id,
        {
          method:      'DELETE',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'X-Auth-token': localStorage.getItem('userToken')
          }
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.text();
          }
        },
        error => console.log('An error occurred.', error)
      ).then(() => {
        dispatch(receivedDeleteDataSink());
      });
  };
}

export function transferDataSinkToProject(dataSinkId, projectId) {
  const requestTransferDataSink = () => ({
    type: 'REQUEST_TRANSFER_DATA_SINK_TO_PROJECT'
  });

  const receivedTransferDataSink = (dataSink) => ({
    dataSink: dataSink,
    type:     'RECEIVE_TRANSFER_DATA_SINK_TO_PROJECT'
  });

  return function (dispatch) {
    dispatch(requestTransferDataSink());

    return fetch(
        `/api/data_sinks/${dataSinkId}/transfer_to_project?projectId=${projectId}`,
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'X-Auth-token': localStorage.getItem('userToken')
          }
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        dispatch(receivedTransferDataSink(json));
      });
  };
}

export function testConnection(dataSink) {
  const requestTestConnection = () => ({
    type: 'REQUEST_SINK_TEST_CONNECTION'
  });

  const receivedTestConnection = (responseCode, connectionTestError) => ({
    type:                'RECEIVE_SINK_TEST_CONNECTION',
    responseCode:        responseCode,
    connectionTestError: connectionTestError
  });

  return function (dispatch) {
    dispatch(requestTestConnection());

    // remove timestamps
    let cleanDataSink = dataSink;
    delete cleanDataSink.createdAt;
    delete cleanDataSink.updatedAt;
    delete cleanDataSink.connectionHeartbeat;
    if (cleanDataSink.port !== undefined) {
      cleanDataSink.port = parseInt(cleanDataSink.port);
    }

    return fetch(
        '/api/data_sinks/test_connection',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSink),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else if (response.status === 400) {
            return response.json().then((json) => {
              if (json.error !== undefined) {
                dispatch(receivedTestConnection(response.status, json.error));
              } else {
                dispatch(receivedTestConnection(response.status));
              }
            });
          } else {
            dispatch(receivedTestConnection(response.status));
          }
        },
        error => console.log('An error occurred.', error)
      );
  };
}

export function getNumberOfRecordsInDataSink(dataSinkId, pipelineId) {
  const requestCountDataSink = () => ({
    type: 'REQUEST_COUNT_DATA_SINK'
  });

  const receiveCountDataSink = (response) => ({
    numberOfRecords: response.count,
    type:            'RECEIVE_COUNT_DATA_SINK'
  });

  return function (dispatch) {
    dispatch(requestCountDataSink());

    return fetch('/api/data_sinks/' + dataSinkId + '/count?pipelineId=' + pipelineId,
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receiveCountDataSink(json));
      });
  };
}

export function retrieveTablesFromDataSink(dataSink) {
  const requestRetrieveTables = () => ({
    type: 'REQUEST_DATA_SINK_TABLES'
  });

  const receiveRetrieveTables = (tables) => ({
    type:   'RECEIVE_DATA_SINK_TABLES',
    tables: tables
  });

  const receiveRetrieveTablesFailed = (error) => ({
    connectionError: error,
    type:            'RECEIVE_DATA_SINK_TABLES_FAILED'
  });

  return function (dispatch) {
    dispatch(requestRetrieveTables());

    // remove timestamps
    let cleanDataSink = dataSink;
    delete cleanDataSink.createdAt;
    delete cleanDataSink.updatedAt;
    delete cleanDataSink.connectionHeartbeat;
    if (cleanDataSink.port !== undefined) {
      cleanDataSink.port = parseInt(cleanDataSink.port);
    }

    return fetch(
        '/api/data_sinks/retrieve_tables',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSink),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else if (response.status >= 400) {
            return response
              .json()
              .then((json) => dispatch(receiveRetrieveTablesFailed(json.error)));
          } else {
            return response
              .json()
              .then((json) => dispatch(receiveRetrieveTables(json)));
          }
        },
        error => console.log('An error occurred.', error)
      );
  };
}

export function resetTestConnection() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_SINK_TEST_CONNECTION'
    });
  }
}

export function resetRetrieveTables() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_SINK_TABLES'
    });
  }
}
