const activityEvents = (state, action) => {
  const initialState = {
    fetchingActivityEvents: false,
    activityEvents:         [],
  };

  switch (action.type) {
    case 'REQUEST_ACTIVITY_EVENTS':
      return {
        ...state,
        fetchingActivityEvents: true
      };
    case 'RECEIVE_ACTIVITY_EVENTS':
      return {
        ...state,
        fetchingActivityEvents: false,
        activityEvents:         action.activityEvents
      };
    default:
      return (state || initialState);
  }
}

export default activityEvents
