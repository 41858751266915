import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class XmlConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isApplyingChanges: false,
      showModal:         false
    };

    this.prepareApplyingChanges = this.prepareApplyingChanges.bind(this);
    this.applyChanges           = this.applyChanges.bind(this);
    this.toggleModal            = this.toggleModal.bind(this);
  }

  prepareApplyingChanges(event) {
    event.preventDefault();

    if (this.props.changedAttributeNames) {
      this.toggleModal();
    } else {
      this.applyChanges();
    }
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  applyChanges() {
    this.setState({
      isApplyingChanges: true,
      showModal:         false
    });

    this.props.reprofileDataSourceProfileFunc()
      .then(() => this.setState({ isApplyingChanges: false }));
  }

  render() {
    const {
      createPipelineStatus,
      dataSourceProfile,
      disableParserConfig,
      handleConfigChangeFunc
    } = this.props;

    const disableFormFields =
      (
        (createPipelineStatus !== undefined) ||
        disableParserConfig ||
        this.state.isApplyingChanges
      );

    const xPathRecordNode =
      (dataSourceProfile.profilingConfig !== undefined && dataSourceProfile.profilingConfig.xPathRecordNode !== undefined)
        ? dataSourceProfile.profilingConfig.xPathRecordNode
        : '';

    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-12'>
            <div className='form-group'>
              <label>XPath for record node:</label>
              <input
                type='text'
                className='form-control'
                disabled={disableFormFields}
                name='xPathRecordNode'
                placeholder='/'
                onChange={handleConfigChangeFunc}
                value={xPathRecordNode} />
            </div>
          </div>
        </div>
        {disableParserConfig !== true &&
          <div className='row mb-3'>
            <div className='col'>
              <button
                className='btn btn-primary d-flex align-items-center'
                disabled={createPipelineStatus !== undefined || this.state.isApplyingChanges}
                onClick={this.prepareApplyingChanges}>
                {this.state.isApplyingChanges &&
                  <span className='spinner-border mr-3' role='status'></span>
                }
                Reprofile with updated settings
              </button>
            </div>
          </div>
        }
        <Modal show={this.state.showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              We detected that you made changes to the attribute names.
              Changing the parsing settings will reset the attribute names to the default.
            </p>
            <p className='mb-0'>
              Do you want to proceed?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="white" onClick={this.toggleModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.applyChanges}>
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default XmlConfig;
