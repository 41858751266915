const dataSinks = (state, action) => {
  const initialState = {
    checkingHealth:       false,
    connectionWorking:    undefined,
    connectionTestError:  undefined,
    creatingDataSink:     false,
    dataSinks:            [],
    dataSink:             undefined,
    fetchingDataSinks:    false,
    fetchingTables:       false,
    fetchingTablesFailed: false,
    numberOfRecords:      undefined,
    restartingConnectors: false,
    tables:               [],
    testingConnection:    false,
    updatingDataSink:     false
  };

  switch (action.type) {
    case 'REQUEST_DATA_SINKS':
      return {
        ...state,
        creatingDataSink:  false,
        fetchingDataSinks: true,
        updatingDataSink:  false
      };
    case 'RECEIVE_DATA_SINKS':
      return {
        ...state,
        fetchingDataSinks: false,
        dataSinks:         action.dataSinks
      };
    case 'REQUEST_DATA_SINK':
      return {
        ...state,
        connectionWorking: undefined,
        creatingDataSink:  false,
        dataSink:          undefined,
        fetchingDataSinks: true,
        numberOfRecords:   undefined,
        testingConnection: false,
        updatingDataSink:  false
      };
    case 'RECEIVE_DATA_SINK':
      return {
        ...state,
        creatingDataSink:  false,
        fetchingDataSinks: false,
        dataSink:          action.dataSink,
        updatingDataSink:  false
      };
    case 'REQUEST_DATA_SINK_CHECK_HEALTH':
      return {
        ...state,
        checkingHealth: true
      };
    case 'RECEIVE_DATA_SINK_CHECK_HEALTH':
      return {
        ...state,
        checkingHealth: false,
        dataSink:       action.dataSink
      };
    case 'REQUEST_ADD_DATA_SINK':
      return {
        ...state,
        creatingDataSink:  true,
        fetchingDataSinks: true,
        dataSink:          undefined
      };
    case 'RECEIVE_ADD_DATA_SINK':
      const dataSinks = state.dataSinks;
      dataSinks.push(action.dataSink);

      return {
        ...state,
        creatingDataSink:  false,
        fetchingDataSinks: false,
        dataSink:          action.dataSink,
        dataSinks:         dataSinks
      };
    case 'REQUEST_UPDATE_DATA_SINK':
      return {
        ...state,
        updatingDataSink: true
      };
    case 'RECEIVE_UPDATE_DATA_SINK':
      return {
        ...state,
        updatingDataSink: false
      };
    case 'REQUEST_TRANSFER_DATA_SINK_TO_PROJECT':
      return {
        ...state,
        updatingDataSink: true
      };
    case 'RECEIVE_TRANSFER_DATA_SINK_TO_PROJECT':
      return {
        ...state,
        dataSink:         action.dataSink,
        updatingDataSink: false
      };
    case 'REQUEST_SINK_TEST_CONNECTION':
      return {
        ...state,
        testingConnection:   true,
        connectionWorking:   undefined,
        connectionTestError: undefined
      };
    case 'REQUEST_COUNT_DATA_SINK':
      return {
        ...state,
      };
    case 'RECEIVE_COUNT_DATA_SINK':
      return {
        ...state,
        numberOfRecords: action.numberOfRecords
      };
    case 'RECEIVE_SINK_TEST_CONNECTION':
      return {
        ...state,
        testingConnection:   false,
        connectionWorking:   (action.responseCode === 202),
        connectionTestError: action.connectionTestError
      };
    case 'RESET_SINK_TEST_CONNECTION':
      return {
        ...state,
        testingConnection:   false,
        connectionWorking:   undefined,
        connectionTestError: undefined
      };
    case 'REQUEST_DATA_SINK_TABLES':
      return {
        ...state,
        connectionTestError:  undefined,
        connectionWorking:    undefined,
        fetchingTables:       true,
        fetchingTablesFailed: false,
        tables:               []
      };
    case 'RECEIVE_DATA_SINK_TABLES':
      return {
        ...state,
        fetchingTables:       false,
        fetchingTablesFailed: false,
        tables:               action.tables
      };
    case 'RECEIVE_DATA_SINK_TABLES_FAILED':
      return {
        ...state,
        connectionTestError:  action.connectionError,
        connectionWorking:    false,
        fetchingTables:       false,
        fetchingTablesFailed: true,
        tables:               []
      };
    case 'RESET_SINK_TABLES':
      return {
        ...state,
        fetchingTables:       false,
        fetchingTablesFailed: false,
        tables:               []
      };
    default:
      return (state || initialState);
  }
}

export default dataSinks;
