import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { Plus }             from 'react-feather';
import MainContent          from '../../components/layout/MainContent';
import Navigation           from '../../components/settings/Navigation';
import { fetchInfo }        from '../../actions/info';
import { fetchCurrentUser } from '../../actions/users';
import {
  deleteApiCredential,
  fetchApiCredentials
} from '../../actions/api_credentials';

class API extends Component {
  componentDidMount() {
    this.props.fetchInfo();
    this.props.fetchCurrentUser();
    this.props.fetchApiCredentials();
  }

  deleteIntegration(apiCredentialId) {
    this.props.deleteApiCredential(apiCredentialId)
      .then(() => this.props.fetchApiCredentials());
  }

  render() {
    const currentUser = this.props.users.currentUser;

    return (
      <MainContent preTitle='Settings' title='Manage account'>
        <div className='card card-fill'>
          <div className='card-body'>
            <div className='row mb-4 mt-n3'>
              <div className='col-12'>
                <Navigation currentUser={currentUser} info={this.props.info.info} />
              </div>
            </div>
            <div className='row align-items-center mt-n2 mb-4'>
              <div className='col'>
                <h3 className='card-header-title'>
                  Manage API integrations
                </h3>
              </div>
              <div className='col-auto'>
                <a href='/settings/api/new' className='btn btn-primary d-flex align-items-center'>
                  <Plus className='feather-icon' />
                  Create new integration
                </a>
              </div>
            </div>
            <hr className='mb-0' />
            {(this.props.apiCredentials.apiCredentials.length === 0) &&
               <div className='mt-4'>
                 No integrations
               </div>
            }
            {(this.props.apiCredentials.apiCredentials.length > 0) &&
              <table className='table table-nowrap card-table'>
                <thead>
                  <tr>
                    <th>
                      <span className='text-muted'>
                        Name
                      </span>
                    </th>
                    <th>
                      <span className='text-muted'>
                        Client Id
                      </span>
                    </th>
                    <th className='text-right'>
                      <span className='text-muted'>
                        Actions
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.apiCredentials.apiCredentials.map(apiCredential => (
                    <tr key={apiCredential.id}>
                      <td>{apiCredential.name}</td>
                      <td>{apiCredential.clientId}</td>
                      <td className='text-right pr-0'>
                        <a className='btn btn-white btn-sm ml-2' href='/settings/api' onClick={(e) => { e.preventDefault(); this.deleteIntegration(apiCredential.id);}}>
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    apiCredentials: state.apiCredentials,
    info:           state.info,
    users:          state.users
  }
}

const mapDispatchToProps = {
  deleteApiCredential: deleteApiCredential,
  fetchApiCredentials: fetchApiCredentials,
  fetchCurrentUser:    fetchCurrentUser,
  fetchInfo:           fetchInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(API);
