import React,
       { Component } from 'react';
import {
  Button,
  Modal
} from 'react-bootstrap';
import {
  AlertCircle,
  Code,
  Play,
  StopCircle,
  Terminal,
  Trash2
} from 'react-feather';
import AceEditor from 'react-ace';
import 'ace-builds/src-min-noconflict/mode-yaml';
import { blurOnEnterKeyDown } from '../../helpers/blurOnEnterKeyDown';
import { formatDate } from '../../helpers/formatDate';

class ExecutableListItem extends Component {
  constructor(props) {
    super(props);

    const deploymentName = (props.pipelineExecutable !== undefined)
      ? props.pipelineExecutable.name
      : undefined;

    this.state = {
      currentName:     deploymentName,
      isEditingName:   false,
      isStarting:      false,
      isStopping:      false,
      showDeleteModal: false,
      showYaml:        false
    };

    this.deleteExecutable  = this.deleteExecutable.bind(this);
    this.startExecutable   = this.startExecutable.bind(this);
    this.haltExecutable    = this.haltExecutable.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.enableEditingName = this.enableEditingName.bind(this);
    this.updateEditingName = this.updateEditingName.bind(this);
    this.finishEditingName = this.finishEditingName.bind(this);
    this.toggleShowYaml    = this.toggleShowYaml.bind(this);
  }

  componentDidMount() {
    this.renderedInterval = setInterval(() => this.setState({ renderedComponent: Date.now() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.renderedInterval);
  }

  deleteExecutable(event) {
    this.props.deleteExecutableFunc(event, this.props.pipelineExecutable.id);
  }

  startExecutable(event) {
    this.setState({ isStarting: true });
    this.props.startExecutableFunc(event, this.props.pipelineExecutable.id)
      .then(() => this.setState({ isStarting: false }));
  }

  haltExecutable(event) {
    this.setState({ isStopping: true });
    this.props.haltExecutableFunc(event, this.props.pipelineExecutable.id)
      .then(() => this.setState({ isStopping: false }));
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  enableEditingName() {
    this.setState({
      isEditingName: true
    });
  }

  updateEditingName(event) {
    this.setState({
      currentName: event.target.value
    });
  }

  finishEditingName() {
    const pipelineExecutable = this.props.pipelineExecutable;

    if (pipelineExecutable !== undefined) {
      this.props.updateExecutableNameFunc(
        pipelineExecutable.pipelineId,
        pipelineExecutable.id,
        this.state.currentName
      );
    }

    // Todo: get value of text field
    this.setState({
      isEditingName: false
    });
  }

  formatSeconds(rawSeconds) {
    const minutes = parseInt(rawSeconds/60);
    const seconds = rawSeconds % 60;
    let formattedText = '';
    if (minutes > 0) {
      formattedText += minutes + 'm';
      if (seconds > 0) {
        formattedText += ' ';
      }
    }
    if (seconds >= 0) {
      formattedText += seconds + 's';
    }

    return formattedText;
  }

  formatDate(date) {
    const dateObj = new Date(date);
    return dateObj.toLocaleString();
  }

  getCompileTime(pipelineExecutable) {
    const createdAt = new Date(pipelineExecutable.createdAt);
    const compiledAt = new Date(pipelineExecutable.compiledAt);
    const diffSeconds = Math.abs(compiledAt - createdAt) / 1000;

    return this.formatSeconds(diffSeconds);
  }

  getTimeDifference(timestamp) {
    const startTime = new Date(timestamp);
    const now       = new Date();
    const diffMs    = now - startTime;

    const diffSeconds = Math.round(diffMs / 1000);
    if (diffSeconds <= 60) {
      return diffSeconds + 's ago';
    }

    const diffMinutes = Math.round(diffSeconds / 60);
    if (diffMinutes <= 60) {
      return diffMinutes + 'm ago';
    }

    const diffHours = Math.round(diffMinutes / 60);
    if (diffHours <= 24) {
      return diffHours + 'h ago';
    }

    const diffDays = Math.round(diffHours / 24);
    return diffDays + 'd ago';
  }

  getUptime(pipelineExecutable) {
    if (pipelineExecutable.startedAt === undefined) {
      return '';
    } else {
      return this.getTimeDifference(pipelineExecutable.startedAt);
    }
  }

  getCreatedAt(pipelineExecutable) {
    if (pipelineExecutable.createdAt === undefined) {
      return '';
    } else {
      return this.getTimeDifference(pipelineExecutable.createdAt);
    }
  }

  toggleShowYaml() {
    this.setState({ showYaml: !this.state.showYaml });
  }

  render() {
    const {
      activeDeploymentExists,
      canEditPipeline,
      hideActionButtonsWhenRunning,
      index,
      pipelineExecutable
    } = this.props;

    const isRunning =
      (
        (pipelineExecutable.startedAt !== undefined) &&
        (pipelineExecutable.stoppedAt === undefined)
      );

    const containerFailed = (pipelineExecutable.containerFailed === true);

    const logsTitle = (this.props.showPipelineLogs) ? 'Hide logs' : 'Show logs';

    const shownAsActiveDeployment = (hideActionButtonsWhenRunning !== true);

    const rawName = (shownAsActiveDeployment)
      ? pipelineExecutable.name
      : this.state.currentName;

    // Determine name of executable, show 'Untitled Deployment' if no name is present
    const pipelineExecutableName = ([undefined, ''].includes(rawName))
      ? 'Untitled Deployment'
      : rawName;

    const startExecutionClassNames = (index === 0)
      ? 'btn btn-outline-primary btn-sm'
      : 'btn btn-white btn-sm';

    return (
      <li
        key={pipelineExecutable.id}
        className='list-group-item datacater-pipeline-deployment-item'>
        <div className='row align-items-center py-2'>
          <div className='col-auto'>
              {isRunning && !containerFailed &&
                <span className='text-success'>●</span>
              }
              {isRunning && containerFailed &&
                <span className='text-danger'>●</span>
              }
              {!isRunning &&
                <span>●</span>
              }
          </div>
              <div className='col-auto py-2 pr-0'>
                <h4 className='mb-0'>
                  #{pipelineExecutable.id}
                </h4>
              </div>
          <div className='col overflow-hidden text-nowrap'>
            <div className='row align-items-center'>
              <div className='col-auto'>
                {!canEditPipeline &&
                  <h4 className='mb-0 p-2'>
                    {pipelineExecutableName}
                  </h4>
                }
                {canEditPipeline && !shownAsActiveDeployment && !this.state.isEditingName &&
                  <h4 className='mb-0 name p-2' onClick={this.enableEditingName}>
                    {pipelineExecutableName}
                  </h4>
                }
                {canEditPipeline && shownAsActiveDeployment && !this.state.isEditingName &&
                  <h4 className='mb-0 name p-2 border-0'>
                    {pipelineExecutableName}
                  </h4>
                }
                {canEditPipeline && this.state.isEditingName &&
                  <input
                    autoFocus
                    className='form-control'
                    onBlur={this.finishEditingName}
                    onChange={this.updateEditingName}
                    onKeyDown={blurOnEnterKeyDown}
                    placeholder='Name this deployment'
                    type='text'
                    value={this.state.currentName || ''} />
                }
              </div>
            </div>
          </div>
          <div className='col-auto'>
            {(hideActionButtonsWhenRunning !== true) && (pipelineExecutable.startedAt !== undefined) && (pipelineExecutable.stoppedAt === undefined) &&
              <span className='uptime font-size-sm'>
                Started {this.getUptime(pipelineExecutable)}
              </span>
            }
            {pipelineExecutable.compiledAt === undefined && pipelineExecutable.buildFailed !== true &&
              <span className='compile-time right font-size-sm'>
                Building deployment...
              </span>
            }
            {pipelineExecutable.compiledAt === undefined && pipelineExecutable.buildFailed === true &&
              <span className='compile-time right d-flex align-items-center text-danger font-size-sm'>
                <AlertCircle className='feather-icon mr-2' />
                Building deployment failed
              </span>
            }
          </div>
          {(pipelineExecutable.compiledAt !== undefined) && (pipelineExecutable.buildFailed !== true) && (((pipelineExecutable.startedAt === undefined) && (pipelineExecutable.stoppedAt !== undefined)) || hideActionButtonsWhenRunning) &&
            <div className='col-auto font-size-sm text-gray'>
              {formatDate(pipelineExecutable.compiledAt)}
            </div>
          }
          <div className='col-auto'>
            {canEditPipeline && !isRunning && pipelineExecutable.compiledAt !== undefined && this.state.isStarting &&
              <button
                className='btn btn-white btn-sm'
                disabled={true}
                to={'/pipelines/' + pipelineExecutable.pipelineId}>
                <Play className='feather-icon' />
                Starting execution...
              </button>
            }
            {canEditPipeline && !isRunning && pipelineExecutable.compiledAt !== undefined && !this.state.isStarting &&
              <button
                className={startExecutionClassNames}
                disabled={activeDeploymentExists}
                onClick={this.startExecutable}>
                <Play className='feather-icon' />
                Start
              </button>
            }
            {!shownAsActiveDeployment && (pipelineExecutable.startedAt !== undefined) && (pipelineExecutable.stoppedAt === undefined) &&
              <button className='btn btn-white btn-sm' disabled={true}>
                Active deployment
              </button>
            }
            {!shownAsActiveDeployment && pipelineExecutable.yamlDescription !== undefined &&
              <button className='btn btn-white btn-sm ml-2' onClick={this.toggleShowYaml}>
                <Code className='feather-icon' /> YAML
              </button>
            }
            {canEditPipeline && !isRunning && !this.state.isStarting && this.props.deleteExecutableFunc !== undefined &&
              <button
                className='btn btn-white btn-sm ml-2'
                onClick={this.toggleDeleteModal}
                title='Delete deployment'>
                <Trash2 className='feather-icon' />
                <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
                  <Modal.Header closeButton>
                    <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Deleting a deployment is irreversible. Do you want to proceed?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='white' onClick={this.toggleDeleteModal}>
                      Cancel
                    </Button>
                    <Button variant='danger' onClick={this.deleteExecutable}>
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </button>
            }
            {isRunning && (hideActionButtonsWhenRunning !== true) &&
              <React.Fragment>
                <button
                  className='btn btn-white btn-sm mr-2'
                  onClick={this.props.toggleLogsFunc}
                  title={logsTitle}>
                  <Terminal className='feather-icon' />
                  Logs
                </button>
                {canEditPipeline && this.state.isStopping &&
                  <button
                    className='btn btn-white btn-sm'
                    disabled={true}
                    title='Halt deployment'>
                    <StopCircle className='feather-icon' />
                    Stopping execution...
                  </button>
                }
                {canEditPipeline && !this.state.isStopping &&
                  <button
                    className='btn btn-white btn-sm'
                    onClick={this.haltExecutable}
                    title='Halt deployment'>
                    <StopCircle className='feather-icon' />
                    Stop execution
                  </button>
                }
              </React.Fragment>
            }
          </div>
        </div>
        {this.state.showYaml &&
          <div className='row align-items-center mt-3 mx-0 p-0 border'>
            <AceEditor
                placeholder=''
                mode='yaml'
                theme='xcode'
                className=''
                readOnly={true}
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                fontSize={13}
                height='300px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={pipelineExecutable.yamlDescription}
                enableLiveAutocompletion={false}
                tabSize={2} />
          </div>
        }
      </li>
    );
  }
}

export default ExecutableListItem;
