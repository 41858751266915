import React, { Component } from 'react';

class PxaProductManagerConfig extends Component {
  render() {
    const {
      dataSink,
      handleChangeFunc,
      handleConfigChangeFunc
    } = this.props;

    const uriRestEndpoint = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.uriRestEndpoint !== undefined)
      ? dataSink.connectorConfig.uriRestEndpoint
      : '';

    const productCoreProperties = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.productCoreProperties !== undefined)
      ? dataSink.connectorConfig.productCoreProperties
      : 'description,name,pm_matrix_type,price,product_type,singleview_page,sku,tax_rate,teaser,usp';

    const productCorePropertiesRelationships = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.productCorePropertiesRelationships !== undefined)
      ? dataSink.connectorConfig.productCorePropertiesRelationships
      : 'product_type,singleview_page';

    const attributeTypeMappings = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.attributeTypeMappings !== undefined)
      ? dataSink.connectorConfig.attributeTypeMappings
      : '';

    const productAttributesToCopy = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.productAttributesToCopy !== undefined)
      ? dataSink.connectorConfig.productAttributesToCopy
      : '';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the token unless you want to change it.</span>)
      : (<span className='text-danger'>*</span>);

    return (
      <React.Fragment>
        <div className='form-group'>
          <label>URI of the REST endpoint<span className='text-danger'>*</span></label>
          <input
            type='text'
            className='form-control'
            name='uriRestEndpoint'
            onChange={handleConfigChangeFunc}
            value={uriRestEndpoint} />
        </div>
        <div className='form-group'>
          <label>Authentication token{editPasswordNotice}</label>
          <input
            type='password'
            className='form-control'
            name='password'
            onChange={handleChangeFunc}
            value={dataSink.password} />
        </div>
        <div className='form-group'>
          <label>Product core properties (comma-separated)</label>
          <input
            type='text'
            className='form-control'
            name='productCoreProperties'
            onChange={handleConfigChangeFunc}
            value={productCoreProperties} />
        </div>
        <div className='form-group'>
          <label>Product core properties, which are relationships (comma-separated)</label>
          <input
            type='text'
            className='form-control'
            name='productCorePropertiesRelationships'
            onChange={handleConfigChangeFunc}
            value={productCorePropertiesRelationships} />
        </div>
        <div className='form-group'>
          <label>Type mapping of attributes (comma-separated list of <span class="font-italic">attribute_name:type</span> pairs)</label>
          <input
            type='text'
            className='form-control'
            name='attributeTypeMappings'
            onChange={handleConfigChangeFunc}
            value={attributeTypeMappings} />
        </div>
        <div className='form-group'>
          <label>Product properties, which should be copied to remaining entities (comma-separated)</label>
          <input
            type='text'
            className='form-control'
            name='productAttributesToCopy'
            onChange={handleConfigChangeFunc}
            value={productAttributesToCopy} />
        </div>
      </React.Fragment>
    );
  }
}

export default PxaProductManagerConfig;
