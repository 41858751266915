import React, { Component } from 'react';
import ConfigForm           from '../../data_stores/ConfigForm';
import RetrieveTables       from '../RetrieveTables';
import TestConnectionButton from '../TestConnectionButton';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class BigQueryConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSink,
      dataSinks,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveTablesFunc
    } = this.props;

    const flatTables = dataSinks.tables
      .map(table => table.name)
      .sort();

    const retrievingTablesIsPossible =
      ![undefined, ''].includes(dataSink.password) &&
      ![undefined, ''].includes(dataSink.databaseName) &&
      ![undefined, ''].includes(dataSink.bucketName);

    const partitioningMethod = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.partitioningMethod !== undefined)
      ? dataSink.connectorConfig.partitioningMethod
      : 'ingestion-time-daily';

    const partitioningColumn = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.partitioningColumn !== undefined)
      ? dataSink.connectorConfig.partitioningColumn
      : '';

    const writeIntoExistingTable = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.writeIntoExistingTable !== undefined)
      ? '' + dataSink.connectorConfig.writeIntoExistingTable
      : 'true';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the credentials unless you want to change it.</span>)
      : (<span className='text-danger'>*</span>);

    const kafkaConnectConfig = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSink.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item' key={tab.name}>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment>Google Cloud credentials (JSON){editPasswordNotice}</React.Fragment>)}
              name='password'
              onChangeFunc={handleChangeFunc}
              type='password'
              value={dataSink.password} />
            <div className="alert alert-light" role="alert">
              Please make sure that the used service account is assigned to the GCP role <i>BigQueryEditor</i>.
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Project name'
              name='databaseName'
              onChangeFunc={handleChangeFunc}
              value={dataSink.databaseName} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Dataset name'
              name='bucketName'
              onChangeFunc={handleChangeFunc}
              value={dataSink.bucketName} />
            <div className='custom-control custom-switch d-flex align-items-center mb-4'>
              <input
                checked={[true, 'true'].includes(writeIntoExistingTable)}
                className='custom-control-input clickable'
                id='writeIntoExistingTable'
                name='writeIntoExistingTable'
                onChange={handleConfigChangeFunc}
                type='checkbox' />
              <label className='custom-control-label clickable' htmlFor='writeIntoExistingTable'>
                Write data into existing BigQuery table
              </label>
            </div>
            {![true, 'true'].includes(writeIntoExistingTable) &&
              <React.Fragment>
                <div class="alert alert-light" role="alert">
                  The connector will automatically create a table in the BigQuery dataset, named according to the below field <i>Table name</i>.
                  If you do not provide a table name, the connector will use the name <i>datacater-pipeline-$pipelineId</i>.
                  When starting the deployment of the pipeline for the first time, DataCater syncs the schema to BigQuery.
                  Subsequent schema changes need to be performed manually in BigQuery.
                </div>
                <ConfigForm
                  errorMessages={errorMessages}
                  label='Table name'
                  name='databaseSchemaName'
                  onChangeFunc={handleChangeFunc}
                  subLabel={(<React.Fragment>Leave empty to use <i>datacater-pipeline-[pipeline_id]</i> as table name.</React.Fragment>)}
                  value={dataSink.databaseSchemaName} />
              </React.Fragment>
            }
            {[true, 'true'].includes(writeIntoExistingTable) &&
              <React.Fragment>
                <RetrieveTables
                  dataSink={dataSink}
                  dataSinks={dataSinks}
                  errorMessages={errorMessages}
                  failedFetchErrorMessage='Fetching the table names from the BigQuery dataset failed. Please check your connection credentials.'
                  handleChangeFunc={handleChangeFunc}
                  missingOptionsErrorMessage='Please provide your Google Cloud credentials, the name of the BigQuery project, and the name of the BigQuery dataset.'
                  resetRetrieveTablesFunc={resetRetrieveTablesFunc}
                  retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSinkFunc}
                  retrievingTablesIsPossible={retrievingTablesIsPossible}
                  tableAttributeName='databaseSchemaName'
                  tables={flatTables} />
                {![undefined, ''].includes(dataSink.databaseSchemaName) &&
                  <React.Fragment>
                    <div className='form-group'>
                      <label>Table partitioning</label>
                      <select
                        className='custom-select'
                        name='partitioningMethod'
                        onChange={handleConfigChangeFunc}
                        value={partitioningMethod}>
                        <option value='ingestion-time-daily'>By ingestion time (daily)</option>
                        <option value='timestamp-column'>By timestamp column (daily)</option>
                        <option value='no-partitioning'>No partitioning</option>
                      </select>
                    </div>
                    {partitioningMethod === 'timestamp-column' &&
                      <ConfigForm
                        errorMessages={errorMessages}
                        isRequired={true}
                        label='Timestamp column to be used for partitioning'
                        name='partitioningColumn'
                        onChangeFunc={handleConfigChangeFunc}
                        value={partitioningColumn} />
                    }
                  </React.Fragment>
                }
              </React.Fragment>
            }
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSinks={dataSinks}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default BigQueryConfig;
