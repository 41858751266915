import React, { Component } from 'react';
import { Key } from 'react-feather';
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { humanizeDataType } from '../../../helpers/humanizeDataType';
import { isValidAttributeName } from '../../../helpers/isValidAttributeName';
import '../../../scss/grid/statistics.scss';

class TableHeaderCell extends Component {
  render() {
    const {
      className,
      column
    } = this.props;

    const {
      attribute,
      attributeIndex,
      handleAttributeChangeFunc,
      selectAttributeAsPrimaryKeyFunc
    } = column;

    if (column.isRowNumber) {
      return (
        <div className={className}>
        </div>
      );
    } else {
      let textfieldClassNames = 'form-control';

      if (!isValidAttributeName(attribute.name)) {
        textfieldClassNames += ' datacater-invalid-attribute-name';
      }

      return (
        <div className={className + ' d-flex align-items-center'}>
          <div className='sample-cell p-2'>
            <div className='row align-items-center m-0'>
              <div className='col overflow-hidden text-nowrap p-0'>
                {handleAttributeChangeFunc !== undefined &&
                  <input
                    className={textfieldClassNames}
                    data-attribute-pos={attributeIndex - 1}
                    name='name'
                    onChange={handleAttributeChangeFunc}
                    placeholder='Please provide an attribute name.'
                    type='text'
                    value={attribute.name || ''} />
                }
                {handleAttributeChangeFunc === undefined &&
                  <span>{attribute.name}</span>
                }
              </div>
              <div className='col-auto pl-2 px-1'>
                {humanizeDataType(attribute.dataType)}
              </div>
              {['double', 'float', 'int', 'long', 'string'].includes(attribute.dataType) &&
                <div className='col-auto px-1'>
                  <div className='avatar avatar-sm'>
                    {attribute.isKey &&
                      <div className='avatar-title font-size-lg bg-primary rounded-circle text-white'>
                        <OverlayTrigger
                            placement='bottom'
                            delay={{ show: 100, hide: 100 }}
                            overlay={(props) => (<Tooltip {...props}>Primary key.</Tooltip>)}>
                          <Key className='feather-icon' />
                        </OverlayTrigger>
                      </div>
                    }
                    {!attribute.isKey &&
                      <div className='avatar-title font-size-lg bg-primary-soft rounded-circle text-primary clickable'>
                        <OverlayTrigger
                            placement='bottom'
                            delay={{ show: 100, hide: 100 }}
                            overlay={(props) => (<Tooltip {...props}>Click to use as primary key for this data source.</Tooltip>)}>
                          <Key className='feather-icon' onClick={(event) => selectAttributeAsPrimaryKeyFunc(attributeIndex -1)}/>
                        </OverlayTrigger>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      );
    }
  }
}

export default TableHeaderCell;
