import React,
       { Component }     from 'react';
import { Link }          from 'react-router-dom';
import {
  AlertCircle,
  Check,
  Database,
  X
} from 'react-feather';
import Dropzone          from 'react-dropzone'
import { ProgressBar }   from 'react-bootstrap';
import FlatFileListItem  from '../../../../flat_files/FlatFileListItem';
import { formatInteger } from '../../../../../helpers/formatInteger';

class FlatFileDataSource extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive:         false,
      isUploading:      false,
      showFlatFileList: false
    };

    this.onDrop             = this.onDrop.bind(this);
    this.onDragEnter        = this.onDragEnter.bind(this);
    this.onDragLeave        = this.onDragLeave.bind(this);
    this.renderUploadButton = this.renderUploadButton.bind(this);
    this.toggleFlatFileList = this.toggleFlatFileList.bind(this);
    this.resetUpload        = this.resetUpload.bind(this);
  }

  onDrop(files) {
    this.props.resetFileUploadFunc();

    if (files.length > 0) {
      const fileSize = files[0].size;
      const fileSizeLimit = 500 * 1024 * 1024;

      // check whether file size exceeds limit
      if (fileSize > fileSizeLimit) {
        this.setState({
          isActive: false
        });
      } else {
        this.setState({
          fileName:    files[0].name,
          fileSize:    files[0].size,
          isActive:    false,
          isUploading: true
        });
        this.props.ingestDataFunc(files);
      }
    } else {
      this.setState({
        isActive: false
      });
    }
  }

  onDragEnter() {
    this.setState({
      isActive: true
    });
  }

  onDragLeave() {
    this.setState({
      isActive: false
    });
  }

  fileSizeInMb(fileSize) {
    if (fileSize !== undefined && !isNaN(fileSize)) {
      return (fileSize / 1024 / 1024).toFixed(2) + 'MB';
    }
  }

  renderUploadButton(isUploading, flatFileUploadFailed, flatFileUploadFailedMessage) {
    if (isUploading) {
      return (
        <button disabled={true} className='btn btn-white btn-sm'>
          <span className='spinner-border mr-3 float-left mt-1' role='status'></span>
          Uploading data...
        </button>
      );
    } else {
      return (
        <button className='btn btn-white btn-sm'>
          Upload new data
        </button>
      );
    }
  }

  toggleFlatFileList(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showFlatFileList: !this.state.showFlatFileList
    });
  }

  resetUpload(event) {
    this.setState({
      isUploading: false
    });
    this.props.resetFileUploadFunc();
  }

  render() {
    const {
      dataSource,
      dataSources,
      failedLoadingSampleRecords,
      pipeline,
      pipelines
    } = this.props;

    const flatFiles = pipelines.flatFiles
      .sort((a, b) => b.id - a.id);

    let flatFile = undefined;
    let flatFileUploadFailed = undefined;
    let flatFileUploadFailedMessage = undefined;
    let flatFileHasBeenProcessed = undefined;

    if (this.props.flatFiles !== undefined) {
      flatFile = this.props.flatFiles.flatFile;
      flatFileUploadFailed =
        this.props.flatFiles.fileUploadSucceeded === false ||
        (flatFile !== undefined && flatFile.processingStatus === 'failed');
      flatFileUploadFailedMessage = (flatFile !== undefined)
        ? flatFile.errorMessage
        : 'Upload failed.';

      flatFileHasBeenProcessed =
        flatFile !== undefined &&
        flatFile.processingStatus !== undefined;
    }

    const isUploading =
      (this.props.flatFiles.isUploadingFiles || this.state.isUploading) &&
      flatFileHasBeenProcessed === false &&
      !this.props.profilingFailed;

    let classNames = 'flat-file-drop-zone';
    if (this.state.isActive) {
      classNames += ' active-flat-file-drop-zone';
    }

    return (
      <div className='card mb-4'>
        <div className='card-header' style={{ height: '50px' }}>
          <h4 className='card-header-title d-flex align-items-center'>
            <Database className='feather-icon mr-2' />
            Data source
          </h4>
          {pipeline.dataSourceStreamId !== undefined &&
            <a className='btn btn-white btn-sm mr-2' href={`/streams/${pipeline.dataSourceStreamId}`}>View stream</a>
          }
          {pipeline.joinedDataSourceStreamId !== undefined &&
            <a className='btn btn-white btn-sm mr-2' href={`/streams/${pipeline.joinedDataSourceStreamId}`}>View join stream</a>
          }
          <button
            className='btn btn-white btn-sm'
            onClick={this.props.moveToSampleDataFunc}>
            Explore data
          </button>
          <button
            className='btn btn-white btn-sm ml-2'
            onClick={this.props.moveToJoinPageFunc}>
            Manage join
          </button>
        </div>
        {!isUploading && flatFileUploadFailed &&
          <div className='card-body text-bg-danger p-3 pl-4'>
            <div className='row align-items-center'>
              <div className='col'>
                <AlertCircle className='feather-icon mr-2' />
                The upload has failed: {flatFileUploadFailedMessage}
              </div>
              <div className='col-auto'>
                <X
                  className='feather-icon mr-2 clickable'
                  onClick={this.resetUpload} />
              </div>
            </div>
          </div>
        }
        {!isUploading && !flatFileUploadFailed && this.props.flatFiles.fileUploadSucceeded &&
          <div className='card-body text-bg-success p-3 pl-4'>
            <div className='row align-items-center'>
              <div className='col'>
                <Check className='feather-icon mr-2' />
                The file has been uploaded successfully and is now being ingested into the pipeline.
              </div>
              <div className='col-auto'>
                <X
                  className='feather-icon mr-2 clickable'
                  onClick={this.resetUpload} />
              </div>
            </div>
          </div>
        }
        {failedLoadingSampleRecords === true &&
          <div className='card-body text-bg-danger p-3 pl-4 d-flex align-items-center'>
            <AlertCircle className='feather-icon mr-2' />
            We couldn't extract sample records from the data source. Please check the health of the data source and consider recreating both the data source and the pipeline.
          </div>
        }
        {isUploading &&
          <div className='card-body p-0'>
            <ProgressBar className='w-100 rounded-0' now={this.props.flatFiles.uploadProgress} label={`${this.props.flatFiles.uploadProgress}%`} />
          </div>
        }
        <div className='card-body p-0'>
          <Dropzone
            multiple={false}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}>
            {({getRootProps, getInputProps}) => (
              <section className={classNames}>
                <div className='drop-zone-wrapper' {...getRootProps({onClick: (event) => { if (event.target.textContent !== 'Upload new data') { event.stopPropagation();}}})}>
                  <input {...getInputProps()} />
                  <div className='row m-0 align-items-center'>
                    <div className='col-auto pl-4 source-type-logos'>
                      <div className='logo-wrap'>
                        <img
                          alt={dataSource.sourceType}
                          src={'/images/' + dataSource.sourceType + '-logo.png'} />
                      </div>
                    </div>
                    <div className='col'>
                      <h4 className='mb-0 name'>
                        <Link to={'/data_sources/' + dataSource.id} className='mr-4'>
                          #{dataSource.id}: {dataSource.name}
                        </Link>
                        <button
                          className='btn btn-white btn-sm mr-2'
                          onClick={this.toggleFlatFileList}>
                          {!this.state.showFlatFileList && 'Show uploads'}
                          {this.state.showFlatFileList && 'Hide uploads'}
                        </button>
                        {this.renderUploadButton(isUploading, flatFileUploadFailed, flatFileUploadFailedMessage)}
                      </h4>
                    </div>
                    <div className='col-auto pr-4'>
                      {dataSources.numberOfRecords !== undefined && formatInteger(dataSources.numberOfRecords) + ' records'}
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        {this.props.flatFiles !== undefined && flatFiles.length > 0 && this.state.showFlatFileList &&
          <div className='card-body border-top'>
            <ul className='list-group list-group-flush list my-n4'>
              {flatFiles.map((flatFile, index) => (
                <FlatFileListItem
                  flatFile={flatFile}
                  key={index} />
              ))}
            </ul>
          </div>
        }
      </div>
    );
  }
}

export default FlatFileDataSource;
