const pipelines = (state, action) => {
  const initialState = {
    activePipelines:                      [],
    duplicatedPipeline:                   undefined,
    duplicatingPipeline:                  false,
    errorMessage:                         undefined,
    exportingPipeline:                    false,
    fetchingFlatFiles:                    false,
    fetchingKafkaSourceConnector:         false,
    fetchingKafkaJoinedSourceConnector:   false,
    fetchingKafkaSinkConnector:           false,
    fetchingPipelines:                    false,
    fetchingPipelineLogs:                 false,
    fetchingPipelineLogsFailed:           false,
    flatFiles:                            [],
    kafkaJoinedSourceConnector:           undefined,
    kafkaSourceConnector:                 undefined,
    kafkaSinkConnector:                   undefined,
    metrics:                              undefined,
    pipelines:                            [],
    pipeline:                             undefined,
    pipelineExecutables:                  [],
    pipelineLogs:                         undefined,
    recreatingKafkaSourceConnector:       false,
    resettingPipeline:                    false,
    resettingOffsetOfPipeline:            false,
    resettingOffsetOfSinkConnector:       false,
    restartingKafkaJoinedSourceConnector: false,
    restartingKafkaSourceConnector:       false,
    restartingKafkaSinkConnector:         false,
    startingPipelineExecutableFailed:     false,
    stoppingPipelineExecutableFailed:     false,
    updateConflict:                       false,
    updatingJoinedDataSourceFailure:      undefined,
    updatingPipeline:                     false,
    yamlRepresentation:                   undefined
  };
  const localState = (state || initialState);

  switch (action.type) {
    case 'REQUEST_PIPELINES':
      return {
        ...state,
        exportingPipeline:   false,
        fetchingPipelines:   true,
        pipelineExecutables: []
      };
    case 'RECEIVE_PIPELINES':
      return {
        ...state,
        fetchingPipelines:   false,
        pipelines:           action.pipelines,
        pipelineExecutables: []
      };
    case 'REQUEST_ACTIVE_PIPELINES':
      return {
        ...state,
        fetchingPipelines: true,
        activePipelines:   []
      };
    case 'RECEIVE_ACTIVE_PIPELINES':
      return {
        ...state,
        fetchingPipelines: false,
        activePipelines:   action.pipelines,
      };
    case 'REQUEST_PIPELINE':
      return {
        ...state,
        duplicatedPipeline:         action.pipeline,
        exportingPipeline:          false,
        fetchingPipelines:          true,
        fetchingPipelineLogsFailed: false,
        metrics:                    undefined,
        pipelineLogs:               undefined,
        updatingPipeline:           false
      };
    case 'RECEIVE_PIPELINE':
      return {
        ...state,
        fetchingPipelines:          false,
        fetchingPipelineLogsFailed: false,
        pipeline:                   action.pipeline,
        updatingPipeline:           false
      };
    case 'REQUEST_PIPELINE_FLAT_FILES':
      return {
        ...state,
        fetchingFlatFiles: true
      };
    case 'RECEIVE_PIPELINE_FLAT_FILES':
      return {
        ...state,
        fetchingFlatFiles: false,
        flatFiles:         action.flatFiles
      };
    case 'REQUEST_PIPELINE_JOINED_SOURCE_CONNECTOR':
      return {
        ...state,
        fetchingKafkaJoinedSourceConnector: true
      };
    case 'RECEIVE_PIPELINE_JOINED_SOURCE_CONNECTOR':
      return {
        ...state,
        fetchingKafkaJoinedSourceConnector: false,
        kafkaJoinedSourceConnector:         action.joinedSourceConnector
      };
    case 'REQUEST_PIPELINE_SOURCE_CONNECTOR':
      return {
        ...state,
        fetchingKafkaSourceConnector: true
      };
    case 'RECEIVE_PIPELINE_SOURCE_CONNECTOR':
      return {
        ...state,
        fetchingKafkaSourceConnector: false,
        kafkaSourceConnector:         action.sourceConnector
      };
    case 'REQUEST_PIPELINE_SINK_CONNECTOR':
      return {
        ...state,
        fetchingKafkaSinkConnector: true
      };
    case 'RECEIVE_PIPELINE_SINK_CONNECTOR':
      return {
        ...state,
        fetchingKafkaSinkConnector: false,
        kafkaSinkConnector:         action.sinkConnector
      };
    case 'REQUEST_RECREATE_PIPELINE_SOURCE_CONNECTOR':
      return {
        ...state,
        recreatingKafkaSourceConnector: true
      };
    case 'RECEIVE_RECREATE_PIPELINE_SOURCE_CONNECTOR':
      return {
        ...state,
        kafkaSourceConnector:           action.sourceConnector,
        recreatingKafkaSourceConnector: false
      };
    case 'REQUEST_RESET_OFFSET_PIPELINE':
      return {
        ...state,
        resettingOffsetOfPipeline: true
      };
    case 'RECEIVE_RESET_OFFSET_PIPELINE':
      return {
        ...state,
        metrics:                   action.metrics,
        resettingOffsetOfPipeline: false
      };
    case 'REQUEST_RESET_OFFSET_PIPELINE_SINK_CONNECTOR':
      return {
        ...state,
        resettingOffsetOfSinkConnector: true
      };
    case 'RECEIVE_RESET_OFFSET_PIPELINE_SINK_CONNECTOR':
      return {
        ...state,
        metrics:                        action.metrics,
        resettingOffsetOfSinkConnector: false
      };
    case 'REQUEST_RESTART_PIPELINE_JOINED_SOURCE_CONNECTOR':
      return {
        ...state,
        restartingKafkaJoinedSourceConnector: true
      };
    case 'RECEIVE_RESTART_PIPELINE_JOINED_SOURCE_CONNECTOR':
      return {
        ...state,
        kafkaJoinedSourceConnector:           action.joinedSourceConnector,
        restartingKafkaJoinedSourceConnector: false
      };
    case 'REQUEST_RESTART_PIPELINE_SOURCE_CONNECTOR':
      return {
        ...state,
        restartingKafkaSourceConnector: true
      };
    case 'RECEIVE_RESTART_PIPELINE_SOURCE_CONNECTOR':
      return {
        ...state,
        kafkaSourceConnector:           action.sourceConnector,
        restartingKafkaSourceConnector: false
      };
    case 'REQUEST_RESTART_PIPELINE_SINK_CONNECTOR':
      return {
        ...state,
        restartingKafkaSinkConnector: true
      };
    case 'RECEIVE_RESTART_PIPELINE_SINK_CONNECTOR':
      return {
        ...state,
        kafkaSinkConnector:           action.sinkConnector,
        restartingKafkaSinkConnector: false
      };
    case 'REQUEST_ADD_PIPELINE':
      return {
        ...state,
        errorMessage:     undefined,
        pipeline:         undefined,
        updatingPipeline: false
      };
    case 'RECEIVE_ADD_PIPELINE':
      return {
        ...state,
        errorMessage:     undefined,
        pipeline:         action.pipeline,
        updatingPipeline: false
      };
    case 'RECEIVE_ADD_PIPELINE_FAILED':
      return {
        ...state,
        errorMessage:     action.errorMessage,
        pipeline:         undefined,
        updatingPipeline: false
      };
    case 'REQUEST_UPDATE_PIPELINE':
      return {
        ...state,
        updatingPipeline: true
      };
    case 'RECEIVE_UPDATE_PIPELINE':
      return {
        ...state,
        pipeline:         action.pipeline,
        updatingPipeline: false
      };
    case 'RECEIVE_UPDATE_PIPELINE_CONFLICT':
      return {
        ...state,
        updateConflict:   true,
        updatingPipeline: false
      };
    case 'REQUEST_TRANSFER_PIPELINE_TO_PROJECT':
      return {
        ...state,
        errorMessage:     undefined,
        updatingPipeline: true
      };
    case 'RECEIVE_TRANSFER_PIPELINE_TO_PROJECT':
      return {
        ...state,
        errorMessage:     undefined,
        pipeline:         action.pipeline,
        updatingPipeline: false
      };
    case 'RECEIVE_TRANSFER_PIPELINE_TO_PROJECT_FAILED':
      return {
        ...state,
        errorMessage:     action.errorMessage,
        updatingPipeline: false
      };
    case 'REQUEST_UPDATE_DATA_SINK_PIPELINE':
      return {
        ...state,
        updatingPipeline: true
      };
    case 'RECEIVE_UPDATE_DATA_SINK_PIPELINE':
      return {
        ...state,
        pipeline:         action.pipeline,
        updatingPipeline: false
      };
    case 'REQUEST_UPDATE_JOINED_DATA_SOURCE_PIPELINE':
      return {
        ...state,
        updatingJoinedDataSourceFailure: undefined,
        updatingPipeline:                true
      };
    case 'RECEIVE_UPDATE_JOINED_DATA_SOURCE_PIPELINE':
      return {
        ...state,
        pipeline:                        action.pipeline,
        updatingJoinedDataSourceFailure: undefined,
        updatingPipeline:                false
      };
    case 'RECEIVE_UPDATE_JOINED_DATA_SOURCE_PIPELINE_FAILURE':
      return {
        ...state,
        updatingJoinedDataSourceFailure: action.error,
        updatingPipeline:                false
      };
    case 'REQUEST_RESET_PIPELINE':
      return {
        ...state,
        resettingPipeline: true
      };
    case 'RECEIVE_RESET_PIPELINE':
      return {
        ...state,
        resettingPipeline: false
      };
    case 'REQUEST_EXPORT_PIPELINE':
      return {
        ...state,
        exportingPipeline: true
      };
    case 'RECEIVE_EXPORT_PIPELINE':
      return {
        ...state,
        exportingPipeline: false
      };
    case 'REQUEST_DUPLICATE_PIPELINE':
      return {
        ...state,
        duplicatingPipeline: true,
        duplicatedPipeline:  undefined
      };
    case 'RECEIVE_DUPLICATE_PIPELINE':
      return {
        ...state,
        duplicatingPipeline: false,
        duplicatedPipeline:  action.pipeline
      };
    case 'RECEIVE_PIPELINE_EXECUTABLES':
      return {
        ...state,
        pipelineExecutables:              action.pipelineExecutables,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'REQUEST_COMPILE_PIPELINE_EXECUTABLE':
      return {
        ...state,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'RECEIVE_COMPILE_PIPELINE_EXECUTABLE':
      return {
        ...state,
        pipelineExecutables:              localState.pipelineExecutables.concat([action.pipelineExecutable]),
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'REQUEST_START_PIPELINE_EXECUTABLE':
      return {
        ...state,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'RECEIVE_START_PIPELINE_EXECUTABLE':
      const startExecutableIdx = localState.pipelineExecutables
        .findIndex(_ => _.id === action.pipelineExecutable.id);
      if (startExecutableIdx > 0) {
        localState.pipelineExecutables[startExecutableIdx] = action.pipelineExecutable;
      }
      return {
        ...state,
        pipelineExecutables:              localState.pipelineExecutables,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'FAILED_START_PIPELINE_EXECUTABLE':
      return {
        ...state,
        startingPipelineExecutableFailed: true,
        stoppingPipelineExecutableFailed: false
      };
    case 'REQUEST_HALT_PIPELINE_EXECUTABLE':
      return {
        ...state,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'RECEIVE_HALT_PIPELINE_EXECUTABLE':
      const haltExecutableIdx = localState.pipelineExecutables
        .findIndex(_ => _.id === action.pipelineExecutable.id);
      if (haltExecutableIdx > 0) {
        localState.pipelineExecutables[haltExecutableIdx] = action.pipelineExecutable;
      }
      return {
        ...state,
        pipelineExecutables:              localState.pipelineExecutables,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'FAILED_HALT_PIPELINE_EXECUTABLE':
      return {
        ...state,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: true
      };
    case 'RECEIVE_DELETE_PIPELINE_EXECUTABLE':
      const pipelineExecutables = localState.pipelineExecutables.filter(_ => _.id !== action.pipelineExecutableId);
      return {
        ...state,
        pipelineExecutables:              pipelineExecutables,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'REQUEST_PIPELINE_EXECUTABLE_LOGS':
      return {
        ...state,
        fetchingPipelineLogs:             true,
        fetchingPipelineLogsFailed:       false,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'RECEIVE_PIPELINE_EXECUTABLE_LOGS':
      return {
        ...state,
        fetchingPipelineLogs:             false,
        fetchingPipelineLogsFailed:       false,
        pipelineLogs:                     action.pipelineLogs,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'RECEIVE_PIPELINE_EXECUTABLE_LOGS_FAILED':
      return {
        ...state,
        fetchingPipelineLogs:             false,
        fetchingPipelineLogsFailed:       true,
        startingPipelineExecutableFailed: false,
        stoppingPipelineExecutableFailed: false
      };
    case 'REQUEST_PIPELINE_METRICS':
      return {
        ...state
      };
    case 'RECEIVE_PIPELINE_METRICS':
      return {
        ...state,
        metrics: action.metrics
      };
    case 'RESET_PIPELINE_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: undefined
      };
    case 'REQUEST_PIPELINE_YAML':
      return {
        ...state,
        yamlRepresentation: undefined,
        yamlError: undefined
      };
    case 'RECEIVE_PIPELINE_YAML':
      return {
        ...state,
        yamlRepresentation: action.yaml,
        yamlError: false
      };
    case 'RECEIVE_PIPELINE_YAML_ERROR':
      return {
        ...state,
        yamlRepresentation: action.yaml,
        yamlError: true
      }
    default:
      return (state || initialState);
  }
}

export default pipelines
