import React, { Component } from 'react';
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';

class RetrieveColumns extends Component {
  constructor(props) {
    super(props);

    this.fetchColumnNames = this.fetchColumnNames.bind(this);
  }

  fetchColumnNames(event) {
    event.preventDefault();

    this.props.retrieveColumnsFromDataSourceFunc(this.props.dataSource);
  }

  render() {
    const {
      columns,
      columnAttributeName,
      dataSource,
      dataSources,
      failedFetchErrorMessage,
      errorMessages,
      handleChangeFunc,
      isRequired,
      label,
      missingOptionsErrorMessage,
      resetRetrieveColumnsFunc,
      retrievingColumnsIsPossible,
      value
    } = this.props;

    const selectClassNames = ((errorMessages !== undefined) && (errorMessages[columnAttributeName] !== undefined))
      ? 'custom-select is-invalid'
      : 'custom-select';

    const inputClassNames = ((errorMessages !== undefined) && (errorMessages[columnAttributeName] !== undefined))
      ? 'form-control is-invalid'
      : 'form-control';

    return (
      <div className='form-group'>
        <label>{label}{(isRequired === true) && <span className='text-danger'>*</span>}</label>
        <div className='row'>
          <div className='col'>
            {columns.length > 0 &&
              <select
                className={selectClassNames}
                name={columnAttributeName}
                onChange={handleChangeFunc}
                value={value}>
                {![undefined, ''].includes(dataSource[columnAttributeName]) && !columns.includes(dataSource[columnAttributeName]) &&
                  <option value={dataSource[columnAttributeName]}>{dataSource[columnAttributeName]}</option>
                }
                <option value=''>--- Choose a column</option>
                {columns.map((column, idx) => (
                  <option key={idx} value={column}>{column}</option>
                ))}
              </select>
            }
            {columns.length === 0 &&
              <input
                type='text'
                className={inputClassNames}
                name={columnAttributeName}
                onChange={handleChangeFunc}
                value={value} />
            }
          </div>
          <div className='col-auto'>
            {dataSources.fetchingColumns &&
              <button className='btn btn-white' disabled={true}>
                Fetching column names...
              </button>
            }
            {!dataSources.fetchingColumns && columns.length === 0 && retrievingColumnsIsPossible &&
              <button className='btn btn-white' onClick={this.fetchColumnNames}>
                Fetch column names
              </button>
            }
            {!dataSources.fetchingColumns && columns.length === 0 && !retrievingColumnsIsPossible &&
              <OverlayTrigger
                  placement='bottom'
                  delay={{ show: 100, hide: 100 }}
                  overlay={(props) => (<Tooltip {...props}>{missingOptionsErrorMessage}</Tooltip>)}>
                <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                  <button
                    className='btn btn-white'
                    disabled={true}
                    style={{ pointerEvents : 'none' }}>
                    Fetch column names
                  </button>
                </div>
              </OverlayTrigger>
            }
            {!dataSources.fetchingColumns && columns.length > 0 &&
              <button className='btn btn-white' onClick={resetRetrieveColumnsFunc}>
                Reset column names
              </button>
            }
          </div>
        </div>
        {((errorMessages !== undefined) && (errorMessages[columnAttributeName] !== undefined)) &&
          <p className='mt-2 mb-0 font-weight-bold text-danger'>{errorMessages[columnAttributeName]}</p>
        }
        {dataSources.fetchingColumnsFailed &&
          <p className='mt-2 mb-0 font-weight-bold text-danger'>{failedFetchErrorMessage}</p>
        }
      </div>
    );
  }
}

export default RetrieveColumns;
