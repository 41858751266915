import React, { Component } from 'react';
import { GitMerge } from 'react-feather';
import DataSourceLogo from '../../data_sources/DataSourceLogo';

class PipelineDesignerDataSource extends Component {
  render () {
    const {
      dataSource,
      joinedDataSource
    } = this.props;

    return (
      <div className='datacater-pipeline-designer-third-row row align-items-center'>
        <div className='col-auto d-flex align-items-center h-75 p-0'>
          <DataSourceLogo className='datacater-pipeline-designer-third-row-data-store-logo' dataSource={dataSource} />
        </div>
        <div className='col-auto pr-0'>
          <h4 className='mb-0'>
            #{dataSource.id}: {dataSource.name}
          </h4>
        </div>
        {joinedDataSource !== undefined &&
          <React.Fragment>
            <div className='col-auto'>
              <GitMerge className='feather-icon' />
            </div>
            <div className='col-auto d-flex align-items-center h-75 p-0'>
              <DataSourceLogo className='datacater-pipeline-designer-third-row-data-store-logo' dataSource={joinedDataSource} />
            </div>
            <div className='col'>
              <h4 className='mb-0'>
                #{joinedDataSource.id}: {joinedDataSource.name}
              </h4>
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

export default PipelineDesignerDataSource;
