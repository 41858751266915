import React, { Component } from 'react';
import { connect }          from 'react-redux';

class NotFoundError extends Component {
  componentDidMount() {
    // do a small trick to add CSS classes to dom elements outside of
    // the application's root element
    const bodyDomElement = document.querySelector('body');
    const rootDomElement = document.querySelector('#root');
    bodyDomElement.className = 'd-flex align-items-center bg-auth border-top border-top-4 border-primary';
    rootDomElement.classList.add('container');
  }

  componentWillUnmount() {
    // get rid of the added CSS classes
    const bodyDomElement = document.querySelector('body');
    const rootDomElement = document.querySelector('#root');
    bodyDomElement.className = '';
    rootDomElement.classList.remove('container');
  }

  render() {
    return (
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0'>
            <div className='text-center'>
              <img src='/images/illustrations/warning.png' alt='Error' className='img-fluid' />
            </div>
          </div>
          <div className='col-12 col-md-5 col-xl-4 order-md-1 my-5'>
            <div className='text-center'>
              <h6 className='text-uppercase text-muted mb-4'>
                404 error
              </h6>
              <h1 className='display-4 mb-4'>
                We could not find the requested resource.
              </h1>
              <a href='/' className='btn btn-lg btn-primary'>
                Return to DataCater
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
  }
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundError);
