import React, { Component } from 'react';
import {
  Button,
  Modal
} from 'react-bootstrap';
import {
  ArrowRight,
  Filter,
  Key,
  Package,
  Tag,
  Trash
} from 'react-feather';
import DataSourceLogo       from '../../../data_sources/DataSourceLogo';
import { humanizeDataType } from '../../../../helpers/humanizeDataType';
import FrequentValues       from '../../../data_profiler/FrequentValues';
import AttributeStats       from '../../../data_profiler/AttributeStats';
import '../../../../scss/grid/statistics.scss';

class TableHeaderCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false
    };

    this.openContextBar    = this.openContextBar.bind(this);
    this.renderAttribute   = this.renderAttribute.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
  }

  renderAttributeName(attribute, dataSource) {
    if (attribute.oldName === undefined) {
      return (
        <div className='d-flex align-items-center'>
          {dataSource !== undefined &&
            <span className='datacater-source-id text-black-50'>
              #{dataSource.dataSourceId}.
            </span>
          }
          {[undefined, ''].includes(attribute.name) &&
            <span className='font-italic'>
              [new attribute]
            </span>
          }
          {![undefined, ''].includes(attribute.name) && attribute.name }
        </div>
      );
    } else {
      return (
        <div className='d-flex align-items-center'>
          <span className='datacater-source-id text-black-50'>
            #{dataSource.dataSourceId}.
          </span>
          {attribute.oldName}
          <ArrowRight className='feather-icon'/>
          {attribute.name}
        </div>
      );
    }
  }

  renderAttributeDataType(attribute) {
    if (attribute.oldDataType === undefined) {
      return humanizeDataType(attribute.dataType);
    } else {
      return (
        <React.Fragment>
          {humanizeDataType(attribute.oldDataType)}
          <ArrowRight className='feather-icon'/>
          {humanizeDataType(attribute.dataType)}
        </React.Fragment>
      );
    }
  }

  openContextBar(attribute) {
    const {
      currentStep,
      editColumnFunc,
      pipelineStep } = this.props.column;

    if (currentStep === 0) {
      editColumnFunc(
        parseInt(attribute.id),
        undefined,
        'assertion');
    } else if (currentStep > 0) {
      editColumnFunc(
        parseInt(attribute.id),
        pipelineStep.sortPosition,
        'transformation');
    }
  }

  renderAttribute(attribute, idx) {
    const {
      assertionOfCurrentStep,
      attributeProfiles,
      canEditPipeline,
      currentStep,
      dataSourceMappings,
      introducedAttributes,
      removeColumnFunc,
      showStatistics,
      transformationOfCurrentStep
    } = this.props.column;

    let sampleCellClassNames = 'sample-cell py-2 px-1';

    let assertion = undefined;
    if (
      (currentStep === 0) &&
      (assertionOfCurrentStep !== undefined) &&
      (![undefined, ''].includes(assertionOfCurrentStep.assertionFilter))
    ) {
      const filterKey = assertionOfCurrentStep.assertionFilter;
      assertion = this.props.column.filters
        .find(filter => filter.key === filterKey);
    }

    let transformation = undefined;
    if (
      (currentStep > 0) &&
      (transformationOfCurrentStep !== undefined) &&
      (![undefined, ''].includes(transformationOfCurrentStep.transformationAction))
    ) {
      const transformationKey = transformationOfCurrentStep.transformationAction;
      transformation = this.props.column.transformers
        .find(transformation => transformation.key === transformationKey);
    }

    const dataSourceMapping = dataSourceMappings !== undefined
      ? dataSourceMappings[attribute.dataSourceProfileId]
      : undefined;

    return (
      <React.Fragment>
        <div
          className={sampleCellClassNames}
          key={idx}>
          <div className='row m-0 align-items-center'>
            {(dataSourceMapping !== undefined) && (attribute.isVirtual !== true) &&
              <div className='col-auto p-0'>
                <DataSourceLogo className='datacater-source-logo' dataSource={{'sourceType': dataSourceMapping.dataSourceType}} />
              </div>
            }
            <div className='col overflow-hidden text-nowrap d-flex align-items-center px-2'>
              {this.renderAttributeName(attribute, dataSourceMapping)}
            </div>
            <div className='col-auto d-flex align-items-center px-2'>
              {attribute.isKey &&
                <Key className='feather-icon mr-2' title='Primary key'/>
              }
              {attribute.isVirtual &&
                <Tag className='feather-icon mr-1' title='Virtual attribute'/>
              }
              {canEditPipeline && introducedAttributes.includes(parseInt(attribute.id)) &&
                <React.Fragment>
                  <Trash
                    className='feather-icon clickable mr-1'
                    onClick={this.toggleDeleteModal}
                    title='Delete Virtual Attribute'/>
                  <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
                    <Modal.Header closeButton>
                      <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Removing a virtual attribute is irreversible.
                      </p>
                      <p className='mb-0'>
                        Do you want to proceed?
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="white" onClick={this.toggleDeleteModal}>
                        Cancel
                      </Button>
                      <Button
                        data-attribute-id={attribute.id}
                        onClick={removeColumnFunc}
                        variant="danger">
                        Proceed
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </React.Fragment>
              }
              {this.renderAttributeDataType(attribute)}
            </div>
          </div>
          {(currentStep === 0) && (assertion == null) &&
            <Button
              disabled={!canEditPipeline}
              variant='white'
              size='sm'
              className='w-100 text-left mt-2 d-flex align-items-center text-nowrap'
              onClick={(event) => this.openContextBar(attribute)}>
              <Filter className='feather-icon mr-2'/>
              Apply filter
            </Button>
          }
          {(currentStep === 0) && (assertion != null) &&
            <Button
              variant='primary'
              size='sm'
              className='w-100 text-left mt-2 d-flex align-items-center text-nowrap'
              onClick={(event) => this.openContextBar(attribute)}>
              <Filter className='feather-icon mr-2'/>
              {assertion.name}
            </Button>
          }
          {(currentStep > 0) && (transformation == null) &&
            <Button
              disabled={!canEditPipeline}
              variant='white'
              size='sm'
              className='w-100 text-left mt-2 d-flex align-items-center text-nowrap'
              onClick={(event) => this.openContextBar(attribute)}>
              <Package className='feather-icon mr-2'/>
              Apply transformation
            </Button>
          }
          {(currentStep > 0) && (transformation != null) &&
            <Button
              variant='primary'
              size='sm'
              className='w-100 text-left mt-2 d-flex align-items-center text-nowrap'
              onClick={(event) => this.openContextBar(attribute)}>
              <Package className='feather-icon mr-2'/>
              {transformation.name}
            </Button>
          }
        </div>

        <div className='datacater-stats-content datacater-most-frequent-values-content'>
          <FrequentValues
            attributeProfile={attributeProfiles[attribute.id]}/>
        </div>

        {showStatistics &&
          <div className='datacater-stats-content border-top'>
            <AttributeStats
              attribute={attribute}
              attributeProfile={attributeProfiles[attribute.id]}/>
          </div>
        }
      </React.Fragment>
    );
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  render() {
    const {
      className,
      column
    } = this.props;

    const { attribute } = column;

    if (column.isRowNumber) {
      const numberOfColumns = this.props.container.columnManager._columns.length - 1;
      return (
        <div className={className}>
          <div className='d-flex align-items-center justify-content-center'>
            {numberOfColumns} attributes
          </div>
        </div>
      );
    } else {
      return (
        <div className={className + ' px-2'}>
          {this.renderAttribute(attribute, attribute.id)}
        </div>
      );
    }
  }
}

export default TableHeaderCell;
