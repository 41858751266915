import React,
       { Component } from 'react';
import CodeEditor    from './CodeEditor';
import {
  isValidAttributeName as isValidAttributeNameCheck
} from '../../../../../../helpers/isValidAttributeName';

class AttributeActionValue extends Component {
  getExampleValue(dataType) {
    const exampleValues = {
      boolean:            'true',
      int:                1,
      long:               1,
      float:              1.0,
      double:             1.0,
      decimal:            1.0,
      string:             'foo',
      uuid:               '087fd5e7-24fa-4e7b-83ac-0fc642979d4b',
      bytes:              '"\u00FF"',
      date:               '2019-10-24',
      'time-millis':      '13:15:00',
      'time-micros':      '13:15:00',
      'timestamp-millis': '2019-10-24 13:15:00',
      'timestamp-micros': '2019-10-24 13:15:00',
      'null':             'null'
    };

    if (exampleValues[dataType] !== undefined) {
      return 'e.g., ' + exampleValues[this.props.dataType];
    } else {
      return '';
    }
  }

  render() {
    const {
      attribute,
      attributes,
      canEditPipeline,
      pipelineStep,
      transformer
    } = this.props;

    if (transformer.key === 'user-defined-transformation') {
      return (
        <CodeEditor
          attributes={attributes}
          attributeId={this.props.attributeId}
          canEditPipeline={canEditPipeline}
          handleChangeFunc={this.props.handleChangeFunc}
          pipelineStep={pipelineStep}
          sortPosition={this.props.sortPosition}
          transformationState={this.props.transformationState}
          value={this.props.value} />
      );
    } else if (transformer.useAttributeAsActionValue === true) {
      // show data source IDs only if two data source mappings exist,
      // i.e., two data sources are joined by the pipeline
      const dataSourceMappings =
        (Object.keys(this.props.dataSourceMappings || {}).length > 1)
          ? this.props.dataSourceMappings
          : {};

      return (
        <select
          className='custom-select d-inline action-value'
          data-attribute-id={this.props.attributeId}
          data-sort-position={this.props.sortPosition}
          disabled={!canEditPipeline}
          name={this.props.name}
          onChange={this.props.handleChangeFunc}
          value={this.props.value || ''}>
          <React.Fragment>
            <option value=''>--- Choose an attribute</option>
            {this.props.attributesWithSameDataType.map(attribute => (
              <option
                key={attribute.id}
                value={attribute.id}>
                {dataSourceMappings[attribute.dataSourceProfileId] !== undefined && '#' + dataSourceMappings[attribute.dataSourceProfileId].dataSourceId + '.'}
                {attribute.name}
              </option>
            ))}
          </React.Fragment>
        </select>
      );
    } else if (transformer.actionValueOptions !== undefined) {
      let actionValueOptions = transformer.actionValueOptions;
      const dataType = attribute.oldDataType || attribute.dataType;

      if (transformer.key === 'cast-data-type') {
        actionValueOptions = actionValueOptions
          .filter(option =>
            (option.supportedOriginalTypes === undefined) ||
            (option.supportedOriginalTypes.includes(dataType))
          );
      }

      return (
        <select
          className='custom-select d-inline action-value'
          data-attribute-id={this.props.attributeId}
          data-sort-position={this.props.sortPosition}
          disabled={!canEditPipeline}
          name={this.props.name}
          onChange={this.props.handleChangeFunc}
          value={this.props.value || ''}>
          {actionValueOptions.map((actionValueOption, index) => (
            <option
              key={index}
              value={actionValueOption.value}>
              {actionValueOption.label}
            </option>
          ))}
        </select>
      );
    } else {
      const placeholderValue = (this.props.defaultActionValue !== undefined)
        ? this.props.defaultActionValue
        : this.getExampleValue(this.props.dataType);

      let errorMessage = '';

      if (transformer.key === 'rename-column') {
        if (!isValidAttributeNameCheck(this.props.value)) {
          errorMessage =
            'Please provide a valid attribute name that is not empty and does ' +
            'contain only the following characters: a-z, A-Z, 0-9, _, -, ß, ä, ö, ü, Ä, Ö, Ü.';
        }

        const isDuplicateName = attributes
          .filter(attr => parseInt(attr.id) !== parseInt(attribute.id))
          .map(attr => attr.name)
          .includes(attribute.name);

        if (errorMessage.length === 0 && isDuplicateName) {
          errorMessage =
            'Please provide a different attribute name, because this data set ' +
            'already contains an attribute with the given name.';
        }
      }

      let textFieldClassNames = errorMessage.length === 0
        ? 'form-control'
        : 'form-control is-invalid';

      return (
        <React.Fragment>
          <input
            type='text'
            className={textFieldClassNames}
            data-attribute-id={this.props.attributeId}
            data-sort-position={this.props.sortPosition}
            disabled={!canEditPipeline}
            name={this.props.name}
            onChange={this.props.handleChangeFunc}
            placeholder={placeholderValue}
            value={this.props.value || ''} />
          {errorMessage.length > 0 &&
            <div className='mt-3 text-danger'>
              {errorMessage}
            </div>
          }
        </React.Fragment>
      );
    }
  }
}

export default AttributeActionValue;
