import React, { Component } from 'react';
import { Search }           from 'react-feather';
import { deepCopy }         from '../../../../../helpers/deepCopy';
import DataSinkLogo         from '../../../../data_sinks/DataSinkLogo';

class SelectDataSinkForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assigningDataSink: false,
      searchQuery:       ''
    };
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
    this.renderDataSink    = this.renderDataSink.bind(this);
    this.selectDataSink    = this.selectDataSink.bind(this);
  }

  updateSearchQuery(event) {
    this.setState({
      searchQuery: event.target.value
    });
  }

  selectDataSink(dataSink) {
    if (this.props.canEditPipeline) {
      this.setState({ assigningDataSink: true });
      this.props.selectDataSinkFunc(
        dataSink.id,
        dataSink.sinkType
      );
    }
  }

  renderDataSink(dataSink, selectDataSinkFunc, index) {
    const className = (this.props.canEditPipeline)
      ? 'list-group-item hoverable clickable overflow-hidden'
      : 'list-group-item hoverable overflow-hidden';

    return (
      <li
        className={className}
        key={index}
        onClick={() => { selectDataSinkFunc(dataSink) }}>
        <div className='row align-items-center px-4 py-2'>
          <div className='col-auto sink-type-logos'>
            <div className='logo-wrap'>
              <DataSinkLogo dataSink={dataSink} />
            </div>
          </div>
          <div
            className='col'>
            <h4 className='mb-0'>
              {dataSink.id !== undefined && '#' + dataSink.id + ': '}
              {dataSink.name}
            </h4>
          </div>
          <div className='col-auto'>
            {this.props.canEditPipeline &&
              <button className='btn btn-white btn-sm'>
                Assign
              </button>
            }
          </div>
        </div>
      </li>
    );
  }

  render() {
    const assigningDataSink = this.state.assigningDataSink;
    const searchTokens      = this.state.searchQuery.toLowerCase().trim().split(' ');
    const dataSinkList      = deepCopy(this.props.dataSinks);
    const pipeline          = this.props.pipeline;

    dataSinkList.unshift({
      id:       undefined,
      sinkType: 'xml',
      name:     'Stream data to a XML file'
    });
    dataSinkList.unshift({
      id:       undefined,
      sinkType: 'json',
      name:     'Stream data to a JSON file'
    });
    dataSinkList.unshift({
      id:       undefined,
      sinkType: 'csv',
      name:     'Stream data to a CSV file'
    });

    const flatFileSinkTypes = ['csv', 'json', 'xml'];

    const dataSinks = dataSinkList
      // do not show created flat file sinks
      .filter(dataSink =>
        (
          (dataSink.id === undefined) ||
          !flatFileSinkTypes.includes(dataSink.sinkType)
        )
      ).filter(dataSink =>
        (
          (dataSink.id === undefined) ||
          (
            // show only personal data sinks for personal pipelines
            // show only project data sinks for project pipelines
            (dataSink.userId === pipeline.userId) &&
            (dataSink.projectId === pipeline.projectId)
          )
        )
      )
      .filter(dataSink =>
        searchTokens
          .map(_ => dataSink.name.toLowerCase().includes(_))
          .filter(_ => _)
          .length === searchTokens.length)
      // show most recent data sink at the top
      .sort((a, b) => b.id - a.id);

    return (
      <div className='card'>
        <div className='card-header'>
          <h4 className='card-header-title'>Assign a data sink</h4>
        </div>
        <div className='card-header'>
          <form>
            <div className='input-group input-group-flush input-group-merge'>
              <input
                type='search'
                className='form-control form-control-prepended search'
                onChange={this.updateSearchQuery}
                placeholder='Search data sinks'
                disabled={!this.props.canEditPipeline}
                value={this.state.searchQuery} />
              <div className='input-group-prepend'>
                <div className='input-group-text'>
                  <Search className='feather-icon' />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='card-body px-0'>
          {(assigningDataSink === true) &&
            <div className='text-center'>
              <span className='spinner-border text-primary' role='status'>
                  <span className='sr-only'>Loading...</span>
              </span>
            </div>
          }
          {(assigningDataSink === false) && (dataSinks.length > 0) &&
            <ul className='list-group list-group-flush list my-n4'>
              {dataSinks.map((dataSink, index) => this.renderDataSink(dataSink, this.selectDataSink, index))}
            </ul>
          }
          {(assigningDataSink === false) && (dataSinks.length === 0) && (this.props.dataSinks.length > 0) &&
            <div className='px-4'>
              No matching data sink was found. Please refine your search query.
            </div>
          }
          {(assigningDataSink === false) && (dataSinks.length === 0) && (this.props.dataSinks.length === 0) &&
            <div className='px-4'>
              No data sinks available.
            </div>
          }
        </div>
      </div>
    );
  }
}

export default SelectDataSinkForm;
