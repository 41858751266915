import React, { Component } from 'react';
import RetrieveTables       from '../RetrieveTables';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class RedshiftConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSink,
      dataSinks,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveTablesFunc
    } = this.props;

    const flatTables = dataSinks.tables
      .map(table => table.name)
      .sort();

    const redshiftEndpoint = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.redshiftEndpoint !== undefined)
      ? dataSink.connectorConfig.redshiftEndpoint
      : '';

    const retrievingTablesIsPossible =
      ![undefined, ''].includes(redshiftEndpoint) &&
      ![undefined, ''].includes(dataSink.username) &&
      ![undefined, ''].includes(dataSink.password);

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the password unless you want to change it.</span>)
      : (<span className='text-danger'>*</span>);

    const kafkaConnectConfig = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSink.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item' key={tab.name}>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Redshift endpoint'
              name='redshiftEndpoint'
              onChangeFunc={handleConfigChangeFunc}
              subLabel={(<React.Fragment>Typically in the format <span className='font-italic'>[cluster_id].[zone].redshift.amazonaws.com:[port]/[database_name]</span>.</React.Fragment>)}
              value={redshiftEndpoint} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Username'
              name='username'
              onChangeFunc={handleChangeFunc}
              value={dataSink.username} />
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment>Password{editPasswordNotice}</React.Fragment>)}
              name='password'
              onChangeFunc={handleChangeFunc}
              value={dataSink.password} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Schema name'
              name='databaseSchemaName'
              onChangeFunc={handleChangeFunc}
              placeholder='public'
              value={dataSink.databaseSchemaName} />
            <RetrieveTables
              dataSink={dataSink}
              dataSinks={dataSinks}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the table names from the Redshift database failed. Please check your connection credentials.'
              handleChangeFunc={handleChangeFunc}
              missingOptionsErrorMessage='Please provide at least the Redshift endpoint, your username, and your password.'
              resetRetrieveTablesFunc={resetRetrieveTablesFunc}
              retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSinkFunc}
              retrievingTablesIsPossible={retrievingTablesIsPossible}
              tableAttributeName='bucketName'
              tables={flatTables} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSinks={dataSinks}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default RedshiftConfig;
