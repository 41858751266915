export function fetchStreams() {
  const requestStreams = () => ({
    type: 'REQUEST_STREAMS'
  });

  const receivedStreams = response => ({
    type:    'RECEIVE_STREAMS',
    streams: response
  });

  return function (dispatch) {
    dispatch(requestStreams());

    return fetch(
        '/api/streams',
        { headers: { 'X-Auth-token': localStorage.getItem('userToken') }}
      )
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedStreams(json));
      });
  };
}

export function fetchStream(id) {
  const requestStream = () => ({
    type: 'REQUEST_STREAM'
  });

  const receivedStream = response => ({
    type:   'RECEIVE_STREAM',
    stream: response
  });

  return function (dispatch) {
    dispatch(requestStream());

    return fetch(
        '/api/streams/' + id,
        { headers: { 'X-Auth-token': localStorage.getItem('userToken') }}
      )
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else if (response.status === 404) {
            window.location = '/404';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedStream(json));
      });
  };
}
