import React, { Component } from 'react';

class PipelineDesignerPipelineStep extends Component {
  getPipelineStepShortName(pipelineStep) {
    let shortName = pipelineStep.sortPosition;

    if (pipelineStep.name === undefined) {
      return shortName;
    }

    const words = pipelineStep.name.split(' ');

    if (words[0] !== undefined && words[0].length > 0) {
      shortName = words[0][0].toUpperCase()[0];

      if (words[1] !== undefined && words[1].length > 0) {
        shortName += words[1][0].toUpperCase()[0];
      }
    }

    return shortName;
  }

  getPipelineStepName(pipelineStep) {
    if (pipelineStep.name === undefined || pipelineStep.name.length === 0) {
      return 'Untitled Step';
    } else {
      return pipelineStep.name;
    }
  }

  render() {
    const {
      canEditPipeline,
      pipelineStep
    } = this.props;

    let classNames = 'list-group-item border-0 p-0 mr-3';
    if (this.props.currentStep === pipelineStep.sortPosition && !this.props.showDataSinkDialog) {
      classNames += ' active-step';
    }

    const activeDropZone =
      ![this.props.draggingItem, this.props.draggingOverItem].includes(undefined) &&
      this.props.draggingOverItem === pipelineStep.sortPosition &&
      ![pipelineStep.sortPosition, pipelineStep.sortPosition+1].includes(this.props.draggingItem);

    return (
      <React.Fragment>
        <li
          className={classNames}
          data-sort-position={pipelineStep.sortPosition}
          onDragLeave={this.props.onDragLeaveFunc}
          onDragOver={(event) => { this.props.onDragOverFunc(event, pipelineStep.sortPosition) }}
          onDrop={this.props.onDropFunc}>
          <div
            className='avatar avatar-sm clickable'
            data-name={this.getPipelineStepName(pipelineStep)}
            data-short-name={this.getPipelineStepShortName(pipelineStep)}
            data-sort-position={pipelineStep.sortPosition}
            draggable={canEditPipeline}
            onDragEnd={this.props.onDragEndFunc}
            onDragStart={this.props.onDragStartFunc}
            onClick={(event) => {this.props.moveToStepFunc(event, pipelineStep.sortPosition)}}>
            <div
              className='avatar-title bg-primary-soft rounded-circle text-primary'
              data-sort-position={pipelineStep.sortPosition}>
              {this.getPipelineStepShortName(pipelineStep)}
            </div>
          </div>
        </li>
        {activeDropZone &&
          <li className='list-group-item border-0 p-0 mr-3 dragged-step'>
            <div className='avatar avatar-sm'>
              <div className='avatar-title bg-primary-soft rounded-circle text-primary droppable'>
                {this.props.draggingShortName}
              </div>
            </div>
          </li>
        }
      </React.Fragment>
    );
  }
}

export default PipelineDesignerPipelineStep;
