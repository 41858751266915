import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { Plus }             from 'react-feather';
import MainContent          from '../../components/layout/MainContent';
import Navigation           from '../../components/settings/Navigation';
import ProjectList          from '../../components/projects/ProjectList';
import { fetchInfo }        from '../../actions/info';
import { fetchCurrentUser } from '../../actions/users';
import { fetchProjects }    from '../../actions/projects';

class ProjectAdmin extends Component {
  componentDidMount() {
    this.props.fetchInfo();
    this.props.fetchCurrentUser();
    this.props.fetchProjects(true);
  }

  render() {
    const currentUser = this.props.users.currentUser;

    if ((currentUser === undefined) || (currentUser.isSuperUser !== true)) {
      return (<div></div>);
    }

    return (
      <MainContent preTitle='Settings' title='Manage projects'>
        <div className='card card-fill'>
          <div className='card-body'>
            <div className='row mb-4 mt-n3'>
              <div className='col-12'>
                <Navigation currentUser={currentUser} info={this.props.info.info} />
              </div>
            </div>
            <div className='row align-items-center mt-n2 mb-4'>
              <div className='col'>
                <h3 className='card-header-title'>
                  All projects
                </h3>
              </div>
              <div className='col-auto'>
                <a href='/admin/projects/new' className='btn btn-primary d-flex align-items-center'>
                  <Plus className='feather-icon' />
                  Create project
                </a>
              </div>
            </div>
            <hr className='mb-0' />
            {this.props.projects.projects.length > 0 &&
              <ProjectList
                projects={this.props.projects.projects} />
            }
            {this.props.projects.projects.length === 0 &&
              <div className='mt-4'>
                No projects available.
              </div>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    info:     state.info,
    projects: state.projects,
    users:    state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser: fetchCurrentUser,
  fetchInfo:        fetchInfo,
  fetchProjects:    fetchProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAdmin);
