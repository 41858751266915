export function fetchUsers() {
  const requestUsers = () => ({
    type: 'REQUEST_USERS'
  });

  const receivedUsers = response => ({
    type:  'RECEIVE_USERS',
    users: response
  });

  return function (dispatch) {
    dispatch(requestUsers());

    return fetch('/api/admin/users',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedUsers(json));
      });
  };
}

export function fetchUser(id) {
  const requestUser = () => ({
    type: 'REQUEST_USER'
  });

  const receivedUser = response => ({
    type: 'RECEIVE_USER',
    user: response
  });

  return function (dispatch) {
    dispatch(requestUser());

    return fetch('/api/admin/users/' + id,
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedUser(json));
      });
  };
}

export function fetchCurrentUser() {
  const requestUser = () => ({
    type: 'REQUEST_CURRENT_USER'
  });

  const receivedUser = response => ({
    type: 'RECEIVE_CURRENT_USER',
    user: response
  });

  return function (dispatch) {
    dispatch(requestUser());

    return fetch('/api/admin/users/current',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedUser(json));
      });
  };
}

export function addUser(user) {
  const requestAddUser = () => ({
    type: 'REQUEST_ADD_USER'
  });

  const receivedAddUser = () => ({
    type: 'RECEIVE_ADD_USER'
  });

  return function (dispatch) {
    dispatch(requestAddUser());

    return fetch(
        '/api/admin/users',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(user),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((response) => {
        dispatch(receivedAddUser());
        return response;
      });
  };
}

export function updateUser(user) {
  const requestUpdateUser = () => ({
    type: 'REQUEST_UPDATE_USER'
  });
  const receivedUpdateUser = () => ({
    type: 'RECEIVE_UPDATE_USER',
  });

  return function (dispatch) {
    dispatch(requestUpdateUser());

    // remove timestamps
    let cleanUser = user;
    delete cleanUser.createdAt;
    delete cleanUser.updatedAt;
    delete cleanUser.firstSignInAt;
    delete cleanUser.lastSignInAt;

    return fetch(
        '/api/admin/users/' + user.id,
        {
          method:      'PUT',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanUser),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((response) => {
        dispatch(receivedUpdateUser());
        return response;
      });
  };
}

export function updateCurrentUser(user) {
  const requestUpdateUser = () => ({
    type: 'REQUEST_UPDATE_CURRENT_USER'
  });
  const receivedUpdateUser = response => ({
    type: 'RECEIVE_UPDATE_CURRENT_USER',
    user: response
  });

  return function (dispatch) {
    dispatch(requestUpdateUser());

    // remove timestamps
    let cleanUser = user;
    delete cleanUser.createdAt;
    delete cleanUser.updatedAt;

    return fetch(
        '/api/admin/users/current',
        {
          method:      'PUT',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanUser),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        dispatch(receivedUpdateUser(json));
        return json;
      });
  };
}

export function updatePassword(currentPassword,
                               newPassword,
                               newPasswordConfirmation) {
  const requestUpdatePassword = () => ({
    type: 'REQUEST_UPDATE_PASSWORD'
  });
  const receivedUpdatePassword = () => ({
    type: 'RECEIVE_UPDATE_PASSWORD'
  });
  const receivedUpdatePasswordwrongPassword = () => ({
    type: 'RECEIVE_UPDATE_PASSWORD_WRONG_PASSWORD'
  });

  return function (dispatch) {
    dispatch(requestUpdatePassword());

    return fetch(
        '/api/admin/users/current/update_password',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify({
            currentPassword:         currentPassword,
            newPassword:             newPassword,
            newPasswordConfirmation: newPasswordConfirmation
          }),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else if (response.status === 403) {
            dispatch(receivedUpdatePasswordwrongPassword());
          } else {
            dispatch(receivedUpdatePassword());
          }
        },
        error => {
        }
      );
  };
}

export function toggleActivation(user) {
  const requestToggleActivationUser = () => ({
    type: 'REQUEST_TOGGLE_ACTIVATION_USER'
  });
  const receivedToggleActivationUser = () => ({
    type: 'RECEIVE_TOGGLE_ACTIVATION_USER'
  });

  return function (dispatch) {
    dispatch(requestToggleActivationUser());

    return fetch(
        '/api/admin/users/' + user.id + '/toggle_activation',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'X-Auth-token': localStorage.getItem('userToken')
          }
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then(() => {
        dispatch(receivedToggleActivationUser());
      });
  };
}

export function resetPassword(user) {
  const requestResetPasswordUser = () => ({
    type: 'REQUEST_RESET_PASSWORD_USER'
  });
  const receivedResetPasswordUser = () => ({
    type: 'RECEIVE_RESET_PASSWORD_USER'
  });

  return function (dispatch) {
    dispatch(requestResetPasswordUser());

    return fetch(
        '/api/admin/users/' + user.id + '/reset_password',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'X-Auth-token': localStorage.getItem('userToken')
          }
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            dispatch(receivedResetPasswordUser());
          }
        },
        error => console.log('An error occurred.', error)
      );
  };
}
