const dataSourceTypes = [
  {
    docs:  'redshift',
    validations: [
      {
        field:      'redshiftEndpoint',
        validation: 'presence'
      },
      {
        field:      'username',
        validation: 'presence'
      },
      {
        field:        'password',
        validation:   'presence',
        validateOnly: 'create'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      }
    ],
    label: 'Amazon Redshift',
    value: 'redshift'
  },
  {
    docs:  'solr',
    validations: [
      {
        field:      'hostname',
        validation: 'presence'
      },
      {
        field:      'port',
        validation: 'presence'
      },
      {
        field:      'port',
        validation: 'number'
      },
      {
        field:      'databaseName',
        validation: 'presence'
      }
    ],
    label: 'Apache Solr',
    value: 'solr'
  },
  {
    docs:  'ax_semantics',
    validations: [
      {
        field:        'password',
        validation:   'presence',
        validateOnly: 'create'
      },
      {
        field:      'collectionId',
        validation: 'presence'
      }
    ],
    label: 'AX Semantics',
    value: 'ax-semantics'
  },
  {
    docs:  'elasticsearch',
    validations: [
      {
        field:      'hostname',
        validation: 'presence'
      },
      {
        field:      'port',
        validation: 'presence'
      },
      {
        field:      'port',
        validation: 'number'
      }
    ],
    label: 'Elasticsearch',
    value: 'elasticsearch'
  },
  {
    docs:  'hubspot',
    validations: [
      {
        field:        'password',
        validation:   'presence',
        validateOnly: 'create'
      }
    ],
    label: 'HubSpot',
    value: 'hubspot'
  },
  {
    docs:  'bigquery',
    validations: [
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'databaseName',
        validation: 'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'databaseSchemaName',
        validation: 'presence',
        validateNotIf: {
          fieldName:   'writeIntoExistingTable',
          fieldValues: ['false'],
        },
      }
    ],
    label: 'Google Cloud BigQuery',
    value: 'google-bigquery'
  },
  {
    docs:  'mysql',
    validations: [
      {
        field:      'hostname',
        validation: 'presence'
      },
      {
        field:      'port',
        validation: 'presence'
      },
      {
        field:      'port',
        validation: 'number'
      },
      {
        field:      'username',
        validation: 'presence'
      },
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'databaseName',
        validation: 'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      }
    ],
    label: 'MySQL',
    value: 'mysql'
  },
  {
    docs:  'postgresql',
    validations: [
      {
        field:      'hostname',
        validation: 'presence'
      },
      {
        field:      'port',
        validation: 'presence'
      },
      {
        field:      'port',
        validation: 'number'
      },
      {
        field:      'username',
        validation: 'presence'
      },
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'databaseName',
        validation: 'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'databaseSchemaName',
        validation: 'presence'
      }
    ],
    label: 'PostgreSQL',
    value: 'postgresql'
  },
  {
    docs:  'rest',
    validations: [
      {
        field:      'uri',
        validation: 'presence'
      },
      {
        field:      'uri',
        format:     '^(http|https):\\/\\/',
        validation: 'format'
      }
    ],
    label: 'REST endpoint',
    value: 'rest'
  },
  {
    docs:  'snowflake',
    label: 'Snowflake',
    value: 'snowflake',
    validations: [
      {
        field:      'snowflakeUrl',
        validation: 'presence'
      },
      {
        field:      'username',
        validation: 'presence'
      },
      {
        field:      'privateKey',
        validation: 'presence'
      },
      {
        field:      'databaseName',
        validation: 'presence'
      }
    ]
  }
];

export default dataSourceTypes;
