import React, { Component } from 'react';
import {
  Code,
  Filter,
  Home,
  PlayCircle,
  Search,
  Settings,
  Target
} from 'react-feather';

class PipelineDesignerNavigation extends Component {
  constructor(props) {
    super(props);

    this.moveToPipelineFilters = this.moveToPipelineFilters.bind(this);
    this.moveToPipelineSteps   = this.moveToPipelineSteps.bind(this);
    this.moveToSampleData      = this.moveToSampleData.bind(this);
  }

  moveToPipelineFilters(event) {
    if (document.querySelector('.loading-pipeline-designer-wrapper') != null) {
      document.querySelector('.loading-pipeline-designer-wrapper').classList.add('d-block');
    }

    setTimeout(
      () => {
        this.props.moveToPageFunc('steps', 0);
      },
      100);
  }

  moveToPipelineSteps(event) {
    if (document.querySelector('.loading-pipeline-designer-wrapper') != null) {
      document.querySelector('.loading-pipeline-designer-wrapper').classList.add('d-block');
    }

    setTimeout(
      () => {
        if (this.props.pipeline.pipelineSteps.length === 0) {
          this.props.addPipelineStepFunc(event, false);
        } else {
          const sortPositions = this.props.pipeline.pipelineSteps
            .map(step => step.sortPosition);
          const minSortPosition = Math.min.apply(Math, sortPositions);
          this.props.moveToPageFunc('steps', minSortPosition);
        }
      },
      100);
  }

  moveToSampleData(event) {
    if (document.querySelector('.loading-pipeline-designer-wrapper') != null) {
      document.querySelector('.loading-pipeline-designer-wrapper').classList.add('d-block');
    }

    setTimeout(
      () => {
        this.props.moveToPageFunc('steps', undefined);
      },
      100);
  }

  render() {
    let instructionBoxClassNames   = 'nav-link d-flex align-items-center';
    let dataSourceBoxClassNames    = 'nav-link';
    let dataSinkBoxClassNames      = 'nav-link';
    let filterBoxClassNames        = 'nav-link';
    let pipelineStepsBoxClassNames = 'nav-link d-flex align-items-center';
    let deploymentsBoxClassNames   = 'nav-link';
    let settingsBoxClassNames      = 'nav-link';

    // only show navigation state, when not in code view
    if (!this.props.codeView) {
      if (this.props.currentPage === 'instructions') {
        instructionBoxClassNames += ' active';
      }

      if (this.props.currentPage === 'steps' && this.props.currentStep === undefined) {
        dataSourceBoxClassNames += ' active';
      }

      if (this.props.currentPage === 'data-sink') {
        dataSinkBoxClassNames += ' active';
      }

      if (this.props.currentPage === 'steps' && this.props.currentStep === 0) {
        filterBoxClassNames += ' active';
      }

      if (this.props.currentPage === 'steps' && this.props.currentStep > 0) {
        pipelineStepsBoxClassNames += ' active';
      }

      if (this.props.currentPage === 'deployments') {
        deploymentsBoxClassNames += ' active';
      }

      if (this.props.currentPage === 'settings') {
        settingsBoxClassNames += ' active';
      }
    }

    return (
      <div className='datacater-pipeline-designer-second-row row align-items-center overflow-hidden text-nowrap'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <ul className='nav nav-tabs font-weight-bold'>
                <li
                  className='nav-item clickable'
                  onClick={(event) => {this.props.moveToPageFunc('instructions')}}>
                  <a className={instructionBoxClassNames}>
                    <Home className='feather-icon mr-1' />
                    <span className='d-none d-xl-inline'>Overview</span>
                    <span className='d-inline d-xl-none'>&nbsp;</span>
                  </a>
                </li>
                <li
                  className='nav-item clickable'
                  onClick={this.moveToSampleData}>
                  <a className={dataSourceBoxClassNames}>
                    <Search className='feather-icon mr-1' />
                    <span className='d-none d-xl-inline'>Explore</span>
                    <span className='d-inline d-xl-none'>&nbsp;</span>
                  </a>
                </li>
                <li
                  className='nav-item clickable'
                  onClick={this.moveToPipelineFilters}>
                  <a className={filterBoxClassNames}>
                    <Filter className='feather-icon mr-1' />
                    <span className='d-none d-xl-inline'>Filter</span>
                    <span className='d-inline d-xl-none'>&nbsp;</span>
                  </a>
                </li>
                <li
                  className='nav-item clickable'
                  onClick={this.moveToPipelineSteps}>
                  <a className={pipelineStepsBoxClassNames}>
                    <Code className='feather-icon mr-1' />
                    <span className='d-none d-xl-inline'>Transform</span>
                    <span className='d-inline d-xl-none'>&nbsp;</span>
                  </a>
                </li>
                <li
                  className='nav-item clickable'
                  onClick={(event) => this.props.moveToPageFunc('data-sink')}>
                  <a className={dataSinkBoxClassNames}>
                    <Target className='feather-icon mr-1' />
                    <span className='d-none d-xl-inline'>Data sink</span>
                    <span className='d-inline d-xl-none'>&nbsp;</span>
                  </a>
                </li>
                <li
                  className='nav-item clickable'
                  onClick={(event) => this.props.moveToPageFunc('deployments')}>
                  <a className={deploymentsBoxClassNames}>
                    <PlayCircle className='feather-icon mr-1' />
                    <span className='d-none d-xl-inline'>Deploy</span>
                    <span className='d-inline d-xl-none'>&nbsp;</span>
                  </a>
                </li>
                {this.props.canEditPipeline &&
                  <li
                    className='nav-item clickable'
                    onClick={(event) => this.props.moveToPageFunc('settings')}>
                    <a className={settingsBoxClassNames}>
                      <Settings className='feather-icon mr-1' />
                      <span className='d-none d-xl-inline'>Settings</span>
                      <span className='d-inline d-xl-none'>&nbsp;</span>
                    </a>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PipelineDesignerNavigation;
