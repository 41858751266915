import React,
       { Component }       from 'react';
import { connect }         from 'react-redux';
import { Redirect }        from 'react-router-dom';
import { fetchInfo }       from '../actions/info';
import { forgotPassword,
         signIn }          from '../actions/user_sessions';
import { Eye }             from 'react-feather';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: '',
      formValues: {
        email:    '',
        password: '',
      },
      infoMessage:        '',
      showForgotPassword: false,
      loginSuccessful:    false
    };

    if (localStorage.getItem('signUpEmail') !== null) {
      this.state.infoMessage = 'Thank you for signing up! Please check your inbox for your credentials.';
      this.state.formValues.email = localStorage.getItem('signUpEmail');
      localStorage.removeItem('signUpEmail');
    }

    this.handleLogin          = this.handleLogin.bind(this);
    this.handleChange         = this.handleChange.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.showForgotPassword   = this.showForgotPassword.bind(this);
  }

  componentDidMount() {
    this.props.fetchInfo();

    // do a small trick to add CSS classes to dom elements outside of
    // the application's root element
    const bodyDomElement = document.querySelector('body');
    const rootDomElement = document.querySelector('#root');
    bodyDomElement.className = 'd-flex align-items-center bg-auth border-top border-top-2 border-primary';
    rootDomElement.classList.add('container-fluid');
    rootDomElement.classList.add('pr-lg-0');

    document.title = 'Sign In | DataCater';
  }

  componentWillUnmount() {
    // get rid of the added CSS classes
    const bodyDomElement = document.querySelector('body');
    const rootDomElement = document.querySelector('#root');
    bodyDomElement.className = '';
    rootDomElement.classList.remove('container-fluid');

    document.title = 'DataCater';
  }

  handleLogin(event) {
    event.preventDefault();
    // TODO: check for empty email or empty password field
    let formErrors = '';

    if (formErrors.length === 0) {
      this.props.signIn(this.state.formValues.email,
                        this.state.formValues.password)
        .then(() => {
          if (this.props.userSessions.userIsAuthenticated === true) {
            this.setState({
              formErrors:      '',
              infoMessage:     '',
              loginSuccessful: true
            });
          } else {
            formErrors = 'Authentication failed.';
            this.setState({
              formErrors:  formErrors,
              infoMessage: ''
            });
          }
        });
    }
  }

  handleForgotPassword(event) {
    event.preventDefault();
    this.props.forgotPassword(this.state.formValues.email).then(() =>
      this.setState({
        formValues: {
          email:    '',
          password: ''
        },
        infoMessage:        'We sent you a new password via email.',
        showForgotPassword: false
      }));
  }

  showForgotPassword(event) {
    event.preventDefault();
    this.setState({
      formErrors:         '',
      infoMessage:        '',
      showForgotPassword: true
    });
  }

  handleChange(event) {
    let userForm = this.state.formValues;
    userForm[event.target.name] = event.target.value;
    this.setState({ formValues: userForm });
  }

  render() {
    if (this.state.loginSuccessful === true) {
      return (<Redirect to='/' />);
    }

    let submitFunc = this.handleLogin;
    let action = 'Sign in';

    if (this.state.showForgotPassword) {
      submitFunc = this.handleForgotPassword;
      action = 'Request password';
    }

    const info = this.props.info.info;

    return (
      <React.Fragment>
        <div className="row justify-content-center mr-lg-0">
          <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
            <a href='/'>
              <img src='/images/logo.png' alt='DataCater Logo' className='login-logo' />
            </a>
            <h1 className="display-4 text-center mb-3" style={{"line-height":"125%"}}>
              {action}
            </h1>
            {this.state.formErrors.length > 0 &&
              <div className='alert alert-danger mt-4 rounded-0'>
                {this.state.formErrors} Please reach out to our <a className='text-white' href='mailto:support@datacater.io'>support</a> if the issues persist.
              </div>
            }
            {this.state.infoMessage.length > 0 &&
              <div className='alert alert-success mt-4 rounded-0'>
                {this.state.infoMessage}
              </div>
            }
            <form>
              <div className='form-group'>
                <label>Email</label>
                <input type='email' name='email' className='form-control' placeholder='name@address.com' value={this.state.formValues.email} onChange={this.handleChange} />
              </div>

              {!this.state.showForgotPassword &&
                <div className='form-group'>
                  <div className='row'>
                    <div className='col'>
                      <label>Password</label>
                    </div>
                    <div className='col-auto'>
                      <a href='password-reset.html' className='form-text small text-muted' onClick={this.showForgotPassword}>
                        Forgot password?
                      </a>
                    </div>
                  </div>

                <div className='input-group input-group-merge'>
                  <input type='password' name='password' className='form-control form-control-appended' placeholder='Enter your password' value={this.state.formValues.password} onChange={this.handleChange} />

                  <div className='input-group-append'>
                    <span className='input-group-text'>
                      <Eye className='feather-icon' />
                    </span>
                  </div>

                  </div>
                </div>
              }

                <button className='btn btn-lg w-100 btn-primary my-3' onClick={submitFunc}>
                  {action}
                </button>
                {info.selfServiceSignUps && action != 'Request password' &&
                  <div className="mt-4">
                    Don't have an account yet? <a href='/sign_up/'>Sign up</a>!
                  </div>
                }
              <div className="d-lg-none mt-4 text-center">
                <a href="https://datacater.io/privacy_policy/">Privacy policy</a>
                <a className="ml-4" href="mailto:support@datacater.io">Support</a>
                <a className="ml-4" href="https://datacater.io/imprint/">Imprint</a>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block pr-lg-0">
            <div className="bg-cover h-100 min-vh-100 mt-n1 me-n3 d-flex justify-content-center align-items-center" style={{"background-image": "url(/images/bg-overlay.jpg)"}}>
            </div>
          </div>
        </div>
        <div className="text-center w-100 pb-3 d-none d-lg-block" style={{"position": "fixed", "bottom":"0"}}>
          <a className="text-white mr-4" href="https://datacater.io/privacy_policy/">Privacy policy</a>
          <a className="text-white mr-4" href="mailto:support@datacater.io">Support</a>
          <a className="text-white" href="https://datacater.io/imprint/">Imprint</a>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    info:         state.info,
    userSessions: state.userSessions
  }
}

const mapDispatchToProps = {
  fetchInfo:      fetchInfo,
  forgotPassword: forgotPassword,
  signIn:         signIn
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
