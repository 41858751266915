import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import DataSourceLogo       from './DataSourceLogo';
import { formatInteger }    from '../../helpers/formatInteger';

class DataSourceListItem extends Component {
  getPathToDataSource(dataSource) {
    return (dataSource.id != null) ? ('/data_sources/' + dataSource.id) : '/data_sources';
  }

  render() {
    const {
      currentUser,
      dataSource
    } = this.props;

    const project =
      (
        ![currentUser, dataSource].includes(undefined) &&
        (dataSource.userId === undefined) &&
        (dataSource.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === dataSource.projectId)
        : undefined;

    return (
      <li className='list-group-item py-2' key={dataSource.id}>
        <div className='row align-items-center py-2'>
          <div className='col-auto source-type-logos'>
            <div className='logo-wrap'>
              <DataSourceLogo dataSource={dataSource} />
            </div>
          </div>
          <div className='col'>
            <h4 className='mb-0 name'>
              {project !== undefined &&
                <React.Fragment>
                  <a href={`/projects/${project.id}`} className='text-gray'>
                    {project.name}
                  </a>
                  <span className='text-gray mx-2'>
                    /
                  </span>
                </React.Fragment>
              }
              <Link to={this.getPathToDataSource(dataSource)}>
                #{dataSource.id}: {dataSource.name}
              </Link>
            </h4>
          </div>
          <div className='col-auto'>
            {this.props.showNumberOfRecords === true &&
             this.props.numberOfRecords !== undefined &&
             formatInteger(this.props.numberOfRecords) + ' records'}
            {this.props.showNumberOfRecords !== true &&
              <Link to={this.getPathToDataSource(dataSource)} className='btn btn-white btn-sm'>
                Show
              </Link>
            }
          </div>
        </div>
      </li>
    );
  }
}

export default DataSourceListItem;
