export function resetDataSinkProfile() {
  const resetProfile = () => ({
    type: 'RESET_DATA_SINK_PROFILE'
  });

  return function (dispatch) {
    dispatch(resetProfile());
  };
}

export function fetchDataSinkProfile(dataSinkProfileId) {
  const requestDataSinkProfile = () => ({
    type: 'REQUEST_DATA_SINK_PROFILE'
  });

  const receivedDataSinkProfile = response => ({
    dataSinkProfile: response,
    type:            'RECEIVE_DATA_SINK_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestDataSinkProfile());

    return fetch('/api/data_sink_profiles/' + dataSinkProfileId,
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        dispatch(receivedDataSinkProfile(json));
      });
  };
}

export function createDataSinkProfile(dataSinkId) {
  const requestCreateDataSinkProfile = () => ({
    type: 'REQUEST_CREATE_DATA_SINK_PROFILE'
  });

  const receivedCreateDataSinkProfile = response => ({
    dataSinkProfile: response,
    type:            'RECEIVE_DATA_SINK_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestCreateDataSinkProfile());

    return fetch(
      '/api/data_sinks/' + dataSinkId + '/data_sink_profiles',
      {
        method:      'POST',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'X-Auth-token': localStorage.getItem('userToken')
        }
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedCreateDataSinkProfile(json));
    });
  };
}

export function addDataSinkProfileAttribute(dataSinkProfileId, attribute) {
  const requestAddDataSinkProfileAttribute = () => ({
    type: 'REQUEST_ADD_DATA_SINK_PROFILE_ATTRIBUTE'
  });

  const receivedAddDataSinkProfileAttribute = response => ({
    dataSinkProfileAttribute: response,
    type:                     'RECEIVE_DATA_SINK_PROFILE_ATTRIBUTE'
  });

  return function (dispatch) {
    dispatch(requestAddDataSinkProfileAttribute());

    return fetch(
      '/api/data_sink_profiles/' + dataSinkProfileId + '/attributes',
      {
        method:      'POST',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'Content-type': 'application/json',
          'X-Auth-token': localStorage.getItem('userToken')
        },
        body: JSON.stringify(attribute)
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedAddDataSinkProfileAttribute(json));
    });
  };
}

export function updateDataSinkProfileAttribute(dataSinkProfileId, attribute) {
  const requestUpdateDataSinkProfileAttribute = () => ({
    type: 'REQUEST_UPDATE_DATA_SINK_PROFILE_ATTRIBUTE'
  });

  const receivedUpdateDataSinkProfileAttribute = response => ({
    dataSinkProfileAttribute: response,
    type:                     'RECEIVE_DATA_SINK_PROFILE_ATTRIBUTE'
  });

  return function (dispatch) {
    dispatch(requestUpdateDataSinkProfileAttribute());

    return fetch(
      '/api/data_sink_profiles/' + dataSinkProfileId + '/attributes/' + attribute.id,
      {
        method:      'PUT',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'Content-type': 'application/json',
          'X-Auth-token': localStorage.getItem('userToken')
        },
        body: JSON.stringify(attribute)
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedUpdateDataSinkProfileAttribute(json));
    });
  };
}

export function deleteDataSinkProfileAttribute(dataSinkProfileId, attributeId) {
  const requestDeleteDataSinkProfileAttribute = () => ({
    type: 'REQUEST_DELETE_DATA_SINK_PROFILE_ATTRIBUTE'
  });

  const receivedDeleteDataSinkProfileAttribute = () => ({
    type: 'RECEIVE_DELETE_DATA_SINK_PROFILE_ATTRIBUTE'
  });

  return function (dispatch) {
    dispatch(requestDeleteDataSinkProfileAttribute());

    return fetch(
      '/api/data_sink_profiles/' + dataSinkProfileId + '/attributes/' + attributeId,
      {
        method:      'DELETE',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'X-Auth-token': localStorage.getItem('userToken')
        }
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.text();
        }
      },
      error => console.log('An error occurred.', error)
    ).then(() => {
      dispatch(receivedDeleteDataSinkProfileAttribute());
    });
  };
}
