import React, { Component } from 'react';
import {
  Check,
  FileText,
  X
} from 'react-feather';

class FlatFileListItem extends Component {
  getTimeDifference(heartbeat) {
    const eventTime = new Date(heartbeat);
    const now       = new Date();
    const diffMs    = now - eventTime;

    const diffSeconds = Math.round(diffMs / 1000);
    if (diffSeconds <= 60) {
      return diffSeconds + 's ago';
    }

    const diffMinutes = Math.round(diffSeconds / 60);
     if (diffMinutes <= 60) {
      return diffMinutes + 'm ago';
    }

    const diffHours = Math.round(diffMinutes / 60);
    if (diffHours <= 24) {
      return diffHours + 'h ago';
    }

    const diffDays = Math.round(diffHours / 24);
    return diffDays + 'd ago';
  }

  render() {
    const flatFile = this.props.flatFile;

    return (
      <li className='list-group-item' key={flatFile.id}>
        <div className='row align-items-center'>
          <div className='col-auto'>
            <div className='avatar avatar-sm'>
              {flatFile.processingStatus !== 'failed' &&
                <div
                  className='avatar-title font-size-lg bg-primary-soft rounded-circle text-primary'
                  title='Ingestion succeeded'>
                  <Check className='feather-icon' />
                </div>
              }
              {flatFile.processingStatus === 'failed' &&
                <div
                  className='avatar-title font-size-lg bg-danger-soft rounded-circle text-danger'
                  title={'Ingestion failed: ' + flatFile.errorMessage + '.'}>
                  <X className='feather-icon' />
                </div>
              }
            </div>
          </div>
          <div className='col'>
            <h4 className='mb-2 name d-flex align-items-center'>
              <FileText className='feather-icon' />
              #{flatFile.id}: {flatFile.fileName}
            </h4>
            {flatFile.createdAt !== undefined &&
              <h5 className='mb-0 font-400'>
                Uploaded  {this.getTimeDifference(flatFile.createdAt)}
              </h5>
            }
          </div>
        </div>
      </li>
    );
  }
}

export default FlatFileListItem;
