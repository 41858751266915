const streams = (state, action) => {
  const initialState = {
    stream:          undefined,
    streams:         [],
    fetchingStreams: false
  };

  switch (action.type) {
    case 'REQUEST_STREAMS':
      return {
        ...state,
        fetchingStreams: true
      };
    case 'RECEIVE_STREAMS':
      return {
        ...state,
        fetchingStreams: false,
        streams:         action.streams
      };
    case 'REQUEST_STREAM':
      return {
        ...state,
        fetchingStreams: true,
        stream:          undefined
      };
    case 'RECEIVE_STREAM':
      return {
        ...state,
        fetchingStreams: false,
        stream:          action.stream
      };
    default:
      return (state || initialState);
  }
};

export default streams;
