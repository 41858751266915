import React,
       { Component } from 'react';
import { connect }   from 'react-redux';
import { Plus }      from 'react-feather';
import MainContent   from '../../components/layout/MainContent';
import Navigation    from '../../components/settings/Navigation';
import UserList      from '../../components/users/UserList';
import { fetchInfo } from '../../actions/info';
import {
  fetchCurrentUser,
  fetchUsers,
  resetPassword
} from '../../actions/users';

class UserAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.resetUserPassword = this.resetUserPassword.bind(this);
  }

  componentDidMount() {
    this.props.fetchInfo();
    this.props.fetchCurrentUser();
    this.props.fetchUsers();
  }

  resetUserPassword(user) {
    this.props.resetPassword(user)
      .then(() => this.props.fetchUsers());
  }

  render() {
    const currentUser = this.props.users.currentUser;

    if ((currentUser === undefined) || (currentUser.isSuperUser !== true)) {
      return (<div></div>);
    }

    return (
      <MainContent preTitle='Settings' title='Manage users'>
        <div className='card card-fill'>
          <div className='card-body'>
            <div className='row mb-4 mt-n3'>
              <div className='col-12'>
                <Navigation currentUser={currentUser} info={this.props.info.info} />
              </div>
            </div>
            <div className='row align-items-center mt-n2 mb-4'>
              <div className='col'>
                <h3 className='card-header-title'>
                  All users
                </h3>
              </div>
              <div className='col-auto'>
                <a href='/admin/users/new' className='btn btn-primary d-flex align-items-center'>
                  <Plus className='feather-icon' />
                  Create user
                </a>
              </div>
            </div>
            <hr />
            {this.props.users.users.length > 0 &&
              <UserList
                resetPasswordFunc={this.resetUserPassword}
                users={this.props.users} />
            }
            {this.props.users.users.length === 0 &&
              <div className='mt-4'>
                No users available.
              </div>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    info:  state.info,
    users: state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser: fetchCurrentUser,
  fetchInfo:        fetchInfo,
  fetchUsers:       fetchUsers,
  resetPassword:    resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin);
