import React, { Component } from 'react';
import ProjectListItem      from './ProjectListItem';

class ProjectList extends Component {
  render() {
    const sortedProjects = this.props.projects
      .sort(function(a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

    return (
      <ul className='list-group list-group-flush list mb-n4'>
        {sortedProjects.map(project => (
          <ProjectListItem
            actionName='Edit'
            key={project.id}
            project={project}
            projectUrl={'/admin/projects/' + project.id + '/edit'} />
        ))}
      </ul>
    );
  }
}

export default ProjectList;
