import React, { Component } from 'react';
import { Trash2 }           from 'react-feather';

class ProjectMembershipAdmin extends Component {
  render() {
    const {
      handleAddMemberFunc,
      handleChangeExistingMemberFunc,
      handleChangeNewMemberFunc,
      newMemberEmail,
      newMemberRole,
      projectMemberships,
      removeExistingMemberFunc
    } = this.props;

    return (
      <React.Fragment>
        {(projectMemberships.length > 0) &&
          <table className='table table-nowrap border-bottom'>
            <thead>
              <tr>
                <th width="35%">Email</th>
                <th width="35%">Role</th>
                <th width="20%">Enable notifications</th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              {projectMemberships.map((membership, index) => (
                <tr key={index}>
                  {!((membership.projectId !== undefined) && (membership.userId === undefined)) &&
                    <td>
                      {membership.userEmail}
                    </td>
                  }
                  {((membership.projectId !== undefined) && (membership.userId === undefined)) &&
                    <td className='text-gray'>
                      {membership.userEmail} (Unknown user)
                    </td>
                  }
                  <td>
                    <select
                      className='custom-select custom-select-sm'
                      data-user-email={membership.userEmail}
                      name='membershipRole'
                      onChange={handleChangeExistingMemberFunc}
                      value={membership.membershipRole}>
                      <option value='viewer'>Viewer</option>
                      <option value='editor'>Editor</option>
                      <option value='administrator'>Administrator</option>
                    </select>
                  </td>
                  <td>
                    <div className='custom-control custom-switch d-flex align-items-center'>
                      <input
                        checked={[undefined, true].includes(membership.enableNotifications)}
                        data-user-email={membership.userEmail}
                        className='custom-control-input clickable'
                        id={'customSwitchNotifications' + membership.userEmail}
                        name='enableNotifications'
                        onChange={handleChangeExistingMemberFunc}
                        type='checkbox' />
                      <label className='custom-control-label clickable' htmlFor={'customSwitchNotifications' + membership.userEmail}>
                        {[undefined, true].includes(membership.enableNotifications) && 'Enabled'}
                        {(membership.enableNotifications === false) && 'Disabled'}
                      </label>
                    </div>
                  </td>
                  <td className='text-right pr-0'>
                    <a
                      className='btn btn-white btn-sm'
                      href='/'
                      onClick={(event) => { event.preventDefault(); removeExistingMemberFunc(membership.userEmail); }}>
                      <Trash2 className='feather-icon clickable' />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
        {(projectMemberships.length === 0) &&
          <div>
            No members assigned to this project.
          </div>
        }
        <div className='row align-items-center my-4'>
          <div className='col-auto'>
            <h4 className='mb-0'>Add member:</h4>
          </div>
          <div className='col'>
            <input
              type='email'
              className='form-control'
              name='userEmail'
              placeholder='Email address'
              onChange={handleChangeNewMemberFunc}
              value={newMemberEmail || ''} />
          </div>
          <div className='col'>
            <select
              className='custom-select'
              name='membershipRole'
              onChange={handleChangeNewMemberFunc}
              value={newMemberRole}>
              <option value=''>--- Select a role</option>
              <option value='viewer'>Viewer</option>
              <option value='editor'>Editor</option>
              <option value='administrator'>Administrator</option>
            </select>
          </div>
          <div className='col-auto'>
            <button
              className='btn btn-white'
              onClick={handleAddMemberFunc}>
              Add
            </button>
          </div>
        </div>
        <div className='card bg-light border mt-4 rounded-0'>
          <div className='card-body'>
            <p className='mb-3'>
              Project member roles
            </p>
            <ul className='small text-muted pl-4 mb-0'>
              <li>
                <span className='font-weight-bold'>Viewer:</span> View all ressources (data sources, data sinks, pipelines) assigned to the project.
              </li>
              <li>
                <span className='font-weight-bold'>Editor:</span> In addition to the Viewer role, update all ressources (data sources, data sinks, pipelines) assigned to the project. Assign new ressources to the project.
              </li>
              <li>
                <span className='font-weight-bold'>Administrator:</span> In addition to the Editor role, delete ressources of the project. Manage the project members.
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProjectMembershipAdmin;
