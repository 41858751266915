import React, { Component } from 'react';
import TestConnectionButton from '../TestConnectionButton';
import ConfigForm           from '../../data_stores/ConfigForm';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class RssConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleConfigChangeFunc,
      handleTestConnectionFunc
    } = this.props;

    const uri = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.uri !== undefined)
      ? dataSource.connectorConfig.uri
      : '';

    const intervalSeconds = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.intervalSeconds !== undefined)
      ? dataSource.connectorConfig.intervalSeconds
      : '';

    const kafkaConnectConfig = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSource.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item'>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='URI'
              name='uri'
              onChangeFunc={handleConfigChangeFunc}
              subLabel='including http:// or https://'
              value={uri} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Sync interval (s)'
              name='intervalSeconds'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='120'
              value={intervalSeconds} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default RssConfig;
