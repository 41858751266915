import React, { Component }          from 'react';
import { connect }                   from 'react-redux';
import { Redirect }                  from 'react-router-dom';
import { Book }                      from 'react-feather';
import MainContent                   from '../../components/layout/MainContent';
import SinkTypeItem                  from '../../components/data_sinks/SinkTypeItem';
import ApacheSolrConfig              from '../../components/data_sinks/configs/ApacheSolrConfig';
import AxSemanticsConfig             from '../../components/data_sinks/configs/AxSemanticsConfig';
import BigQueryConfig                from '../../components/data_sinks/configs/BigQueryConfig';
import ElasticsearchConfig           from '../../components/data_sinks/configs/ElasticsearchConfig';
import HubSpotConfig                 from '../../components/data_sinks/configs/HubSpotConfig';
import MySqlConfig                   from '../../components/data_sinks/configs/MySqlConfig';
import PostgreSqlConfig              from '../../components/data_sinks/configs/PostgreSqlConfig';
import PxaProductManagerConfig       from '../../components/data_sinks/configs/PxaProductManagerConfig';
import RedshiftConfig                from '../../components/data_sinks/configs/RedshiftConfig';
import RestConfig                    from '../../components/data_sinks/configs/RestConfig';
import SnowflakeConfig               from '../../components/data_sinks/configs/SnowflakeConfig';
import ConfigForm                    from '../../components/data_stores/ConfigForm';
import dataSinkTypes                 from '../../data_stores/data_sink_types';
import { deepCopy }                  from '../../helpers/deepCopy';
import { parseQueryString }          from '../../helpers/parseQueryString';
import { validateConnectorConfig }   from '../../helpers/validateConnectorConfig';
import {
  addDataSink,
  resetRetrieveTables,
  resetTestConnection,
  retrieveTablesFromDataSink,
  testConnection
} from '../../actions/data_sinks';
import { fetchProject }     from '../../actions/projects';
import { fetchCurrentUser } from '../../actions/users';

class NewDataSink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatingDataSinkFailed: false,
      dataSinkCreated:        false,
      errorMessages:          {},
      dataSink: {
        autoRestartFailedConnector: true,
        connectionType:             'direct-connection',
        sinkType:                   undefined,
        hostname:                   '',
        username:                   '',
        password:                   '',
        httpScheme:                 'https',
        port:                       '',
        databaseName:               '',
        bucketName:                 '',
        databaseSchemaName:         'public',
        name:                       ''
      }
    };

    this.getDatabaseNameLabel = this.getDatabaseNameLabel.bind(this);
    this.getBucketNameLabel   = this.getBucketNameLabel.bind(this);
    this.showBucketNameField  = this.showBucketNameField.bind(this);
    this.handleCreateDataSink = this.handleCreateDataSink.bind(this);
    this.handleChange         = this.handleChange.bind(this);
    this.handleConfigChange   = this.handleConfigChange.bind(this);
    this.changeSinkType       = this.changeSinkType.bind(this);
    this.handleTestConnection = this.handleTestConnection.bind(this);
  }

  componentDidMount() {
    this.props.resetRetrieveTables();
    this.props.fetchCurrentUser();

    const params = parseQueryString(this.props.location.search);
    if (params['projectId'] !== undefined) {
      this.props.fetchProject(params['projectId'])
        .then(() => {
          if (this.props.projects.project !== undefined) {
            let dataSink = this.state.dataSink;
            dataSink['projectId'] = this.props.projects.project.id;
            this.setState({ dataSink: dataSink });
          }
        });
    }
  }

  getDatabaseNameLabel() {
    if (this.state.dataSink.sinkType === 'elasticsearch') {
      return 'Index name';
    } else if (this.state.dataSink.sinkType === 'solr') {
      return 'Core name';
    } else {
      return 'Database name';
    }
  }

  getBucketNameLabel() {
    if (this.state.dataSink.sinkType === 'mongodb') {
      return 'Collection name';
    } else {
      return 'Table name';
    }
  }

  showBucketNameField() {
    return !['solr', 'elasticsearch'].includes(this.state.dataSink.sinkType);
  }

  handleCreateDataSink(event) {
    event.preventDefault();
    const dataSink = this.state.dataSink;

    const selectedSinkType = dataSinkTypes
      .find(sinkType => sinkType.value === dataSink.sinkType);
    const validations = (selectedSinkType !== undefined)
      ? (selectedSinkType.validations || []).filter(validation => [undefined, 'create'].includes(validation.validateOnly))
      : [];
    const errorMessages = validateConnectorConfig(
      dataSink,
      validations
    );

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        creatingDataSinkFailed: true,
        errorMessages:          errorMessages
      });
      window.scrollTo(0, 0);
    } else {
      this.props.addDataSink(dataSink)
        .then(() => this.setState({
          dataSinkCreated: true,
          errorMessage:    ''
        }));
    }
  }

  getDefaultPort(sinkType) {
    switch (sinkType) {
      case 'postgresql':
        return 5432;
      case 'mysql':
        return 3306;
      case 'mongodb':
        return 27017;
      case 'elasticsearch':
        return 9200;
      case 'solr':
        return 8983;
      default:
        return undefined;
    }
  }

  handleChange(event) {
    let dataSink = this.state.dataSink;
    let errorMessages = this.state.errorMessages;

    dataSink[event.target.name] = (event.target.type === 'checkbox')
      ? event.target.checked
      : event.target.value;

    // reset error message
    errorMessages[event.target.name] = undefined;

    if (event.target.name === 'connectionType' &&
        event.target.value === 'direct-connection' &&
        [undefined, ''].includes(dataSink.port)) {
      dataSink.port = this.getDefaultPort(dataSink.sinkType);
    }

    if (
      dataSink.sinkType === 'google-bigquery' &&
      event.target.name === 'password'
    ) {
      try {
        // try to parse project name from credentials
        const credentials = JSON.parse(event.target.value);
        if (credentials !== undefined && credentials.project_id !== undefined) {
          dataSink.databaseName = credentials.project_id;
        }
      } catch (e) {
        // do not change the project id if parsing the credentials failed
      }
    }

    // For Google BigQuery:
    // Try to automatically fill in partitioningMethod and partitioningColumn
    // if the table name has been changed
    if (
      dataSink.sinkType === 'google-bigquery' &&
      event.target.name === 'databaseSchemaName'
    ) {
      const tableName = event.target.value;
      const table = this.props.dataSinks.tables.find(table => table.name === tableName);

      if (table != null) {
        if (dataSink.connectorConfig === undefined) {
          dataSink.connectorConfig = {};
        }

        dataSink.connectorConfig.partitioningMethod = table.partitioningMethod;

        if (dataSink.connectorConfig.partitioningMethod === 'timestamp-column') {
          dataSink.connectorConfig.partitioningColumn = table.partitioningColumn;
        }
      }
    }

    this.props.resetTestConnection();

    // find currently selected sink type
    const selectedSinkType = dataSinkTypes
      .find(sinkType => sinkType.value === dataSink.sinkType);
    // validate and fill error message
    if ((selectedSinkType !== undefined) && (selectedSinkType.validations !== undefined)) {
      const relevantValidations = selectedSinkType.validations
        .filter(validation => [undefined, 'create'].includes(validation.validateOnly))
        .filter(validation => validation.field === event.target.name);
      const validationResult = validateConnectorConfig(
        dataSink,
        relevantValidations
      );
      errorMessages[event.target.name] = validationResult[event.target.name];
    }

    this.setState({
      creatingDataSinkFailed: false,
      errorMessages:          errorMessages,
      dataSink:               dataSink
    });
  }

  handleConfigChange(event) {
    let dataSink = this.state.dataSink;
    let errorMessages = this.state.errorMessages;

    if (dataSink.connectorConfig === undefined) {
      dataSink.connectorConfig = {};
    }

    // update data sink config
    dataSink.connectorConfig[event.target.name] = (event.target.type === 'checkbox')
      ? '' + event.target.checked
      : event.target.value;

    // reset error message
    errorMessages[event.target.name] = undefined;

    this.props.resetTestConnection();

    // find currently selected sink type
    const selectedSinkType = dataSinkTypes
      .find(sinkType => sinkType.value === dataSink.sinkType);

    // validate and fill error message
    if ((selectedSinkType !== undefined) && (selectedSinkType.validations !== undefined)) {
      const relevantValidations = selectedSinkType.validations
        .filter(validation => [undefined, 'create'].includes(validation.validateOnly))
        .filter(validation => validation.field === event.target.name);
      const validationResult = validateConnectorConfig(
        dataSink,
        relevantValidations
      );
      errorMessages[event.target.name] = validationResult[event.target.name];
    }

    this.setState({
      creatingDataSinkFailed: false,
      dataSink:               dataSink,
      errorMessages:          errorMessages
    });
  }

  changeSinkType(event, sinkType) {
    event.preventDefault();

    let dataSink = this.state.dataSink;
    const oldSinkType = dataSink.sinkType;
    dataSink.sinkType = sinkType;

    if (
      this.getDefaultPort(oldSinkType) === undefined ||
      this.getDefaultPort(oldSinkType) === parseInt(dataSink.port) ||
      dataSink.port.length === 0
    ) {
      dataSink.port = this.getDefaultPort(dataSink.sinkType);
    }

    if (dataSink.sinkType === 'postgresql') {
      dataSink.databaseSchemaName = 'public';
    } else {
      dataSink.databaseSchemaName = '';
    }

    if (dataSink.sinkType === 'google-bigquery') {
      if (
        dataSink.connectorConfig === undefined ||
        dataSink.connectorConfig.partitioningMethod === undefined
      ) {
        dataSink.connectorConfig = {
          partitioningMethod: 'ingestion-time-daily'
        };
      }
    }

    this.props.resetTestConnection();

    this.setState({ dataSink: dataSink });
  }

  handleTestConnection() {
    // the backend always requires a data sink name, although it is not relevant
    // in this case. TODO: fix this in the future, such that we can test a connection
    // without requiring the user to enter a data source name
    this.props.testConnection(
      Object.assign(deepCopy(this.state.dataSink), { name: 'viktoria' }));
  }

  render() {
    const project = this.props.projects.project;
    const currentUser = this.props.users.currentUser;

    if (this.state.dataSinkCreated) {
      const createdDataSink = this.props.dataSinks.dataSink;
      let redirectTo = '/data_sinks';

      if (createdDataSink !== undefined) {
        redirectTo += '/' + createdDataSink.id;
      }

      return (<Redirect to={redirectTo} />);
    }

    const contentTitle = (project === undefined)
      ? 'Create data sink'
      : `Create data sink for project #${project.id}: ${project.name}`;

    const contentButtonLabel = (project === undefined)
      ? 'Go back to data sinks'
      : 'Go back to project';

    const contentButtonLink = (project === undefined)
      ? '/data_sinks'
      : `/projects/${project.id}`;

    const dataSink = this.state.dataSink;
    const coreSinkTypes = deepCopy(dataSinkTypes);

    // add custom connectors, if configured
    if (
      (currentUser !== undefined) &&
      (currentUser.allowConnectors !== undefined) &&
      currentUser.allowConnectors.includes('typo3-pxa-product-manager')
    ) {
      coreSinkTypes.push({
        label: 'TYPO3 Pixelant Product Manager',
        value: 'typo3-pxa-product-manager'
      });
    }

    const sinkTypes = coreSinkTypes
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
      .map(function(sinkType) {
        return Object.assign(
          {},
          sinkType,
          {
            isSelected: (dataSink.sinkType === sinkType.value)
          }
        );
      });

    const selectedSinkType = sinkTypes.find(sinkType => sinkType.isSelected);

    return (
      <MainContent preTitle='Data Sinks' title={contentTitle} buttonLabel={contentButtonLabel} buttonLink={contentButtonLink}>
        <form>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>Select data sink type</h4>
            </div>
            <div className='card-body py-0'>
              <ul className='list-group list-group-flush list list-group-lg mx-n4 sink-type-logos'>
                {selectedSinkType === undefined && sinkTypes.map((sinkType, index) => (
                  <SinkTypeItem
                    changeSinkTypeFunc={this.changeSinkType}
                    dataSink={sinkType}
                    key={index} />
                ))}
                {selectedSinkType !== undefined &&
                  <SinkTypeItem
                    changeSinkTypeFunc={this.changeSinkType}
                    dataSink={selectedSinkType} />
                }
              </ul>
            </div>
          </div>
          {dataSink.sinkType !== undefined &&
            <React.Fragment>
              {this.state.creatingDataSinkFailed &&
                <div className='alert alert-danger fade show rounded-0' role='alert'>
                  The data sink configuration is invalid, please check the form fields.
                </div>
              }
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>General settings</h4>
                </div>
                <div className='card-body'>
                  <ConfigForm
                    errorMessages={this.state.errorMessages}
                    isRequired={true}
                    label='Name'
                    name='name'
                    onChangeFunc={this.handleChange}
                    value={dataSink.name} />
                  <div className='mt-4 mb-2'>
                    <div className='custom-control custom-switch d-flex align-items-center'>
                      <input
                        checked={dataSink.autoRestartFailedConnector}
                        className='custom-control-input clickable'
                        id='customSwitch1'
                        name='autoRestartFailedConnector'
                        onChange={this.handleChange}
                        type='checkbox' />
                      <label className='custom-control-label clickable' htmlFor='customSwitch1'>
                        Automatically restart failed connector
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <h4 className='card-header-title'>{selectedSinkType.label}-specific settings</h4>
                    </div>
                    {selectedSinkType.docs !== undefined &&
                      <div className='col-auto'>
                        <a href={`https://datacater.io/docs/sink_connectors/${selectedSinkType.docs}/`} target='_blank' className='btn btn-white' rel='noopener noreferrer'>
                          <Book className='feather-icon mr-1' />
                          Documentation
                        </a>
                      </div>
                    }
                  </div>
                </div>
                <div className='card-body'>
                  {dataSink.sinkType === 'redshift' &&
                    <RedshiftConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'solr' &&
                    <ApacheSolrConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSink.sinkType === 'ax-semantics' &&
                    <AxSemanticsConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange} />
                  }
                  {dataSink.sinkType === 'elasticsearch' &&
                    <ElasticsearchConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSink.sinkType === 'google-bigquery' &&
                    <BigQueryConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'hubspot' &&
                    <HubSpotConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSink.sinkType === 'mysql' &&
                    <MySqlConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'postgresql' &&
                    <PostgreSqlConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'rest' &&
                    <RestConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange} />
                  }
                  {dataSink.sinkType === 'snowflake' &&
                    <SnowflakeConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'typo3-pxa-product-manager' &&
                    <PxaProductManagerConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange} />
                  }
                </div>
              </div>
              <div className='card'>
                <div className='card-body'>
                  {!this.props.dataSinks.creatingDataSink &&
                    <button
                      className='btn btn-primary'
                      onClick={this.handleCreateDataSink}
                      type='submit'>
                      Create data sink
                    </button>
                  }
                  {this.props.dataSinks.creatingDataSink &&
                    <button
                      className='btn btn-primary'
                      disabled={true}
                      type='submit'>
                      <span className='spinner-border mr-2' role='status'>
                      </span>
                      Creating...
                    </button>
                  }
                </div>
              </div>
            </React.Fragment>
          }
        </form>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    dataSinks: state.dataSinks,
    pipelines: state.pipelines,
    projects:  state.projects,
    users:     state.users
  }
}

const mapDispatchToProps = {
  addDataSink:                addDataSink,
  fetchCurrentUser:           fetchCurrentUser,
  fetchProject:               fetchProject,
  testConnection:             testConnection,
  resetRetrieveTables:        resetRetrieveTables,
  resetTestConnection:        resetTestConnection,
  retrieveTablesFromDataSink: retrieveTablesFromDataSink
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDataSink);
