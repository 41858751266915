const dataSinkProfiles = (state, action) => {
  const initialState = {
    fetchingDataSinkProfiles: false,
    dataSinkProfile:          undefined,
    dataSinkProfileAttribute: undefined
  };

  switch (action.type) {
    case 'RESET_DATA_SINK_PROFILE':
      return {
        ...state,
        dataSinkProfile:          undefined,
        fetchingDataSinkProfiles: false
      };
    case 'REQUEST_CREATE_DATA_SINK_PROFILE':
      return {
        ...state,
        dataSinkProfile:          undefined,
        fetchingDataSinkProfiles: true
      };
    case 'REQUEST_DATA_SINK_PROFILE':
      return {
        ...state,
        fetchingDataSinkProfiles: true
      };
    case 'RECEIVE_DATA_SINK_PROFILE':
      return {
        ...state,
        dataSinkProfile:          action.dataSinkProfile,
        fetchingDataSinkProfiles: false
      };
    case 'REQUEST_ADD_DATA_SINK_PROFILE_ATTRIBUTE':
      return {
        ...state,
        dataSinkProfileAttribute: undefined
      };
    case 'REQUEST_UPDATE_DATA_SINK_PROFILE_ATTRIBUTE':
      return {
        ...state,
        dataSinkProfileAttribute: undefined
      };
    case 'RECEIVE_DATA_SINK_PROFILE_ATTRIBUTE':
      return {
        ...state,
        dataSinkProfileAttribute: action.dataSinkProfileAttribute
      };
    default:
      return (state || initialState);
  }
}

export default dataSinkProfiles
