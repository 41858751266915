import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import MainContent from '../../components/layout/MainContent';
import GeneralForm from '../../components/pipelines/form/GeneralForm';
import { deepCopy } from '../../helpers/deepCopy';
import {
  addPipeline,
  fetchPipelines
} from '../../actions/pipelines';
import { fetchDataSources } from '../../actions/data_sources';
import {
  createDataSourceProfile,
  fetchDataSourceProfile,
  resetDataSourceProfile,
  updateDataSourceProfile
} from '../../actions/data_source_profiles';
import {
  fetchDataSourcesOfProject,
  fetchPipelinesOfProject,
  fetchProject
} from '../../actions/projects';
import { fetchCurrentUser } from '../../actions/users';
import { parseQueryString } from '../../helpers/parseQueryString';

class NewPipeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pipeline: {
        dataSourceId:       undefined,
        pipelineSteps:      [],
        pipelineAssertions: []
      },
      pipelineCreated:     false,
      profilingDataSource: false,
      profilingFailed:     false
    };

    this.handleChange         = this.handleChange.bind(this);
    this.handleStartProfiling = this.handleStartProfiling.bind(this);
  }

  componentDidMount() {
    this.props.resetDataSourceProfile();
    this.props.fetchCurrentUser();
    this.props.fetchPipelines();
    this.props.fetchDataSources();

    const params = parseQueryString(this.props.location.search);

    if (params['dataSourceId'] !== undefined && !isNaN(params['dataSourceId'])) {
      this.handleStartProfiling(params['dataSourceId']);
    } else if (params['projectId'] !== undefined) {
      this.props.fetchProject(params['projectId'])
        .then(() => {
          if (this.props.projects.project !== undefined) {
            let pipeline = this.state.pipeline;
            pipeline['projectId'] = this.props.projects.project.id;
            this.setState({ pipeline: pipeline });
          }
        });
    }
  }

  handleChange(event) {
    let newValue = event.target.value;

    if (['dataSourceId'].includes(event.target.name) &&
        parseInt(event.target.value) === 0) {
      newValue = undefined;
    }

    let pipeline = deepCopy(this.state.pipeline);
    pipeline[event.target.name] = newValue;

    this.setState({
      pipeline:    pipeline
    });
  }

  waitForProfiling() {
    const dataSourceProfile = this.props.dataSourceProfiles.dataSourceProfile;
    if (dataSourceProfile != null) {
      if (
        (dataSourceProfile.profilingStatus !== undefined) &&
        dataSourceProfile.profilingStatus.includes('profiled')
      ) {
        clearInterval(this.profilingIntervalId);

        this.setState({
          dataSourceProfile: dataSourceProfile
        });

        let pipeline = deepCopy(this.state.pipeline);

        pipeline.dataSourceId        = dataSourceProfile.dataSourceId;
        pipeline.dataSourceProfileId = dataSourceProfile.id;
        pipeline.pipelineAssertions  = dataSourceProfile.attributes.map(function(attribute) {
          return {
            attributeId:     parseInt(attribute.id),
            assertionFilter: '',
            assertionValue:  '',
          };
        });

        this.props.addPipeline(pipeline).then(() => {
          if (this.props.pipelines.errorMessage === undefined) {
            // stop polling profile
            clearInterval(this.profilingIntervalId);
            // wait 1.5 sec to give Kafka Connect connector time to startup
            setTimeout(
              () => {
                this.setState({
                  pipelineCreated:     true,
                  profilingDataSource: false
                });
              },
              1500
            );
          } else {
            this.setState({
              profilingDataSource: false
            });
          }
        });
      } else if (
        dataSourceProfile.profilingStatus !== undefined &&
        dataSourceProfile.profilingStatus.includes('failed')
      ) {
        // handle error
        this.setState({
          profilingDataSource: false,
          profilingFailed:     true
        });
      } else {
        this.props.fetchDataSourceProfile(dataSourceProfile.id);
      }
    }
  }

  handleStartProfiling(dataSourceId) {
    if (dataSourceId !== undefined && !isNaN(dataSourceId) && dataSourceId > 0) {
      let pipeline = deepCopy(this.state.pipeline);

      pipeline.dataSourceId = dataSourceId;

      this.setState({
        pipeline:            pipeline,
        profilingDataSource: true,
        profilingFailed:     false
      });

      this.props.createDataSourceProfile(dataSourceId);

      // clear old interval
      if (this.profilingIntervalId != null) {
        clearInterval(this.profilingIntervalId);
      }

      this.profilingIntervalId = setInterval(this.waitForProfiling.bind(this), 500);
    }
  }

  render() {
    const currentUser = this.props.users.currentUser;
    const project = this.props.projects.project;

    if (this.state.pipelineCreated && (parseInt(this.props.pipelines.pipeline.id) > 0)) {
      const url = `/pipelines/${this.props.pipelines.pipeline.id}/edit`;

      return (<Redirect to={url} />);
    }

    const contentTitle = (project === undefined)
      ? 'Create pipeline'
      : `Create pipeline for project #${project.id}: ${project.name}`;

    const contentButtonLabel = (project === undefined)
      ? 'Go back to pipelines'
      : 'Go back to project';

    const contentButtonLink = (project === undefined)
      ? '/pipelines'
      : `/projects/${project.id}`;

    if (this.props.pipelines.fetchingPipelines === true) {
      return (<MainContent preTitle='Pipelines' title={contentTitle} buttonLabel={contentButtonLabel} buttonLink={contentButtonLink} />);
    }

    // check whether user has exceeded pipeline quota;
    const numberOfPipelines = this.props.pipelines.pipelines
      .filter(pipeline => (pipeline.userId !== undefined))
      .length;
    const allowedNumberOfPipelines = (currentUser !== undefined) ? currentUser.maxNumberOfPipelines : undefined;

    if (!isNaN(allowedNumberOfPipelines) && (numberOfPipelines >= allowedNumberOfPipelines)) {
      const pipelineWording = (allowedNumberOfPipelines === 1) ? 'pipeline' : 'pipelines';

      return (
        <MainContent preTitle='Pipelines' title={contentTitle} buttonLabel={contentButtonLabel} buttonLink={contentButtonLink}>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>
                Maximum number of pipelines reached
              </h4>
            </div>
            <div className='card-body'>
              Your account allows to create up to {allowedNumberOfPipelines} {pipelineWording}.
              You reached the maximum number of pipelines that you can create.
            </div>
          </div>
        </MainContent>
      );
    }

    const dataSourceProfile = this.props.dataSourceProfiles.dataSourceProfile;

    // Indicate status of creating a pipeline
    let createPipelineStatus = undefined;
    if (this.state.pipeline.dataSourceId !== undefined) {
      createPipelineStatus = 'created_stream';
    }
    if (
      this.state.profilingDataSource &&
      !this.state.profilingFailed &&
      (
        !this.state.refineProfileOfFlatFile ||
        this.props.dataSources.connectingToFlatFile
      )
    ) {
      createPipelineStatus = 'prepare_pipeline';

      if (this.state.profilingDataSource) {
        createPipelineStatus = 'profile_data';
      }

      if (this.state.pipelineCreated) {
        createPipelineStatus = 'profiled_data';
      }
    }

    const projectMemberships = (currentUser !== undefined)
      ? currentUser.projectMemberships
          .filter(_ => ['editor', 'administrator'].includes(_.membershipRole))
          .map(_ => _.projectId)
      : [];

    // select only data sources of user or of projects,
    // where user has editor or administrator role
    const dataSources = this.props.dataSources.dataSources
      .filter(dataSource =>
        (currentUser !== undefined) &&
        (this.props.projects.fetchingProjects === false) &&
        (
          // only show personal data sources, if no project is given
          (
            (project === undefined) &&
            (dataSource.userId === currentUser.id)
          ) ||
          // show data sources of all projects if no project is given
          (
            (project === undefined) &&
            (dataSource.projectId !== undefined) &&
            projectMemberships.includes(dataSource.projectId)
          ) ||
          // show data sources of one project if project is given
          (
            (project !== undefined) &&
            (dataSource.projectId !== undefined) &&
            projectMemberships.includes(project.id) &&
            (dataSource.projectId === project.id)
          )
        )
      );

    const params = parseQueryString(this.props.location.search);
    const dataSourceParamIsSet =
      params['dataSourceId'] !== undefined &&
      !isNaN(params['dataSourceId']);

    return (
      <MainContent preTitle='Pipelines' title={contentTitle} buttonLabel={contentButtonLabel} buttonLink={contentButtonLink}>
        <GeneralForm
          createPipelineStatus={createPipelineStatus}
          currentUser={currentUser}
          dataSources={dataSources}
          dataSourceParamIsSet={dataSourceParamIsSet}
          errorMessage={this.props.pipelines.errorMessage}
          handleChangeFunc={this.handleChange}
          handleStartProfilingFunc={this.handleStartProfiling}
          history={this.props.history}
          pipeline={this.state.pipeline}
          profilingDataSource={this.state.profilingDataSource}
          profilingFailed={this.state.profilingFailed} />
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    dataSources:        state.dataSources,
    dataSourceProfiles: state.dataSourceProfiles,
    pipelines:          state.pipelines,
    projects:           state.projects,
    users:              state.users
  }
}

const mapDispatchToProps = {
  addPipeline:               addPipeline,
  createDataSourceProfile:   createDataSourceProfile,
  fetchCurrentUser:          fetchCurrentUser,
  fetchDataSources:          fetchDataSources,
  fetchDataSourcesOfProject: fetchDataSourcesOfProject,
  fetchDataSourceProfile:    fetchDataSourceProfile,
  fetchPipelines:            fetchPipelines,
  fetchPipelinesOfProject:   fetchPipelinesOfProject,
  fetchProject:              fetchProject,
  resetDataSourceProfile:    resetDataSourceProfile,
  updateDataSourceProfile:   updateDataSourceProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPipeline);
