import React,
       { Component }        from 'react';
import { connect }          from 'react-redux';
import MainContent          from '../../components/layout/MainContent';
import ProjectListItem      from '../../components/projects/ProjectListItem';
import { fetchCurrentUser } from '../../actions/users';
import { Search }           from 'react-feather';

class ListProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: ''
    };
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
  }

  componentDidMount() {
    this.props.fetchCurrentUser();
  }

  updateSearchQuery(event) {
    this.setState({
      searchQuery: event.target.value
    });
  }

  render() {
    const currentUser = this.props.users.currentUser;
    const searchTokens = this.state.searchQuery.toLowerCase().trim().split(' ');
    const projects = (currentUser !== undefined)
      ? currentUser.projects
      : [];
    const filteredProjects = projects
      .filter(project =>
        searchTokens
          .map(_ => project.name.toLowerCase().includes(_))
          .filter(_ => _)
          .length === searchTokens.length
      )
      .sort(function(a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

    const buttonLabel = ((currentUser !== undefined) && currentUser.isSuperUser)
      ? 'Create project'
      : undefined;

    const buttonLink = ((currentUser !== undefined) && currentUser.isSuperUser)
      ? '/admin/projects/new'
      : undefined;

    return (
      <MainContent preTitle='Projects' title='List projects' buttonLabel={buttonLabel} buttonLink={buttonLink}>
        <div className='card'>
          <div className='card-header'>
            <form>
              <div className='input-group input-group-flush input-group-merge'>
                <input
                  type='search'
                  className='form-control form-control-prepended search'
                  onChange={this.updateSearchQuery}
                  placeholder='Search projects'
                  value={this.state.searchQuery} />
                <div className='input-group-prepend'>
                  <div className='input-group-text'>
                    <Search className='feather-icon' />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='card-body'>
            {(filteredProjects.length > 0) &&
              <ul className='list-group list-group-flush list my-n4'>
                {filteredProjects.map(project => (
                  <ProjectListItem
                    actionName='Show'
                    currentUser={this.props.users.currentUser}
                    history={this.props.history}
                    key={project.id}
                    project={project}
                    projectUrl={'/projects/' + project.id} />
                ))}
              </ul>
            }
            {!this.props.users.fetchingUsers && (filteredProjects.length === 0) && (projects.length > 0) &&
              <div className='text-center'>
                <img src='/images/illustrations/no_search_result.svg' alt='No project available' className='img-fluid mb-4' style={{'maxWidth': '272px'}} />
                <p className='mb-0'>
                  No matching project was found. Please refine your search query.
                </p>
              </div>
            }
            {!this.props.users.fetchingUsers && (filteredProjects.length === 0) && (projects.length === 0) &&
              <div className='text-center'>
                <img src='/images/illustrations/void.svg' alt='No project available' className='img-fluid mb-4' style={{'maxWidth': '272px'}} />
                <p className='mb-0'>
                  No project available.
                </p>
              </div>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    users: state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser: fetchCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(ListProjects);
