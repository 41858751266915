import React, { Component } from 'react';

class StatusResponse extends Component {
  formatResponse(kafkaConnector) {
    if (
      (kafkaConnector != null) &&
      (kafkaConnector.statusResponse !== undefined)
    ) {
      return kafkaConnector.statusResponse;
    } else {
      return 'No response available.';
    }
  }

  render() {
    if (this.props.fetchingKafkaConnector) {
      return (
        <div className='spinner-border' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      );
    } else {
      return (
        <pre className={this.props.kafkaConnectorClassName + ' mb-0'}>
          {this.formatResponse(this.props.kafkaConnector)}
        </pre>
      );
    }
  }
}

export default StatusResponse;
