import React, { Component } from 'react';
import RetrieveColumns      from '../RetrieveColumns';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class SftpConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveColumnsFunc,
      retrieveColumnsFromDataSourceFunc
    } = this.props;

    const flatColumns = dataSources.columns
      .map(column => column.name);

    const protocol = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.protocol !== undefined)
      ? dataSource.connectorConfig.protocol
      : 'sftp';

    const authenticationMethod = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.authenticationMethod !== undefined)
      ? dataSource.connectorConfig.authenticationMethod
      : 'ssh-key';

    const placeholderPort = (protocol === 'ftp') ? '21' : '22';

    const syncIntervalMode = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.syncIntervalMode !== undefined)
      ? dataSource.connectorConfig.syncIntervalMode
      : 'seconds';

    const intervalSeconds = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.intervalSeconds !== undefined)
      ? dataSource.connectorConfig.intervalSeconds
      : '';

    const cronExpression = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.cronExpression !== undefined)
      ? dataSource.connectorConfig.cronExpression
      : '';

    const workingDirectory = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.workingDirectory !== undefined)
      ? dataSource.connectorConfig.workingDirectory
      : '';

    const fileNameFilter = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.fileNameFilter !== undefined)
      ? dataSource.connectorConfig.fileNameFilter
      : '';

    const recordKeyName = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.recordKeyName !== undefined)
      ? dataSource.connectorConfig.recordKeyName
      : '';

    const dataFormat = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.dataFormat !== undefined)
      ? dataSource.connectorConfig.dataFormat
      : 'xml';

    const csvDelimiterValue = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.csvDelimiterValue !== undefined)
      ? dataSource.connectorConfig.csvDelimiterValue
      : '';

    const generateAttributeNamesFromHeaderRow = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.generateAttributeNamesFromHeaderRow !== undefined)
      ? '' + dataSource.connectorConfig.generateAttributeNamesFromHeaderRow
      : 'true';

    const xmlXpathRootNode = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.xmlXpathRootNode !== undefined)
      ? dataSource.connectorConfig.xmlXpathRootNode
      : '';

    const retrievingColumnsIsPossible =
      ![undefined, ''].includes(dataSource.hostname) &&
      ![undefined, ''].includes(dataSource.password) &&
      ![undefined, ''].includes(dataSource.username);

    const passwordLabel = (protocol === 'ftp') ? 'password' : 'SSH key';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the {passwordLabel} unless you want to change it.</span>)
      : (<span className='text-danger'>*</span>);

    const kafkaConnectConfig = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSource.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item'>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <div className='form-group'>
              <label>Protocol</label>
              <select
                className='custom-select'
                name='protocol'
                onChange={handleConfigChangeFunc}
                value={protocol}>
                <option value='ftp'>FTP</option>
                <option value='sftp'>SFTP</option>
              </select>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Hostname or IP'
              name='hostname'
              onChangeFunc={handleChangeFunc}
              value={dataSource.hostname} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Port'
              name='port'
              onChangeFunc={handleChangeFunc}
              placeholder={placeholderPort}
              value={dataSource.port} />
            {protocol === 'sftp' &&
              <div className='form-group'>
                <label>Authentication method</label>
                <select
                  className='custom-select'
                  name='authenticationMethod'
                  onChange={handleConfigChangeFunc}
                  value={authenticationMethod}>
                  <option value='ssh-key'>SSH key</option>
                  <option value='password'>Password</option>
                </select>
              </div>
            }
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Username'
              name='username'
              onChangeFunc={handleChangeFunc}
              value={dataSource.username} />
            {protocol === 'ftp' &&
              <ConfigForm
                errorMessages={errorMessages}
                label={(<React.Fragment>Password{editPasswordNotice}</React.Fragment>)}
                name='password'
                onChangeFunc={handleChangeFunc}
                type='password'
                value={dataSource.password} />
            }
            {protocol === 'sftp' &&
              <React.Fragment>
                {authenticationMethod === 'ssh-key' &&
                  <ConfigForm
                    errorMessages={errorMessages}
                    label={(<React.Fragment>Private SSH key{editPasswordNotice}</React.Fragment>)}
                    name='password'
                    onChangeFunc={handleChangeFunc}
                    rows={5}
                    type='textarea'
                    value={dataSource.password} />
                }
                {authenticationMethod === 'password' &&
                  <ConfigForm
                    errorMessages={errorMessages}
                    label={(<React.Fragment>Password{editPasswordNotice}</React.Fragment>)}
                    name='password'
                    onChangeFunc={handleChangeFunc}
                    type='password'
                    value={dataSource.password} />
                }
              </React.Fragment>
            }
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='form-group'>
                  <label>Sync mode</label>
                  <select
                    className='custom-select'
                    name='syncIntervalMode'
                    onChange={handleConfigChangeFunc}
                    value={syncIntervalMode}>
                    <option value='seconds'>Sync in fixed second intervals</option>
                    <option value='cron'>CRON expression</option>
                  </select>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                {syncIntervalMode === 'seconds' &&
                  <ConfigForm
                    errorMessages={errorMessages}
                    label='Sync interval (s)'
                    name='intervalSeconds'
                    onChangeFunc={handleConfigChangeFunc}
                    placeholder='3600'
                    value={intervalSeconds} />
                }
                {syncIntervalMode === 'cron' &&
                  <ConfigForm
                    errorMessages={errorMessages}
                    label={(<React.Fragment>Sync interval (<a href='https://www.manpagez.com/man/5/crontab/'>CRON expression</a>)</React.Fragment>)}
                    name='cronExpression'
                    onChangeFunc={handleConfigChangeFunc}
                    placeholder='0 */1 * * ?'
                    value={cronExpression} />
                }
              </div>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              label='Working directory'
              name='workingDirectory'
              onChangeFunc={handleConfigChangeFunc}
              value={workingDirectory} />
            <ConfigForm
              errorMessages={errorMessages}
              label='File name filter'
              name='fileNameFilter'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='.*'
              subLabel='Regular expression'
              value={fileNameFilter} />
            <div className='form-group'>
              <label>File type</label>
              <select
                className='custom-select'
                name='dataFormat'
                onChange={handleConfigChangeFunc}
                value={dataFormat}>
                <option value='csv'>CSV</option>
                <option value='xml'>XML</option>
              </select>
            </div>
            {dataFormat === 'csv' &&
              <React.Fragment>
                <ConfigForm
                  errorMessages={errorMessages}
                  label='CSV delimiter value'
                  name='csvDelimiterValue'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder=','
                  value={csvDelimiterValue} />
                <div className='custom-control custom-switch d-flex align-items-center mb-4'>
                  <input
                    checked={[true, 'true'].includes(generateAttributeNamesFromHeaderRow)}
                    className='custom-control-input clickable'
                    id='generateAttributeNamesSwitch'
                    name='generateAttributeNamesFromHeaderRow'
                    onChange={handleConfigChangeFunc}
                    type='checkbox' />
                  <label className='custom-control-label clickable' htmlFor='generateAttributeNamesSwitch'>
                    Generate attribute names from CSV header row
                  </label>
                </div>
              </React.Fragment>
            }
            {dataFormat === 'xml' &&
              <ConfigForm
                errorMessages={errorMessages}
                label={(<React.Fragment><a href='https://www.w3.org/TR/1999/REC-xpath-19991116/' target='_blank' rel='noopener noreferrer'>XPath</a> to root node</React.Fragment>)}
                name='xmlXpathRootNode'
                onChangeFunc={handleConfigChangeFunc}
                placeholder='/*'
                value={xmlXpathRootNode} />
            }
            <RetrieveColumns
              columnAttributeName='recordKeyName'
              columns={flatColumns}
              dataSource={dataSource}
              dataSources={dataSources}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the column names from the SFTP server failed. Please check your connection credentials.'
              handleChangeFunc={handleConfigChangeFunc}
              isRequired={true}
              label='Name of the attribute holding the record key'
              missingOptionsErrorMessage='Please provide at least the hostname, username, and private SSH key.'
              resetRetrieveColumnsFunc={resetRetrieveColumnsFunc}
              retrieveColumnsFromDataSourceFunc={retrieveColumnsFromDataSourceFunc}
              retrievingColumnsIsPossible={retrievingColumnsIsPossible}
              value={recordKeyName} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default SftpConfig;
