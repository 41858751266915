import React, { Component } from 'react';
import {
  Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import {
  HelpCircle,
  Package
} from 'react-feather';
import Filter               from '../../../../../../pipelines/Filter.js';
import NewAttributeForm     from './NewAttributeForm';
import AttributeActionValue from './AttributeActionValue';
import AttributeFilterValue from '../AttributeFilterValue';

class Config extends Component {
  getFiltersForAttribute(attribute, dataType) {
    return Filter.getFilters()
      // select only filter functions that can be applied to the given data type
      .filter(filter =>
        (filter.restrictToDataType === undefined ||
          filter.restrictToDataType.includes(dataType)) ||
        (attribute.transformationFilter !== undefined &&
          attribute.transformationFilter.length > 0 &&
          filter.key === attribute.transformationFilter))
      .sort((a, b) => (a.name > b.name) - (a.name < b.name));
  }

  render() {
    const {
      attribute,
      attributes,
      canEditPipeline,
      dataType,
      editColumn,
      flattenedDataType,
      handleChangeFunc,
      pipelineAttribute,
      pipelineStep,
      sortPosition,
      transformer
    } = this.props;

    if ((transformer !== undefined) && (transformer.key === 'add-column')) {
      return (
        <NewAttributeForm
          attribute={pipelineAttribute}
          attributes={attributes}
          canEditPipeline={canEditPipeline}
          handleChangeFunc={handleChangeFunc}
          pipelineStep={pipelineStep}
          sortPosition={sortPosition}
        />
      );
    }

    const attributesWithSameDataType = attributes
      .filter(attribute => attribute.dataType === flattenedDataType);

    // select only filters that may be applied to the given attribute
    const filters = this.getFiltersForAttribute(
      pipelineAttribute,
      flattenedDataType
    );

    // find the assigned filter
    const filter = filters
      .find(filter => filter.key === pipelineAttribute.transformationFilter);

    // Define default values for the transformer
    let defaultActionValue = undefined;

    if (transformer !== undefined && transformer.hasActionValue) {
      // By default, casting should keep the data type
      if (transformer.key === 'cast-data-type') {
        defaultActionValue = dataType;
      } else if (transformer.key === 'rename-column') {
        defaultActionValue = '';
      } else if (transformer.defaultActionValue !== undefined) {
        defaultActionValue = transformer.defaultActionValue;
      }
    }

    return (
      <React.Fragment>
        <div className='datacater-context-bar-fixed-double-element border-bottom'>
          <div className='datacater-context-bar-fixed-element w-100'>
            <div className='row w-100 m-0 align-items-center'>
              <div className='col p-0'>
                <h3 className='mb-0 text-nowrap'>
                  <Package className='feather-icon mr-2 mt-n1'/>
                  {transformer.name}
                </h3>
              </div>
              <div className='col-auto p-0'>
                <OverlayTrigger
                  placement='bottom'
                  delay={{ show: 100, hide: 100 }}
                  overlay={(props) => <Tooltip {...props}>{transformer.description}</Tooltip>}>
                <HelpCircle className='feather-icon' />
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className='datacater-context-bar-fixed-element w-100 datacater-context-bar-search-field'>
            <Button
              onClick={handleChangeFunc}
              data-attribute-id={attribute.id}
              data-sort-position={sortPosition}
              data-property=''
              data-value=''
              disabled={!canEditPipeline}
              className='delete-btn w-100'
              variant='link'>
              Reset transformation
            </Button>
          </div>
        </div>
        <div className='form-group mb-0 py-4 datacater-context-bar-function-config'>
          {transformer.hasActionValue &&
            <div className='form-group mb-3'>
              {transformer.actionValueName !== undefined &&
                <label>{transformer.actionValueName}:</label>
              }
              <AttributeActionValue
                attribute={attribute}
                attributeId={editColumn.attributeId}
                attributesWithSameDataType={attributesWithSameDataType}
                attributes={attributes}
                canEditPipeline={canEditPipeline}
                dataSourceMappings={this.props.dataSourceMappings}
                dataType={dataType}
                defaultActionValue={defaultActionValue}
                handleChangeFunc={handleChangeFunc}
                name='actionValue'
                pipelineStep={pipelineStep}
                sortPosition={editColumn.sortPosition}
                transformer={transformer}
                transformationState={this.props.transformationState}
                value={pipelineAttribute.actionValue}
                value2={pipelineAttribute.actionValue2} />
            </div>
          }

          {transformer.hasActionValue2 &&
            <div className='form-group mb-3'>
              {transformer.actionValue2Name !== undefined &&
                <label>{transformer.actionValue2Name}:</label>
              }
              <AttributeActionValue
                attribute={attribute}
                attributeId={editColumn.attributeId}
                attributesWithSameDataType={attributesWithSameDataType}
                attributes={attributes}
                canEditPipeline={canEditPipeline}
                dataSourceMappings={this.props.dataSourceMappings}
                dataType={dataType}
                defaultActionValue={transformer.defaultActionValue2}
                handleChangeFunc={handleChangeFunc}
                name='actionValue2'
                pipelineStep={pipelineStep}
                sortPosition={editColumn.sortPosition}
                transformer={transformer}
                transformationState={this.props.transformationState}
                value={pipelineAttribute.actionValue2} />
            </div>
          }

          {transformer.allowFilters &&
            <React.Fragment>
              <div className='form-group mb-3'>
                <label>Restrict transformation:</label>
                <select
                  className='custom-select'
                  data-attribute-id={editColumn.attributeId}
                  data-sort-position={editColumn.sortPosition}
                  data-property='transformationFilter'
                  disabled={!canEditPipeline}
                  onChange={handleChangeFunc}
                  value={pipelineAttribute.transformationFilter || ''}>
                  <React.Fragment>
                    <option value=''>Apply transformation to all values</option>
                    {filters.map((filter, index) => (
                      <option
                        key={index}
                        value={filter.key}>
                        Restrict transformation to {filter.name}
                      </option>
                    ))}
                  </React.Fragment>
                </select>
              </div>

              {transformer.allowFilters && (filter !== undefined) && filter.hasFilterValue &&
                <div className='form-group'>
                  <label>{filter.filterValueName}:</label>
                  <AttributeFilterValue
                    attributeId={editColumn.attributeId}
                    canEditPipeline={canEditPipeline}
                    dataType={dataType}
                    filter={filter}
                    handleChangeFunc={handleChangeFunc}
                    name='filterValue'
                    sortPosition={editColumn.sortPosition}
                    value={pipelineAttribute.filterValue}/>
                </div>
              }

              {!transformer.allowFilters &&
                <div className='form-group mb-3'>
                  <label>Restrict transformation:</label>
                  <div className='font-italic'>This transformation function does not allow filters and is applied to all values.</div>
                </div>
              }
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default Config;
