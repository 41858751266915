import React, { Component } from 'react';
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { Layers } from 'react-feather';
import { blurOnEnterKeyDown } from '../../../helpers/blurOnEnterKeyDown';

class PipelineNameForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editingName: false
    };

    this.enableEditingName = this.enableEditingName.bind(this);
    this.handleUpdate      = this.handleUpdate.bind(this);
  }

  enableEditingName(event) {
    this.setState({
      editingName: true
    });
  }

  handleUpdate(event) {
    this.setState({
      editingName: false
    });
  }

  render() {
    const {
      canEditPipeline,
      currentUser,
      pipeline,
      project
    } = this.props;

    const name = (
      (pipeline !== undefined) &&
      (pipeline.name !== undefined) &&
      (pipeline.name.length > 0)
    ) ? pipeline.name : 'Untitled Pipeline';

    const userEmail =
      (
        ![currentUser, pipeline].includes(undefined) &&
        (pipeline.userId === currentUser.id)
      )
        ? currentUser.email
        : undefined;

    let pipelineOwner = undefined;

    if (userEmail !== undefined) {
      pipelineOwner = (
        <div className='col-auto pr-0'>
          <OverlayTrigger
              placement='bottom'
              delay={{ show: 100, hide: 100 }}
              overlay={(props) => (<Tooltip {...props}>{userEmail}</Tooltip>)}>
            <Layers className='feather-icon' />
          </OverlayTrigger>
        </div>
      );
    } else if (project !== undefined) {
      pipelineOwner = (
        <div className='col-auto pr-0'>
          <OverlayTrigger
              placement='bottom'
              delay={{ show: 100, hide: 100 }}
              overlay={(props) => (<Tooltip {...props}>Project #{project.id}: {project.name}</Tooltip>)}>
            <a href={`/projects/${project.id}`}>
              <Layers className='feather-icon' />
            </a>
          </OverlayTrigger>
        </div>
      );
    }

    if (canEditPipeline && this.state.editingName) {
      return (
        <div className='row align-items-center'>
          {pipelineOwner}
          <div className='col'>
            <input
              autoFocus
              type='text'
              className='form-control d-inline-block'
              name='name'
              onBlur={this.handleUpdate}
              onChange={this.props.handleChangeFunc}
              onKeyDown={blurOnEnterKeyDown}
              placeholder='Name the pipeline'
              value={pipeline.name} />
          </div>
        </div>
      );
    } else {
      return (
        <div className='row align-items-center'>
          {pipelineOwner}
          <div className='col pl-1'>
            {canEditPipeline &&
              <h4
                className='d-inline-block m-0 mr-2'
                onClick={this.enableEditingName}>
                {name}
              </h4>
            }
            {!canEditPipeline &&
              <h4 className='d-inline-block m-0 mr-2 border-none'>
                {name}
              </h4>
            }
          </div>
        </div>
      );
    }
  }
}

export default PipelineNameForm;
