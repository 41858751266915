import React, { Component } from 'react';
import ConfigForm from '../../data_stores/ConfigForm';

class AxSemanticsConfig extends Component {
  render() {
    const {
      dataSink,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc
    } = this.props;

    const collectionId = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.collectionId !== undefined)
      ? dataSink.connectorConfig.collectionId
      : '';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the refresh token unless you want to change it.</span>)
      : (<span className='text-danger'>*</span>);

    return (
      <React.Fragment>
        <ConfigForm
          errorMessages={errorMessages}
          label={(<React.Fragment>AX Semantics refresh token{editPasswordNotice}</React.Fragment>)}
          name='password'
          onChangeFunc={handleChangeFunc}
          value={dataSink.password} />
        <ConfigForm
          errorMessages={errorMessages}
          isRequired={true}
          label='AX Semantics collection ID'
          name='collectionId'
          onChangeFunc={handleConfigChangeFunc}
          subLabel='You can provide multiple collection IDs, separated by a comma (,).'
          value={collectionId} />
      </React.Fragment>
    );
  }
}

export default AxSemanticsConfig;
