import React, { Component }    from 'react';
import { connect }             from 'react-redux';
import { ProgressBar }         from 'react-bootstrap';
import Activity                from '../components/activities/Activity';
import PipelineListItem        from '../components/pipelines/PipelineListItem';
import ProjectWidgetListItem   from '../components/projects/ProjectWidgetListItem';
import { fetchActivityEvents } from '../actions/activity_events';
import { fetchDataSources }    from '../actions/data_sources';
import { fetchDataSinks }      from '../actions/data_sinks';
import {
  fetchActivePipelines,
  fetchPipelines
}                              from '../actions/pipelines';
import { fetchCurrentUser }    from '../actions/users';
import { daysInTrialLeft }     from '../helpers/daysInTrialLeft';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedDataSources: false,
      loadedPipelines:   false
    };

    this.hideWelcomeWidget = this.hideWelcomeWidget.bind(this);
  }

  componentDidMount() {
    this.props.fetchCurrentUser();
    this.props.fetchActivityEvents();
    this.props.fetchActivePipelines();
    this.props.fetchPipelines().then(() => {
      this.setState({
        loadedPipelines: true
      });
    });
    this.props.fetchDataSources().then(() => {
      this.setState({
        loadedDataSources: true
      });
    });
    this.props.fetchDataSinks();
  }

  hideWelcomeWidget(event) {
    event.preventDefault();
    localStorage.setItem('showWelcomeScreen', false);
    // force re-rendering of component (without welcome screen)
    this.forceUpdate();
  }

  render() {
    // personal pipelines
    const pipelines = this.props.pipelines.pipelines
      .filter(pipeline => pipeline.userId !== undefined);

    const activePipelines = this.props.pipelines.activePipelines
      // sort by pipeline id descending
      .sort((a, b) => b.id - a.id);

    const activities = this.props.activityEvents.activityEvents;
    const history = this.props.history;
    const loadingActivities = this.props.activityEvents.fetchingActivityEvents;

    const currentUser = this.props.users.currentUser;

    const projects = ((currentUser !== undefined) && (currentUser.projects !== undefined))
        ? currentUser.projects
        : [];

    const sortedProjects = projects
      .sort(function(a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

    const reachedPipelineLimit = (
      (currentUser !== undefined) &&
      !isNaN(currentUser.maxNumberOfPipelines) &&
      (pipelines.length >= currentUser.maxNumberOfPipelines)
    );

    const nonTemporaryDataSources = this.props.dataSources.dataSources
      .filter(dataSource => !dataSource.isTemporary);

    const dataSourcesAreMissing = (
      !this.props.dataSources.fetchingDataSources &&
      (nonTemporaryDataSources.length === 0)
    );

    const pipelinesAreMissing = (
      !this.props.pipelines.fetchingPipelines &&
      (this.props.pipelines.pipelines.length === 0)
    );

    const showWelcomeWidget =
      (localStorage.getItem('showWelcomeScreen') !== 'false') &&
      this.state.loadedDataSources &&
      this.state.loadedPipelines &&
      (dataSourcesAreMissing || pipelinesAreMissing);

    let trialEndsInDays = undefined;
    if ((currentUser !== undefined) && (currentUser.trialEndsAt !== undefined)) {
      trialEndsInDays = daysInTrialLeft(currentUser.trialEndsAt);
    }

    return (
      <div className='main-content'>
        <div className='header'>
          <div className='container'>
            <div className='header-body'>
              <div className='row align-items-end'>
                <div className='col'>
                  <h6 className='header-pretitle'>
                    Home
                  </h6>
                  <h1 className='header-title'>
                    Dashboard
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          {showWelcomeWidget &&
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body text-center'>
                    <div className='row justify-content-center'>
                      <div className='col-12'>
                        <a className='float-right position-relative' href='/home/' onClick={this.hideWelcomeWidget}>Hide tips</a>
                        <img src='/images/illustrations/welcome.svg' alt='Welcome to DataCater' className='img-fluid mt-n5 mb-4 ml-6' style={{'maxWidth': '272px'}} />
                        <h2 className='font-weight-bold'>
                          Welcome to DataCater!
                        </h2>
                        <p className='text-muted mb-4'>
                          Get started using DataCater by creating your first data source or having a look at our quickstart tutorial.
                        </p>
                        <p className='mb-0'>
                          {dataSourcesAreMissing &&
                            <a href='/data_sources/new/' className='btn btn-primary lift'>
                              Create first data source
                            </a>
                          }
                          {!dataSourcesAreMissing && pipelinesAreMissing &&
                            <a href='/pipelines/new/' className='btn btn-primary lift'>
                              Create first pipeline
                            </a>
                          }
                          <a href='https://datacater.io/docs/quickstart/' className='btn btn-white lift ml-2' target='_blank'>
                            Quickstart tutorial
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className='row'>
            <div className='col-12 col-xl-7 order-1 order-xl-0'>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>
                    Recent Activity
                  </h4>
                </div>
                <div className='card-body'>
                  {loadingActivities &&
                    <div className='spinner-border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  }
                  {!loadingActivities && activities.length === 0 &&
                    <div>
                      No activity available.
                    </div>
                  }
                  {!loadingActivities && activities.length > 0 &&
                    <div className='list-group list-group-flush list-group-activity my-n3'>
                      {activities.map(activity => (
                        <Activity
                          activity={activity}
                          key={activity.id} />
                      ))}
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-5 order-0 order-xl-1'>
              {(currentUser !== undefined) && !isNaN(currentUser.maxNumberOfPipelines) &&
                <div className='card'>
                  <div className='card-header'>
                    <h4 className='card-header-title font-weight-bold'>Usage</h4>
                  </div>
                  {(trialEndsInDays !== undefined) && !isNaN(trialEndsInDays) && (trialEndsInDays >= 0) &&
                    <div className='card-body border-bottom'>
                      Your DataCater trial ends in {trialEndsInDays} days. <a href='mailto:info@datacater.io?subject=Upgrade my trial account to paid'>Upgrade to paid.</a>
                    </div>
                  }
                  <div className='card-body'>
                    {reachedPipelineLimit &&
                      <div className='alert alert-danger alert-dismissible mt-n4 ml-n4 mr-n4 rounded-0'>
                        You reached the maximum number of pipelines. Please reach out to the support for raising the limit.
                      </div>
                    }
                    <p>
                      <span className='font-weight-bold'>{pipelines.length}</span> of <span className='font-weight-bold'>{currentUser.maxNumberOfPipelines}</span> pipelines
                    </p>
                    <ProgressBar now={(pipelines.length / currentUser.maxNumberOfPipelines) * 100} />
                  </div>
                </div>
              }
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>
                    Projects
                  </h4>
                </div>
                <div className='card-body'>
                  {sortedProjects.length > 0 &&
                    <ul className='list-group list-group-flush list my-n4 datacater-widget-projects'>
                      {sortedProjects.map(project => (
                        <ProjectWidgetListItem
                          key={project.id}
                          project={project} />
                      ))}
                    </ul>
                  }
                  {sortedProjects.length === 0 && 'No projects.'}
                </div>
              </div>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>
                    Running Pipelines
                  </h4>
                </div>
                <div className='card-body'>
                  {activePipelines.length > 0 &&
                    <ul className='list-group list-group-flush list my-n4 datacater-widget-running-pipelines'>
                      {activePipelines.map(pipeline => (
                        <PipelineListItem
                           dataSinks={this.props.dataSinks.dataSinks}
                           dataSources={this.props.dataSources.dataSources}
                           history={history}
                           key={pipeline.id}
                           pipeline={pipeline} />
                      ))}
                    </ul>
                  }
                  {activePipelines.length === 0 && 'No running pipelines.'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    activityEvents: state.activityEvents,
    dataSinks:      state.dataSinks,
    dataSources:    state.dataSources,
    pipelines:      state.pipelines,
    users:          state.users
  }
}

const mapDispatchToProps = {
  fetchActivityEvents:  fetchActivityEvents,
  fetchActivePipelines: fetchActivePipelines,
  fetchCurrentUser:     fetchCurrentUser,
  fetchDataSinks:       fetchDataSinks,
  fetchDataSources:     fetchDataSources,
  fetchPipelines:       fetchPipelines
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
