import React, { Component } from 'react';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';

class OpenSkyConfig extends Component {
  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc
    } = this.props;

    const intervalMillis = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.intervalMillis !== undefined)
      ? dataSource.connectorConfig.intervalMillis
      : '';

    return (
      <React.Fragment>
        <ConfigForm
          errorMessages={errorMessages}
          label='Username'
          name='username'
          onChangeFunc={handleChangeFunc}
          value={dataSource.username} />
        <ConfigForm
          errorMessages={errorMessages}
          label='Password'
          name='password'
          onChangeFunc={handleChangeFunc}
          type='password'
          value={dataSource.password} />
        <ConfigForm
          errorMessages={errorMessages}
          label='Interval in between API requests (ms)'
          name='intervalMillis'
          onChangeFunc={handleConfigChangeFunc}
          value={intervalMillis} />
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default OpenSkyConfig;
