import React, {Component} from 'react';
import { ListGroup }      from 'react-bootstrap';
import { deepCopy }       from '../../../../helpers/deepCopy';

class AttributeAssertionList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      searchQuery: ''
    };

    this.updateSearchQuery = this.updateSearchQuery.bind(this);
  }

  updateSearchQuery (event) {
    event.preventDefault();

    this.setState({
      searchQuery: event.target.value
    });
  }

  render () {
    const { assertion, attribute } = this.props;

    const searchTokens = this.state.searchQuery.toLowerCase().trim().split(' ');
    const filtersItems = deepCopy(this.props.filters);

    const filters = filtersItems
      .filter(filter =>
        filter.restrictToDataType === undefined ||
        filter.restrictToDataType.includes(attribute.dataType))
      .filter(filter =>
          searchTokens
            .map(token =>
              filter.name.toLowerCase().includes(token) ||
              filter.description.toLowerCase().includes(token))
            .filter(_ => _)
            .length === searchTokens.length)
      .sort((a, b) => (a.name > b.name) - (a.name < b.name));

    return (
      <React.Fragment>
        <div className='border-bottom border-light datacater-context-bar-fixed-element mx-n4 px-4 datacater-context-bar-search-field'>
          <div className='input-group input-group-flush input-group-merge'>
            <input
              type='search'
              className='form-control form-control-prepended search'
              onChange={this.updateSearchQuery}
              placeholder='Search filter functions'
              value={this.state.searchQuery}
            />
            <div className='input-group-prepend'>
              <div className='input-group-text'>
                <span className='fe fe-search'></span>
              </div>
            </div>
          </div>
        </div>
        {filters.length > 0 &&
          <div className='datacater-popover-pipeline-filter-list pt-2 list-group-flush list mx-n4 datacater-context-bar-flex-list'>
            {filters.map((filter, index) => (
              <ListGroup.Item
                className='px-5 pt-3 pb-0 border-0 font-size-sm'
                key={index}
                action
                onClick={(event) => {
                  this.props.handleChangeFunc(event, assertion.attributeId, 'assertionFilter', filter.key)
                }}>
                <div className='row align-items-center justify-content-center border-bottom pb-3'>
                  <div className='col pl-0 pr-3'>
                    <div className='font-weight-bold mb-1'>
                      Require {filter.name}
                    </div>
                    <div>
                      {filter.description}
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </div>
        }
        {filters.length === 0 &&
          <h4 className='pt-4 mb-0 text-center text-black datacater-context-bar-flex-list'>
            No filter function found.
          </h4>
        }
      </React.Fragment>
    );
  }
}

export default AttributeAssertionList;
