export function fetchProjectMembershipsOfProject(projectId) {
  const requestProjectMemberships = () => ({
    type: 'REQUEST_PROJECT_MEMBERSHIPS'
  });

  const receivedProjectMemberships = response => ({
    type:  'RECEIVE_PROJECT_MEMBERSHIPS',
    projectMemberships: response
  });

  return function (dispatch) {
    dispatch(requestProjectMemberships());

    return fetch('/api/projects/' + projectId + '/memberships',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedProjectMemberships(json));
      });
  };
}

export function replaceProjectMembershipsOfProject(projectId, projectMemberships) {
  const requestReplaceProjectMemberships = () => ({
    type: 'REQUEST_REPLACE_PROJECT_MEMBERSHIPS'
  });

  const receivedReplaceProjectMemberships = response => ({
    type:               'RECEIVE_REPLACE_PROJECT_MEMBERSHIPS',
    projectMemberships: response
  });

  return function (dispatch) {
    dispatch(requestReplaceProjectMemberships());

    const cleanProjectMemberships = projectMemberships
      .map(membership => {
        delete membership.createdAt;

        return membership;
      });

    return fetch(
        '/api/projects/' + projectId + '/memberships',
        {
          method:      'PUT',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify({
            projectMemberships: cleanProjectMemberships
          }),
        }
      )
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedReplaceProjectMemberships(json));
      });
  };
}

export function toggleNotificationsOfProject(projectId) {
  return function (dispatch) {
    return fetch(
        `/api/projects/${projectId}/toggle_notifications`,
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'X-Auth-token': localStorage.getItem('userToken')
          }
        }
      )
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.text();
          }
        },
        error => console.log('An error occurred.', error)
      )
  };
}

