import React, { Component } from 'react';

class ConfigForm extends Component {
  render() {
    const {
      errorMessages,
      isRequired,
      label,
      name,
      onChangeFunc,
      placeholder,
      rows,
      subLabel,
      type,
      value
    } = this.props;

    const requiredJsx = isRequired
      ? (<span className='text-danger'>*</span>)
      : (<React.Fragment></React.Fragment>);

    const classNames = ((errorMessages !== undefined) && (errorMessages[name] !== undefined))
      ? 'form-control is-invalid'
      : 'form-control';

    return (
      <div className='form-group'>
        <label>
          {label}{requiredJsx}
          {(subLabel !== undefined) &&
            <span className='ml-1 text-gray font-size-sm'>{subLabel}</span>
          }
        </label>
        {(type === 'textarea') &&
          <textarea className={classNames} rows={rows || 5} name={name} onChange={onChangeFunc}>{value || ''}</textarea>
        }
        {[undefined, 'text', 'password'].includes(type) &&
          <input
            type={type || 'text'}
            className={classNames}
            name={name}
            onChange={onChangeFunc}
            placeholder={placeholder}
            value={value || ''} />
        }
        {((errorMessages !== undefined) && (errorMessages[name] !== undefined)) &&
          <p className='mt-2 mb-0 font-weight-bold text-danger'>{errorMessages[name]}</p>
        }
      </div>
    );
  }
}

export default ConfigForm;
