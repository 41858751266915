export function fetchDataSources() {
  const requestDataSources = () => ({
    type: 'REQUEST_DATA_SOURCES'
  });

  const receivedDataSources = response => ({
    type:        'RECEIVE_DATA_SOURCES',
    dataSources: response
  });

  return function (dispatch) {
    dispatch(requestDataSources());

    return fetch('/api/data_sources',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedDataSources(json));
      });
  };
}

export function fetchDataSource(id) {
  const requestDataSource = () => ({
    type: 'REQUEST_DATA_SOURCE'
  });

  const receivedDataSource = response => ({
    type:       'RECEIVE_DATA_SOURCE',
    dataSource: response
  });

  return function (dispatch) {
    dispatch(requestDataSource());

    return fetch('/api/data_sources/' + id,
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else if (response.status === 404) {
            window.location = '/404';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedDataSource(json));
      });
  };
}

export function addDataSource(dataSource) {
  const requestAddDataSource = () => ({
    type: 'REQUEST_ADD_DATA_SOURCE'
  });

  const receivedAddDataSource = response => ({
    type:       'RECEIVE_ADD_DATA_SOURCE',
    dataSource: response
  });

  return function (dispatch) {
    dispatch(requestAddDataSource());

    let cleanDataSource = dataSource;
    if (cleanDataSource.port !== undefined) {
      cleanDataSource.port = parseInt(cleanDataSource.port);
    }

    return fetch(
        '/api/data_sources',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSource)
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        dispatch(receivedAddDataSource(json));
      });
  };
}

export function updateDataSource(dataSource) {
  const requestUpdateDataSource = () => ({
    type: 'REQUEST_UPDATE_DATA_SOURCE'
  });

  const receivedUpdateDataSource = () => ({
    type: 'RECEIVE_UPDATE_DATA_SOURCE'
  });

  return function (dispatch) {
    dispatch(requestUpdateDataSource());

    // remove timestamps
    let cleanDataSource = dataSource;
    delete cleanDataSource.createdAt;
    delete cleanDataSource.updatedAt;
    delete cleanDataSource.connectionHeartbeat;
    if (cleanDataSource.port !== undefined) {
      cleanDataSource.port = parseInt(cleanDataSource.port);
    }

    return fetch(
        '/api/data_sources/' + dataSource.id,
        {
          method:      'PUT',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSource),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then(() => {
        dispatch(receivedUpdateDataSource());
      });
  };
}

export function deleteDataSource(id) {
  const requestDeleteDataSource = () => ({
    type: 'REQUEST_DELETE_DATA_SOURCE'
  });

  const receivedDeleteDataSource = () => ({
    type: 'RECEIVE_DELETE_DATA_SOURCE'
  });

  return function (dispatch) {
    dispatch(requestDeleteDataSource());

    return fetch(
        '/api/data_sources/' + id,
        {
          method:      'DELETE',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'X-Auth-token': localStorage.getItem('userToken')
          }
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.text();
          }
        },
        error => console.log('An error occurred.', error)
      ).then(() => {
        dispatch(receivedDeleteDataSource());
      });
  };
}

export function persistDataSource(dataSourceId, dataSource) {
  return function (dispatch) {
    let cleanDataSource = dataSource;
    if (cleanDataSource.port !== undefined) {
      cleanDataSource.port = parseInt(cleanDataSource.port);
    }

    return fetch(
      '/api/data_sources/' + dataSourceId + '/persist',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSource)
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.text();
          }
        },
        error => console.log('An error occurred.', error)
      );
  };
}

export function transferDataSourceToProject(dataSourceId, projectId) {
  const requestTransferDataSource = () => ({
    type: 'REQUEST_TRANSFER_DATA_SOURCE_TO_PROJECT'
  });

  const receivedTransferDataSource = (dataSource) => ({
    dataSource: dataSource,
    type:       'RECEIVE_TRANSFER_DATA_SOURCE_TO_PROJECT'
  });

  return function (dispatch) {
    dispatch(requestTransferDataSource());

    return fetch(
        `/api/data_sources/${dataSourceId}/transfer_to_project?projectId=${projectId}`,
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'X-Auth-token': localStorage.getItem('userToken')
          }
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        dispatch(receivedTransferDataSource(json));
      });
  };
}

export function testConnection(dataSource) {
  const requestTestConnection = () => ({
    type: 'REQUEST_SOURCE_TEST_CONNECTION'
  });

  const receivedTestConnection = (responseCode, connectionTestError) => ({
    type:                'RECEIVE_SOURCE_TEST_CONNECTION',
    responseCode:        responseCode,
    connectionTestError: connectionTestError
  });

  return function (dispatch) {
    dispatch(requestTestConnection());

    // remove timestamps
    let cleanDataSource = dataSource;
    delete cleanDataSource.createdAt;
    delete cleanDataSource.updatedAt;
    delete cleanDataSource.connectionHeartbeat;

    if (cleanDataSource.port !== undefined) {
      cleanDataSource.port = parseInt(cleanDataSource.port);
    }

    return fetch(
        '/api/data_sources/test_connection',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSource),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else if (response.status === 400) {
            return response.json().then((json) => {
              if (json.error !== undefined) {
                dispatch(receivedTestConnection(response.status, json.error));
              } else {
                dispatch(receivedTestConnection(response.status));
              }
            });
          } else {
            dispatch(receivedTestConnection(response.status));
          }
        },
        error => console.log('An error occurred.', error)
      );
  };
}

export function getNumberOfRecordsInDataSource(dataSourceId, pipelineId) {
  const requestCountDataSource = () => ({
    type: 'REQUEST_COUNT_DATA_SOURCE'
  });

  const receiveCountDataSource = (response) => ({
    numberOfRecords: response.count,
    type:            'RECEIVE_COUNT_DATA_SOURCE'
  });

  return function (dispatch) {
    dispatch(requestCountDataSource());

    return fetch('/api/data_sources/' + dataSourceId + '/count?pipelineId=' + pipelineId,
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receiveCountDataSource(json));
      });
  };
}

export function getNumberOfRecordsInJoinedDataSource(dataSourceId, pipelineId) {
  const requestCountJoinedDataSource = () => ({
    type: 'REQUEST_COUNT_JOINED_DATA_SOURCE'
  });

  const receiveCountJoinedDataSource = (response) => ({
    numberOfRecords: response.count,
    type:            'RECEIVE_COUNT_JOINED_DATA_SOURCE'
  });

  return function (dispatch) {
    dispatch(requestCountJoinedDataSource());

    return fetch(
        '/api/data_sources/' + dataSourceId + '/count?pipelineId=' + pipelineId,
        { headers: { 'X-Auth-token': localStorage.getItem('userToken') }}
      )
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receiveCountJoinedDataSource(json));
      });
  };
}

export function retrieveTablesFromDataSource(dataSource) {
  const requestRetrieveTables = () => ({
    type: 'REQUEST_DATA_SOURCE_TABLES'
  });

  const receiveRetrieveTables = (tables) => ({
    type:   'RECEIVE_DATA_SOURCE_TABLES',
    tables: tables
  });

  const receiveRetrieveTablesFailed = (error) => ({
    connectionError: error,
    type:            'RECEIVE_DATA_SOURCE_TABLES_FAILED'
  });

  return function (dispatch) {
    dispatch(requestRetrieveTables());

    // remove timestamps
    let cleanDataSource = dataSource;
    delete cleanDataSource.createdAt;
    delete cleanDataSource.updatedAt;
    delete cleanDataSource.connectionHeartbeat;
    if (cleanDataSource.port !== undefined) {
      cleanDataSource.port = parseInt(cleanDataSource.port);
    }

    return fetch(
        '/api/data_sources/retrieve_tables',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSource),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else if (response.status >= 400) {
            return response
              .json()
              .then((json) => dispatch(receiveRetrieveTablesFailed(json.error)));
          } else {
            return response
              .json()
              .then((json) => dispatch(receiveRetrieveTables(json)));
          }
        },
        error => console.log('An error occurred.', error)
      );
  };
}

export function retrieveColumnsFromDataSource(dataSource) {
  const requestRetrieveColumns = () => ({
    type: 'REQUEST_DATA_SOURCE_COLUMNS'
  });

  const receiveRetrieveColumns = (columns) => ({
    columns: columns,
    type:    'RECEIVE_DATA_SOURCE_COLUMNS'
  });

  const receiveRetrieveColumnsFailed = (error) => ({
    connectionError: error,
    type:            'RECEIVE_DATA_SOURCE_COLUMNS_FAILED'
  });

  return function (dispatch) {
    dispatch(requestRetrieveColumns());

    // remove timestamps
    let cleanDataSource = dataSource;
    delete cleanDataSource.createdAt;
    delete cleanDataSource.updatedAt;
    delete cleanDataSource.connectionHeartbeat;
    if (cleanDataSource.port !== undefined) {
      cleanDataSource.port = parseInt(cleanDataSource.port);
    }

    return fetch(
        '/api/data_sources/retrieve_columns',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanDataSource),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else if (response.status >= 400) {
            return response
              .json()
              .then((json) => dispatch(receiveRetrieveColumnsFailed(json.error)));
          } else {
            return response
              .json()
              .then((json) => dispatch(receiveRetrieveColumns(json)));
          }
        },
        error => console.log('An error occurred.', error)
      );
  };
}

export function resetTestConnection() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_SOURCE_TEST_CONNECTION'
    });
  }
}

export function resetRetrieveTables() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_SOURCE_TABLES'
    });
  }
}

export function resetRetrieveColumns() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_SOURCE_COLUMNS'
    });
  }
}
