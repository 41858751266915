import React, { Component } from 'react';
import { Link }             from 'react-router-dom';

class StreamListItem extends Component {
  getPathToStream(stream) {
    return (stream.id != null) ? ('/streams/' + stream.id) : '/streams';
  }

  render() {
    const {
      currentUser,
      stream
    } = this.props;

    const project =
      (
        ![currentUser, stream].includes(undefined) &&
        (stream.userId === undefined) &&
        (stream.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === stream.projectId)
        : undefined;

    return (
      <li className='list-group-item py-2' key={stream.id}>
        <div className='row align-items-center py-2'>
          <div className='col-auto source-type-logos'>
            <div className='logo-wrap'>
              <img
                className='w-50'
                src='/images/kafka-logo.png'
                alt='Apache Kafka' />
            </div>
          </div>
          <div className='col'>
            <h4 className='mb-0 name'>
              {project !== undefined &&
                <React.Fragment>
                  <a href={`/projects/${project.id}`} className='text-gray'>
                    {project.name}
                  </a>
                  <span className='text-gray mx-2'>
                    /
                  </span>
                </React.Fragment>
              }
              <Link to={this.getPathToStream(stream)}>
                #{stream.id}: {stream.name}
              </Link>
            </h4>
          </div>
          <div className='col-auto'>
            <Link to={this.getPathToStream(stream)} className='btn btn-white btn-sm'>
              Show
            </Link>
          </div>
        </div>
      </li>
    );
  }
}

export default StreamListItem;
