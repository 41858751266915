import React,
       { Component }      from 'react';
import { connect }        from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import MainContent      from '../../components/layout/MainContent';
import PipelineListItem from '../../components/pipelines/PipelineListItem';
import DataSinkLogo     from '../../components/data_sinks/DataSinkLogo';
import {
  deleteDataSink,
  fetchDataSink,
  transferDataSinkToProject
} from '../../actions/data_sinks';
import { fetchDataSources } from '../../actions/data_sources';
import { fetchPipelines }   from '../../actions/pipelines';
import { fetchCurrentUser } from '../../actions/users';

class ShowDataSink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSinkDeleted:   false,
      errorMessage:      '',
      showDeleteModal:   false,
      showTransferForm:  false,
      showTransferModal: false,
      transferProjectId: undefined
    };

    this.handleDeleteDataSink     = this.handleDeleteDataSink.bind(this);
    this.toggleDeleteModal        = this.toggleDeleteModal.bind(this);
    this.toggleTransferModal      = this.toggleTransferModal.bind(this);
    this.enableTransferForm       = this.enableTransferForm.bind(this);
    this.changeProjectForTransfer = this.changeProjectForTransfer.bind(this);
    this.handleTransferDataSink   = this.handleTransferDataSink.bind(this);
  }

  componentDidMount() {
    this.props.fetchCurrentUser();
    this.props.fetchDataSink(this.getDataSinkId());
    this.props.fetchPipelines();
    this.props.fetchDataSources();
  }

  getDataSinkId() {
    return parseInt(this.props.match.params.id);
  }

  handleDeleteDataSink(event) {
    event.preventDefault();

    this.props.deleteDataSink(this.getDataSinkId())
      .then(() => {
        this.setState({ dataSinkDeleted: true })
      });
  }

  toggleTransferModal() {
    if (
      // ignore valid project id if hiding transfer modal
      this.state.showTransferModal ||
      // validate valid project id if showing transfer modal
      !isNaN(parseInt(this.state.transferProjectId))
    ) {
      this.setState({
        showTransferModal: !this.state.showTransferModal
      });
    }
  }

  enableTransferForm() {
    this.setState({
      showTransferForm: true
    });
  }

  changeProjectForTransfer(event) {
    this.setState({
      transferProjectId: event.target.value
    });
  }

  handleTransferDataSink() {
    this.props.transferDataSinkToProject(
      this.getDataSinkId(),
      this.state.transferProjectId
    );
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  render() {
    const dataSink = this.props.dataSinks.dataSink;

    if (this.state.dataSinkDeleted) {
      if ((dataSink !== undefined) && (dataSink.projectId !== undefined)) {
        return (<Redirect to={`/projects/${dataSink.projectId}`} />);
      } else {
        return (<Redirect to='/data_sinks' />);
      }
    }

    if (dataSink === undefined) {
      return (<div></div>);
    }

    const publishingPipelines = this.props.pipelines.pipelines
      .filter(pipeline => pipeline.dataSinkId === dataSink.id)
      .sort((a, b) => (a.id - b.id));

    const currentUser = this.props.users.currentUser;
    const userEmail =
      (
        ![currentUser, dataSink].includes(undefined) &&
        (dataSink.userId === currentUser.id)
      )
        ? currentUser.email
        : undefined;

    const project =
      (
        ![currentUser, dataSink].includes(undefined) &&
        (dataSink.userId === undefined) &&
        (dataSink.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === dataSink.projectId)
        : undefined;

    const projectMembership = (project !== undefined)
      ? currentUser.projectMemberships.find(_ => _.projectId === project.id)
      : undefined;

    const canEditDataSink = (
      (projectMembership === undefined) ||
      ['editor', 'administrator'].includes(projectMembership.membershipRole)
    );

    const canDeleteDataSink = (
      (projectMembership === undefined) ||
      ['administrator'].includes(projectMembership.membershipRole)
    );

    return (
      <MainContent preTitle='Data Sinks' title={'#' + dataSink.id + ': ' + dataSink.name} buttonLabel='Go back to data sinks' buttonLink='/data_sinks'>
        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col-auto sink-type-logos'>
                <div className='logo-wrap'>
                  <DataSinkLogo dataSink={dataSink} />
                </div>
              </div>
              <div className='col'>
                <h4 className='card-header-title'>
                    {userEmail !== undefined &&
                      <React.Fragment>
                        <span className='text-gray'>
                          {userEmail}
                        </span>
                        <span className='text-gray mx-2'>
                          /
                        </span>
                      </React.Fragment>
                    }
                    {project !== undefined &&
                      <React.Fragment>
                        <a href={`/projects/${project.id}`} className='text-gray'>
                          {project.name}
                        </a>
                        <span className='text-gray mx-2'>
                          /
                        </span>
                      </React.Fragment>
                    }
                    {dataSink.name}
                </h4>
              </div>
              <div className='col-auto'>
                {canEditDataSink &&
                  <Link className='btn btn-primary' to={'/data_sinks/' + dataSink.id + '/edit'}>
                    Edit
                  </Link>
                }
                {canDeleteDataSink && (publishingPipelines.length === 0) &&
                  <React.Fragment>
                    <button
                      className='btn btn-white delete-btn ml-2'
                      onClick={this.toggleDeleteModal}>
                      Delete
                    </button>
                    <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
                      <Modal.Header closeButton>
                        <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Deleting a data sink is irreversible. Do you want to proceed?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="white" onClick={this.toggleDeleteModal}>
                          Cancel
                        </Button>
                        <Button variant="danger" onClick={this.handleDeleteDataSink}>
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </React.Fragment>
                }
                {canDeleteDataSink && (publishingPipelines.length > 0) &&
                  <OverlayTrigger
                      placement='bottom'
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => (<Tooltip {...props}>Please delete all publishing pipelines before deleting this data sink.</Tooltip>)}>
                    <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                      <button
                        className='btn btn-white delete-btn ml-2'
                        disabled={true}
                        style={{ pointerEvents : 'none' }}>
                        Delete
                      </button>
                    </div>
                  </OverlayTrigger>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-header-title'>Publishing pipelines</h4>
          </div>
          <div className='card-body'>
            {publishingPipelines.length > 0 &&
              <ul className='list-group list-group-flush list my-n4'>
                {publishingPipelines.map(pipeline => (
                  <PipelineListItem
                    currentUser={this.props.users.currentUser}
                    dataSinks={[dataSink]}
                    dataSources={this.props.dataSources.dataSources}
                    history={this.props.history}
                    key={pipeline.id}
                    pipeline={pipeline} />
                ))}
              </ul>
            }
            {publishingPipelines.length === 0 &&
              <div className='m-0'>
                No pipeline is publishing to this data sink.
              </div>
            }
          </div>
        </div>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-header-title'>Access</h4>
          </div>
          {dataSink.projectId === undefined && currentUser !== undefined &&
            <div className='card-body'>
              {!this.state.showTransferForm &&
                <React.Fragment>
                  <p>
                    You are the only user that can access this data sink.
                  </p>
                  <button className='btn btn-outline-primary' onClick={this.enableTransferForm}>
                    Transfer data sink to project
                  </button>
                </React.Fragment>
              }
              {this.state.showTransferForm &&
                <React.Fragment>
                  <div className='form-group'>
                    <label>
                    Transfer this data sink to the following project:
                    </label>
                    <select
                      className='custom-select'
                      onChange={this.changeProjectForTransfer}
                      value={this.state.transferProjectId}>
                      <option value=''>-- Please choose a project</option>
                      {currentUser.projects.map(project => (
                        <option value={project.id}>#{project.id}: {project.name}</option>
                      ))}
                    </select>
                  </div>
                  <button
                    className='btn btn-primary'
                    disabled={(this.state.showTransferModal || (this.state.transferProjectId === undefined))}
                    onClick={this.toggleTransferModal}>
                    Transfer to project
                  </button>
                  <Modal show={this.state.showTransferModal} onHide={this.toggleTransferModal}>
                    <Modal.Header closeButton>
                      <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Transferring a data sink to a project is irreversible.
                      </p>
                      <p className='mb-0'>
                        Do you want to proceed?
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="white" onClick={this.toggleTransferModal}>
                        Cancel
                      </Button>
                      <Button variant="danger" onClick={this.handleTransferDataSink}>
                        Transfer
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </React.Fragment>
              }
            </div>
          }
          {(dataSink.projectId !== undefined) && (project !== undefined) &&
            <div className='card-body'>
              All members of the project <a href={`/projects/${project.id}`}>#{project.id}: {project.name}</a> can access this data sink.
            </div>
          }
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    dataSinks:   state.dataSinks,
    dataSources: state.dataSources,
    pipelines:   state.pipelines,
    users:       state.users
  }
}

const mapDispatchToProps = {
  deleteDataSink:            deleteDataSink,
  fetchCurrentUser:          fetchCurrentUser,
  fetchDataSink:             fetchDataSink,
  fetchDataSources:          fetchDataSources,
  fetchPipelines:            fetchPipelines,
  transferDataSinkToProject: transferDataSinkToProject
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowDataSink);
