import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  Button,
  Modal,
  OverlayTrigger,
  ProgressBar,
  Tooltip
} from 'react-bootstrap';
import {
  AlertCircle,
  Plus,
  Search
} from 'react-feather';
import MainContent from '../../components/layout/MainContent';
import DataSinkListItem from '../../components/data_sinks/DataSinkListItem';
import DataSourceListItem from '../../components/data_sources/DataSourceListItem';
import PipelineListItem from '../../components/pipelines/PipelineListItem';
import StreamListItem from '../../components/streams/StreamListItem';
import {
  deleteProject,
  fetchDataSinksOfProject,
  fetchDataSourcesOfProject,
  fetchPipelinesOfProject,
  fetchStreamsOfProject,
  fetchProject
} from '../../actions/projects';
import {
  fetchProjectMembershipsOfProject,
  toggleNotificationsOfProject
} from '../../actions/project_memberships';
import { fetchCurrentUser } from '../../actions/users';

class ShowProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDeleted:  false,
      errorMessage:    '',
      searchQuery:     '',
      showDeleteModal: false,
      shownTab:        'pipelines'
    };

    this.handleDeleteProject = this.handleDeleteProject.bind(this);
    this.toggleDeleteModal   = this.toggleDeleteModal.bind(this);
    this.changeShownTab      = this.changeShownTab.bind(this);
    this.updateSearchQuery   = this.updateSearchQuery.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  componentDidMount() {
    const projectId = this.getProjectId();

    this.props.fetchCurrentUser();
    this.props.fetchProject(projectId);
    this.props.fetchProjectMembershipsOfProject(projectId);
    this.props.fetchDataSinksOfProject(projectId);
    this.props.fetchDataSourcesOfProject(projectId);
    this.props.fetchPipelinesOfProject(projectId);
    this.props.fetchStreamsOfProject(projectId);
  }

  toggleNotifications() {
    const projectId = this.getProjectId();

    this.props.toggleNotificationsOfProject(projectId)
      .then(() => this.props.fetchProjectMembershipsOfProject(projectId));
  }

  getProjectShortName(project) {
    let shortName = '?';

    if (project.name === undefined) {
      return shortName;
    }

    const words = project.name.split(' ');

    if (words[0] !== undefined && words[0].length > 0) {
      shortName = words[0][0].toUpperCase()[0];

      if (words[1] !== undefined && words[1].length > 0) {
        shortName += words[1][0].toUpperCase()[0];
      }
    }

    return shortName;
  }

  getProjectId() {
    return parseInt(this.props.match.params.id);
  }

  handleDeleteProject(event) {
    event.preventDefault();

    this.props.deleteProject(this.getProjectId())
      .then(() => {
        this.setState({ projectDeleted: true })
      });
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  changeShownTab(event, newTabName) {
    event.preventDefault();
    this.setState({
      searchQuery: '',
      shownTab:    newTabName
    });
  }

  updateSearchQuery(event) {
    this.setState({
      searchQuery: event.target.value
    });
  }

  render() {
    if (this.state.projectDeleted) {
      return (<Redirect to='/projects' />);
    }

    const project = this.props.projects.project;
    if (project === undefined) {
      return (<div></div>);
    }

    const projectUrl = '/projects/' + project.id;

    // sort by membership role, then by name
    // capitalize membership role
    const projectMemberships = this.props.projectMemberships
      .projectMemberships
      // humanize membership roles
      .map(membership => {
        let humanizedRoleName = '';

        switch (membership.membershipRole) {
          case 'viewer':
            humanizedRoleName = 'Viewer';
            break;
          case 'administrator':
            humanizedRoleName = 'Administrator';
            break;
          case 'editor':
            humanizedRoleName = 'Editor';
            break;
          default:
          break;
        }

        return Object.assign(
          {},
          membership,
          { membershipRole: humanizedRoleName }
        );
      })
      // sort memberships by email address
      .sort((a,b) => {
        return ('' + a.userEmail).localeCompare(b.userEmail);
      });

    const pipelineTabClassNames =
      (this.state.shownTab === 'pipelines')
        ? 'nav-link active font-weight-bold'
        : 'nav-link';
    const dataSourceTabClassNames =
      (this.state.shownTab === 'data_sources')
        ? 'nav-link active font-weight-bold'
        : 'nav-link';
    const dataSinkTabClassNames =
      (this.state.shownTab === 'data_sinks')
        ? 'nav-link active font-weight-bold'
        : 'nav-link';
    const streamTabClassNames =
      (this.state.shownTab === 'streams')
        ? 'nav-link active font-weight-bold'
        : 'nav-link';

    const searchTokens = this.state.searchQuery.toLowerCase().trim().split(' ');

    const dataSinks = this.props.projects.dataSinks;
    const filteredDataSinks = dataSinks
      .filter(dataSink => !['csv', 'json', 'xml'].includes(dataSink.sinkType))
      .filter(dataSink =>
        searchTokens
          .map(_ => dataSink.name.toLowerCase().includes(_))
          .filter(_ => _)
          .length === searchTokens.length
      )
      .sort(function(a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

    const dataSources = this.props.projects.dataSources;
    const filteredDataSources = dataSources
      .filter(dataSource =>
        searchTokens
          .map(_ => dataSource.name.toLowerCase().includes(_))
          .filter(_ => _)
          .length === searchTokens.length
      )
      .sort(function(a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

    const pipelines = this.props.projects.pipelines;
    const filteredPipelines = pipelines
      .map(pipeline => Object.assign({}, { name: 'Untitled Pipeline' }, pipeline))
      .filter(pipeline =>
        searchTokens
          .map(_ => pipeline.name.toLowerCase().includes(_))
          .filter(_ => _)
          .length === searchTokens.length
      )
      .sort(function(a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

    const streams = this.props.projects.streams;
    const filteredStreams = streams
      .filter(stream =>
        searchTokens
          .map(_ => stream.name.toLowerCase().includes(_))
          .filter(_ => _)
          .length === searchTokens.length
      )
      .sort(function(a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

    const numberOfRessources =
      dataSinks.length +
      dataSources.length +
      pipelines.length +
      streams.length;

    let searchPlaceholder = '';
    switch (this.state.shownTab) {
      case 'pipelines':
        searchPlaceholder = 'Search pipelines of this project';
        break;
      case 'data_sinks':
        searchPlaceholder = 'Search data sinks of this project';
        break;
      case 'data_sources':
        searchPlaceholder = 'Search data sources of this project';
        break;
      case 'streams':
        searchPlaceholder = 'Search streams of this project';
        break;
      default:
        searchPlaceholder = '';
        break;
    }

    const currentUser = this.props.users.currentUser;

    const adminUserIds = projectMemberships
      .filter(membership => (membership.membershipRole === 'Administrator'))
      .map(membership => membership.userId);

    const canManageProject =
      (
        (currentUser !== undefined) &&
        (adminUserIds.includes(currentUser.id))
      );

    const editorUserIds = projectMemberships
      .filter(membership => (['Editor', 'Administrator'].includes(membership.membershipRole)))
      .map(membership => membership.userId);

    const canEditRessources =
      (
        (currentUser !== undefined) &&
        (editorUserIds.includes(currentUser.id))
      );

    const projectMembershipOfCurrentUser = projectMemberships
      .find(pm =>
        (currentUser !== undefined) &&
        (pm.userId === currentUser.id)
      );

    const notificationsEnabled = (
      (projectMembershipOfCurrentUser !== undefined) &&
      [undefined, true].includes(projectMembershipOfCurrentUser.enableNotifications)
    );

    const reachedPipelineLimit = (
      (project !== undefined) &&
      !isNaN(project.maxNumberOfPipelines) &&
      (pipelines.length >= project.maxNumberOfPipelines)
    );

    return (
      <MainContent preTitle='Projects' title={'Project #' + project.id + ': ' + project.name} buttonLabel='Go back to projects' buttonLink='/projects'>
        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col-auto'>
                <div className='avatar avatar-sm'>
                  <div className='avatar-title font-size-x-lg bg-primary-soft rounded text-primary'>
                    {this.getProjectShortName(project)}
                  </div>
                </div>
              </div>
              <div className='col'>
                <h4 className='card-header-title'>
                  #{project.id}: {project.name}
                </h4>
              </div>
              {(canManageProject === true) &&
                <div className='col-auto'>
                  <Link className='btn btn-primary' to={'/projects/' + project.id + '/edit'}>
                    Edit
                  </Link>
                  <button
                    className='btn btn-white delete-btn ml-2'
                    onClick={this.toggleDeleteModal}>
                    Delete
                  </button>
                  <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
                    <Modal.Header closeButton>
                      <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Deleting this project is irreversible and will also irreversibly delete all associated resources.
                      </p>
                      <h4 className='d-flex align-items-center'>
                        <AlertCircle className='feather-icon mr-2' />Deleting this project deletes the following data sinks:
                      </h4>
                      {(dataSinks.length > 0) &&
                        <ul className='list-group list-group-flush list mb-4'>
                          {dataSinks.map(dataSink => (
                            <DataSinkListItem
                              currentUser={currentUser}
                              dataSink={dataSink}
                              history={this.props.history}
                              key={dataSink.id} />
                          ))}
                        </ul>
                      }
                      {(dataSinks.length === 0) &&
                        <p className='my-4'>
                          No data sink assigned to this project.
                        </p>
                      }
                      <h4 className='d-flex align-items-center'>
                        <AlertCircle className='feather-icon mr-2' />Deleting this project deletes the following data sources:
                      </h4>
                      {(dataSources.length > 0) &&
                        <ul className='list-group list-group-flush list mb-4'>
                          {dataSources.map(dataSource => (
                            <DataSourceListItem
                              currentUser={currentUser}
                              dataSource={dataSource}
                              history={this.props.history}
                              key={dataSource.id} />
                          ))}
                        </ul>
                      }
                      {(dataSources.length === 0) &&
                        <p className='my-4'>
                          No data source assigned to this project.
                        </p>
                      }
                      <h4 className='d-flex align-items-center'>
                        <AlertCircle className='feather-icon mr-2' />Deleting this project deletes the following pipelines:
                      </h4>
                      {(pipelines.length > 0) &&
                        <ul className='list-group list-group-flush list mb-0'>
                          {pipelines.map(pipeline => (
                            <PipelineListItem
                              currentUser={currentUser}
                              dataSinks={dataSinks}
                              dataSources={dataSources}
                              pipeline={pipeline}
                              history={this.props.history}
                              key={pipeline.id} />
                          ))}
                        </ul>
                      }
                      {(pipelines.length === 0) &&
                        <p className='mt-4 mb-0'>
                          No pipeline assigned to this project.
                        </p>
                      }
                      <h4 className='d-flex align-items-center'>
                        <AlertCircle className='feather-icon mr-2' />Deleting this project deletes the following streams:
                      </h4>
                      {(streams.length > 0) &&
                        <ul className='list-group list-group-flush list mb-0'>
                          {streams.map(stream => (
                            <StreamListItem
                              currentUser={currentUser}
                              stream={streams}
                              history={this.props.history}
                              key={stream.id} />
                          ))}
                        </ul>
                      }
                      {(streams.length === 0) &&
                        <p className='mt-4 mb-0'>
                          No stream assigned to this project.
                        </p>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant='white' onClick={this.toggleDeleteModal}>
                        Cancel
                      </Button>
                      <Button variant='danger' onClick={this.handleDeleteProject}>
                        {(numberOfRessources === 0) && 'Delete'}
                        {(numberOfRessources === 1) && ('Delete project and 1 associated ressource')}
                        {(numberOfRessources > 1) && ('Delete project and ' + numberOfRessources + ' associated ressources')}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              }
            </div>
          </div>
          {![undefined, ''].includes(project.description) &&
            <div className='card-body'>
              <div className='text-muted'>
                {project.description}
              </div>
            </div>
          }
        </div>
        <div className='row'>
          <div className='col-12 col-xl-8 order-1 order-xl-0'>
            <div className='card'>
              <div className='card-header'>
                <ul className='nav nav-tabs card-header-tabs'>
                  <li className='nav-item'>
                    <Link
                      className={dataSourceTabClassNames}
                      onClick={(event) => this.changeShownTab(event, 'data_sources')}
                      to={projectUrl}>
                      Data Sources
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={dataSinkTabClassNames}
                      onClick={(event) => this.changeShownTab(event, 'data_sinks')}
                      to={projectUrl}>
                      Data Sinks
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={pipelineTabClassNames}
                      onClick={(event) => this.changeShownTab(event, 'pipelines')}
                      to={projectUrl}>
                      Pipelines
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={streamTabClassNames}
                      onClick={(event) => this.changeShownTab(event, 'streams')}
                      to={projectUrl}>
                      Streams
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='card-header'>
                <div className='row'>
                  <div className='col'>
                    <form>
                      <div className='input-group input-group-flush input-group-merge'>
                        <input
                          type='search'
                          className='form-control form-control-prepended search'
                          onChange={this.updateSearchQuery}
                          placeholder={searchPlaceholder}
                          value={this.state.searchQuery} />
                        <div className='input-group-prepend'>
                          <div className='input-group-text'>
                            <Search className='feather-icon' />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='col-auto'>
                    {canEditRessources && (this.state.shownTab === 'pipelines') && !reachedPipelineLimit &&
                      <Link className='btn btn-outline-primary' to={`/pipelines/new?projectId=${project.id}`}>
                        <Plus className='feather-icon' /> Create pipeline
                      </Link>
                    }
                    {canEditRessources && (this.state.shownTab === 'pipelines') && reachedPipelineLimit &&
                      <OverlayTrigger
                          placement='bottom'
                          delay={{ show: 100, hide: 100 }}
                          overlay={(props) => (<Tooltip {...props}>You reached the maximum number of pipelines.</Tooltip>)}>
                        <button className='btn btn-outline-primary' disabled={true}>
                          <Plus className='feather-icon' /> Create pipeline
                        </button>
                      </OverlayTrigger>
                    }
                    {canEditRessources && (this.state.shownTab === 'data_sources') &&
                      <Link className='btn btn-outline-primary' to={`/data_sources/new?projectId=${project.id}`}>
                        <Plus className='feather-icon' /> Create data source
                      </Link>
                    }
                    {canEditRessources && (this.state.shownTab === 'data_sinks') &&
                      <Link className='btn btn-outline-primary' to={`/data_sinks/new?projectId=${project.id}`}>
                        <Plus className='feather-icon' /> Create data sink
                      </Link>
                    }
                  </div>
                </div>
              </div>
              <div className='card-body'>
                {(this.state.shownTab === 'pipelines') &&
                  <React.Fragment>
                    {filteredPipelines.length > 0 &&
                      <ul className='list-group list-group-flush list my-n4'>
                        {filteredPipelines.map(pipeline => (
                          <PipelineListItem
                            currentUser={currentUser}
                            dataSinks={dataSinks}
                            dataSources={dataSources}
                            pipeline={pipeline}
                            history={this.props.history}
                            key={pipeline.id} />
                        ))}
                      </ul>
                    }
                    {filteredPipelines.length === 0 && pipelines.length > 0 &&
                      <div>
                        No matching pipeline was found. Please refine your search query.
                      </div>
                    }
                    {filteredPipelines.length === 0 && pipelines.length === 0 &&
                      <div>
                        No pipeline assigned to this project.
                      </div>
                    }
                  </React.Fragment>
                }
                {(this.state.shownTab === 'data_sources') &&
                  <React.Fragment>
                    {filteredDataSources.length > 0 &&
                      <ul className='list-group list-group-flush list my-n4'>
                        {filteredDataSources.map(dataSource => (
                          <DataSourceListItem
                            currentUser={currentUser}
                            dataSource={dataSource}
                            history={this.props.history}
                            key={dataSource.id} />
                        ))}
                      </ul>
                    }
                    {filteredDataSources.length === 0 && dataSources.length > 0 &&
                      <div>
                        No matching data source was found. Please refine your search query.
                      </div>
                    }
                    {filteredDataSources.length === 0 && dataSources.length === 0 &&
                      <div>
                        No data source assigned to this project.
                      </div>
                    }
                  </React.Fragment>
                }
                {(this.state.shownTab === 'data_sinks') &&
                  <React.Fragment>
                    {filteredDataSinks.length > 0 &&
                      <ul className='list-group list-group-flush list my-n4'>
                        {filteredDataSinks.map(dataSink => (
                          <DataSinkListItem
                            currentUser={currentUser}
                            dataSink={dataSink}
                            history={this.props.history}
                            key={dataSink.id} />
                        ))}
                      </ul>
                    }
                    {filteredDataSinks.length === 0 && dataSinks.length > 0 &&
                      <div>
                        No matching data sink was found. Please refine your search query.
                      </div>
                    }
                    {filteredDataSinks.length === 0 && dataSinks.length === 0 &&
                      <div>
                        No data sink assigned to this project.
                      </div>
                    }
                  </React.Fragment>
                }
                {(this.state.shownTab === 'streams') &&
                  <React.Fragment>
                    {filteredStreams.length > 0 &&
                      <ul className='list-group list-group-flush list my-n4'>
                        {filteredStreams.map(stream => (
                          <StreamListItem
                            currentUser={currentUser}
                            history={this.props.history}
                            stream={stream}
                            key={stream.id} />
                        ))}
                      </ul>
                    }
                    {filteredStreams.length === 0 && streams.length > 0 &&
                      <div>
                        No matching stream was found. Please refine your search query.
                      </div>
                    }
                    {filteredStreams.length === 0 && streams.length === 0 &&
                      <div>
                        No stream assigned to this project.
                      </div>
                    }
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
          <div className='col-12 col-xl-4 order-0 order-xl-1'>
            {!isNaN(project.maxNumberOfPipelines) &&
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title font-weight-bold'>Usage</h4>
                </div>
                <div className='card-body'>
                  {reachedPipelineLimit &&
                    <div className="alert alert-danger alert-dismissible mt-n4 ml-n4 mr-n4 rounded-0">
                      You reached the maximum number of pipelines. Please reach out to the support for raising the limit.
                    </div>
                  }
                  <p>
                    <span className='font-weight-bold'>{pipelines.length}</span> of <span className='font-weight-bold'>{project.maxNumberOfPipelines}</span> pipelines
                  </p>
                  <ProgressBar now={(pipelines.length / project.maxNumberOfPipelines) * 100} />
                </div>
              </div>
            }
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-header-title font-weight-bold'>Notifications</h4>
              </div>
              <div className='card-body'>
                <div className='custom-control custom-switch d-flex align-items-center'>
                  <input
                    checked={notificationsEnabled}
                    className='custom-control-input clickable'
                    id='customSwitch1'
                    name='autoRestartFailedConnector'
                    onChange={this.toggleNotifications}
                    type='checkbox' />
                  <label className='custom-control-label clickable' htmlFor='customSwitch1'>
                    {notificationsEnabled && 'Enabled'}
                    {!notificationsEnabled && 'Disabled'}
                  </label>
                </div>
                <div className='mt-4'>
                  {notificationsEnabled && 'You will receive notifications related to health changes of the resources of this project.'}
                  {!notificationsEnabled && 'You will not receive any notifications related to health changes of the resources of this project.'}
                </div>
              </div>
            </div>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-header-title font-weight-bold'>Project Members</h4>
              </div>
              <div className='card-body'>
                {(projectMemberships.length > 0) &&
                  <div className='list-group list-group-flush my-n3'>
                    {projectMemberships.map((membership, index) => (
                      <div className='list-group-item' key={index}>
                        <div className='row align-items-center'>
                          <div className='col'>
                            {(membership.userId !== undefined) &&
                              <h4 className='mb-1'>{membership.userEmail}</h4>
                            }
                            {(membership.userId === undefined) &&
                              <h4 className='mb-1 text-gray'>{membership.userEmail} (Unknown user)</h4>
                            }
                            <p className='card-text small'>{membership.membershipRole}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                }
                {(projectMemberships.length === 0) &&
                  <div>No user assigned to this project.</div>
                }
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    projects:           state.projects,
    projectMemberships: state.projectMemberships,
    users:              state.users
  }
}

const mapDispatchToProps = {
  deleteProject:                    deleteProject,
  fetchCurrentUser:                 fetchCurrentUser,
  fetchDataSinksOfProject:          fetchDataSinksOfProject,
  fetchDataSourcesOfProject:        fetchDataSourcesOfProject,
  fetchPipelinesOfProject:          fetchPipelinesOfProject,
  fetchStreamsOfProject:            fetchStreamsOfProject,
  fetchProject:                     fetchProject,
  fetchProjectMembershipsOfProject: fetchProjectMembershipsOfProject,
  toggleNotificationsOfProject:     toggleNotificationsOfProject
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowProject);
