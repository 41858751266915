import React, { Component } from 'react';
import UserListItem from './UserListItem';

class UserList extends Component {
  render() {
    const sortedUsers = this.props.users.users.sort(function(a, b) {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    });

    return (
      <table className='table table-nowrap card-table my-n4'>
        <thead>
          <tr>
            <th>
              <span className='text-muted'>
                Status
              </span>
            </th>
            <th>
              <span className='text-muted'>
                Name
              </span>
            </th>
            <th>
              <span className='text-muted'>
                Last sign in
              </span>
            </th>
            <th className='text-right'>
              <span className='text-muted'>
                Actions
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map(user => (
            <UserListItem
              key={user.id}
              resetPasswordFunc={this.props.resetPasswordFunc}
              user={user} />
          ))}
        </tbody>
      </table>
    );
  }
}

export default UserList;
