import React, { Component }        from 'react';
import { connect }                 from 'react-redux';
import { Redirect }                from 'react-router-dom';
import { Book }                    from 'react-feather';
import MainContent                 from '../../components/layout/MainContent';
import ADSBHubConfig               from '../../components/data_sources/configs/ADSBHubConfig';
import AwsS3Config                 from '../../components/data_sources/configs/AwsS3Config';
import AzureSqlDatabaseConfig      from '../../components/data_sources/configs/AzureSqlDatabaseConfig';
import AzureBlobStorageConfig      from '../../components/data_sources/configs/AzureBlobStorageConfig';
import BigQueryConfig              from '../../components/data_sources/configs/BigQueryConfig';
import CSVConfig                   from '../../components/data_sources/configs/CSVConfig';
import GoogleCloudStorageConfig    from '../../components/data_sources/configs/GoogleCloudStorageConfig';
import GoogleDriveConfig           from '../../components/data_sources/configs/GoogleDriveConfig';
import JSONConfig                  from '../../components/data_sources/configs/JSONConfig';
import XMLConfig                   from '../../components/data_sources/configs/XMLConfig';
import MySQLCDCConfig              from '../../components/data_sources/configs/MySQLCDCConfig';
import OpenSkyConfig               from '../../components/data_sources/configs/OpenSkyConfig';
import PostgreSQLCDCConfig         from '../../components/data_sources/configs/PostgreSQLCDCConfig';
import RestConfig                  from '../../components/data_sources/configs/RestConfig';
import RssConfig                   from '../../components/data_sources/configs/RssConfig';
import SftpConfig                  from '../../components/data_sources/configs/SftpConfig';
import TypeformConfig              from '../../components/data_sources/configs/TypeformConfig';
import ConfigForm                  from '../../components/data_stores/ConfigForm';
import dataSourceTypes             from '../../data_stores/data_source_types';
import DataSourceLogo              from '../../components/data_sources/DataSourceLogo';
import { deepCopy }                from '../../helpers/deepCopy';
import { validateConnectorConfig } from '../../helpers/validateConnectorConfig';
import {
  fetchDataSource,
  updateDataSource,
  resetRetrieveColumns,
  resetRetrieveTables,
  resetTestConnection,
  retrieveColumnsFromDataSource,
  retrieveTablesFromDataSource,
  testConnection
} from '../../actions/data_sources';
import {
  copyDataSourceProfileWithNewAttributes,
  fetchRecentDataSourceProfileOfDataSource,
  updateAndReprofileDataSourceProfile
} from '../../actions/data_source_profiles';
import {
  fetchFlatFileOfDataSource,
  getSampleRecordsOfFlatFile
} from '../../actions/flat_files';
import { fetchPipelines } from '../../actions/pipelines';
import { fetchCurrentUser } from '../../actions/users';

class EditDataSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSourceUpdated:        false,
      errorMessages:            {},
      updatingDataSourceFailed: false
    };

    this.getDatabaseNameLabel             = this.getDatabaseNameLabel.bind(this);
    this.getBucketNameLabel               = this.getBucketNameLabel.bind(this);
    this.showBucketNameField              = this.showBucketNameField.bind(this);
    this.handleUpdateDataSource           = this.handleUpdateDataSource.bind(this);
    this.handleChange                     = this.handleChange.bind(this);
    this.handleConfigChange               = this.handleConfigChange.bind(this);
    this.handleTestConnection             = this.handleTestConnection.bind(this);
    this.updateDataSourceProfile          = this.updateDataSourceProfile.bind(this);
    this.updateDataSourceProfileAttribute = this.updateDataSourceProfileAttribute.bind(this);
    this.selectAttributeAsPrimaryKey      = this.selectAttributeAsPrimaryKey.bind(this);
    this.reprofileDataSourceProfile       = this.reprofileDataSourceProfile.bind(this);
  }

  componentDidMount() {
    this.props.resetRetrieveColumns();
    this.props.resetRetrieveTables();
    this.props.fetchCurrentUser();

    this.props.fetchDataSource(this.getDataSourceId()).then(() => {
      const dataSource = this.props.dataSources.dataSource;

      if (['csv', 'json', 'xml'].includes(dataSource.sourceType)) {
        this.props.fetchFlatFileOfDataSource(dataSource.id).then(() =>
          this.props.fetchRecentDataSourceProfileOfDataSource(dataSource.id).then(() =>
            this.props.getSampleRecordsOfFlatFile(
              this.props.flatFiles.flatFile,
              this.props.dataSourceProfiles.dataSourceProfile.id
            ).then(() =>
              this.setState({
                dataSourceProfile: this.props.dataSourceProfiles.dataSourceProfile
              }))));
      }

      this.setState({ dataSource: this.props.dataSources.dataSource })
    });
    this.props.fetchPipelines();
  }

  getDatabaseNameLabel() {
    return 'Database name';
  }

  getBucketNameLabel() {
    if (this.props.dataSources.dataSource.sourceType === 'mongodb') {
      return 'Collection name';
    } else {
      return 'Table name';
    }
  }

  showBucketNameField() {
    return true;
  }

  getDataSourceId() {
    return parseInt(this.props.match.params.id);
  }

  handleUpdateDataSource(event) {
    event.preventDefault();
    const dataSource = this.state.dataSource;

    const selectedSourceType = dataSourceTypes
      .find(sourceType => sourceType.value === dataSource.sourceType);
    const validations = (selectedSourceType !== undefined)
      ? (selectedSourceType.validations || []).filter(validation => [undefined, 'edit'].includes(validation.validateOnly))
      : [];
    const errorMessages = validateConnectorConfig(
      dataSource,
      validations
    );

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        updatingDataSourceFailed: true,
        errorMessages:            errorMessages
      });
      window.scrollTo(0, 0);
    } else {
      if (['csv', 'xml', 'json'].includes(dataSource.sourceType)) {
        const dataSourceProfile = this.props.dataSourceProfiles.dataSourceProfile;
        if (dataSourceProfile.profilingStatus.includes('profiled') &&
            dataSourceProfile.dataSourceId !== undefined) {
          // update names of attributes
          // TODO: Create new data source profile for data source
          this.props.copyDataSourceProfileWithNewAttributes(
            this.state.dataSourceProfile.id,
            this.state.dataSourceProfile.attributes
          ).then(() =>
            // update data source
            this.props.updateDataSource(dataSource)
              .then(() => this.setState({
                dataSourceUpdated: true,
                errorMessage:      ''
              }))
          );
        } else {
          this.setState({ errorMessage: 'Failed creating the data source.' });
          window.scrollTo(0, 0);
        }
      } else {
        this.props.updateDataSource(dataSource)
          .then(() => this.setState({
            dataSourceUpdated: true,
            errorMessage:      ''
          }));
      }
    }
  }

  handleChange(event) {
    let dataSource = this.state.dataSource;
    let errorMessages = this.state.errorMessages;

    dataSource[event.target.name] = (event.target.type === 'checkbox')
      ? event.target.checked
      : event.target.value;

    // reset error message
    errorMessages[event.target.name] = undefined;

    if (event.target.name === 'connectionType' &&
        event.target.value === 'direct-connection' &&
        [undefined, ''].includes(dataSource.port)) {
      if (dataSource.sourceType === 'postgresql') {
        dataSource.port = 5432;
      } else if (dataSource.sourceType === 'mysql') {
        dataSource.port = 3306;
      } else if (dataSource.sourceType === 'mongodb') {
        dataSource.port = 27017;
      }
    }

    if (
      (dataSource.sourceType === 'google-bigquery') &&
      (event.target.name === 'password')
    ) {
      try {
        // try to parse project name and service account from credentials
        const credentials = JSON.parse(event.target.value);

        if ((credentials !== undefined) && (credentials.project_id !== undefined)) {
          dataSource.databaseName = credentials.project_id;
        }

        if ((credentials !== undefined) && (credentials.client_email !== undefined)) {
          if (dataSource.connectorConfig === undefined) {
            dataSource.connectorConfig = {};
          }

          dataSource.connectorConfig.serviceAccountEmail = credentials.client_email;
        }
      } catch (e) {
        // do not change the project id if parsing the credentials failed
      }
    }

    if (
      (dataSource.sourceType === 'google-cloud-storage') &&
      (event.target.name === 'password')
    ) {
      try {
        // try to parse project name and service account from credentials
        const credentials = JSON.parse(event.target.value);

        if ((credentials !== undefined) && (credentials.project_id !== undefined)) {
          dataSource.databaseName = credentials.project_id;
        }
      } catch (e) {
        // do not change the project id if parsing the credentials failed
      }
    }

    this.props.resetTestConnection();

    // find currently selected source type
    const selectedSourceType = dataSourceTypes
      .find(sourceType => sourceType.value === dataSource.sourceType);

    // validate and fill error message
    if ((selectedSourceType !== undefined) && (selectedSourceType.validations !== undefined)) {
      const relevantValidations = selectedSourceType.validations
        .filter(validation => [undefined, 'edit'].includes(validation.validateOnly))
        .filter(validation => validation.field === event.target.name);
      const validationResult = validateConnectorConfig(
        dataSource,
        relevantValidations
      );
      errorMessages[event.target.name] = validationResult[event.target.name];
    }

    this.setState({
      dataSource:               dataSource,
      errorMessages:            errorMessages,
      updatingDataSourceFailed: false
    });
  }

  handleConfigChange(event) {
    let dataSource = this.state.dataSource;
    let errorMessages = this.state.errorMessages;

    if (dataSource.connectorConfig === undefined) {
      dataSource.connectorConfig = {};
    }

    // reset error message
    errorMessages[event.target.name] = undefined;

    dataSource.connectorConfig[event.target.name] = (event.target.type === 'checkbox')
      ? '' + event.target.checked
      : event.target.value;

    this.props.resetTestConnection();

    // find currently selected source type
    const selectedSourceType = dataSourceTypes
      .find(sourceType => sourceType.value === dataSource.sourceType);

    // validate and fill error message
    if ((selectedSourceType !== undefined) && (selectedSourceType.validations !== undefined)) {
      const relevantValidations = selectedSourceType.validations
        .filter(validation => [undefined, 'edit'].includes(validation.validateOnly))
        .filter(validation => validation.field === event.target.name);
      const validationResult = validateConnectorConfig(
        dataSource,
        relevantValidations
      );
      errorMessages[event.target.name] = validationResult[event.target.name];
    }

    this.setState({
      dataSource:               dataSource,
      errorMessages:            errorMessages,
      updatingDataSourceFailed: false
    });
  }

  handleTestConnection() {
    // the backend always requires a data source name, although it is not relevant
    // in this case. TODO: fix this in the future, such that we can test a connection
    // without requiring the user to enter a data source name
    this.props.testConnection(
      Object.assign(deepCopy(this.state.dataSource), { name: 'viktoria' }));
  }

  updateDataSourceProfile(event) {
    const property = event.target.name;
    const value    = event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value;

    let dataSourceProfile = deepCopy(this.state.dataSourceProfile);
    if (dataSourceProfile !== undefined) {
      dataSourceProfile[property] = value;
      this.setState({
        dataSourceProfile: dataSourceProfile
      });
    }
  }

  updateDataSourceProfileAttribute(event) {
    const attributeIdx = event.target.dataset.attributePos;
    const property     = event.target.name;
    const value        = event.target.value;

    let dataSourceProfile = deepCopy(this.state.dataSourceProfile);

    if (!isNaN(attributeIdx) && dataSourceProfile !== undefined) {
      dataSourceProfile.attributes[attributeIdx][property] = value;
      this.setState({
        attributeValidation:   undefined,
        changedAttributeNames: true,
        dataSourceProfile:     dataSourceProfile
      });
    }
  }

  selectAttributeAsPrimaryKey(attributePos) {
    let dataSourceProfile = deepCopy(this.state.dataSourceProfile);

    if (
      (dataSourceProfile !== undefined) &&
      (dataSourceProfile.attributes[attributePos] !== undefined)
    ) {
      // reset key
      dataSourceProfile.attributes = dataSourceProfile.attributes
        .map(attribute => Object.assign({}, attribute, { isKey: false }));

      // assign new key
      dataSourceProfile.attributes[attributePos]['isKey'] = true;

      // update profile
      this.setState({
        attributeValidation:   undefined,
        changedAttributeNames: true,
        dataSourceProfile:     dataSourceProfile
      });
    }
  }

  reprofileDataSourceProfile() {
    return this.props.updateAndReprofileDataSourceProfile(this.state.dataSourceProfile)
      .then(() => {
        // update sample records based on new data source profile
        this.props.getSampleRecordsOfFlatFile(this.props.flatFiles.flatFile);
        this.setState({
          changedAttributeNames: false,
          dataSourceProfile:     this.props.dataSourceProfiles.dataSourceProfile
        });
      });
  }

  render() {
    const dataSourceUri = '/data_sources/' + this.getDataSourceId();
    if (this.state.dataSourceUpdated) {
      let redirectTo = dataSourceUri;
      return (<Redirect to={redirectTo} />);
    }

    const dataSource = this.state.dataSource;
    if (dataSource === undefined) {
      return (<div></div>);
    }

    const selectedSourceType = dataSourceTypes
      .find(sourceType => sourceType.value === dataSource.sourceType);

    // Indicate status of updating the pipeline
    let updatePipelineStatus = undefined;
    if (this.props.dataSources.updatingDataSource) {
      updatePipelineStatus = '';
    }

    const currentUser = this.props.users.currentUser;
    const userEmail =
      (
        ![currentUser, dataSource].includes(undefined) &&
        (dataSource.userId === currentUser.id)
      )
        ? currentUser.email
        : undefined;

    const project =
      (
        ![currentUser, dataSource].includes(undefined) &&
        (dataSource.userId === undefined) &&
        (dataSource.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === dataSource.projectId)
        : undefined;

    const projectMembership = (project !== undefined)
      ? currentUser.projectMemberships.find(_ => _.projectId === project.id)
      : undefined;

    const canEditDataSource = (
      (projectMembership === undefined) ||
      ['editor', 'administrator'].includes(projectMembership.membershipRole)
    );

    if (!canEditDataSource) {
      return (<div></div>);
    }

    return (
      <MainContent preTitle='Data Sources' title='Edit data source' buttonLabel='Go back to data source' buttonLink={dataSourceUri}>
        <form>
          <div className='card'>
            <div className='card-header'>
              <div className='row align-items-center'>
                <div className='col-auto source-type-logos'>
                  <div className='logo-wrap'>
                    <DataSourceLogo dataSource={dataSource} />
                  </div>
                </div>
                <div className='col'>
                  <h4 className='card-header-title'>
                    {userEmail !== undefined &&
                      <React.Fragment>
                        <span className='text-gray'>
                          {userEmail}
                        </span>
                        <span className='text-gray mx-2'>
                          /
                        </span>
                      </React.Fragment>
                    }
                    {project !== undefined &&
                      <React.Fragment>
                        <a href={`/projects/${project.id}`} className='text-gray'>
                          {project.name}
                        </a>
                        <span className='text-gray mx-2'>
                          /
                        </span>
                      </React.Fragment>
                    }
                    {dataSource.name}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {this.state.updatingDataSourceFailed &&
            <div className='alert alert-danger fade show rounded-0' role='alert'>
              The data source configuration is invalid, please check the form fields.
            </div>
          }
          {dataSource.sourceType !== undefined &&
            <React.Fragment>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>General settings</h4>
                </div>
                <div className='card-body border-bottom'>
                  <ConfigForm
                    errorMessages={this.state.errorMessages}
                    isRequired={true}
                    label='Name'
                    name='name'
                    onChangeFunc={this.handleChange}
                    value={dataSource.name} />
                  <div className='mt-4 mb-2'>
                    <div className='custom-control custom-switch d-flex align-items-center'>
                      <input
                        checked={dataSource.autoRestartFailedConnector}
                        className='custom-control-input clickable'
                        id='customSwitch1'
                        name='autoRestartFailedConnector'
                        onChange={this.handleChange}
                        type='checkbox' />
                      <label className='custom-control-label clickable' htmlFor='customSwitch1'>
                        Automatically restart failed connector
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      {selectedSourceType !== undefined &&
                        <h4 className='card-header-title'>{selectedSourceType.label}-specific settings</h4>
                      }
                      {selectedSourceType === undefined &&
                        <h4 className='card-header-title'>Connector-specific settings</h4>
                      }
                    </div>
                    {(selectedSourceType !== undefined) && (selectedSourceType.docs !== undefined) &&
                      <div className='col-auto'>
                        <a href={`https://datacater.io/docs/source_connectors/${selectedSourceType.docs}/`} target='_blank' className='btn btn-white' rel='noopener noreferrer'>
                          <Book className='feather-icon mr-1' />
                          Documentation
                        </a>
                      </div>
                    }
                  </div>
                </div>
                <div className='card-body border-bottom'>
                  {dataSource.sourceType === 'aws-s3' &&
                    <AwsS3Config
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'azure-sql-database' &&
                    <AzureSqlDatabaseConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'azure-blob' &&
                    <AzureBlobStorageConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'adsbhub' &&
                    <ADSBHubConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSource.sourceType === 'csv' && this.state.dataSourceProfile !== undefined &&
                    <CSVConfig
                      changedAttributeNames={this.state.changedAttributeNames}
                      createPipelineStatus={updatePipelineStatus}
                      dataSourceProfile={this.state.dataSourceProfile}
                      dataSourceProfiles={this.props.dataSourceProfiles}
                      disableParserConfig={true}
                      errorMessages={this.state.errorMessages}
                      flatFiles={this.props.flatFiles}
                      handleAttributeChangeFunc={this.updateDataSourceProfileAttribute}
                      reprofileDataSourceProfileFunc={this.reprofileDataSourceProfile}
                      refineProfileOfFlatFile={true}
                      selectAttributeAsPrimaryKeyFunc={this.selectAttributeAsPrimaryKey} />
                  }
                  {dataSource.sourceType === 'google-bigquery' &&
                    <BigQueryConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'google-cloud-storage' &&
                    <GoogleCloudStorageConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'google-drive' &&
                    <GoogleDriveConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'json' && this.state.dataSourceProfile !== undefined &&
                    <JSONConfig
                      changedAttributeNames={this.state.changedAttributeNames}
                      createPipelineStatus={updatePipelineStatus}
                      dataSourceProfile={this.state.dataSourceProfile}
                      dataSourceProfiles={this.props.dataSourceProfiles}
                      disableParserConfig={true}
                      errorMessages={this.state.errorMessages}
                      flatFiles={this.props.flatFiles}
                      handleAttributeChangeFunc={this.updateDataSourceProfileAttribute}
                      refineProfileOfFlatFile={true}
                      reprofileDataSourceProfileFunc={this.reprofileDataSourceProfile}
                      selectAttributeAsPrimaryKeyFunc={this.selectAttributeAsPrimaryKey} />
                  }
                  {dataSource.sourceType === 'mysql' &&
                    <MySQLCDCConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'opensky' &&
                    <OpenSkyConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSource.sourceType === 'postgresql' &&
                    <PostgreSQLCDCConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'rest' &&
                    <RestConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource} />
                  }
                  {dataSource.sourceType === 'rss' &&
                    <RssConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSource.sourceType === 'sftp' &&
                    <SftpConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource} />
                  }
                  {dataSource.sourceType === 'typeform' &&
                    <TypeformConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'xml' && this.state.dataSourceProfile !== undefined &&
                    <XMLConfig
                      changedAttributeNames={this.state.changedAttributeNames}
                      createPipelineStatus={updatePipelineStatus}
                      dataSourceProfile={this.state.dataSourceProfile}
                      dataSourceProfiles={this.props.dataSourceProfiles}
                      disableParserConfig={true}
                      errorMessages={this.state.errorMessages}
                      flatFiles={this.props.flatFiles}
                      handleAttributeChangeFunc={this.updateDataSourceProfileAttribute}
                      refineProfileOfFlatFile={true}
                      reprofileDataSourceProfileFunc={this.reprofileDataSourceProfile}
                      selectAttributeAsPrimaryKeyFunc={this.selectAttributeAsPrimaryKey} />
                  }
                </div>
              </div>
              <div className='card'>
                <div className='card-body'>
                  {!this.props.dataSources.updatingDataSource &&
                    <button
                      className='btn btn-primary'
                      onClick={this.handleUpdateDataSource}
                      type='submit'>
                      Update data source
                    </button>
                  }
                  {this.props.dataSources.updatingDataSource &&
                    <button
                      className='btn btn-primary'
                      disabled={true}
                      type='submit'>
                      <span className='spinner-border mr-2' role='status'>
                      </span>
                      Updating...
                    </button>
                  }
                </div>
              </div>
            </React.Fragment>
          }
        </form>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    dataSources:        state.dataSources,
    dataSourceProfiles: state.dataSourceProfiles,
    flatFiles:          state.flatFiles,
    pipelines:          state.pipelines,
    users:              state.users
  }
}

const mapDispatchToProps = {
  copyDataSourceProfileWithNewAttributes:   copyDataSourceProfileWithNewAttributes,
  fetchCurrentUser:                         fetchCurrentUser,
  fetchDataSource:                          fetchDataSource,
  fetchFlatFileOfDataSource:                fetchFlatFileOfDataSource,
  fetchPipelines:                           fetchPipelines,
  fetchRecentDataSourceProfileOfDataSource: fetchRecentDataSourceProfileOfDataSource,
  getSampleRecordsOfFlatFile:               getSampleRecordsOfFlatFile,
  resetRetrieveColumns:                     resetRetrieveColumns,
  resetRetrieveTables:                      resetRetrieveTables,
  resetTestConnection:                      resetTestConnection,
  retrieveColumnsFromDataSource:            retrieveColumnsFromDataSource,
  retrieveTablesFromDataSource:             retrieveTablesFromDataSource,
  testConnection:                           testConnection,
  updateAndReprofileDataSourceProfile:      updateAndReprofileDataSourceProfile,
  updateDataSource:                         updateDataSource
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDataSource);
