import React, { Component } from 'react';
import RetrieveColumns      from '../RetrieveColumns';
import RetrieveTables       from '../RetrieveTables';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class MySQLCDCConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveTablesFunc
    } = this.props;

    const flatColumns = dataSources.columns
      .map(column => column.name)
      .sort();

    const flatTables = dataSources
      .tables
      .map(table => table.name)
      .sort();

    const retrievingTablesIsPossible =
      ![undefined, ''].includes(dataSource.hostname) &&
      ![undefined, ''].includes(dataSource.port) &&
      ![undefined, ''].includes(dataSource.username) &&
      ![undefined, ''].includes(dataSource.password) &&
      ![undefined, ''].includes(dataSource.databaseName);

    const retrievingColumnsIsPossible =
      ![undefined, ''].includes(dataSource.hostname) &&
      ![undefined, ''].includes(dataSource.port) &&
      ![undefined, ''].includes(dataSource.username) &&
      ![undefined, ''].includes(dataSource.password) &&
      ![undefined, ''].includes(dataSource.databaseName) &&
      ![undefined, ''].includes(dataSource.bucketName);

    const primaryKeyColumn = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.primaryKeyColumn !== undefined)
      ? dataSource.connectorConfig.primaryKeyColumn
      : '';

    const sslMode = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.sslMode !== undefined)
      ? dataSource.connectorConfig.sslMode
      : 'no-ssl';

    const serverTimezone = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.serverTimezone !== undefined)
      ? dataSource.connectorConfig.serverTimezone
      : '';

    const accessMethod = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.accessMethod !== undefined)
      ? dataSource.connectorConfig.accessMethod
      : 'binlog';

    const timestampColumn = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.timestampColumn !== undefined)
      ? dataSource.connectorConfig.timestampColumn
      : '';

    const syncIntervalSeconds = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.syncIntervalSeconds !== undefined)
      ? dataSource.connectorConfig.syncIntervalSeconds
      : '';

    const cdcMode = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.cdcMode !== undefined)
      ? dataSource.connectorConfig.cdcMode
      : 'bulk';

    const autoDetectPrimaryKey = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.autoDetectPrimaryKey !== undefined)
      ? '' + dataSource.connectorConfig.autoDetectPrimaryKey
      : 'true';

    const kafkaConnectConfig = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSource.connectorConfig.kafkaConnectConfig
      : '{}';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the password unless you want to change it.</span>)
      : (<span className='text-danger'>*</span>);

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Change Data Capture', key: 'cdc' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item'>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Hostname or IP'
              name='hostname'
              onChangeFunc={handleChangeFunc}
              value={dataSource.hostname} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Port'
              name='port'
              onChangeFunc={handleChangeFunc}
              placeholder={3306}
              value={dataSource.port} />
            <div className='form-group'>
              <label>SSL</label>
              <select
                className='custom-select'
                name='sslMode'
                onChange={handleConfigChangeFunc}
                value={sslMode}>
                <option value='no-ssl'>Do not use SSL</option>
                <option value='use-ssl-no-verification'>Use SSL</option>
              </select>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Username'
              name='username'
              onChangeFunc={handleChangeFunc}
              value={dataSource.username} />
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment>Password{editPasswordNotice}</React.Fragment>)}
              name='password'
              onChangeFunc={handleChangeFunc}
              type='password'
              value={dataSource.password} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Server timezone'
              name='serverTimezone'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='UTC'
              value={serverTimezone} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Database name'
              name='databaseName'
              onChangeFunc={handleChangeFunc}
              value={dataSource.databaseName} />
            <RetrieveTables
              dataSource={dataSource}
              dataSources={dataSources}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the table names from the MySQL database failed. Please check your connection credentials.'
              handleChangeFunc={handleChangeFunc}
              missingOptionsErrorMessage='Please provide the hostname, the port, the username, the password, and the name of the MySQL database.'
              resetRetrieveTablesFunc={resetRetrieveTablesFunc}
              retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSourceFunc}
              retrievingTablesIsPossible={retrievingTablesIsPossible}
              tableAttributeName='bucketName'
              tables={flatTables} />
            <div className='custom-control custom-switch d-flex align-items-center mb-4'>
              <input
                checked={[true, 'true'].includes(autoDetectPrimaryKey)}
                className='custom-control-input clickable'
                id='autoDetectPrimaryKey'
                name='autoDetectPrimaryKey'
                onChange={handleConfigChangeFunc}
                type='checkbox' />
              <label className='custom-control-label clickable' htmlFor='autoDetectPrimaryKey'>
                Automatically detect primary key column
              </label>
            </div>
            {autoDetectPrimaryKey === 'false' &&
              <RetrieveColumns
                columnAttributeName='primaryKeyColumn'
                columns={flatColumns}
                dataSource={dataSource}
                dataSources={dataSources}
                failedFetchErrorMessage='Fetching the column names from the MySQL table failed. Please check your connection credentials.'
                handleChangeFunc={handleConfigChangeFunc}
                label='Primary key column'
                missingOptionsErrorMessage='Please provide the hostname, the port, the username, the password, the database name, and the table name.'
                resetRetrieveColumnsFunc={this.props.resetRetrieveColumnsFunc}
                retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSourceFunc}
                retrievingColumnsIsPossible={retrievingColumnsIsPossible}
                value={primaryKeyColumn} />
            }
          </React.Fragment>
        }
        {(this.state.currentTab === 'cdc') &&
          <React.Fragment>
            <div className='form-group'>
              <label>Connector variant</label>
              <select
                className='custom-select'
                name='accessMethod'
                onChange={handleConfigChangeFunc}
                value={accessMethod}>
                <option value='binlog'>MySQL Binary Log</option>
                <option value='jdbc'>MySQL JDBC Driver</option>
              </select>
            </div>
            {accessMethod === 'jdbc' &&
              <React.Fragment>
                <div className='form-group'>
                  <label>Change Data Capture mode</label>
                  <select
                    className='custom-select'
                    name='cdcMode'
                    onChange={handleConfigChangeFunc}
                    value={cdcMode}>
                    <option value='bulk'>BULK: Fetch all data at each sync</option>
                    <option value='incrementing'>INCREMENTING: Use the primary key column to fetch data that have been inserted since the last sync</option>
                    <option value='timestamp+incrementing'>TIMESTAMP/INCREMENTING: Use the timestamp column and the primary key column to fetch data that have been inserted or updated since the last sync</option>
                  </select>
                </div>
                {['timestamp', 'timestamp+incrementing'].includes(cdcMode) &&
                  <RetrieveColumns
                    columnAttributeName='timestampColumn'
                    columns={flatColumns}
                    dataSource={dataSource}
                    dataSources={dataSources}
                    failedFetchErrorMessage='Fetching the column names from the MySQL table failed. Please check your connection credentials.'
                    handleChangeFunc={handleConfigChangeFunc}
                    label='Timestamp column'
                    missingOptionsErrorMessage='Please provide the hostname, the port, the username, the password, the database name, and the table name.'
                    resetRetrieveColumnsFunc={this.props.resetRetrieveColumnsFunc}
                    retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSourceFunc}
                    retrievingColumnsIsPossible={retrievingColumnsIsPossible}
                    value={timestampColumn} />
                }
                <ConfigForm
                  errorMessages={errorMessages}
                  label='Sync interval (seconds)'
                  name='syncIntervalSeconds'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder='60'
                  value={syncIntervalSeconds} />
              </React.Fragment>
            }
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default MySQLCDCConfig;
