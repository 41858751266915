import { deepCopy } from '../helpers/deepCopy';

export function resetDataSourceProfile() {
  const resetProfile = () => ({
    type: 'RESET_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(resetProfile());
  };
}

export function resetJoinedDataSourceProfile() {
  const resetProfile = () => ({
    type: 'RESET_JOINED_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(resetProfile());
  };
}

export function fetchDataSourceProfile(dataSourceProfileId) {
  const requestDataSourceProfile = () => ({
    type: 'REQUEST_DATA_SOURCE_PROFILE'
  });

  const receivedDataSourceProfile = response => ({
    dataSourceProfile: response,
    type:              'RECEIVE_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestDataSourceProfile());

    return fetch('/api/data_source_profiles/' + dataSourceProfileId,
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        let dataSourceProfile = json;
        // cast attribute IDs to string for easier usage in data profiler
        dataSourceProfile.attributes = dataSourceProfile.attributes.map(function(attribute) {
          return Object.assign({}, attribute, { id: attribute.id + '' });
        });
        dispatch(receivedDataSourceProfile(dataSourceProfile));
      });
  };
}

export function fetchJoinedDataSourceProfile(dataSourceProfileId) {
  const requestDataSourceProfile = () => ({
    type: 'REQUEST_JOINED_DATA_SOURCE_PROFILE'
  });

  const receivedDataSourceProfile = response => ({
    joinedDataSourceProfile: response,
    type:                    'RECEIVE_JOINED_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestDataSourceProfile());

    return fetch('/api/data_source_profiles/' + dataSourceProfileId,
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        let dataSourceProfile = json;
        dataSourceProfile.sampleRecords = (dataSourceProfile.sampleRecords !== undefined)
          ? JSON.parse(dataSourceProfile.sampleRecords)
          : [];
        // cast attribute IDs to string for easier usage in data profiler
        dataSourceProfile.attributes = dataSourceProfile.attributes.map(function(attribute) {
          return Object.assign({}, attribute, { id: attribute.id + '' });
        });
        dispatch(receivedDataSourceProfile(dataSourceProfile));
      });
  };
}

export function fetchRecentDataSourceProfileOfDataSource(dataSourceId) {
  const requestDataSourceProfile = () => ({
    type: 'REQUEST_DATA_SOURCE_PROFILE'
  });

  const receivedDataSourceProfile = response => ({
    dataSourceProfile: response,
    type:              'RECEIVE_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestDataSourceProfile());

    return fetch(
        '/api/data_sources/' + dataSourceId + '/data_source_profiles/recent',
        { headers: { 'X-Auth-token': localStorage.getItem('userToken') }}
      )
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        dispatch(receivedDataSourceProfile(json));
      });
  };
}

export function fetchSampleRecords(dataSourceProfileId, count) {
  const requestSampleRecords = () => ({
    type: 'REQUEST_DATA_SOURCE_PROFILE_SAMPLE_RECORDS'
  });

  const receivedSampleRecords = response => ({
    type:          'RECEIVE_DATA_SOURCE_PROFILE_SAMPLE_RECORDS',
    sampleRecords: response
  });

  const receivedSampleRecordsFailed = () => ({
    type: 'RECEIVE_DATA_SOURCE_PROFILE_SAMPLE_RECORDS_FAILED'
  });

  return function (dispatch) {
    dispatch(requestSampleRecords());

    let parsedCount = 5000;
    if (count !== undefined && !isNaN(count)) {
      parsedCount = parseInt(count);
    }

    return fetch(
      '/api/data_source_profiles/' + dataSourceProfileId + '/sample_records?count=' + parsedCount,
      { headers: { 'X-Auth-token': localStorage.getItem('userToken') }}
    )
    .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else if (response.status >= 400) {
          dispatch(receivedSampleRecordsFailed());
          return Promise.resolve(undefined);
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    )
    .then((response) => {
      if (response !== undefined) {
        dispatch(receivedSampleRecords(response));
      }
    });
  };
}

export function fetchJoinedSampleRecords(dataSourceProfileId, count) {
  const requestSampleRecords = () => ({
    type: 'REQUEST_JOINED_DATA_SOURCE_PROFILE_SAMPLE_RECORDS'
  });

  const receivedSampleRecords = response => ({
    type:          'RECEIVE_JOINED_DATA_SOURCE_PROFILE_SAMPLE_RECORDS',
    sampleRecords: response
  });

  const receivedSampleRecordsFailed = () => ({
    type: 'RECEIVE_JOINED_DATA_SOURCE_PROFILE_SAMPLE_RECORDS_FAILED'
  });

  return function (dispatch) {
    dispatch(requestSampleRecords());

    return fetch(
      `/api/data_source_profiles/${dataSourceProfileId}/sample_records?count=${count}`,
      { headers: { 'X-Auth-token': localStorage.getItem('userToken') }}
    )
    .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else if (response.status >= 400) {
          dispatch(receivedSampleRecordsFailed());
          return Promise.resolve(undefined);
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    )
    .then((response) => {
      if (response !== undefined) {
        dispatch(receivedSampleRecords(response));
      }
    });
  };
}

export function createDataSourceProfile(dataSourceId) {
  const requestCreateDataSourceProfile = () => ({
    type: 'REQUEST_CREATE_DATA_SOURCE_PROFILE'
  });

  const receivedCreateDataSourceProfile = response => ({
    dataSourceProfile: response,
    type:              'RECEIVE_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestCreateDataSourceProfile());

    return fetch(
      '/api/data_sources/' + dataSourceId + '/data_source_profiles',
      {
        method:      'POST',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'X-Auth-token': localStorage.getItem('userToken')
        }
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedCreateDataSourceProfile(json));
    });
  };
}

export function updateDataSourceProfile(dataSourceProfile) {
  const requestUpdateDataSourceProfile = () => ({
    type: 'REQUEST_DATA_SOURCE_PROFILE'
  });

  const receivedUpdateDataSourceProfile = response => ({
    dataSourceProfile: response,
    type:              'RECEIVE_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestUpdateDataSourceProfile());

    let cleanedDataSourceProfile = deepCopy(dataSourceProfile);
    delete cleanedDataSourceProfile.createdAt;
    delete cleanedDataSourceProfile.attributes;
    delete cleanedDataSourceProfile.sampleRecords;

    return fetch(
      '/api/data_source_profiles/' + dataSourceProfile.id,
      {
        method:      'PUT',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'Content-type': 'application/json',
          'X-Auth-token': localStorage.getItem('userToken')
        },
        body: JSON.stringify(cleanedDataSourceProfile)
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedUpdateDataSourceProfile(json));
    });
  };
}

export function replaceDataSourceProfileAttributes(dataSourceProfileId, dataSourceProfileAttributes) {
  const requestReplaceDataSourceProfileAttributes = () => ({
    type: 'REQUEST_REPLACE_DATA_SOURCE_PROFILE_ATTRIBUTES'
  });

  const receivedReplaceDataSourceProfileAttributes = response => ({
    attributes: response,
    type:       'RECEIVE_REPLACE_DATA_SOURCE_PROFILE_ATTRIBUTES'
  });

  return function (dispatch) {
    dispatch(requestReplaceDataSourceProfileAttributes());

    const requestObj = {
      attributes: dataSourceProfileAttributes
    };

    return fetch(
      '/api/data_source_profiles/' + dataSourceProfileId + '/replace_attributes',
      {
        method:      'POST',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'Content-type': 'application/json',
          'X-Auth-token': localStorage.getItem('userToken')
        },
        body: JSON.stringify(requestObj)
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedReplaceDataSourceProfileAttributes(json));
    });
  };
}

export function copyDataSourceProfileWithNewAttributes(
  dataSourceProfileId,
  dataSourceProfileAttributes
) {
  const requestUpdateDataSourceProfileAttributes = () => ({
    type: 'REQUEST_UPDATE_REPROFILE_DATA_SOURCE_PROFILE'
  });

  const receivedUpdateDataSourceProfileAttributes = response => ({
    dataSourceProfile: response,
    type:              'RECEIVE_UPDATE_REPROFILE_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestUpdateDataSourceProfileAttributes());

    const requestObj = {
      attributes: dataSourceProfileAttributes
    };

    return fetch(
      '/api/data_source_profiles/' + dataSourceProfileId + '/copy',
      {
        method:      'POST',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'Content-type': 'application/json',
          'X-Auth-token': localStorage.getItem('userToken')
        },
        body: JSON.stringify(requestObj)
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedUpdateDataSourceProfileAttributes(json));
    });
  };
}

export function updateAndReprofileDataSourceProfile(dataSourceProfile) {
  const requestUpdateDataSourceProfile = () => ({
    type: 'REQUEST_UPDATE_REPROFILE_DATA_SOURCE_PROFILE'
  });

  const receivedUpdateDataSourceProfile = response => ({
    dataSourceProfile: response,
    type:              'RECEIVE_UPDATE_REPROFILE_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestUpdateDataSourceProfile());

    let cleanedDataSourceProfile = deepCopy(dataSourceProfile);
    delete cleanedDataSourceProfile.createdAt;
    delete cleanedDataSourceProfile.attributes;
    delete cleanedDataSourceProfile.sampleRecords;

    if (cleanedDataSourceProfile.csvIgnoreFirstRows !== undefined) {
      cleanedDataSourceProfile.csvIgnoreFirstRows = parseInt(cleanedDataSourceProfile.csvIgnoreFirstRows);
    }

    return fetch(
      '/api/data_source_profiles/' + dataSourceProfile.id + '/reprofile',
      {
        method:      'POST',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'Content-type': 'application/json',
          'X-Auth-token': localStorage.getItem('userToken')
        },
        body: JSON.stringify(cleanedDataSourceProfile)
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedUpdateDataSourceProfile(json));
    });
  };
}

export function createDataSourceProfileForFile(file) {
  const requestCreateDataSourceProfile = () => ({
    type: 'REQUEST_CREATE_DATA_SOURCE_PROFILE'
  });

  const receivedCreateDataSourceProfile = response => ({
    dataSourceProfile: response,
    type:              'RECEIVE_DATA_SOURCE_PROFILE'
  });

  return function (dispatch) {
    dispatch(requestCreateDataSourceProfile());

    return fetch(
      '/api/flat_files/' + file.id + '/profile',
      {
        method:      'POST',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'X-Auth-token': localStorage.getItem('userToken')
        }
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedCreateDataSourceProfile(json));
    });
  };
}

export function addDataSourceProfileAttribute(dataSourceProfileId) {
  const requestAddDataSourceProfileAttribute = () => ({
    type: 'REQUEST_ADD_DATA_SOURCE_PROFILE_ATTRIBUTE'
  });

  const receivedAddDataSourceProfileAttribute = response => ({
    dataSourceProfileAttribute: response,
    type:                       'RECEIVE_DATA_SOURCE_PROFILE_ATTRIBUTE'
  });

  return function (dispatch) {
    dispatch(requestAddDataSourceProfileAttribute());

    return fetch(
      '/api/data_source_profiles/' + dataSourceProfileId + '/attributes',
      {
        method:      'POST',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'X-Auth-token': localStorage.getItem('userToken')
        }
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedAddDataSourceProfileAttribute(json));
    });
  };
}

export function deleteDataSourceProfileAttribute(dataSourceProfileId, attributeId) {
  const requestDeleteDataSourceProfileAttribute = () => ({
    type: 'REQUEST_DELETE_DATA_SOURCE_PROFILE_ATTRIBUTE'
  });

  const receivedDeleteDataSourceProfileAttribute = () => ({
    type: 'RECEIVE_DELETE_DATA_SOURCE_PROFILE_ATTRIBUTE'
  });

  return function (dispatch) {
    dispatch(requestDeleteDataSourceProfileAttribute());

    return fetch(
      '/api/data_source_profiles/' + dataSourceProfileId + '/attributes/' + attributeId,
      {
        method:      'DELETE',
        credentials: 'same-origin',
        mode:        'cors',
        cache:       'no-cache',
        headers: {
          'X-Auth-token': localStorage.getItem('userToken')
        }
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.text();
        }
      },
      error => console.log('An error occurred.', error)
    ).then((json) => {
      dispatch(receivedDeleteDataSourceProfileAttribute(json));
    });
  };
}
