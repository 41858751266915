import React, { Component } from 'react';

class DataSourceLogo extends Component {
  render() {
    const {
      className,
      dataSource,
    } = this.props;

    const sourcesWithoutLogo = ['rest', 'ftp', 'rss'];

    let value = dataSource.sourceType || dataSource.value;
    // dirty hack to ensure backwards compatibility (we started with sftp, then added ftp)
    if (value === 'sftp') {
      value = 'ftp';
    }

    if (sourcesWithoutLogo.includes(value)) {
      const classNames = (className === undefined)
        ? 'avatar w-100 h-100'
        : 'avatar w-100 h-100 ' + className;

      return (
        <div className={classNames}>
          <div className='avatar-title font-size-sm bg-primary-soft rounded-circle text-black font-weight-bold'>
            {value.toUpperCase()}
          </div>
        </div>
      )
    } else {
      return (
        <img
          className={className}
          src={'/images/' + value + '-logo.png'}
          alt={value + ' Logo'} />
      );
    }
  }
}

export default DataSourceLogo;
