import React,
       { Component }          from 'react';
import { connect }            from 'react-redux';
import MainContent            from '../../components/layout/MainContent';
import TransformerCatalogItem from '../../components/catalog/TransformerCatalogItem';
import Transformer            from '../../pipelines/Transformer';
import { Search }             from 'react-feather';

class Catalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        dataTypes: []
      },
      searchQuery: ''
    };

    this.updateSearchQuery    = this.updateSearchQuery.bind(this);
    this.toggleDataTypeFilter = this.toggleDataTypeFilter.bind(this);
  }

  updateSearchQuery(event) {
    this.setState({
      searchQuery: event.target.value
    });
  }

  toggleDataTypeFilter(event) {
    event.preventDefault();
    const dataType = event.target.dataset.dataType;
    let filters = this.state.filters;
    if (filters.dataTypes.includes(dataType)) {
      filters.dataTypes.splice(filters.dataTypes.findIndex(_ => _ === dataType), 1);
    } else {
      filters.dataTypes.push(dataType);
    }
    this.setState({ filters: filters });
  }

  render() {
    const searchTokens = this.state.searchQuery.toLowerCase().trim().split(' ');
    const transformers = Transformer.getTransformers()
      .filter(transformer => transformer.hidden === undefined || transformer.hidden === false)
      .filter(transformer => this.state.filters.dataTypes.length === 0 ||
                             [undefined, []].includes(transformer.restrictToDataType) ||
                             transformer.restrictToDataType.filter(_ => this.state.filters.dataTypes.includes(_)).length === this.state.filters.dataTypes.length)
      .filter(transformer => transformer.key !== undefined && transformer.key.length > 0)
      .filter(transformer => searchTokens
                               .map(_ => transformer.name.toLowerCase().includes(_))
                               .filter(_ => _)
                               .length === searchTokens.length)
      .map(transformer => Object.assign(transformer, { isTransformer: true }))
      .sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });
    const dataTypes = Transformer.getTransformers()
      .find(transformer => transformer.key === 'cast-data-type')
      .actionValueOptions
      .filter(_ => _.value.length > 0)
      .sort(function(a, b) {
        if (a.label < b.label) {
          return -1;
        } else if (a.label > b.label) {
          return 1;
        } else {
          return 0;
        }
      });

    return (
      <MainContent preTitle='Documentation' title='Transformations'>
        <div className='card card-fill'>
          <div className='card-header'>
            <form>
              <div className='input-group input-group-flush input-group-merge'>
                <input
                  type='search'
                  className='form-control form-control-prepended search'
                  onChange={this.updateSearchQuery}
                  placeholder='Search transformation functions'
                  value={this.state.searchQuery} />
                <div className='input-group-prepend'>
                  <div className='input-group-text'>
                    <Search className='feather-icon' />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='card-header'>
            <h4 className='card-header-title text-right'>
             {transformers.length} {(transformers.length === 1) ? 'transformation function' : 'transformation functions'}
            </h4>
          </div>
          <div className='card-body'>
            <div class='list-group list-group-flush list my-n3'>
              {transformers.map((item, index) => (
                <TransformerCatalogItem
                  dataTypes={dataTypes}
                  item={item}
                  key={index} />
              ))}
            </div>
            {transformers.length === 0 &&
              <React.Fragment>
                <div className='mb-3 mt-4'>
                  Are we missing a transformation function that you could make use of?
                </div>
                <a href='mailto:support@datacater.io' className='btn btn-primary'>Let us know</a>
              </React.Fragment>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
  }
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
