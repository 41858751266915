const users = (state, action) => {
  const initialState = {
    currentUser:          undefined,
    fetchingUsers:        false,
    passwordUpdateStatus: undefined,
    users:                [],
    user:                 undefined
  };

  switch (action.type) {
    case 'REQUEST_USERS':
      return {
        ...state,
        fetchingUsers: true
      };
    case 'RECEIVE_USERS':
      return {
        ...state,
        fetchingUsers: false,
        users:         action.users
      };
    case 'REQUEST_USER':
      return {
        ...state,
        fetchingUsers: true,
        user:          undefined
      };
    case 'RECEIVE_USER':
      return {
        ...state,
        fetchingUsers: false,
        user:          action.user
      };
    case 'REQUEST_CURRENT_USER':
      return {
        ...state,
        fetchingUsers: true
      };
    case 'RECEIVE_CURRENT_USER':
      return {
        ...state,
        fetchingUsers: false,
        currentUser:   action.user
      };
    case 'REQUEST_UPDATE_CURRENT_USER':
      return {
        ...state,
        fetchingUsers: true
      };
    case 'RECEIVE_UPDATE_CURRENT_USER':
      return {
        ...state,
        currentUser:   action.user,
        fetchingUsers: false
      };
    case 'REQUEST_UPDATE_PASSWORD':
      return {
        ...state,
        passwordUpdateStatus: undefined
      };
    case 'RECEIVE_UPDATE_PASSWORD':
      return {
        ...state,
        passwordUpdateStatus: undefined
      };
    case 'RECEIVE_UPDATE_PASSWORD_WRONG_PASSWORD':
      return {
        ...state,
        passwordUpdateStatus: 'Your current password is not valid.'
      };
    default:
      return (state || initialState);
  }
};

export default users;
