import React, { Component } from 'react';
import {
  Copy,
  Trash2
} from 'react-feather';
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal:   false,
      showTransferForm:  false,
      showTransferModal: false,
      transferProjectId: undefined
    };

    this.toggleDeleteModal               = this.toggleDeleteModal.bind(this);
    this.deletePipeline                  = this.deletePipeline.bind(this);
    this.toggleTransferModal             = this.toggleTransferModal.bind(this);
    this.enableTransferForm              = this.enableTransferForm.bind(this);
    this.changeProjectForTransfer        = this.changeProjectForTransfer.bind(this);
    this.handleTransferPipelineToProject = this.handleTransferPipelineToProject.bind(this);
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  deletePipeline() {
    this.setState({
      showDeleteModal: false
    });
    this.props.deletePipelineFunc();
  }

  toggleTransferModal() {
    // reset error message when hiding transfer modal
    if (this.state.showTransferModal) {
      this.props.resetErrorMessageFunc();
    }

    if (
      // ignore valid project id if hiding transfer modal
      this.state.showTransferModal ||
      // validate valid project id if showing transfer modal
      !isNaN(parseInt(this.state.transferProjectId))
    ) {
      this.setState({
        showTransferModal: !this.state.showTransferModal
      });
    }
  }

  enableTransferForm() {
    this.setState({
      showTransferForm: true
    });
  }

  changeProjectForTransfer(event) {
    this.setState({
      transferProjectId: event.target.value
    });
  }

  handleTransferPipelineToProject() {
    this.props.transferPipelineFunc(
      this.props.pipeline.id,
      this.state.transferProjectId
    );
  }

  render() {
    const {
      canDeletePipeline,
      currentUser,
      pipeline,
      pipelines,
      pipelineHasActiveDeployment,
      project
    } = this.props;

    return (
      <React.Fragment>
        <div className='container mt-4'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title d-flex align-items-center'>
                    Pipeline
                  </h4>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <p className='h4'>
                            Duplicate this pipeline
                          </p>
                          <p className='mb-0'>
                            Create a full copy of this pipeline.
                          </p>
                        </div>
                        <div className='col-auto'>
                          {!pipelines.duplicatingPipeline &&
                            <button
                              className='btn btn-white'
                              onClick={() => this.props.duplicatePipelineFunc(pipeline.id)}>
                              <Copy className='feather-icon' />
                              Duplicate this pipeline
                            </button>
                          }
                          {pipelines.duplicatingPipeline &&
                            <button
                              className='btn btn-white'
                              disabled={true}>
                              <span className='spinner-border mr-2' role='status'>
                              </span>
                              <Copy className='feather-icon' />
                              Duplicating this pipeline...
                            </button>
                          }
                        </div>
                      </div>
                      {pipelines.duplicatedPipeline !== undefined &&
                        <div className='row'>
                          <div className='col'>
                            <div className='alert alert-success mb-0 mt-3'>
                              We successfully duplicated this pipeline
                              to <a className='text-white'  href={`/pipelines/${pipelines.duplicatedPipeline.id}/edit`}>pipeline #{pipelines.duplicatedPipeline.id}</a>.
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>
                    Access
                  </h4>
                </div>
                {(project === undefined) && (currentUser !== undefined) &&
                  <div className='card-body'>
                    {!this.state.showTransferForm &&
                      <div className='row align-items-center'>
                        <div className='col'>
                          You are the only user that can access this pipeline.
                        </div>
                        <div className='col-auto'>
                          <button className='btn btn-outline-primary' onClick={this.enableTransferForm}>
                            Transfer pipeline to project
                          </button>
                        </div>
                      </div>
                    }
                    {this.state.showTransferForm &&
                      <React.Fragment>
                        <div className='form-group'>
                          <label>
                          Transfer this pipeline to the following project:
                          </label>
                          <select
                            className='custom-select'
                            onChange={this.changeProjectForTransfer}
                            value={this.state.transferProjectId}>
                            <option value=''>-- Please choose a project</option>
                            {currentUser.projects.map(project => (
                              <option key={project.id} value={project.id}>#{project.id}: {project.name}</option>
                            ))}
                          </select>
                        </div>
                        <button
                          className='btn btn-primary'
                          disabled={(this.state.showTransferModal || (this.state.transferProjectId === undefined))}
                          onClick={this.toggleTransferModal}>
                          Transfer to project
                        </button>
                        <Modal show={this.state.showTransferModal} onHide={this.toggleTransferModal}>
                          <Modal.Header closeButton>
                            <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {pipelines.errorMessage !== undefined &&
                              <div className='alert alert-danger mt-n4 ml-n4 mr-n4 rounded-0 font-weight-bold'>
                                {pipelines.errorMessage}
                              </div>
                            }
                            <p>
                              Transferring a pipeline to a project is irreversible.
                            </p>
                            <p>
                              Transferring a pipeline to a project will also transfer the assigned data source and data sink to the given project.
                            </p>
                            <p className='mb-0'>
                              Do you want to proceed?
                            </p>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="white" onClick={this.toggleTransferModal}>
                              Cancel
                            </Button>
                            <Button variant="danger" onClick={this.handleTransferPipelineToProject}>
                              Transfer
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </React.Fragment>
                    }
                  </div>
                }
                {(project !== undefined) &&
                  <div className='card-body'>
                    All members of the project <a href={`/projects/${project.id}`}>#{project.id}: {project.name}</a> can access this pipeline.
                  </div>
                }
              </div>
              {canDeletePipeline &&
                <div className='card'>
                  <div className='card-header'>
                    <h4 className='card-header-title d-flex align-items-center'>
                      Danger Zone
                    </h4>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='row align-items-center'>
                          <div className='col'>
                              <p className='h4'>
                                Delete this pipeline
                              </p>
                              <p className='mb-0'>
                                Deleting a pipeline is irreversible. There is no way back.
                              </p>
                            </div>
                            <div className='col-auto align-items-center'>
                              <React.Fragment>
                                {!pipelineHasActiveDeployment &&
                                  <React.Fragment>
                                    <button
                                      className='btn btn-white delete-btn'
                                      onClick={this.toggleDeleteModal}>
                                      <Trash2 className='feather-icon' />
                                      Delete this pipeline
                                    </button>
                                    <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
                                      <Modal.Header closeButton>
                                        <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        Deleting a pipeline is irreversible. Do you want to proceed?
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button variant="white" onClick={this.toggleDeleteModal}>
                                          Cancel
                                        </Button>
                                        <Button variant="danger" onClick={this.props.handleDeleteFunc}>
                                          Delete
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </React.Fragment>
                                }
                                {pipelineHasActiveDeployment &&
                                  <OverlayTrigger
                                      placement='bottom'
                                      delay={{ show: 100, hide: 100 }}
                                      overlay={(props) => (<Tooltip {...props}>Please stop the active deployment before deleting the pipeline.</Tooltip>)}>
                                    <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                                      <button
                                        className='btn btn-white delete-btn'
                                        disabled={true}
                                        style={{ pointerEvents : 'none' }}>
                                        <Trash2 className='feather-icon' />
                                        Delete pipeline
                                      </button>
                                    </div>
                                  </OverlayTrigger>
                                }
                            </React.Fragment>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsPage;
