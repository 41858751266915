import React, { Component } from 'react';
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';

class TypeformConfig extends Component {
  constructor(props) {
    super(props);

    this.fetchTableNames = this.fetchTableNames.bind(this);
  }

  fetchTableNames(event) {
    event.preventDefault();

    this.props.retrieveTablesFromDataSourceFunc(this.props.dataSource);
  }
  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveTablesFunc
    } = this.props;

    const intervalMillis = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.intervalMillis !== undefined)
      ? dataSource.connectorConfig.intervalMillis
      : '';

    const catchupIntervalMillis = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.catchupIntervalMillis !== undefined)
      ? dataSource.connectorConfig.catchupIntervalMillis
      : '';


    // require the personal access token
    const retrievingTablesIsPossible =
      ![undefined, ''].includes(dataSource.password);

    const formIds = dataSources.tables.map(table => table.id);

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the token unless you want to change it.</span>)
      : (<span className='text-danger'>*</span>);

    return (
      <React.Fragment>
        <ConfigForm
          errorMessages={errorMessages}
          label={(<React.Fragment>Personal access token{editPasswordNotice}</React.Fragment>)}
          name='password'
          onChangeFunc={handleChangeFunc}
          type='password'
          value={dataSource.password} />
        <ConfigForm
          errorMessages={errorMessages}
          label='Workspace id'
          name='databaseName'
          onChangeFunc={handleChangeFunc}
          value={dataSource.databaseName} />
        <div className='form-group'>
          <label>Form id<span className='text-danger'>*</span></label>
          <div className='row'>
            <div className='col'>
              {dataSources.tables.length > 0 &&
                <select
                  className='custom-select'
                  name='bucketName'
                  onChange={handleChangeFunc}
                  value={dataSource.bucketName}>
                  {![undefined, ''].includes(dataSource.bucketName) && !formIds.includes(dataSource.bucketName) &&
                    <option value={dataSource.bucketName}>{dataSource.bucketName}</option>
                  }
                  <option value=''>--- Choose a form</option>
                  {dataSources.tables.map(form => (
                    <option key={form.id} value={form.id}>{form.id} - {form.name}</option>
                  ))}
                </select>
              }
              {dataSources.tables.length === 0 &&
                <input
                  type='text'
                  className='form-control'
                  name='bucketName'
                  onChange={handleChangeFunc}
                  value={dataSource.bucketName} />
              }
            </div>
            <div className='col-auto'>
              {dataSources.fetchingTables &&
                <button className='btn btn-white' disabled={true}>
                  Fetching forms...
                </button>
              }
              {!dataSources.fetchingTables && dataSources.tables.length === 0 && retrievingTablesIsPossible &&
                <button className='btn btn-white' onClick={this.fetchTableNames}>
                  Fetch available forms
                </button>
              }
              {!dataSources.fetchingTables && dataSources.tables.length === 0 && !retrievingTablesIsPossible &&
                <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (<Tooltip {...props}>Please provide the personal access token of your Typeform account.</Tooltip>)}>
                  <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                    <button
                      className='btn btn-white'
                      disabled={true}
                      style={{ pointerEvents : 'none' }}>
                      Fetch available forms
                    </button>
                  </div>
                </OverlayTrigger>
              }
              {!dataSources.fetchingTables && dataSources.tables.length > 0 &&
                <button className='btn btn-white' onClick={resetRetrieveTablesFunc}>
                  Reset forms
                </button>
              }
            </div>
          </div>
          {dataSources.fetchingTablesFailed &&
            <p className='mt-2 mb-0 font-weight-bold text-danger'>
              Fetching the available forms from your Typeform account failed. Please check the permissions of your personal access token.
            </p>
          }
          {(errorMessages !== undefined) && (errorMessages.bucketName !== undefined) &&
            <p className='mt-2 mb-0 font-weight-bold text-danger'>{errorMessages.bucketName}</p>
          }
        </div>
        <div className='form-group'>
          <label>Interval in between requests (ms)</label>
          <input
            type='text'
            className='form-control'
            name='intervalMillis'
            onChange={handleConfigChangeFunc}
            placeholder='60000'
            value={intervalMillis} />
        </div>
        <ConfigForm
          errorMessages={errorMessages}
          label='Interval in between requests (ms)'
          name='intervalMillis'
          onChangeFunc={handleConfigChangeFunc}
          placeholder='60000'
          value={intervalMillis} />
        <ConfigForm
          errorMessages={errorMessages}
          label='Interval in between requests, when catching up (ms)'
          name='catchupIntervalMillis'
          onChangeFunc={handleConfigChangeFunc}
          placeholder='30000'
          value={catchupIntervalMillis} />
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default TypeformConfig;
