import React, { Component } from 'react';
import RetrieveColumns      from '../RetrieveColumns';
import RetrieveTables       from '../RetrieveTables';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class GoogleCloudStorageConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveColumnsFunc,
      resetRetrieveTablesFunc,
      retrieveColumnsFromDataSourceFunc,
      retrieveTablesFromDataSourceFunc
    } = this.props;

    const flatColumns = dataSources.columns
      .map(column => column.name);

    const flatTables = dataSources.tables
      .map(table => table.name)
      .sort();

    const retrievingTablesIsPossible =
      ![undefined, ''].includes(dataSource.password) &&
      ![undefined, ''].includes(dataSource.databaseName);

    const retrievingColumnsIsPossible =
      ![undefined, ''].includes(dataSource.password) &&
      ![undefined, ''].includes(dataSource.databaseName) &&
      ![undefined, ''].includes(dataSource.bucketName);

    const primaryKeyColumn = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.primaryKeyColumn !== undefined)
      ? dataSource.connectorConfig.primaryKeyColumn
      : '';

    const intervalSeconds = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.intervalSeconds !== undefined)
      ? dataSource.connectorConfig.intervalSeconds
      : '';

    const fileNameFilter = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.fileNameFilter !== undefined)
      ? dataSource.connectorConfig.fileNameFilter
      : '';

    const dataFormat = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.dataFormat !== undefined)
      ? dataSource.connectorConfig.dataFormat
      : 'csv';

    const csvDelimiterValue = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.csvDelimiterValue !== undefined)
      ? dataSource.connectorConfig.csvDelimiterValue
      : '';

    const csvQuote = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.csvQuote !== undefined)
      ? dataSource.connectorConfig.csvQuote
      : '';

    const csvQuoteEscape = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.csvQuoteEscape !== undefined)
      ? dataSource.connectorConfig.csvQuoteEscape
      : '';

    const csvLineSeparator = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.csvLineSeparator !== undefined)
      ? dataSource.connectorConfig.csvLineSeparator
      : '';

    const csvComment = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.csvComment !== undefined)
      ? dataSource.connectorConfig.csvComment
      : '';

    const generateAttributeNamesFromHeaderRow = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.generateAttributeNamesFromHeaderRow !== undefined)
      ? '' + dataSource.connectorConfig.generateAttributeNamesFromHeaderRow
      : 'true';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the credentials unless you want to change them.</span>)
      : (<span className='text-danger'>*</span>);

    const kafkaConnectConfig = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSource.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item'>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment>Google Cloud credentials (JSON){editPasswordNotice}</React.Fragment>)}
              name='password'
              onChangeFunc={handleChangeFunc}
              type='password'
              value={dataSource.password} />
            <div class="alert alert-light" role="alert">
              Please make sure that the used service account is assigned to the role <i>Storage Admin</i>.
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Project name'
              name='databaseName'
              onChangeFunc={handleChangeFunc}
              value={dataSource.databaseName} />
            <RetrieveTables
              dataSource={dataSource}
              dataSources={dataSources}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the buckets from Google Cloud Storage failed. Please check your connection credentials and make sure that the service account has the role "storage.buckets.list".'
              handleChangeFunc={handleChangeFunc}
              label='Bucket'
              missingOptionsErrorMessage='Please provide your Google Cloud credentials and the name of the GoogleCloudStorage project.'
              resetRetrieveTablesFunc={resetRetrieveTablesFunc}
              retrieveTablesFromDataSourceFunc={retrieveTablesFromDataSourceFunc}
              retrievingTablesIsPossible={retrievingTablesIsPossible}
              tableAttributeName='bucketName'
              tables={flatTables} />
            <ConfigForm
              errorMessages={errorMessages}
              label='File name filter'
              name='fileNameFilter'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='.*'
              subLabel='Regular expression'
              value={fileNameFilter} />
            <div className='form-group'>
              <label>File format</label>
              <select
                className='custom-select'
                name='dataFormat'
                onChange={handleConfigChangeFunc}
                value={dataFormat}>
                <option value='csv'>CSV</option>
              </select>
            </div>
            {dataFormat === 'csv' &&
              <React.Fragment>
                <ConfigForm
                  errorMessages={errorMessages}
                  label='CSV delimiter value'
                  name='csvDelimiterValue'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder=','
                  value={csvDelimiterValue} />
                <ConfigForm
                  errorMessages={errorMessages}
                  label='CSV quote character'
                  name='csvQuote'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder='"'
                  value={csvQuote} />
                <ConfigForm
                  errorMessages={errorMessages}
                  label='CSV quote escape character'
                  name='csvQuoteEscape'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder='"'
                  value={csvQuoteEscape} />
                <ConfigForm
                  errorMessages={errorMessages}
                  label='CSV line separator'
                  name='csvLineSeparator'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder='\n'
                  value={csvLineSeparator} />
                <ConfigForm
                  errorMessages={errorMessages}
                  label='CSV comment character'
                  name='csvComment'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder='#'
                  value={csvComment} />
                <div className='custom-control custom-switch d-flex align-items-center mb-4'>
                  <input
                    checked={[true, 'true'].includes(generateAttributeNamesFromHeaderRow)}
                    className='custom-control-input clickable'
                    id='generateAttributeNamesSwitch'
                    name='generateAttributeNamesFromHeaderRow'
                    onChange={handleConfigChangeFunc}
                    type='checkbox' />
                  <label className='custom-control-label clickable' htmlFor='generateAttributeNamesSwitch'>
                    Generate attribute names from CSV header row
                  </label>
                </div>
              </React.Fragment>
            }
            <RetrieveColumns
              columnAttributeName='primaryKeyColumn'
              columns={flatColumns}
              dataSource={dataSource}
              dataSources={dataSources}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the column names from the GoogleCloudStorage dataset failed. Please check your connection credentials.'
              handleChangeFunc={handleConfigChangeFunc}
              isRequired={true}
              label='Primary key column'
              missingOptionsErrorMessage='Please provide your Google Cloud credentials, the name of the GoogleCloudStorage project, the name of the GoogleCloudStorage dataset, and the name of the GoogleCloudStorage table.'
              resetRetrieveColumnsFunc={resetRetrieveColumnsFunc}
              retrieveColumnsFromDataSourceFunc={retrieveColumnsFromDataSourceFunc}
              retrievingColumnsIsPossible={retrievingColumnsIsPossible}
              value={primaryKeyColumn} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Sync interval (s)'
              name='intervalSeconds'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='120'
              value={intervalSeconds} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default GoogleCloudStorageConfig;
