import React, { Component } from 'react';
import TestConnectionButton from '../TestConnectionButton';
import ConfigForm           from '../../data_stores/ConfigForm';

class ADSBHubConfig extends Component {
  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc
    } = this.props;

    const intervalMillis = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.intervalMillis !== undefined)
      ? dataSource.connectorConfig.intervalMillis
      : '';

    const fetchInterval = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.fetchInterval !== undefined)
      ? dataSource.connectorConfig.fetchInterval
      : '';

    const socketConnectionTimeout = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.socketConnectionTimeout !== undefined)
      ? dataSource.connectorConfig.socketConnectionTimeout
      : '';

    return (
      <React.Fragment>
        <ConfigForm
          errorMessages={errorMessages}
          label='Hostname'
          name='hostname'
          onChangeFunc={handleChangeFunc}
          placeholder='data.adsbhub.org'
          value={dataSource.hostname} />
        <ConfigForm
          errorMessages={errorMessages}
          label='Port'
          name='port'
          onChangeFunc={handleChangeFunc}
          placeholder='5002'
          value={dataSource.port} />
        <ConfigForm
          errorMessages={errorMessages}
          label='Interval in between API requests (ms)'
          name='intervalMillis'
          onChangeFunc={handleConfigChangeFunc}
          placeholder='120000'
          value={intervalMillis} />
        <ConfigForm
          errorMessages={errorMessages}
          label='Fetch interval per API request (ms)'
          name='fetchInterval'
          onChangeFunc={handleConfigChangeFunc}
          placeholder='20000'
          value={fetchInterval} />
        <ConfigForm
          errorMessages={errorMessages}
          label='Timeout when connecting to API (ms)'
          name='socketConnectionTimeout'
          onChangeFunc={handleConfigChangeFunc}
          placeholder='5000'
          value={socketConnectionTimeout} />
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default ADSBHubConfig;
