import React, { Component }       from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import TableHeaderCell            from './grid/TableHeaderCell';
import Filter                     from '../../../pipelines/Filter';
import { isString }               from '../../../helpers/isString';
import '../../../scss/grid.scss'

class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollLeft: 0,
      scrollTop:  0
    };

    this.setRef             = this.setRef.bind(this);
    this.getCell            = this.getCell.bind(this);
    this.getCellProps       = this.getCellProps.bind(this);
    this.saveScrollPosition = this.saveScrollPosition.bind(this);
  }

  /**
   * get Cell
   * formats a grid cell
   * @param sample
   * @param attribute
   * @returns {*}
   */
  getCell(sample, attribute) {
    let classNames = 'sample-cell w-100 text-nowrap';
    if (sample.lastChange !== undefined &&
      sample.lastChange[attribute.id] !== undefined) {
      if (sample.lastChange[attribute.id] === this.props.currentStep) {
        classNames += ' changed-in-current-step';
      } else {
        classNames += ' changed-in-previous-step';
      }
    }

    if (
      this.props.editColumnAttribute !== undefined &&
      parseInt(this.props.editColumnAttribute.id) === parseInt(attribute.id)
    ) {
      classNames += ' sample-cell-editing';
    }


    const rawValue = sample.values[attribute.id];
    let cellContent = (<React.Fragment></React.Fragment>);

    const renderBoolean = function(value) {
      if (value === true) {
        return 'true';
      } else if (value === false) {
        return 'false';
      } else {
        return '';
      }
    }

    const renderDate = function(value, dataType) {
      // check whether value is an instance of Date
      if (Object.prototype.toString.call(value) !== "[object Date]") {
        return 'Invalid Date';
      } else if (value === null) {
        return '';
      } else if (dataType === 'date') {
        return value.toLocaleDateString(); // only date
      } else if (dataType === 'timestamp-millis') {
        return value.toLocaleString(); // date & time
      } else if (dataType === 'time-millis') {
        return value.toLocaleTimeString(); // only time
      } else {
        return '';
      }
    }

    // Javascript uses 64-bit ints, Scala/Java uses 32-bit ints.
    // Check whether this int would overflow in Scala/Java
    const renderInt = function(value) {
      if (isNaN(value)) {
        return '';
      } else if (value < -2147483647 || value > 2147483647) {
        return (<span className='text-black-50'>Integer Overflow</span>);
      } else {
        return value;
      }
    }

    const renderNumber = function(value) {
      if (isNaN(value)) {
        return '';
      } else {
        return value;
      }
    }

    const renderString = function(value) {
      if (!isString(value)) {
        // When a string value is casted to a Date and the user returns to the step, where
        // the date was still a string, it could be that the sample records still hold Date objects
        // while the data type of the attribute has already been considered to be a string.
        // For such strange scenarios, we must make sure that we don't render Date objects as strings
        // as this would lead to JavaScript errors.
        if (Object.prototype.toString.call(value) === "[object Date]") {
          return '';
        } else {
          return value;
        }
      }

      // Limit shown string length
      const tokens = (value.length > 1500)
        ? value.substring(0,1500).split(' ')
        : value.split(' ');

      if (tokens.length === 0) {
        return value;
      } else {
        return (
          <React.Fragment>
            {tokens.map((entry, index) => (
              <React.Fragment key={index}>
                {index > 0 &&
                  <span className='text-black-50'>·</span>
                }
                {entry}
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      }
    }

    if (rawValue == null) {
      cellContent = (<React.Fragment></React.Fragment>);
    } else if (attribute.dataType === 'boolean') {
      if (rawValue.constructor === Array) {
        cellContent = (
          <React.Fragment>
            <span className='text-black-50'>(</span>
            {rawValue.map((entry, index) => (
              <React.Fragment key={index}>
                {index > 0 &&
                  <span className='text-black-50'>,</span>
                }
                {renderBoolean(entry)}
              </React.Fragment>
            ))}
            <span className='text-black-50'>)</span>
          </React.Fragment>
        );
      } else {
        cellContent = renderBoolean(rawValue);
      }
    } else if (['time-millis', 'timestamp-millis', 'date'].includes(attribute.dataType)) {
      if (rawValue.constructor === Array) {
        cellContent = (
          <React.Fragment>
            <span className='text-black-50'>(</span>
            {rawValue.map((entry, index) => (
              <React.Fragment key={index}>
                {index > 0 &&
                  <span className='text-black-50'>,</span>
                }
                {renderDate(entry, attribute.dataType)}
              </React.Fragment>
            ))}
            <span className='text-black-50'>)</span>
          </React.Fragment>
        );
      } else {
        cellContent = renderDate(rawValue, attribute.dataType);
      }
    } else if (attribute.dataType === 'int') {
      if (rawValue.constructor === Array) {
        cellContent = (
          <React.Fragment>
            <span className='text-black-50'>(</span>
            {rawValue.map((entry, index) => (
              <React.Fragment key={index}>
                {index > 0 &&
                  <span className='text-black-50'>,</span>
                }
                {renderInt(entry)}
              </React.Fragment>
            ))}
            <span className='text-black-50'>)</span>
          </React.Fragment>
        );
      } else {
        cellContent = renderInt(rawValue);
      }
    } else if (['long', 'float', 'double'].includes(attribute.dataType)) {
      if (rawValue.constructor === Array) {
        cellContent = (
          <React.Fragment>
            <span className='text-black-50'>(</span>
            {rawValue.map((entry, index) => (
              <React.Fragment key={index}>
                {index > 0 &&
                  <span className='text-black-50'>,</span>
                }
                {renderNumber(entry)}
              </React.Fragment>
            ))}
            <span className='text-black-50'>)</span>
          </React.Fragment>
        );
      } else {
        cellContent = renderNumber(rawValue);
      }
    } else {
      if (rawValue.constructor === Array) {
        cellContent = (
          <React.Fragment>
            <span className='text-black-50'>(</span>
            {rawValue.map((entry, index) => (
              <React.Fragment key={index}>
                {index > 0 &&
                  <span className='text-black-50'>,</span>
                }
                {renderString(entry)}
              </React.Fragment>
            ))}
            <span className='text-black-50'>)</span>
          </React.Fragment>
        );
      } else {
        cellContent = renderString(rawValue);
      }
    }

    return (
      <div
        className={classNames}
        key={attribute.id}
        title={'' + rawValue}>
        {cellContent}
      </div>
    );
  }

  /**
   * Return the cell props
   * @param columnIndex
   * @returns {{'data-col-idx': *}}
   */
  getCellProps({columnIndex}) {
    return {
      'data-col-idx': columnIndex
    }
  }

  /**
   * get column returns the config object for a column
   * @param attribute
   * @param idx
   * @param filters
   * @returns {{showStatistics, transformers, introducedAttributes, dataGetter: (function({rowData: *, rowIndex: *}): *), isRowNumber: boolean, cellRenderer: cellRenderer, attributeProfiles, frozen: *, handlePipelineStepChangeFunc, filters: *, title: *, editColumnAttribute, handleAssertionChangeFunc, pipelineStep, currentStep, removeColumnFunc, showMostFrequentValues, width: number, attributes, attribute: *, assertions, editColumnFunc, key: number}}
   */
  getColumn(attribute, idx, filters) {
    const me = this;

    const assertionOfCurrentStep = this.props.assertions
      .find(assertion => parseInt(assertion.attributeId) === parseInt(attribute.id));

    let transformationOfCurrentStep = undefined;
    if (this.props.pipelineStep !== undefined) {
      transformationOfCurrentStep = this.props.pipelineStep
        .attributes
        .find(transformation => parseInt(transformation.transformAttributeId) === parseInt(attribute.id));
    }

    return {
      // column properties
      title:                        attribute.name,
      width:                        attribute.id === 0 ? 50 : 300,
      key:                          parseInt(attribute.id),
      frozen:                       attribute.id === 0 ? 'left' : false,
      resizable:                    true,
      dataGetter:                   ({rowData, rowIndex}) => attribute.id !== 0 ? rowData.values[attribute.id] : rowIndex + 1,
      cellRenderer:                 function({cellData, columns, column, columnIndex, rowData, rowIndex, container, isScrolling}) {
        if (!column.isRowNumber && column.attribute !== undefined) {
          return me.getCell(rowData, column.attribute);
        } else {
          return cellData;
        }
      },
      // custom properties used for header sub components
      isRowNumber:                  attribute.id === 0,
      attribute:                    attribute,
      dataSourceMappings:           this.props.dataSourceMappings,
      filters:                      filters,
      attributes:                   this.props.attributes,
      attributeProfiles:            this.props.attributeProfiles,
      assertionOfCurrentStep:       assertionOfCurrentStep,
      pipelineStep:                 this.props.pipelineStep,
      currentStep:                  this.props.currentStep,
      introducedAttributes:         this.props.introducedAttributes,
      canEditPipeline:              this.props.canEditPipeline,
      editColumnAttribute:          this.props.editColumnAttribute,
      editColumnFunc:               this.props.editColumnFunc,
      removeColumnFunc:             this.props.removeColumnFunc,
      handlePipelineStepChangeFunc: this.props.handlePipelineStepChangeFunc,
      handleAssertionChangeFunc:    this.props.handleAssertionChangeFunc,
      showStatistics:               this.props.showStatistics,
      transformationOfCurrentStep:  transformationOfCurrentStep,
      transformers:                 this.props.transformers
    }
  }

  /**
   * Get the array with the columns configuration
   * @returns {[]}
   */
  getColumns() {
    const filters = Filter.getFilters();
    let columnsConfig = [...this.props.attributes];
    let columns = [];

    // add # row number column
    columnsConfig.unshift({id: 0, name: '#'})
    columnsConfig.map((attribute, idx) => columns.push(this.getColumn(attribute, idx, filters)));

    return columns;
  }

  /**
   * Returns the data for the grid
   */
  getData() {
    // filter records which were dropped
    const sampleRecords = this.props.sampleRecords
      .filter(record => record.isDropped !== true);

    // we have to make sure that the samples get an id property. this is needed for row hover / selection
    sampleRecords.forEach(function(sample, index) {
      sample['id'] = index + 1;
    });

    return sampleRecords;
  }

  /**
   * Calculate the header height
   * @returns {number}
   */
  calculateHeaderHeight() {
    const {currentStep, showStatistics} = this.props;
    let headerHeight = 149;

    if (showStatistics) {
      headerHeight += 158;
    }

    // filters or pipeline steps (for Apply filter/transformation button)
    if (currentStep !== undefined) {
      headerHeight += 35;
    }

    return headerHeight;
  }

  /**
   * Get the grid class names
   * for example for highlighting the active edit column etc.
   * @param columns
   * @returns {string}
   */
  getClassNames(columns) {
    const {
      currentStep,
      editColumnAttribute
    } = this.props;
    let classNames;
    let activeColumnIndex;

    // highlight active column
    if (editColumnAttribute !== undefined) {
      activeColumnIndex = columns.findIndex(function(column) {
        return column.attribute !== undefined && parseInt(column.attribute.id) === parseInt(editColumnAttribute.id);
      });

      if (activeColumnIndex) {
        classNames = `active-col-${activeColumnIndex}`;
      }
    }

    if (currentStep === 0) {
      classNames += ' datacater-grid-filters';
    } else if (currentStep > 0) {
      classNames += ' datacater-grid-pipeline-steps';
    }

    return classNames;
  }

  /**
   * React lifecycle method componentDidUpdate
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    // scroll to last known position
    document.querySelector('.datacater-grid__body').scrollLeft = this.state.scrollLeft;
    document.querySelector('.datacater-grid__body').scrollTop  = this.state.scrollTop;

    if (this.props.addedColumn) {
      this.grid.scrollToLeft(this.grid.getTotalColumnsWidth());
    }
  }

  saveScrollPosition({ scrollLeft, scrollTop, horizontalScrollDirection, verticalScrollDirection, scrollUpdateWasRequested }) {
    // persist scroll position without re-rendering component
    this.state.scrollLeft = scrollLeft;
    this.state.scrollTop  = scrollTop;
  }

  /**
   * Set grid Ref
   * @param ref
   */
  setRef(ref) {
    this.grid = ref;
  }

  render() {
    const columns  = this.getColumns();
    let classNames = this.getClassNames(columns);
    const data     = this.getData();

    return (
      <div className='datacater-grid-container'>
        <AutoResizer>
          {({width, height}) => (
            <BaseTable
              fixed
              classPrefix='datacater-grid'
              className={classNames}
              ref={this.setRef}
              width={width}
              height={height}
              rowHeight={24}
              headerHeight={this.calculateHeaderHeight()}
              columns={columns}
              data={data}
              components={{TableHeaderCell}}
              cellProps={this.getCellProps}
              onScroll={this.saveScrollPosition}
              headerCellProps={this.getCellProps}
            />
          )}
        </AutoResizer>
      </div>
    );
  }
}

export default Grid;
