import React, { Component }          from 'react';
class PipelineDesignerFilters extends Component {
  render () {
    const activeFilters = this.props.assertions
      .filter(assertion => ![undefined, ''].includes(assertion.assertionFilter))
      .length;

    let filteredRecords = 0;
    if (this.props.originalRecordsSize !== undefined &&
        this.props.sampleRecordsSize !== undefined) {
      filteredRecords = this.props.originalRecordsSize -
                        this.props.sampleRecordsSize;
    }

    let dropRate = 100.0;
    if (this.props.originalRecordsSize !== undefined &&
        this.props.originalRecordsSize > 0) {
      dropRate = (filteredRecords / this.props.originalRecordsSize) * 100.0;
    }

    return (
      <div className='row align-items-center m-0 h-75'>
        <div className='col-auto text-center pl-0'>
          <h5 className='mb-2'>
            Active Filters
          </h5>
          <h4 className='mb-0'>{activeFilters}</h4>
        </div>
        <div className='col-auto text-center border-left'>
          <h5 className='mb-2'>
            Drop Rate
          </h5>
          <h4 className='mb-0'>{dropRate.toFixed(2)}%</h4>
        </div>
      </div>
    );
  }
}

export default PipelineDesignerFilters;
