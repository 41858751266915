import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import DataSinkLogo         from './DataSinkLogo';
import { formatInteger }    from '../../helpers/formatInteger';

class DataSinkListItem extends Component {
  constructor(props) {
    super(props);
    this.goToDataSink = this.goToDataSink.bind(this);
  }

  getPathToDataSink(dataSink) {
    return (dataSink.id != null) ? ('/data_sinks/' + dataSink.id) : '/data_sinks';
  }

  goToDataSink(event) {
    event.preventDefault();
    const dataSinkId = this.props.dataSink.id;
    if (dataSinkId != null) {
      this.props.history.push('/data_sinks/' + dataSinkId);
    }
  }

  render() {
    const {
      currentUser,
      dataSink
    } = this.props;

    const project =
      (
        ![currentUser, dataSink].includes(undefined) &&
        (dataSink.userId === undefined) &&
        (dataSink.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === dataSink.projectId)
        : undefined;

    return (
      <li className='list-group-item py-2' key={dataSink.id}>
        <div className='row align-items-center py-2'>
          <div className='col-auto sink-type-logos'>
            <div className='logo-wrap'>
              <DataSinkLogo dataSink={dataSink} />
            </div>
          </div>
          <div className='col'>
            <h4 className='mb-0 name'>
              {project !== undefined &&
                <React.Fragment>
                  <a href={`/projects/${project.id}`} className='text-gray'>
                    {project.name}
                  </a>
                  <span className='text-gray mx-2'>
                    /
                  </span>
                </React.Fragment>
              }
              <Link to={this.getPathToDataSink(dataSink)}>
                #{dataSink.id}: {dataSink.name}
              </Link>
            </h4>
          </div>
          <div className='col-auto'>
            {this.props.showNumberOfRecords === true &&
             this.props.numberOfRecords !== undefined &&
             formatInteger(this.props.numberOfRecords) + ' records'}
            {this.props.showNumberOfRecords !== true &&
              <Link to={this.getPathToDataSink(dataSink)} className='btn btn-white btn-sm'>
                Show
              </Link>
            }
          </div>
        </div>
      </li>
    );
  }
}

export default DataSinkListItem;
