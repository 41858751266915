export function fetchInfo() {
  const requestInfo = () => ({
    type: 'REQUEST_INFO'
  });

  const receivedInfo = response => ({
    info: response,
    type: 'RECEIVE_INFO'
  });

  return function (dispatch) {
    dispatch(requestInfo());

    return fetch(
        '/api/info',
      )
      .then(response => response.json())
      .then((json) => {
        dispatch(receivedInfo(json));
      });
  };
}
