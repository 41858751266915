import React, { Component } from 'react';
import { Package }          from 'react-feather';
import Transformer          from '../../pipelines/Transformer';
import { humanizeDataType } from '../../helpers/humanizeDataType';

class TransformerCatalogItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionValue:  '',
      dataType:     '',
      exampleValue: ''
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.getOutput    = this.getOutput.bind(this);
  }

  handleUpdate(event) {
    let update = this.state;
    update[event.target.name] = event.target.value;
    this.setState(update);
  }

  getOutput() {
    const transformer = Transformer.getTransformers()
      .find(transformer => transformer.key === this.props.item.key);

    if (transformer === undefined || (this.state.exampleValue.length === 0 &&
                                      this.state.actionValue.length === 0)) {
      return '';
    }

    let dataType = this.state.dataType;
    if (dataType.length === 0 && this.props.item.restrictToDataType !== undefined) {
      dataType = this.props.item.restrictToDataType.sort()[0];
    } else if (dataType.length === 0) {
      dataType = 'string';
    }

    let exampleValue = this.state.exampleValue;
    if (['int', 'long'].includes(dataType)) {
      exampleValue = parseInt(this.state.exampleValue);
    } else if (['double', 'float'].includes(dataType)) {
      exampleValue = parseFloat(this.state.exampleValue);
    } else if (dataType === 'boolean') {
      exampleValue = (['true', '1', 'TRUE'].includes(this.state.exampleValue));
    }
    // TODO: support dates

    if (transformer.hasActionValue) {
      let actionValue = this.state.actionValue;
      if (['int', 'long'].includes(dataType)) {
        actionValue = parseInt(actionValue);
      } else if (['double', 'float'].includes(dataType)) {
        actionValue = parseFloat(actionValue);
      } else if (dataType === 'boolean') {
        actionValue = (['true', '1', 'TRUE'].includes(actionValue));
      }
      if (actionValue.length === 0 && this.props.item.actionValueOptions !== undefined) {
        actionValue = this.props.item.actionValueOptions[0].value;
      }

      return transformer.transformationFunc(
        {},
        this.state.dataType,
        exampleValue,
        actionValue);
    } else {
      return transformer.transformationFunc(
        {},
        this.state.dataType,
        exampleValue);
    }
  }

  extractDataTypes(item) {
    if ([undefined, ''].includes(item.restrictToDataType)) {
      return ['All data types'];
    } else {
      return item.restrictToDataType
                 .map(type => humanizeDataType(type))
                 .sort();
    }
  }

  render() {
    const item = this.props.item;
    const dataTypes = this.props.dataTypes
      .filter(dataType => (item.restrictToDataType !== undefined)
                            ? item.restrictToDataType.includes(dataType.value)
                            : true);
    return (
      <li className='list-group-item'>
        <div className='row align-items-center py-2'>
          <div className='col-auto'>
            <span>
              <Package className='feather-icon' />
            </span>
          </div>
          <div className='col ml-n2'>
            <h4 className='mb-2 name'>
              <span className='mr-2'>{item.name}</span>
            {this.extractDataTypes(item).map((dataType, idx) => (
              <div className='badge badge-primary ml-1'>
                {dataType}
              </div>
            ))}
            </h4>
            <div class='text-muted mb-3'>
              {item.description}
            </div>
            {!item.previewInRepository &&
              <div>
                <h5>No preview available.</h5>
              </div>
            }
            {item.previewInRepository && item.hasActionValue &&
              <div className='row'>
                <div className='col-3'>
                  <h5>Input Data Type:</h5>
                  <select
                    className='custom-select'
                    name='dataType'
                    onChange={this.handleUpdate}
                    value={this.state.dataType}>
                    {dataTypes.map((actionValueOption, index) => (
                      <option
                        key={index}
                        value={actionValueOption.value}>
                        {actionValueOption.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-3'>
                  <h5>Input Value:</h5>
                  <input
                    className='form-control'
                    onChange={this.handleUpdate}
                    name='exampleValue'
                    type='text'
                    value={this.state.exampleValue} />
                </div>
                <div className='col-3'>
                  <h5>{item.actionValueName}:</h5>
                  {item.actionValueOptions === undefined &&
                    <input
                      className='form-control'
                      onChange={this.handleUpdate}
                      name='actionValue'
                      type='text'
                      value={this.state.actionValue} />
                  }
                  {item.actionValueOptions !== undefined &&
                    <select
                      className='custom-select'
                      name='actionValue'
                      onChange={this.handleUpdate}
                      value={this.state.actionValue}>
                      {item.actionValueOptions.map((actionValueOption, index) => (
                        <option
                          key={index}
                          value={actionValueOption.value}>
                          {actionValueOption.label}
                        </option>
                      ))}
                    </select>
                  }
                </div>
                <div className='col-3'>
                  <h5>Output:</h5>
                  <h5 className='mb-0 d-flex align-items-center datacater-catalog-preview'>
                    {this.getOutput()}
                  </h5>
                </div>
              </div>
            }
            {item.previewInRepository && !item.hasActionValue &&
              <div className='row'>
                <div className='col-3'>
                  <h5>Input Data Type:</h5>
                  <select
                    className='custom-select'
                    name='dataType'
                    onChange={this.handleUpdate}
                    value={this.state.dataType}>
                    {dataTypes.map((actionValueOption, index) => (
                      <option
                        key={index}
                        value={actionValueOption.value}>
                        {actionValueOption.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-6'>
                  <h5>Input Value:</h5>
                  <input
                    className='form-control'
                    onChange={this.handleUpdate}
                    name='exampleValue'
                    type='text'
                    value={this.state.exampleValue} />
                </div>
                <div className='col-3'>
                  <h5>Output:</h5>
                  <h5 className='mb-0 d-flex align-items-center datacater-catalog-preview'>
                    {this.getOutput()}
                  </h5>
                </div>
              </div>
            }
          </div>
        </div>
      </li>
    );
  }
}

export default TransformerCatalogItem;
