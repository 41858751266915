const projects = (state, action) => {
  const initialState = {
    addingProjects:   false,
    fetchingProjects: false,
    dataSinks:        [],
    dataSources:      [],
    pipelines:        [],
    projects:         [],
    project:          undefined,
    streams:          []
  };

  switch (action.type) {
    case 'REQUEST_PROJECTS':
      return {
        ...state,
        fetchingProjects: true
      };
    case 'RECEIVE_PROJECTS':
      return {
        ...state,
        fetchingProjects: false,
        projects:         action.projects
      };
    case 'REQUEST_PROJECT':
      return {
        ...state,
        fetchingProjects: true,
        project:          undefined
      };
    case 'RECEIVE_PROJECT':
      return {
        ...state,
        fetchingProjects: false,
        project:          action.project
      };
    case 'REQUEST_PROJECT_DATA_SINKS':
      return {
        ...state,
        dataSinks: []
      };
    case 'RECEIVE_PROJECT_DATA_SINKS':
      return {
        ...state,
        dataSinks: action.dataSinks
      };
    case 'REQUEST_PROJECT_DATA_SOURCES':
      return {
        ...state,
        dataSources: []
      };
    case 'RECEIVE_PROJECT_DATA_SOURCES':
      return {
        ...state,
        dataSources: action.dataSources
      };
    case 'REQUEST_PROJECT_PIPELINES':
      return {
        ...state,
        pipelines: []
      };
    case 'RECEIVE_PROJECT_PIPELINES':
      return {
        ...state,
        pipelines: action.pipelines
      };
    case 'REQUEST_PROJECT_STREAMS':
      return {
        ...state,
        streams: []
      };
    case 'RECEIVE_PROJECT_STREAMS':
      return {
        ...state,
        streams: action.streams
      };
    case 'REQUEST_ADD_PROJECT':
      return {
        ...state,
        addingProjects: true,
        project:        undefined
      };
    case 'RECEIVE_ADD_PROJECT':
      return {
        ...state,
        addingProjects: false,
        project:        action.project
      };
    default:
      return (state || initialState);
  }
};

export default projects;
