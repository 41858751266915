import React,
       { Component }        from 'react';
import { AlertCircle,
         ArrowRight,
         Key,
         Trash2 }           from 'react-feather';
import { OverlayTrigger,
         Tooltip }          from 'react-bootstrap';
import { humanizeDataType } from '../../../../../helpers/humanizeDataType';

class DataSinkProfileAttributeForm extends Component {
  getAttributeLabel(attribute) {
    return attribute.name;
  }

  renderAttributeName(attribute, pipelineAttribute) {
    if (attribute.isTemplate && pipelineAttribute !== undefined) {
      // replace wildcard (*) by name of pipeline attribute
      return attribute.name.replace(
        '*',
        pipelineAttribute.name);
    } else {
      return attribute.name;
    }
  }

  render() {
    const canEditPipeline = this.props.canEditPipeline;
    const attribute = this.props.attribute;
    const pipelineAttributes = this.props.pipelineAttributes
      // require identical data types
      .filter(pipelineAttribute => pipelineAttribute.dataType === attribute.dataType)
      // require primary keys of sinks to be filled with primary keys of sources
//      .filter(pipelineAttribute =>
//        [undefined, false].includes(attribute.isKey) || pipelineAttribute.isKey === true)
      .sort(function(x, y) {
        if (x.name < y.name) {
          return -1;
        }
        if (x.name > y.name) {
          return 1;
        }
        return 0;
      });

    // determine selected pipeline attribute
    const pipelineAttribute = pipelineAttributes
      .find(_ => parseInt(_.id) === attribute.dataSourceProfileAttributeId);

    const isTimeField =
      ['date', 'time-millis', 'timestamp-millis'].includes(attribute.dataType);

    const dataSourceProfileAttributeIdValue =
      (isTimeField && attribute.fillWithProcessingTime)
        ? 'fill-with-processing-time'
        : attribute.dataSourceProfileAttributeId || '';

    return (
      <li className='list-group-item'>
        <div className='row align-items-center'>
          <div className='col'>
            <select
              className='custom-select custom-select-sm d-block w-100'
              data-attribute-id={attribute.id}
              disabled={!canEditPipeline}
              name='dataSourceProfileAttributeId'
              onChange={this.props.handleDataSinkProfileChangeFunc}
              value={dataSourceProfileAttributeIdValue}>
              {isTimeField &&
                <option value='fill-with-processing-time'>FILL WITH PROCESSING TIME</option>
              }
              <option value=''>--- Choose an attribute of type {humanizeDataType(attribute.dataType)}</option>
              {pipelineAttributes.map(pipelineAttribute => (
                <option key={pipelineAttribute.id} value={pipelineAttribute.id}>
                  {this.getAttributeLabel(pipelineAttribute)}
                </option>
              ))}
            </select>
          </div>
          <div className='col-auto'>
            <ArrowRight className='feather-icon' />
          </div>
          <div className='col'>
            <h4 className='font-400 mb-0'>
              {this.renderAttributeName(attribute, pipelineAttribute)}
              {attribute.isKey &&
                <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (<Tooltip {...props}>Primary key</Tooltip>)}>
                  <Key className='feather-icon ml-2 mt-n1' />
                </OverlayTrigger>
              }
              {!attribute.isNullable &&
                <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (<Tooltip {...props}>This attribute requires non-empty values</Tooltip>)}>
                  <AlertCircle className='feather-icon ml-2 mt-n1' />
                </OverlayTrigger>
              }
              {attribute.isVirtual &&
                <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (<Tooltip {...props}>Remove virtual attribute</Tooltip>)}>
                  <Trash2
                    className='feather-icon ml-2 mt-n1 clickable'
                    onClick={() => {this.props.deleteDataSinkAttributeFunc(attribute.id)}} />
                </OverlayTrigger>
              }
              <span className='float-right'>
                {humanizeDataType(attribute.dataType)}
              </span>
            </h4>
          </div>
        </div>
      </li>
    );
  }
}

export default DataSinkProfileAttributeForm;
