const transformations = (state, action) => {
  const initialState = {
    executionError:            undefined,
    isExecutingTransformation: false
  };

  switch (action.type) {
    case 'REQUEST_EXECUTION_TRANSFORMATION':
      return {
        ...state,
        executionError:            undefined,
        isExecutingTransformation: true
      };
    case 'RECEIVE_SUCCESSFUL_EXECUTION_TRANSFORMATION':
      return {
        ...state,
        executionError:            undefined,
        isExecutingTransformation: false
      };
    case 'RECEIVE_FAILED_EXECUTION_TRANSFORMATION':
      return {
        ...state,
        executionError:            action.executionError,
        isExecutingTransformation: false
      };
    case 'RESET_EXECUTION_ERROR':
      return {
        ...state,
        executionError: undefined
      };
    default:
      return (state || initialState);
  }
};

export default transformations;
