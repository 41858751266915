export function fetchActivityEvents() {
  const requestActivityEvents = () => ({
    type: 'REQUEST_ACTIVITY_EVENTS'
  });

  const receivedActivityEvents = response => ({
    type:           'RECEIVE_ACTIVITY_EVENTS',
    activityEvents: response
  });

  return function (dispatch) {
    dispatch(requestActivityEvents());

    return fetch('/api/activity_events',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedActivityEvents(json));
      });
  };
}
