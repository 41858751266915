import React, { Component } from 'react';
import { Check }            from 'react-feather';
import DataSourceLogo       from './DataSourceLogo';

class SourceTypeItem extends Component {
  render() {
    const {
      changeSourceTypeFunc,
      dataSource,
      preventSelecting
    } = this.props;

    if (dataSource.isSelected || preventSelecting) {
      return (
        <li className='list-group-item p-3 px-4 active'>
          <div className='row align-items-center'>
            <div className='col-auto'>
              <Check className='feather-icon' />
            </div>
            <div className='col-auto'>
              <div className='logo-wrap float-left'>
                <DataSourceLogo dataSource={dataSource} />
              </div>
            </div>
            <div className='col'>
              <h4 className='mb-0'>{dataSource.label}</h4>
            </div>
            {preventSelecting !== true &&
              <div className='col-auto'>
                <a
                  className='btn btn-white'
                  href='/data_sources/new'
                  onClick={(event) => changeSourceTypeFunc(event, undefined)}>
                  Unselect
                </a>
              </div>
            }
          </div>
        </li>
      );
    } else {
      return (
        <li
          className='list-group-item p-3 px-4 clickable hoverable'
          onClick={(event) => changeSourceTypeFunc(event, dataSource.value)}>
          <div className='row align-items-center'>
            <div className='col-auto'>
              <div className='logo-wrap float-left'>
                <DataSourceLogo dataSource={dataSource} />
              </div>
            </div>
            <div className='col'>
              <h4 className='mb-0'>{dataSource.label}</h4>
            </div>
            <div className='col-auto'>
              <a
                className='btn btn-white'
                href='/data_sources/new'
                onClick={(event) => changeSourceTypeFunc(event, dataSource.value)}>
                Select
              </a>
            </div>
          </div>
        </li>
      );
    }
  }
}

export default SourceTypeItem;
