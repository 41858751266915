export function validateConnectorConfig(config, validations) {
  let errorMessages = {};

  // always validate name
  if (config.name === undefined || config.name.trim().length === 0) {
    errorMessages['name'] = 'Please provide a value.';
  }

  if (validations !== undefined) {
    validations.forEach(validation => {
      const fieldName = validation.field;
      // first look in main config
      let fieldValue = config[fieldName];
      // if there's no value, look in connector-specific config
      if ((fieldValue === undefined) && (config.connectorConfig !== undefined)) {
        fieldValue = config.connectorConfig[fieldName];
      }

      // validate not if
      if (
        (validation.validateNotIf !== undefined) &&
        (
          validation.validateNotIf.fieldValues.includes(config[validation.validateNotIf.fieldName]) ||
          (
            (config.connectorConfig !== undefined) &&
            validation.validateNotIf.fieldValues.includes(config.connectorConfig[validation.validateNotIf.fieldName])
          )
        )
      ) {
        return;
      }

      // validate if
      if (
        (validation.validateIf !== undefined) &&
        (
          !validation.validateIf.fieldValues.includes(config[validation.validateIf.fieldName]) &&
          !(
            (config.connectorConfig !== undefined) &&
            validation.validateIf.fieldValues.includes(config.connectorConfig[validation.validateIf.fieldName])
          )
        )
      ) {
        return;
      }

      switch(validation.validation) {
        case 'format':
          if (![undefined, ''].includes(fieldValue)) {
            const regExp = new RegExp(validation.format);
            if (fieldValue.match(regExp) == null) {
              errorMessages[fieldName] = `Please provide a value that matches ${validation.format}.`;
            }
          }
          break;
        case 'number':
          if (![undefined, ''].includes(fieldValue)) {
            if (isNaN(fieldValue)) {
              errorMessages[fieldName] = 'Please provide a valid number.';
            } else if ((validation.min !== undefined) && (parseInt(fieldValue) < validation.min)) {
              errorMessages[fieldName] = `Please provide a number equal or greater than ${validation.min}.`;
            } else if ((validation.max !== undefined) && (parseInt(fieldValue) > validation.max)) {
              errorMessages[fieldName] = `Please provide a number equal or less than ${validation.max}.`;
            }
          }
          break;
        default:
        case 'presence':
          if ([undefined, ''].includes(fieldValue)) {
            errorMessages[fieldName] = 'Please provide a value.';
          }
          break;
      }
    });
  }

  return errorMessages;
}
