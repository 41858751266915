const apiCredentials = (state, action) => {
  const initialState = {
    apiCredential:          undefined,
    apiCredentials:         [],
    fetchingapiCredentials: false
  };

  switch (action.type) {
    case 'REQUEST_API_CREDENTIALS':
      return {
        ...state,
        fetchingApiCredentials: true
      };
    case 'RECEIVE_API_CREDENTIALS':
      return {
        ...state,
        fetchingApiCredentials: false,
        apiCredentials:         action.apiCredentials
      };
    case 'REQUEST_ADD_API_CREDENTIAL':
      return {
        ...state,
        apiCredential: undefined
      };
    case 'RECEIVE_ADD_API_CREDENTIAL':
      return {
        ...state,
        apiCredential: action.apiCredential
      };
    default:
      return (state || initialState);
  }
};

export default apiCredentials;
