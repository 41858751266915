import { combineReducers } from 'redux';
import activityEvents      from './activity_events';
import apiCredentials      from './api_credentials';
import dataSinks           from './data_sinks';
import dataSinkProfiles    from './data_sink_profiles';
import dataSources         from './data_sources';
import dataSourceProfiles  from './data_source_profiles';
import flatFiles           from './flat_files';
import info                from './info';
import kafkaConnectors     from './kafka_connectors';
import pipelines           from './pipelines';
import pipelineDesigner    from './pipeline_designer';
import projects            from './projects';
import projectMemberships  from './project_memberships';
import streams             from './streams';
import transformations     from './transformations';
import users               from './users';
import userSessions        from './user_sessions';

export default combineReducers({
  activityEvents,
  apiCredentials,
  dataSinks,
  dataSinkProfiles,
  dataSources,
  dataSourceProfiles,
  flatFiles,
  info,
  kafkaConnectors,
  pipelines,
  pipelineDesigner,
  projects,
  projectMemberships,
  streams,
  transformations,
  users,
  userSessions
});
