import React,
       { Component } from 'react';
import {
  AlertCircle,
  HelpCircle
} from 'react-feather';
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import ExecutableListItem from '../../../../components/pipelines/ExecutableListItem';
import ExecutableLogs from '../../../../components/pipelines/ExecutableLogs';
import { formatInteger } from '../../../../helpers/formatInteger';
import { isValidAttributeName } from '../../../../helpers/isValidAttributeName';

class DeploymentsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showResetModal: false
    };

    this.toggleResetModal = this.toggleResetModal.bind(this);
    this.resetPipeline    = this.resetPipeline.bind(this);
  }

  toggleResetModal() {
    this.setState({
      showResetModal: !this.state.showResetModal
    });
  }

  resetPipeline() {
    this.setState({
      showResetModal: false
    });
    this.props.resetPipelineFunc();
  }

  render() {
    const {
      canEditPipeline,
      dataSink,
      pipeline,
      pipelines,
      pipelineHasActiveDeployment
    } = this.props;

    const isSinkWithoutSchema =
      (dataSink !== undefined) &&
      (['ax-semantics', 'csv', 'elasticsearch', 'json', 'mongodb'].includes(dataSink.sinkType));

    const pipelineExecutables = this.props.pipelineExecutables
    //      .filter(_ => (_.startedAt === undefined) || (_.stoppedAt !== undefined))
      .sort((a, b) => b.id - a.id);

    const runningPipelineExecutables = this.props.pipelineExecutables
      .filter(_ => (_.startedAt !== undefined) && (_.stoppedAt === undefined));

    const runningPipelineExecutable = (runningPipelineExecutables.length > 0)
      ? runningPipelineExecutables[0]
      : undefined;

    const runningPipelineInformation = (runningPipelineExecutable !== undefined)
      ? '(#' + runningPipelineExecutable.id + ')'
      : '';

    let pipelineErrors = [];

    if (pipeline.dataSinkId === undefined) {
      pipelineErrors.push('Please assign a data sink to the pipeline.');
    }

    const attributeNames = this.props.attributes
      .map(attribute => attribute.name);

    const uidAttribute = this.props.attributes
      .find(attribute => (attribute.name === 'uid') && (attribute.dataType === 'string'));

    if (
      (dataSink !== undefined) &&
      (dataSink.sinkType === 'ax-semantics') &&
      (uidAttribute == null)
    ) {
      pipelineErrors.push('AX Semantics requires the attribute `uid` of type String. Please rename one of the existing attributes or add a new one.');
    }


    const remoteIdAttribute = this.props.attributes
      .find(attribute => (attribute.name === 'remoteId') && (attribute.dataType === 'string'));
    if (
      (dataSink !== undefined) &&
      (dataSink.sinkType === 'typo3-pxa-product-manager') &&
      (remoteIdAttribute == null)
    ) {
      pipelineErrors.push('The TYPO3 Pixelant Product Manager requires the attribute `remoteId` of type String. Please rename one of the existing attributes or add a new one.');
    }

    const duplicateNames = attributeNames
      .filter((name, index) => attributeNames.indexOf(name) !== index);

    const illegalAttributeNames = attributeNames
      .filter(name => !isValidAttributeName(name));

    if (isSinkWithoutSchema && (duplicateNames.length > 0)) {
      pipelineErrors.push(
        'Please use unique attribute names for the following duplicates: ' +
        duplicateNames.join(', ') +
        '.'
      );
    }

    if (isSinkWithoutSchema && (illegalAttributeNames.length > 0)) {
      pipelineErrors.push(
        'Please use valid names (allowed characters: a-z, A-Z, 0-9, _, -, ß, ä, ö, ü, Ä, Ö, Ü) for the following attributes: ' +
        illegalAttributeNames.join(', ') +
        '.'
      );
    }

    const createDeploymentClassNames = (pipelines.pipelineExecutables.length === 0)
      ? 'btn btn-outline-primary'
      : 'btn btn-white';

    const metrics = pipelines.metrics;
    const pipelineLag = (metrics !== undefined) ? metrics.pipelineLag : undefined;
    const sinkConnectorLag = (metrics !== undefined) ? metrics.sinkConnectorLag : undefined;

    return (
      <div className='container mt-4'>
        <div className='row'>
          <div className='col'>
            {pipelineErrors.length > 0 &&
              <div>
                {pipelineErrors.map((error, idx) => (
                  <div className='alert alert-danger mb-4 d-flex align-items-center' key={idx}>
                    <AlertCircle className='feather-icon mr-2' /> {error}
                  </div>
                ))}
              </div>
            }
            <div className='row'>
              <div className='col-12 col-md'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h6 className='text-uppercase text-muted mb-2'>
                          Lag of pipeline
                        </h6>
                        <span className='mb-0'>
                          {(pipelineLag == null) && 'not available' }
                          {(pipelineLag === 1) && '1 record' }
                          {((pipelineLag === 0) || (pipelineLag > 1)) && `${formatInteger(pipelineLag)} records` }
                        </span>
                      </div>
                      <div className='col-auto'>
                        <OverlayTrigger
                            placement='bottom'
                            delay={{ show: 100, hide: 100 }}
                            overlay={(props) => (<Tooltip {...props}>The lag of the pipeline equals the number of records, which have been extracted by the source connector but have not yet been processed by the pipeline.</Tooltip>)}>
                          <HelpCircle className='feather-icon clickable' />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h6 className='text-uppercase text-muted mb-2'>
                          Lag of sink connector
                        </h6>
                        <span className='mb-0'>
                          {(sinkConnectorLag == null) && 'not available' }
                          {(sinkConnectorLag === 1) && '1 record' }
                          {((sinkConnectorLag === 0) || (sinkConnectorLag > 1)) && `${formatInteger(sinkConnectorLag)} records` }
                        </span>
                      </div>
                      <div className='col-auto'>
                        <OverlayTrigger
                            placement='bottom'
                            delay={{ show: 100, hide: 100 }}
                            overlay={(props) => (<Tooltip {...props}>The lag of the sink connector equals the number of records, which have been processed by the pipeline but have not yet been published by the sink connector.</Tooltip>)}>
                          <HelpCircle className='feather-icon clickable' />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-header-title'>Active deployment</h4>
                {canEditPipeline && pipelines.resettingOffsetOfPipeline && !pipelineHasActiveDeployment &&
                  <button
                    className='btn btn-sm btn-white d-flex align-items-center mr-2'
                    disabled={true}>
                    <span className='spinner-border mr-2' role='status'>
                    </span>
                    Resetting pipeline offset...
                  </button>
                }
                {canEditPipeline && !pipelines.resettingOffsetOfPipeline && !pipelineHasActiveDeployment &&
                  <button
                    className='btn btn-sm btn-white d-flex align-items-center mr-2'
                    onClick={() => this.props.resetOffsetOfPipelineFunc(pipeline.id)}>
                    Reset pipeline offset
                  </button>
                }
                {canEditPipeline && pipelineHasActiveDeployment &&
                  <OverlayTrigger
                      placement='bottom'
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => (<Tooltip {...props}>Please stop the active deployment before resetting the offset of the pipeline.</Tooltip>)}>
                    <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                      <button
                        className='btn btn-sm btn-white d-flex align-items-center mr-2'
                        disabled={true}>
                        Reset pipeline offset
                      </button>
                    </div>
                  </OverlayTrigger>
                }
                {canEditPipeline && !pipelines.resettingOffsetOfSinkConnector && (pipeline.dataSinkId !== undefined) &&
                  <button
                    className='btn btn-sm btn-white d-flex align-items-center mr-2'
                    onClick={() => this.props.resetOffsetOfSinkConnectorFunc(pipeline.id)}>
                    Reset sink connector offset
                  </button>
                }
                {canEditPipeline && pipelines.resettingOffsetOfSinkConnector && (pipeline.dataSinkId !== undefined) &&
                  <button
                    className='btn btn-sm btn-white d-flex align-items-center mr-2'
                    disabled={true}>
                    <span className='spinner-border mr-2' role='status'>
                    </span>
                    Resetting sink connector offset...
                  </button>
                }
                {canEditPipeline && (pipeline.dataSinkId === undefined) &&
                  <OverlayTrigger
                      placement='bottom'
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => (<Tooltip {...props}>You cannot reset the sink connector offset, because no data sink is assigned to this pipeline.</Tooltip>)}>
                    <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                      <button
                        className='btn btn-sm btn-white d-flex align-items-center mr-2'
                        disabled={true}>
                        Reset sink connector offset
                      </button>
                    </div>
                  </OverlayTrigger>
                }
                {canEditPipeline && pipelines.resettingPipeline &&
                  <button
                    className='btn btn-sm btn-white d-flex align-items-center mr-2'
                    disabled={true}>
                    <span className='spinner-border mr-2' role='status'>
                    </span>
                    Syncing...
                  </button>
                }
                {canEditPipeline && !pipelines.resettingPipeline && (pipeline.dataSinkId !== undefined) &&
                  <React.Fragment>
                    <button
                      className='btn btn-sm btn-white d-flex align-items-center mr-2'
                      onClick={this.toggleResetModal}>
                      Start full re-sync
                    </button>
                    <Modal show={this.state.showResetModal} onHide={this.toggleResetModal}>
                      <Modal.Header closeButton>
                        <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          Starting a full re-sync reprocesses all data from the data source.
                        </p>
                        <p>
                          If your pipeline transforms the primary key of the data, you may need to manually empty the data sink.
                        </p>
                        <p className='mb-0'>
                          Do you want to proceed?
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant='white' onClick={this.toggleResetModal}>
                          Cancel
                        </Button>
                        <Button variant='danger' onClick={this.resetPipeline}>
                          Proceed
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </React.Fragment>
                }
                {canEditPipeline && !pipelines.resettingPipeline && (pipeline.dataSinkId === undefined) &&
                  <OverlayTrigger
                      placement='bottom'
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => (<Tooltip {...props}>Please assign a data sink before starting the re-sync.</Tooltip>)}>
                    <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                      <button
                        className='btn btn-sm btn-white d-flex align-items-center mr-2'
                        disabled={true}
                        style={{ pointerEvents : 'none' }}>
                       Start full re-sync
                      </button>
                    </div>
                  </OverlayTrigger>
                }
              </div>
              <div className='card-body'>
                {pipelines.stoppingPipelineExecutableFailed &&
                  <div className='alert alert-danger mt-n4 mx-n4 rounded-0 d-flex align-items-center'>
                    <AlertCircle className='feather-icon mr-2' />
                    Stopping the pipeline has failed.
                  </div>
                }
                {(pipeline.isRunning === false) && (runningPipelineExecutable !== undefined) &&
                  <div className='alert alert-danger mt-n4 mx-n4 rounded-0'>
                    The container of the executed deployment {runningPipelineInformation} failed. Please try manually stopping and restarting the deployment.
                  </div>
                }
                {(runningPipelineExecutable === undefined) &&
                  <div>
                    No active deployment.
                  </div>
                }
                {(runningPipelineExecutable !== undefined) &&
                  <ul className='list-group list-group-flush list my-n4'>
                    <React.Fragment>
                      <ExecutableListItem
                        canEditPipeline={canEditPipeline}
                        haltExecutableFunc={this.props.haltExecutableFunc}
                        pipelineExecutable={runningPipelineExecutable}
                        toggleLogsFunc={this.props.toggleLogsOfRunningExecutableFunc}
                        showPipelineLogs={this.props.showPipelineLogs}
                        startExecutableFunc={this.props.startExecutableFunc}
                        updateExecutableNameFunc={this.props.updateExecutableNameFunc} />
                      {this.props.showPipelineLogs &&
                         <ExecutableLogs
                           closeLogsFunc={this.props.toggleLogsOfRunningExecutableFunc}
                           logs={pipelines.pipelineLogs} />
                      }
                    </React.Fragment>
                  </ul>
                }
              </div>
            </div>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-header-title'>Deployments</h4>
                {canEditPipeline && (pipelineErrors.length > 0) &&
                  <OverlayTrigger
                      placement='bottom'
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => (<Tooltip {...props}>Please fix the listed errors before creating a deployment.</Tooltip>)}>
                    <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                      <button
                        className='btn btn-white'
                        disabled={true}
                        style={{ pointerEvents : 'none' }}>
                        Create deployment
                      </button>
                    </div>
                  </OverlayTrigger>
                }
                {canEditPipeline && (pipelineErrors.length === 0) &&
                  <button
                    className={createDeploymentClassNames}
                    onClick={this.props.compileExecutableFunc}>
                    Create deployment
                  </button>
                }
                {!canEditPipeline &&
                  <button className='btn btn-white' disabled={true}>
                    Create deployment
                  </button>
                }
              </div>
              <div className='card-body'>
                {this.props.errorMessage.length > 0 &&
                  <div className='alert alert-danger mt-n4 mx-n4 rounded-0'>
                    {this.props.errorMessage}
                  </div>
                }
                {pipelines.startingPipelineExecutableFailed &&
                  <div className='alert alert-danger mt-n4 mx-n4 rounded-0 d-flex align-items-center'>
                    <AlertCircle className='feather-icon mr-2' />
                    Starting the pipeline has failed.
                  </div>
                }
                {pipelines.pipelineExecutables.length === 0 &&
                  <React.Fragment>No deployments available.</React.Fragment>
                }
                {pipelines.pipelineExecutables.length > 0 &&
                  <ul className='list-group list-group-flush list my-n4'>
                    {pipelineExecutables.map((pipelineExecutable, index) => (
                      <ExecutableListItem
                        activeDeploymentExists={(runningPipelineExecutable !== undefined)}
                        canEditPipeline={canEditPipeline}
                        deleteExecutableFunc={this.props.deleteExecutableFunc}
                        haltExecutableFunc={this.props.haltExecutableFunc}
                        hideActionButtonsWhenRunning={true}
                        index={index}
                        key={pipelineExecutable.id}
                        pipelineExecutable={pipelineExecutable}
                        startExecutableFunc={this.props.startExecutableFunc}
                        updateExecutableNameFunc={this.props.updateExecutableNameFunc} />
                    ))}
                  </ul>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeploymentsPage;
