import React, { Component } from 'react';
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import {
  AlertCircle,
  ArrowRight,
  Check,
  Search
} from 'react-feather';
import DataSinkProfileAttributeForm from './data_sink/DataSinkProfileAttributeForm';
import SelectDataSinkForm from './data_sink/SelectDataSinkForm';
import DataSinkLogo from '../../../data_sinks/DataSinkLogo';

class DataSinkPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationResult : '',
      verificationMessage: '',
      attributeErrorMessage: '',
      dataSinkAttribute: {
        name: '',
        dataType: 'string'
      },
      searchQuery: '',
      showOnlyRequiredAttributes: false
    };
    this.verifyMapping            = this.verifyMapping.bind(this);
    this.handleChange             = this.handleChange.bind(this);
    this.addAttribute             = this.addAttribute.bind(this);
    this.proxySinkChange          = this.proxySinkChange.bind(this);
    this.proxyAttributeChange     = this.proxyAttributeChange.bind(this);
    this.updateTemplateAttribute  = this.updateTemplateAttribute.bind(this);
    this.addTemplateAttribute     = this.addTemplateAttribute.bind(this);
    this.updateSearchQuery        = this.updateSearchQuery.bind(this);
    this.toggleRequiredAttributes = this.toggleRequiredAttributes.bind(this);
    this.autoMapSchema            = this.autoMapSchema.bind(this);
  }

  handleChange(event) {
    event.preventDefault();
    let dataSinkAttribute = this.state.dataSinkAttribute;
    dataSinkAttribute[event.target.name] = event.target.value;
    this.setState({
      dataSinkAttribute:   dataSinkAttribute,
      verificationResult:  '',
      verificationMessage: ''
    });
  }

  addAttribute(event) {
    event.preventDefault();
    const { name, dataType } = this.state.dataSinkAttribute;
    if (name.length > 0 && dataType.length > 0) {
      // add attribute to data sink profile
      this.props.addDataSinkAttributeFunc(this.state.dataSinkAttribute);
      this.setState({
        attributeErrorMessage: '',
        dataSinkAttribute: {
          name:     '',
          dataType: 'string'
        }
      });
    } else {
      this.setState({
        attributeErrorMessage: 'Please provide a name' +
                               ' and choose a data type for the new attribute.'
      });
    }
  }

  proxySinkChange(dataSinkId, dataSinkType) {
    // reset state when changing the data sink
    this.setState({
      verificationResult:    '',
      verificationMessage:   '',
      attributeErrorMessage: '',
      dataSinkAttribute: {
        name:     '',
        dataType: 'string'
      }
    });
    this.props.selectDataSinkFunc(dataSinkId, dataSinkType);
  }

  proxyAttributeChange(event) {
    this.setState({
      verificationResult:    '',
      verificationMessage:   ''
    });
    this.props.handleDataSinkProfileChangeFunc(event);
  }

  verifyMapping(event) {
    event.preventDefault();
    // check whether any data sink attribute, which is not allowed
    // to hold NULL values, is filled with empty values
    const missingMappings = this.props.dataSinkProfileAttributes
      .filter(_ =>
        !_.isNullable &&
        (_.dataSourceProfileAttributeId === undefined) &&
        (_.fillWithProcessingTime !== true)
      )
      .map(_ => _.name);

    if (missingMappings.length === 0) {
      this.setState({
        verificationResult:  'success',
        verificationMessage: 'Your mapping looks good, go ahead!'
      });
    } else {
      this.setState({
        verificationResult:  'error',
        verificationMessage: 'The following data sink attributes require non-empty values: ' +
                             missingMappings.join(', ') + '.'
      });
    }

    // verify that the primary key of the data sink is filled with the
    // primary key of the data source
    const dataSourceKeyAttributeIds = this.props.attributes
      .filter(sourceAttribute => sourceAttribute.isKey === true)
      .map(sourceAttribute => parseInt(sourceAttribute.id));
    const sinkKeyAttributesWithInvalidMapping = this.props.dataSinkProfileAttributes
      .filter(sinkAttribute => sinkAttribute.isKey === true)
      .filter(sinkAttribute =>
        sinkAttribute.dataSourceProfileAttributeId === undefined ||
        !dataSourceKeyAttributeIds.includes(parseInt(sinkAttribute.dataSourceProfileAttributeId))
      )
      .map(sinkAttribute => sinkAttribute.name);

    if (sinkKeyAttributesWithInvalidMapping.length > 0) {
      this.setState({
        verificationResult:  'error',
        verificationMessage: 'The following data sink attributes need to be ' +
                             'filled with an attribute that is a primary key ' +
                             'in the consumed data source: ' +
                             sinkKeyAttributesWithInvalidMapping.join(', ') + '.'
      });
    }
  }

  updateTemplateAttribute(event) {
    this.setState({ templateAttributeId: event.target.value });
  }

  addTemplateAttribute(event) {
    const templateAttributeId = this.state.templateAttributeId;
    const templateAttribute   = this.props.dataSinkProfileAttributes
      .find(_ => _.id === parseInt(templateAttributeId));

    if (![undefined, ''].includes(templateAttribute)) {
      // add new attribute based on given template attribute
      this.props.addDataSinkAttributeFunc({
        name:       templateAttribute.name,
        dataType:   templateAttribute.dataType,
        isNullable: true,
        isKey:      false,
        isTemplate: true,
        isVirtual:  true
      });
      // reset select box
      this.setState({ templateAttributeId: '' });
    }
  }

  updateSearchQuery(event) {
    this.setState({
      searchQuery: event.target.value
    });
  }

  toggleRequiredAttributes() {
    this.setState({
      showOnlyRequiredAttributes: !this.state.showOnlyRequiredAttributes
    });
  }

  autoMapSchema(event) {
    event.preventDefault();

    const pipelineAttributes = this.props.attributes;
    // consider only non-template attributes (e.g., no dynamic fields from Solr)
    const sinkAttributes = this.props.dataSinkProfileAttributes
      .filter(attribute => [undefined, false].includes(attribute.isTemplate));

    const toBeUpdatedSinkAttributes = [];
    // for each sink attribute, check whether there is a matching attribute
    // coming from the pipeline (we use name and data type for the match)
    sinkAttributes.forEach((sinkAttribute) => {
      const matchingPipelineAttribute = pipelineAttributes
        .find(pipelineAttribute =>
          (pipelineAttribute.name === sinkAttribute.name) &&
          (pipelineAttribute.dataType === sinkAttribute.dataType)
        );

      if (matchingPipelineAttribute !== undefined) {
        toBeUpdatedSinkAttributes.push(
          Object.assign(
            {},
            sinkAttribute,
            { dataSourceProfileAttributeId: parseInt(matchingPipelineAttribute.id) }
          )
        );
      }
    });

    // update attributes in the backend
    this.props.handleMultipleDataSinkProfileChangesFunc(toBeUpdatedSinkAttributes);
  }

  render() {
    const {
      canEditPipeline,
      dataSink,
      dataSinkProfile,
      pipeline,
      pipelineHasActiveDeployment
    } = this.props;

    const dataSinkSelected = (dataSink !== undefined);
    const profilingDataSink = (
      (dataSinkSelected) &&
      (dataSinkProfile !== undefined) &&
      (dataSinkProfile.profilingStatus === 'profiling')
    );
    const profiledDataSink = (
      (dataSinkSelected) &&
      (dataSinkProfile !== undefined) &&
      (dataSinkProfile.profilingStatus !== 'profiling')
    );
    const profilingSucceeded = (
      (dataSinkSelected) &&
      (dataSinkProfile !== undefined) &&
      (dataSinkProfile.profilingStatus === 'profiled')
    );

    const searchQuery = this.state.searchQuery.trim().toLowerCase();

    const profileAttributes = this.props.dataSinkProfileAttributes
      // do not select template attributes (e.g., dynamic fields of Apache Solr)
      // unless they have been added by the user (i.e., they are virtual)
      .filter(attribute =>
        [undefined, false].includes(attribute.isTemplate) ||
        (attribute.isVirtual === true)
      )
      // show only required attributes if selected
      .filter(attribute =>
        !this.state.showOnlyRequiredAttributes ||
        !attribute.isNullable
      )
      // show only attributes matching the search query
      .filter(attribute =>
        // no search query given
        (searchQuery.length === 0) ||
        // search query given
        (
          attribute.name !== undefined &&
          attribute.name.toLowerCase().includes(searchQuery)
        )
      )
      .sort((a, b) => a.id - b.id);

    const templateAttributes = this.props.dataSinkProfileAttributes
      // select template attribute unless they have been added by the user
      .filter(attribute =>
        (attribute.isTemplate === true) &&
        [undefined, false].includes(attribute.isVirtual)
      )
      .sort((a, b) => a.id - b.id);

    let isSinkWithoutSchema = (
      (dataSink !== undefined) &&
      [
        'ax-semantics', 'csv', 'elasticsearch', 'hubspot',
        'json', 'mongodb', 'rest', 'snowflake', 'typo3-pxa-product-manager',
        'xml'
      ].includes(dataSink.sinkType)
    );

    if (
      (dataSink !== undefined) &&
      (dataSink.sinkType === 'google-bigquery') &&
      (dataSink.connectorConfig !== undefined) &&
      (dataSink.connectorConfig.writeIntoExistingTable === 'false')
    ) {
      isSinkWithoutSchema = true;
    }

    let sinkLabel = '';
    if (dataSink !== undefined) {
      sinkLabel = '#' + dataSink.id + ': ' + dataSink.name;

      if (dataSink.sinkType === 'csv') {
        sinkLabel = 'Stream data to a CSV file';
      } else if (dataSink.sinkType === 'json') {
        sinkLabel = 'Stream data to a JSON file';
      } else if (dataSink.sinkType === 'xml') {
        sinkLabel = 'Stream data to a XML file';
      }
    }

    return (
      <div className='container mt-4'>
        <div className='row'>
          <div className='col'>
            {!dataSinkSelected &&
              <SelectDataSinkForm
                canEditPipeline={canEditPipeline}
                dataSinks={this.props.dataSinks}
                pipeline={pipeline}
                selectDataSinkFunc={this.proxySinkChange} />
            }
            {dataSinkSelected &&
              <React.Fragment>
                <div className='card'>
                  <div className='card-header'>
                    <h4 className='card-header-title'>Manage data sink</h4>
                  </div>
                  <div className='card-body'>
                    <div className='row align-items-center'>
                      <div className='col-auto sink-type-logos'>
                        <div className='logo-wrap'>
                          <DataSinkLogo dataSink={dataSink} />
                        </div>
                      </div>
                      <div className='col'>
                        <h4 className='mb-0'>
                          {sinkLabel}
                        </h4>
                      </div>
                      <div className='col-auto'>
                        {!pipelineHasActiveDeployment &&
                          <button
                            className='btn btn-white'
                            disabled={!canEditPipeline}
                            onClick={this.props.resetDataSinkFunc}>
                            Choose different data sink
                          </button>
                        }
                        {pipelineHasActiveDeployment &&
                          <OverlayTrigger
                              placement='bottom'
                              delay={{ show: 100, hide: 100 }}
                              overlay={(props) => (<Tooltip {...props}>Please stop the active deployment before choosing a different data sink.</Tooltip>)}>
                            <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                              <button
                                className='btn btn-white'
                                disabled={true}
                                style={{ pointerEvents : 'none' }}>
                                Choose different data sink
                              </button>
                            </div>
                          </OverlayTrigger>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  {isSinkWithoutSchema &&
                    <div className='card-body'>
                      <Check className='feather-icon mr-3' />
                      This sink type does not require any schema mapping.
                    </div>
                  }
                  {profilingDataSink && !isSinkWithoutSchema &&
                    <div className='card-body'>
                      <div className='text-center'>
                        <span className='spinner-border text-primary' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </span>
                      </div>
                    </div>
                  }
                  {profiledDataSink && !profilingSucceeded && !isSinkWithoutSchema &&
                    <React.Fragment>
                      <div className='card-header'>
                        <h4 className='card-header-title'>Profiling failed</h4>
                      </div>
                      <div className='card-body d-flex align-items-center'>
                        <AlertCircle className='feather-icon mr-2' />
                        Profiling the data sink failed with the following message:
                      </div>
                      <div className='card-body text-bg-danger p-3 pl-4'>
                        {dataSinkProfile.profilingFailureMessage}
                      </div>
                     </React.Fragment>
                  }
                  {profiledDataSink && profilingSucceeded && !isSinkWithoutSchema &&
                    <React.Fragment>
                      <div className='card-header'>
                        <h4 className='card-header-title'>Map schema of pipeline to data sink</h4>
                        <button
                          className='btn btn-sm btn-white mr-2'
                          onClick={this.autoMapSchema}
                          type='submit'>
                          Auto-map schema
                        </button>
                        <button
                          className='btn btn-sm btn-white'
                          onClick={this.verifyMapping}
                          type='submit'>
                          Verify mapping
                        </button>
                      </div>
                      {this.state.verificationResult !== undefined && this.state.verificationResult === 'error' &&
                        <div className='card-body border-bottom text-bg-danger py-3 d-flex align-items-center'>
                          <AlertCircle className='feather-icon mr-2' />
                          {this.state.verificationMessage}
                        </div>
                      }
                      {this.state.verificationResult !== undefined && this.state.verificationResult === 'success' &&
                        <div className='card-body border-bottom text-bg-success py-3'>
                          <Check className='feather-icon mr-2' />
                          {this.state.verificationMessage}
                        </div>
                      }
                      <div className='card-body border-bottom py-0'>
                        <ul className='list-group list-group-flush'>
                          <li className='list-group-item py-2'>
                            <div className="row align-items-center">
                              <div className="col">
                                <form>
                                  <div className='input-group input-group-flush input-group-merge'>
                                    <input
                                      type='search'
                                      className='form-control form-control-prepended search'
                                      onChange={this.updateSearchQuery}
                                      placeholder='Search data sink attributes'
                                      value={this.state.searchQuery} />
                                    <div className='input-group-prepend'>
                                      <div className='input-group-text'>
                                        <Search className='feather-icon' />
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div className='col-auto'>
                                {!this.state.showOnlyRequiredAttributes &&
                                  <button
                                    className='btn btn-sm btn-white'
                                    onClick={this.toggleRequiredAttributes}>
                                    <AlertCircle className='feather-icon mr-2' />
                                    Show only required attributes
                                  </button>
                                }
                                {this.state.showOnlyRequiredAttributes &&
                                  <button
                                    className='btn btn-sm btn-primary'
                                    onClick={this.toggleRequiredAttributes}>
                                    <AlertCircle className='feather-icon mr-2' />
                                    Show only required attributes
                                  </button>
                                }
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item'>
                            <div className='row align-items-center'>
                              <div className='col'>
                                <h4 className='mb-0'>Pipeline</h4>
                              </div>
                              <div className='col-auto'>
                                <ArrowRight className='feather-icon' />
                              </div>
                              <div className='col'>
                                <h4 className='mb-0'>Data Sink</h4>
                              </div>
                            </div>
                          </li>
                          {profileAttributes.map(attribute => (
                            <DataSinkProfileAttributeForm
                              attribute={attribute}
                              canEditPipeline={canEditPipeline}
                              deleteDataSinkAttributeFunc={this.props.deleteDataSinkAttributeFunc}
                              handleDataSinkProfileChangeFunc={this.proxyAttributeChange}
                              key={attribute.id}
                              pipelineAttributes={this.props.attributes} />
                          ))}
                        </ul>
                      </div>
                      {(dataSink.sinkType === 'solr') && (templateAttributes.length > 0) &&
                        <div className='card-body'>
                          <div className='row'>
                            <div className='col'>
                              <select
                                className='custom-select d-inline w-auto mr-3'
                                disabled={!canEditPipeline}
                                name='addTemplateAttribute'
                                onChange={this.updateTemplateAttribute}
                                value={this.state.templateAttributeId}>
                                <option>Select dynamic field</option>
                                {templateAttributes.map((attribute) => (
                                  <option
                                    key={attribute.id}
                                    value={attribute.id}>
                                    {attribute.name}
                                  </option>
                                ))}
                              </select>
                              <button
                                className='btn btn-white'
                                disabled={!canEditPipeline}
                                onClick={this.addTemplateAttribute}>
                                Add attribute
                              </button>
                            </div>
                          </div>
                        </div>
                      }
                    </React.Fragment>
                  }
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default DataSinkPage;
