import React, { Component } from 'react';
import { Search, X }        from 'react-feather';
import DataSourceLogo       from '../../../../data_sources/DataSourceLogo';

class SelectJoinedDataSourceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assigningJoinedDataSource: false,
      searchQuery: ''
    };

    this.updateSearchQuery      = this.updateSearchQuery.bind(this);
    this.renderDataSource       = this.renderDataSource.bind(this);
    this.selectJoinedDataSource = this.selectJoinedDataSource.bind(this);
  }

  updateSearchQuery(event) {
    this.setState({
      searchQuery: event.target.value
    });
  }

  selectJoinedDataSource(dataSource) {
    this.setState({ assigningJoinedDataSource: true });
    this.props.selectJoinedDataSourceFunc(
      dataSource.id
    );
  }

  renderDataSource(dataSource, selectJoinedDataSourceFunc, index) {
    return (
      <li
        className='list-group-item hoverable clickable overflow-hidden'
        key={index}
        onClick={() => { selectJoinedDataSourceFunc(dataSource) }}>
        <div className='row align-items-center px-4 py-2'>
          <div className='col-auto sink-type-logos'>
            <div className='logo-wrap'>
              <DataSourceLogo dataSource={dataSource} />
            </div>
          </div>
          <div
            className='col'>
            <h4 className='mb-0'>
              {dataSource.id !== undefined && '#' + dataSource.id + ': '}
              {dataSource.name}
            </h4>
          </div>
          <div className='col-auto'>
            <button
              className='btn btn-white btn-sm'>
              Assign
            </button>
          </div>
        </div>
      </li>
    );
  }

  render() {
    const pipeline = this.props.pipeline;

    const assigningJoinedDataSource =
      this.state.assigningJoinedDataSource &&
      (this.props.pipelines.updatingJoinedDataSourceFailure === undefined);

    const searchTokens = this.state.searchQuery.toLowerCase().trim().split(' ');

    const availableDataSources = this.props.dataSources
      .filter(dataSource =>
        // show only personal data sources for personal pipelines
        // show only project data sources for project pipelines
        (dataSource.userId === pipeline.userId) &&
        (dataSource.projectId === pipeline.projectId)
      )
      // hide temporary data sources
      .filter(dataSource => dataSource.isTemporary !== true)
      // hide data sources not supported in join;
      .filter(dataSource => !['adsbhub', 'opensky'].includes(dataSource.sourceType));

    const dataSources = availableDataSources
      .filter(dataSource =>
        (
          searchTokens
            .map(token => dataSource.name.toLowerCase().includes(token))
            .filter(_ => _)
            .length
        ) === searchTokens.length
      )
      // show most recent data source at the top
      .sort((a, b) => b.id - a.id);

    return (
      <div className='card'>
        <div className='card-header'>
          <h4 className='card-header-title'>Select a data source to join</h4>
        </div>
        <div className='card-header'>
          <form>
            <div className='input-group input-group-flush input-group-merge'>
              <input
                type='search'
                className='form-control form-control-prepended search'
                onChange={this.updateSearchQuery}
                placeholder='Search data sources'
                value={this.state.searchQuery} />
              <div className='input-group-prepend'>
                <div className='input-group-text'>
                  <Search className='feather-icon' />
                </div>
              </div>
            </div>
          </form>
        </div>
        {this.props.pipelines.updatingJoinedDataSourceFailure !== undefined &&
          <div className='card-header text-bg-danger'>
            <span className='d-flex align-items-center'>
              <X className='feather-icon mr-3' />
              {this.props.pipelines.updatingJoinedDataSourceFailure}
            </span>
          </div>
        }
        <div className='card-body px-0'>
          {assigningJoinedDataSource === true &&
            <div className='d-flex align-items-center justify-content-center'>
              <span className='spinner-border text-primary mr-3' role='status'>
                  <span className='sr-only'>Loading...</span>
              </span>
              Creating connector for joining data source...
            </div>
          }
          {assigningJoinedDataSource === false && dataSources.length > 0 &&
            <ul className='list-group list-group-flush list my-n4'>
              {dataSources.map((dataSource, index) => this.renderDataSource(dataSource, this.selectJoinedDataSource, index))}
            </ul>
          }
          {assigningJoinedDataSource === false && dataSources.length === 0 && availableDataSources.length > 0 &&
            <div className='px-4'>
              No matching data source was found. Please refine your search query.
            </div>
          }
          {assigningJoinedDataSource === false && dataSources.length === 0 && availableDataSources.length === 0 &&
            <div className='px-4'>
              No data sources available.
            </div>
          }
        </div>
      </div>
    );
  }
}

export default SelectJoinedDataSourceForm;
