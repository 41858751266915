import React,
       { Component } from 'react';
import { connect }   from 'react-redux';
import MainContent   from '../../components/layout/MainContent';
import Navigation    from '../../components/settings/Navigation';
import { fetchInfo } from '../../actions/info';
import { fetchCurrentUser,
         updatePassword } from '../../actions/users';
import { deepCopy }       from '../../helpers/deepCopy';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.initialFormState = {
      currentPassword:         '',
      newPassword:             '',
      newPasswordConfirmation: '',
      errorMessage:            '',
      infoMessage:             ''
    };
    this.state = deepCopy(this.initialFormState);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchInfo();
    this.props.fetchCurrentUser();
  }

  handleChange(event) {
    let update = {};
    update[event.target.name] = event.target.value;
    this.setState(update);
  }

  handleSubmit(event) {
    event.preventDefault();
    const initialFormState = deepCopy(this.initialFormState);

    if (this.state.currentPassword.length === 0) {
      this.setState(Object.assign(initialFormState,
                                  { errorMessage: 'Please provide your current password.' }));
    } else if (this.state.newPassword !== this.state.newPasswordConfirmation) {
      this.setState(Object.assign(initialFormState,
                                  { errorMessage: 'Please correctly confirm your new password.' }));
    } else if (this.state.newPassword.length < 8) {
      this.setState(Object.assign(initialFormState,
                                  { errorMessage: 'Please use at least eight characters for your new password.' }));
    } else if (this.state.newPassword === this.state.newPassword.toLowerCase() ||
               this.state.newPassword === this.state.newPassword.toUpperCase()) {
      this.setState(Object.assign(initialFormState,
                                  { errorMessage: 'Please use both lower and upper case characters for your new password.' }));
    } else {
      this.props.updatePassword(
        this.state.currentPassword,
        this.state.newPassword,
        this.state.newPasswordConfirmation
      ).then(() => {
        if (this.props.users.passwordUpdateStatus === undefined) {
          this.setState(Object.assign(initialFormState,
                                      { infoMessage: 'Your password has been updated successfully.' }));
        } else {
          this.setState(Object.assign(initialFormState,
                                      { errorMessage: this.props.users.passwordUpdateStatus }));
        }
      });
    }
  }

  render() {
    const currentUser = this.props.users.currentUser;

    return (
      <MainContent preTitle='Settings' title='Update password'>
        <div className='card card-fill'>
          <div className='card-body'>
            <div className='row mb-4 mt-n3'>
              <div className='col-12'>
                <Navigation currentUser={currentUser} info={this.props.info.info} />
              </div>
            </div>
            <h3 className='card-header-title mb-4'>Update password</h3>
            {this.state.infoMessage.length > 0 &&
              <div className='alert alert-success my-4 rounded-0'>
                {this.state.infoMessage}
              </div>
            }
            {this.state.errorMessage.length > 0 &&
              <div className='alert alert-danger my-4 rounded-0'>
                Error: {this.state.errorMessage}
              </div>
            }
            <div className='row'>
              <div className='col-12 col-md-6'>
                <form>
                  <div className='form-group'>
                    <label htmlFor='currentPassword'>Current password:</label>
                    <input
                      type='password'
                      className='form-control'
                      name='currentPassword'
                      onChange={this.handleChange}
                      value={this.state.currentPassword} />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='newPassword'>New password:</label>
                    <input
                      type='password'
                      className='form-control'
                      name='newPassword'
                      onChange={this.handleChange}
                      value={this.state.newPassword} />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='newPasswordConfirmation'>Confirm new password:</label>
                    <input
                      type='password'
                      className='form-control'
                      name='newPasswordConfirmation'
                      onChange={this.handleChange}
                      value={this.state.newPasswordConfirmation} />
                  </div>
                  <button
                    className='btn btn-primary'
                    onClick={this.handleSubmit}
                    type='submit'>
                    Change password
                  </button>
                </form>
              </div>
              <div className='col-12 col-md-6 order-md-2'>
                <div class="card bg-light border ml-md-4">
                  <div class="card-body">
                    <p class="mb-2">
                      Password requirements
                    </p>
                    <p class="small text-muted mb-2">
                      To create a new password, you have to meet all of the following requirements:
                    </p>
                    <ul class="small text-muted pl-4 mb-0">
                      <li>
                        Minimum 8 characters
                      </li>
                      <li>
                        Both lower and upper case characters
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    info:  state.info,
    users: state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser: fetchCurrentUser,
  fetchInfo:         fetchInfo,
  updatePassword:   updatePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
