import React, { Component } from 'react';
import RetrieveColumns      from '../RetrieveColumns';
import TestConnectionButton from '../TestConnectionButton';
import ConfigForm           from '../../data_stores/ConfigForm';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class RestConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveColumnsFunc,
      retrieveColumnsFromDataSourceFunc
    } = this.props;

    const flatColumns = dataSources.columns
      .map(column => column.name)
      .sort();

    const uri = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.uri !== undefined)
      ? dataSource.connectorConfig.uri
      : '';

    const httpMethod = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.httpMethod !== undefined)
      ? dataSource.connectorConfig.httpMethod
      : 'get';

    const syncIntervalMode = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.syncIntervalMode !== undefined)
      ? dataSource.connectorConfig.syncIntervalMode
      : 'milliseconds';

    const intervalMillis = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.intervalMillis !== undefined)
      ? dataSource.connectorConfig.intervalMillis
      : '';

    const cronExpression = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.cronExpression !== undefined)
      ? dataSource.connectorConfig.cronExpression
      : '';

    const httpRequestHeaders = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.httpRequestHeaders !== undefined)
      ? dataSource.connectorConfig.httpRequestHeaders
      : '';

    const httpRequestBody = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.httpRequestBody !== undefined)
      ? dataSource.connectorConfig.httpRequestBody
      : '';

    const httpRequestParameters = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.httpRequestParameters !== undefined)
      ? dataSource.connectorConfig.httpRequestParameters
      : '';

    const httpBasicAuthenticationUsername = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.httpBasicAuthenticationUsername !== undefined)
      ? dataSource.connectorConfig.httpBasicAuthenticationUsername
      : '';

    const jsonPointerRecordsList = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.jsonPointerRecordsList !== undefined)
      ? dataSource.connectorConfig.jsonPointerRecordsList
      : '';

    const jsonPointerRecord = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.jsonPointerRecord !== undefined)
      ? dataSource.connectorConfig.jsonPointerRecord
      : '';

    const jsonPointerPaginationNumberRecords = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.jsonPointerPaginationNumberRecords !== undefined)
      ? dataSource.connectorConfig.jsonPointerPaginationNumberRecords
      : '';

    const recordsPerPage = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.recordsPerPage !== undefined)
      ? dataSource.connectorConfig.recordsPerPage
      : '';

    const recordKeyName = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.recordKeyName !== undefined)
      ? dataSource.connectorConfig.recordKeyName
      : '';

    const recordTimestampName = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.recordTimestampName !== undefined)
      ? dataSource.connectorConfig.recordTimestampName
      : '';

    const timestampOffset = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.timestampOffset !== undefined)
      ? dataSource.connectorConfig.timestampOffset
      : '';

    const timestampFormat = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.timestampFormat !== undefined)
      ? dataSource.connectorConfig.timestampFormat
      : '';

    const timestampType = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.timestampType !== undefined)
      ? dataSource.connectorConfig.timestampType
      : 'formattedTimestamp';

    const keyReplacementPattern = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.keyReplacementPattern !== undefined)
      ? dataSource.connectorConfig.keyReplacementPattern
      : '';

    const keyReplacementValue = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.keyReplacementValue !== undefined)
      ? dataSource.connectorConfig.keyReplacementValue
      : '';

    const tokenExchangeUri = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.tokenExchangeUri !== undefined)
      ? dataSource.connectorConfig.tokenExchangeUri
      : '';

    const tokenExchangeHttpMethod = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.tokenExchangeHttpMethod !== undefined)
      ? dataSource.connectorConfig.tokenExchangeHttpMethod
      : 'post';

    const tokenExchangeHttpRequestHeaders = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.tokenExchangeHttpRequestHeaders !== undefined)
      ? dataSource.connectorConfig.tokenExchangeHttpRequestHeaders
      : '';

    const tokenExchangeHttpRequestBody = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.tokenExchangeHttpRequestBody !== undefined)
      ? dataSource.connectorConfig.tokenExchangeHttpRequestBody
      : '';

    const jsonPointerToken = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.tokenExchangeJsonPointer !== undefined)
      ? dataSource.connectorConfig.tokenExchangeJsonPointer
      : '';

    const requestTimeout = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.requestTimeout !== undefined)
      ? dataSource.connectorConfig.requestTimeout
      : '';

    const treatEmptyStringsAsNullValues = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.treatEmptyStringsAsNullValues !== undefined)
      ? '' + dataSource.connectorConfig.treatEmptyStringsAsNullValues
      : 'true';

    const retrievingColumnsIsPossible =
      ![undefined, ''].includes(uri) &&
      ![undefined, ''].includes(httpMethod);

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the password unless you want to change it.</span>)
      : (<React.Fragment></React.Fragment>);

    const kafkaConnectConfig = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSource.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Authentication', key: 'auth' },
      { name: 'Change Data Capture', key: 'cdc' },
      { name: 'Pagination', key: 'pagination' },
      { name: 'Advanced', key: 'advanced' }
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item'>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='URI'
              name='uri'
              onChangeFunc={handleConfigChangeFunc}
              subLabel='including http:// or https://'
              value={uri} />
            <div className='form-group'>
              <label>HTTP method</label>
              <select
                className='custom-select'
                name='httpMethod'
                onChange={handleConfigChangeFunc}
                value={httpMethod}>
                <option value='GET'>GET</option>
                <option value='POST'>POST</option>
              </select>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              label='HTTP query parameters'
              name='httpRequestParameters'
              onChangeFunc={handleConfigChangeFunc}
              value={httpRequestParameters} />
            <ConfigForm
              errorMessages={errorMessages}
              label='HTTP request headers'
              name='httpRequestHeaders'
              onChangeFunc={handleConfigChangeFunc}
              value={httpRequestHeaders} />
            {(httpMethod === 'POST') &&
              <ConfigForm
                errorMessages={errorMessages}
                label='HTTP request body'
                name='httpRequestBody'
                onChangeFunc={handleConfigChangeFunc}
                type='textarea'
                value={httpRequestBody} />
            }
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment><a href='https://tools.ietf.org/html/rfc6901'>JSON Pointer</a> to records list</React.Fragment>)}
              name='jsonPointerRecordsList'
              onChangeFunc={handleConfigChangeFunc}
              value={jsonPointerRecordsList} />
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment><a href='https://tools.ietf.org/html/rfc6901'>JSON Pointer</a> to record</React.Fragment>)}
              name='jsonPointerRecord'
              onChangeFunc={handleConfigChangeFunc}
              value={jsonPointerRecord} />
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='form-group'>
                  <label>Sync mode</label>
                  <select
                    className='custom-select'
                    name='syncIntervalMode'
                    onChange={handleConfigChangeFunc}
                    value={syncIntervalMode}>
                    <option value='milliseconds'>Sync in fixed millisecond intervals</option>
                    <option value='cron'>CRON expression</option>
                  </select>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                {syncIntervalMode === 'milliseconds' &&
                  <ConfigForm
                    errorMessages={errorMessages}
                    label='Sync interval (ms)'
                    name='intervalMillis'
                    onChangeFunc={handleConfigChangeFunc}
                    placeholder='120000'
                    value={intervalMillis} />
                }
                {syncIntervalMode === 'cron' &&
                  <ConfigForm
                    errorMessages={errorMessages}
                    label={(<React.Fragment>Sync interval (<a href='https://www.manpagez.com/man/5/crontab/'>CRON expression</a>)</React.Fragment>)}
                    name='cronExpression'
                    onChangeFunc={handleConfigChangeFunc}
                    placeholder='*/2 * * * *'
                    value={cronExpression} />
                }
              </div>
            </div>
            <RetrieveColumns
              columnAttributeName='recordKeyName'
              columns={flatColumns}
              dataSource={dataSource}
              dataSources={dataSources}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the column names from the REST endpoint failed. Please check your connection credentials.'
              handleChangeFunc={handleConfigChangeFunc}
              isRequired={true}
              label='Name of the attribute holding the record key'
              missingOptionsErrorMessage='Please provide at least the URI of the REST endpoint.'
              resetRetrieveColumnsFunc={resetRetrieveColumnsFunc}
              retrieveColumnsFromDataSourceFunc={retrieveColumnsFromDataSourceFunc}
              retrievingColumnsIsPossible={retrievingColumnsIsPossible}
              value={recordKeyName} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'auth') &&
          <React.Fragment>
            <h3 className='card-title mb-3'>HTTP Basic authentication</h3>
            <ConfigForm
              errorMessages={errorMessages}
              label='HTTP Basic authentication username'
              name='httpBasicAuthenticationUsername'
              onChangeFunc={handleConfigChangeFunc}
              value={httpBasicAuthenticationUsername} />
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment>HTTP Basic authentication password{editPasswordNotice}</React.Fragment>)}
              name='password'
              onChangeFunc={handleChangeFunc}
              type='password'
              value={dataSource.password} />
            <h3 className='card-title mb-3'>Token exchange</h3>
            <div className='card bg-light border rounded-0'>
              <div className='card-body'>
                  If the API is using time-based access tokens for authentication, you can here specify a token exchange endpoint, which
                  is called for generating a new token when the API returns HTTP status code 401.
              </div>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              label='Token exchange URI'
              name='tokenExchangeUri'
              onChangeFunc={handleConfigChangeFunc}
              subLabel='including http:// or https://'
              value={tokenExchangeUri} />
            <div className='form-group'>
              <label>Token exchange HTTP method</label>
              <select
                className='custom-select'
                name='tokenExchangeHttpMethod'
                onChange={handleConfigChangeFunc}
                value={tokenExchangeHttpMethod}>
                <option value='get'>GET</option>
                <option value='post'>POST</option>
              </select>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              label='Token exchange HTTP request headers'
              name='tokenExchangeHttpRequestHeaders'
              onChangeFunc={handleConfigChangeFunc}
              value={tokenExchangeHttpRequestHeaders} />
            {(tokenExchangeHttpMethod === 'post') &&
              <ConfigForm
                errorMessages={errorMessages}
                label='Token exchange HTTP request body'
                name='tokenExchangeHttpRequestBody'
                onChangeFunc={handleConfigChangeFunc}
                type='textarea'
                value={tokenExchangeHttpRequestBody} />
            }
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment><a href='https://tools.ietf.org/html/rfc6901'>JSON Pointer</a> to token</React.Fragment>)}
              name='tokenExchangeJsonPointer'
              onChangeFunc={handleConfigChangeFunc}
              value={jsonPointerToken} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'cdc') &&
          <React.Fragment>
            <RetrieveColumns
              columnAttributeName='recordTimestampName'
              columns={flatColumns}
              dataSource={dataSource}
              dataSources={dataSources}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the column names from the REST endpoint failed. Please check your connection credentials.'
              handleChangeFunc={handleConfigChangeFunc}
              label="Name of the attribute holding the timestamp of the record's most recent update"
              missingOptionsErrorMessage='Please provide at least the URI of the REST endpoint.'
              resetRetrieveColumnsFunc={resetRetrieveColumnsFunc}
              retrieveColumnsFromDataSourceFunc={retrieveColumnsFromDataSourceFunc}
              retrievingColumnsIsPossible={retrievingColumnsIsPossible}
              value={recordTimestampName} />
            {![undefined, ''].includes(recordTimestampName) &&
              <React.Fragment>
                <div className='form-group'>
                  <label>Timestamp type</label>
                  <select
                    className='custom-select'
                    name='timestampType'
                    onChange={handleConfigChangeFunc}
                    value={timestampType}>
                    <option value='formattedTimestamp'>Formatted Timestamp</option>
                    <option value='epochMillis'>Epoch Milliseconds</option>
                    <option value='epochSeconds'>Epoch Seconds</option>
                  </select>
                </div>
                {[undefined, '', 'formattedTimestamp'].includes(timestampType) &&
                  <ConfigForm
                    errorMessages={errorMessages}
                    label='Format of timestamp values'
                    name='timestampFormat'
                    onChangeFunc={handleConfigChangeFunc}
                    placeholder="yyyy-MM-dd'T'HH:mm:ss[.SSS]X"
                    value={timestampFormat} />
                }
                <ConfigForm
                  errorMessages={errorMessages}
                  label='Initial timestamp offset'
                  name='timestampOffset'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder='1970-01-01T00:00:00Z'
                  subLabel='Please use the format ISO INSTANT, e.g., 2021-09-09T11:51:07Z.'
                  value={timestampOffset} />
              </React.Fragment>
            }
          </React.Fragment>
        }
        {(this.state.currentTab === 'pagination') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment><a href='https://tools.ietf.org/html/rfc6901'>JSON Pointer</a> to total number of records</React.Fragment>)}
              name='jsonPointerPaginationNumberRecords'
              onChangeFunc={handleConfigChangeFunc}
              value={jsonPointerPaginationNumberRecords} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Number of records per page'
              name='recordsPerPage'
              onChangeFunc={handleConfigChangeFunc}
              value={recordsPerPage} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              label='Replace pattern in key values (regular expression)'
              name='keyReplacementPattern'
              onChangeFunc={handleConfigChangeFunc}
              value={keyReplacementPattern} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Replace with value'
              name='keyReplacementValue'
              onChangeFunc={handleConfigChangeFunc}
              value={keyReplacementValue} />
            <ConfigForm
              errorMessages={errorMessages}
              label='HTTP request timeout (s)'
              name='requestTimeout'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='120'
              value={requestTimeout} />
            <div className='custom-control custom-switch d-flex align-items-center mb-4'>
              <input
                checked={[true, 'true'].includes(treatEmptyStringsAsNullValues)}
                className='custom-control-input clickable'
                id='treatEmptyStringsAsNullValues'
                name='treatEmptyStringsAsNullValues'
                onChange={handleConfigChangeFunc}
                type='checkbox' />
              <label className='custom-control-label clickable' htmlFor='treatEmptyStringsAsNullValues'>
                Treat empty strings as NULL values
              </label>
            </div>
            <div className="mb-4">
              <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
              <AceEditor
                  placeholder=''
                  mode='json'
                  theme='xcode'
                  className=''
                  onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                  onChange={(value, event) => {
                    handleConfigChangeFunc({
                      "target": {
                        "name": "kafkaConnectConfig",
                        "value": value
                      }
                    });
                  }}
                  fontSize={13}
                  height='200px'
                  width='100%'
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  value={kafkaConnectConfig}
                  tabSize={2} />
            </div>
          </React.Fragment>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default RestConfig;
