import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProjectListItem extends Component {
  getProjectShortName(project) {
    let shortName = '?';

    if (project.name === undefined) {
      return shortName;
    }

    const words = project.name.split(' ');

    if (words[0] !== undefined && words[0].length > 0) {
      shortName = words[0][0].toUpperCase()[0];

      if (words[1] !== undefined && words[1].length > 0) {
        shortName += words[1][0].toUpperCase()[0];
      }
    }

    return shortName;
  }

  render() {
    const {
      actionName,
      project,
      projectUrl
    } = this.props;

    return (
      <li className='list-group-item py-2' key={project.id}>
        <div className='row align-items-center py-2'>
          <div className='col-auto'>
            <Link
              to={projectUrl}
              className='avatar'>
              <div className='avatar-title font-size-x-lg bg-primary-soft rounded text-primary'>
                {this.getProjectShortName(project)}
              </div>
            </Link>
          </div>
          <div className='col ml-2'>
            <h4 className='mb-0 name'>
              <Link to={projectUrl}>
                #{project.id}: {project.name}
              </Link>
            </h4>
          </div>
          <div className='col-auto col-show-btn d-none d-lg-flex'>
            <Link to={projectUrl} className='btn btn-white btn-sm mr-3'>
              {actionName}
            </Link>
          </div>
        </div>
      </li>
    );
  }
}

export default ProjectListItem;
