import React, { Component } from 'react';
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';

class RetrieveTables extends Component {
  constructor(props) {
    super(props);

    this.fetchTableNames = this.fetchTableNames.bind(this);
  }

  fetchTableNames(event) {
    event.preventDefault();

    this.props.retrieveTablesFromDataSourceFunc(this.props.dataSource);
  }

  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      failedFetchErrorMessage,
      handleChangeFunc,
      missingOptionsErrorMessage,
      resetRetrieveTablesFunc,
      retrievingTablesIsPossible,
      tableAttributeName,
      tables
    } = this.props;

    const selectClassNames = ((errorMessages !== undefined) && (errorMessages[tableAttributeName] !== undefined))
      ? 'custom-select is-invalid'
      : 'custom-select';

    const inputClassNames = ((errorMessages !== undefined) && (errorMessages[tableAttributeName] !== undefined))
      ? 'form-control is-invalid'
      : 'form-control';

    const label = (this.props.label !== undefined) ? this.props.label : 'Table';

    return (
      <div className='form-group'>
        <label>{label} name<span className='text-danger'>*</span></label>
        <div className='row'>
          <div className='col'>
            {tables.length > 0 &&
              <select
                className={selectClassNames}
                name={tableAttributeName}
                onChange={handleChangeFunc}
                value={dataSource[tableAttributeName]}>
                {![undefined, ''].includes(dataSource[tableAttributeName]) && !tables.includes(dataSource[tableAttributeName]) &&
                  <option value={dataSource[tableAttributeName]}>{dataSource[tableAttributeName]}</option>
                }
                <option value=''>--- Choose a {label.toLowerCase()}</option>
                {tables.map((table, idx) => (
                  <option key={idx} value={table}>{table}</option>
                ))}
              </select>
            }
            {tables.length === 0 &&
              <input
                type='text'
                className={inputClassNames}
                name={tableAttributeName}
                onChange={handleChangeFunc}
                value={dataSource[tableAttributeName]} />
            }
          </div>
          <div className='col-auto'>
            {dataSources.fetchingTables &&
              <button className='btn btn-white' disabled={true}>
                Fetching {label.toLowerCase()} names...
              </button>
            }
            {!dataSources.fetchingTables && tables.length === 0 && retrievingTablesIsPossible &&
              <button className='btn btn-white' onClick={this.fetchTableNames}>
                Fetch {label.toLowerCase()} names
              </button>
            }
            {!dataSources.fetchingTables && tables.length === 0 && !retrievingTablesIsPossible &&
              <OverlayTrigger
                  placement='bottom'
                  delay={{ show: 100, hide: 100 }}
                  overlay={(props) => (<Tooltip {...props}>{missingOptionsErrorMessage}</Tooltip>)}>
                <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                  <button
                    className='btn btn-white'
                    disabled={true}
                    style={{ pointerEvents : 'none' }}>
                    Fetch {label.toLowerCase()} names
                  </button>
                </div>
              </OverlayTrigger>
            }
            {!dataSources.fetchingTables && tables.length > 0 &&
              <button className='btn btn-white' onClick={resetRetrieveTablesFunc}>
                Reset {label.toLowerCase()} names
              </button>
            }
          </div>
        </div>
        {((errorMessages !== undefined) && (errorMessages[tableAttributeName] !== undefined)) &&
          <p className='mt-2 mb-0 font-weight-bold text-danger'>{errorMessages[tableAttributeName]}</p>
        }
        {dataSources.fetchingTablesFailed &&
          <p className='mt-2 mb-0 font-weight-bold text-danger'>{failedFetchErrorMessage}</p>
        }
      </div>
    );
  }
}

export default RetrieveTables;
