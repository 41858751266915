import React, { Component }       from 'react';
import SelectJoinedDataSourceForm from './join/SelectJoinedDataSourceForm';
import ManageJoinedDataSourceForm from './join/ManageJoinedDataSourceForm';
import '../../../../scss/designer/join.scss';

class JoinPage extends Component {
  render() {
    const {
      dataSource,
      dataSources,
      dataSourceProfiles,
      handleJoinConfigChangeFunc,
      pipeline,
      pipelines,
      resetJoinedDataSourceFunc,
      selectJoinedDataSourceFunc
    } = this.props;

    const joinedDataSource = dataSources
      .find(entry => parseInt(entry.id) === pipeline.joinedDataSourceId);

    const showManageJoinPage =
      (pipeline.joinedDataSourceId !== undefined) &&
      (pipeline.joinedDataSourceProfileId !== undefined) &&
      (dataSourceProfiles.joinedDataSourceProfile !== undefined);

    return (
      <div className='container mt-4'>
        <div className='row'>
          <div className='col'>
            {!showManageJoinPage &&
              <SelectJoinedDataSourceForm
                dataSources={dataSources}
                pipeline={pipeline}
                pipelines={pipelines}
                selectJoinedDataSourceFunc={selectJoinedDataSourceFunc} />
            }
            {showManageJoinPage &&
              <ManageJoinedDataSourceForm
                dataSource={dataSource}
                dataSourceProfiles={dataSourceProfiles}
                handleJoinConfigChangeFunc={handleJoinConfigChangeFunc}
                joinedDataSource={joinedDataSource}
                pipeline={pipeline}
                pipelines={pipelines}
                resetJoinedDataSourceFunc={resetJoinedDataSourceFunc} />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default JoinPage;
