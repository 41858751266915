const projectMemberships = (state, action) => {
  const initialState = {
    projectMemberships: []
  };

  switch (action.type) {
    case 'RESET_PROJECT_MEMBERSHIPS':
    case 'REQUEST_PROJECT_MEMBERSHIPS':
      return {
        ...state,
        projectMemberships: []
      };
    case 'RECEIVE_PROJECT_MEMBERSHIPS':
      return {
        ...state,
        projectMemberships: action.projectMemberships
      };
    default:
      return (state || initialState);
  }
}

export default projectMemberships
