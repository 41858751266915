import React, { Component }    from 'react';
import { connect }             from 'react-redux';
import { Link, Redirect }      from 'react-router-dom';
import { Slack }               from 'react-feather';
import MainContent             from '../../components/layout/MainContent';
import ProjectMembershipAdmin  from '../../components/projects/ProjectMembershipAdmin';
import { deepCopy }            from '../../helpers/deepCopy';
import { isValidEmailAddress } from '../../helpers/isValidEmailAddress';
import {
  fetchProject,
  updateProject
} from '../../actions/projects';
import {
  fetchProjectMembershipsOfProject,
  replaceProjectMembershipsOfProject
} from '../../actions/project_memberships';
import { fetchCurrentUser } from '../../actions/users';

class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab:         'general',
      errorMessage:       '',
      newMemberEmail:     '',
      newMemberRole:      '',
      project:            {},
      projectMemberships: [],
      projectUpdated:     false
    };

    this.handleAddMember            = this.handleAddMember.bind(this);
    this.handleChange               = this.handleChange.bind(this);
    this.handleChangeNewMember      = this.handleChangeNewMember.bind(this);
    this.handleChangeExistingMember = this.handleChangeExistingMember.bind(this);
    this.handleSubmit               = this.handleSubmit.bind(this);
    this.removeExistingMember       = this.removeExistingMember.bind(this);
    this.setCurrentTab              = this.setCurrentTab.bind(this);
  }

  componentDidMount() {
    this.props.fetchCurrentUser();
    this.props.fetchProject(this.getProjectId())
      .then(() => this.setState({ project: this.props.projects.project }));
    this.props.fetchProjectMembershipsOfProject(this.getProjectId())
      .then(() => this.setState({ projectMemberships: this.props.projectMemberships.projectMemberships }));
  }

  getProjectShortName(project) {
    let shortName = '?';

    if (project.name === undefined) {
      return shortName;
    }

    const words = project.name.split(' ');

    if (words[0] !== undefined && words[0].length > 0) {
      shortName = words[0][0].toUpperCase()[0];

      if (words[1] !== undefined && words[1].length > 0) {
        shortName += words[1][0].toUpperCase()[0];
      }
    }

    return shortName;
  }

  handleAddMember(event) {
    event.preventDefault();
    const existingMemberEmails = this.state.projectMemberships
      .map(membership => membership.userEmail);

    if (
      // Ensure that email is not empty
      ![undefined, ''].includes(this.state.newMemberEmail) &&
      // Verify that no existing membership uses this email
      !existingMemberEmails.includes(this.state.newMemberEmail) &&
      // Verify correctness of provided email
      isValidEmailAddress(this.state.newMemberEmail) &&
      // Verify correctness of provided role
      ['viewer', 'editor', 'administrator'].includes(this.state.newMemberRole)
    ) {
      let projectMemberships = this.state.projectMemberships;
      // add member to list
      projectMemberships.push({
        userEmail:       this.state.newMemberEmail,
        membershipRole:  this.state.newMemberRole
      });
      this.setState({
        newMemberEmail:     '',
        newMemberRole:      '',
        projectMemberships: projectMemberships
      });
    }
  }

  handleChange(event) {
    let project = this.state.project;

    if (event.target.type === 'number') {
      project[event.target.name] = parseInt(event.target.value);
    } else {
      project[event.target.name] = event.target.value;
    }

    this.setState({ project: project });
  }

  handleChangeNewMember(event) {
    if (event.target.name === 'userEmail') {
      this.setState({ newMemberEmail: event.target.value });
    } else if (event.target.name === 'membershipRole') {
      this.setState({ newMemberRole: event.target.value });
    }
  }

  handleChangeExistingMember(event) {
    const attributeName = event.target.name;
    const userEmail = event.target.dataset.userEmail;

    let projectMemberships = this.state.projectMemberships;
    const membershipIndex = deepCopy(projectMemberships)
      .findIndex(membership => membership.userEmail === userEmail);

    if (membershipIndex !== -1) {
      if (attributeName === 'enableNotifications') {
        const toggledNotifications = (projectMemberships[membershipIndex].enableNotifications === false)
          ? true
          : false;
        projectMemberships[membershipIndex].enableNotifications = toggledNotifications;
      } else {
        projectMemberships[membershipIndex].membershipRole = event.target.value;
      }
    }

    this.setState({ projectMemberships: projectMemberships });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.project.name.length === 0) {
      this.setState({
        errorMessage: 'Please provide a name.'
      });
    } else {
      this.props.updateProject(this.state.project).then((response) => {
        if (response.errorMessage !== undefined) {
          this.setState({
            errorMessage: response.errorMessage
          });
        } else {
          this.props.replaceProjectMembershipsOfProject(
            this.getProjectId(),
            this.state.projectMemberships
          ).then(() =>
            this.setState({
              errorMessage:       '',
              projectMemberships: this.props.projectMemberships.projectMemberships,
              projectUpdated:     true
            })
          );
        }
      });
    }
  }

  removeExistingMember(userEmail) {
    let projectMemberships = deepCopy(this.state.projectMemberships)
      .filter(membership => membership.userEmail !== userEmail);

    this.setState({ projectMemberships: projectMemberships });
  }

  getProjectId() {
    return parseInt(this.props.match.params.id);
  }

  setCurrentTab(event) {
    event.preventDefault();

    const newTab = event.target.dataset.newTab;
    this.setState({
      currentTab: newTab
    });
  }

  render() {
    const projectUri = '/projects/' + this.getProjectId();
    if (this.state.projectUpdated) {
      return (<Redirect to={projectUri} />);
    }

    const project = this.state.project;

    if (project === undefined) {
      return (<div></div>);
    }

    const currentUser = this.props.users.currentUser;

    const adminRole = (currentUser !== undefined)
      ? currentUser.projectMemberships
          .find(membership =>
            (membership.membershipRole === 'administrator') &&
            (membership.projectId === parseInt(this.getProjectId()))
          )
      : undefined;

    const canManageProject = (
      (currentUser !== undefined) &&
      (adminRole !== undefined)
    );

    if (!canManageProject) {
      return (<div></div>);
    }

    let navItems = [
      {
        label:   'General',
        tabName: 'general'
      },
      {
        label:   'Project members',
        tabName: 'project-members'
      },
      {
        label:   'Notifications',
        tabName: 'notifications'
      }
    ];

    if (project === undefined) {
      return (<React.Fragment></React.Fragment>);
    }

    return (
      <MainContent preTitle='Projects' title={`Edit project #${project.id}: ${project.name}`} buttonLabel='Go back to project' buttonLink={projectUri}>
        <form>
          <div className='card'>
            <div className='card-header'>
              <ul className='nav nav-tabs card-header-tabs'>
                {navItems.map((navItem, index) => (
                  <li className='nav-item' key={index}>
                    {(this.state.currentTab === navItem.tabName) &&
                      <Link className='nav-link text-center active font-weight-bold text-black' data-new-tab={navItem.tabName} to={window.location.href} onClick={this.setCurrentTab}>
                        {navItem.label}
                      </Link>
                    }
                    {(this.state.currentTab !== navItem.tabName) &&
                      <Link className='nav-link text-center' data-new-tab={navItem.tabName} to={window.location.href} onClick={this.setCurrentTab}>
                        {navItem.label}
                      </Link>
                    }
                  </li>
                ))}
              </ul>
            </div>
            <div className='card-body'>
              {this.state.errorMessage.length > 0 &&
                <div className='alert alert-danger mb-4 rounded-0'>
                {this.state.errorMessage}
                </div>
              }
              <div className='row'>
                <div className='col-12'>
                  {(this.state.currentTab === 'general') &&
                    <React.Fragment>
                      <div className='form-group'>
                        <label htmlFor='name'>Name:</label>
                        <input
                          type='text'
                          className='form-control'
                          name='name'
                          onChange={this.handleChange}
                          value={project.name || ''} />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='description'>Description:</label>
                        <input
                          type='text'
                          className='form-control'
                          name='description'
                          onChange={this.handleChange}
                          value={project.description || ''} />
                      </div>
                    </React.Fragment>
                  }
                  {(this.state.currentTab === 'project-members') &&
                    <ProjectMembershipAdmin
                      handleAddMemberFunc={this.handleAddMember}
                      handleChangeExistingMemberFunc={this.handleChangeExistingMember}
                      handleChangeNewMemberFunc={this.handleChangeNewMember}
                      newMemberEmail={this.state.newMemberEmail}
                      newMemberRole={this.state.newMemberRole}
                      projectMemberships={this.state.projectMemberships}
                      removeExistingMemberFunc={this.removeExistingMember} />
                  }
                  {(this.state.currentTab === 'notifications') &&
                    <React.Fragment>
                      <div className='form-group'>
                        <label htmlFor='name' className='d-flex align-items-center'>
                          <Slack className='feather-icon mr-1' /> Send notifications to a <a className='ml-2' href='https://api.slack.com/messaging/webhooks#getting_started' target='_blank' rel='noopener noreferrer'>Slack Webhook</a>:
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='notificationSlackWebhook'
                          onChange={this.handleChange}
                          value={project.notificationSlackWebhook || ''} />
                      </div>
                      <div className='card bg-light border mt-4 rounded-0'>
                        <div className='card-body'>
                          <p className='mb-3'>
                            How to create a Slack webhook
                          </p>
                          <ul className='small text-muted pl-4 mb-0'>
                            <li>
                              <a href='https://api.slack.com/apps/new' target='_blank' rel='noopener noreferrer'>Create a new Slack app</a>.
                            </li>
                            <li>
                              Activate the incoming webhooks feature on the settings page of your Slack app.
                            </li>
                            <li>
                              Create a new incoming webhook and associate it with the channel that you want DataCater to send notifications to.
                            </li>
                            <li>
                              Copy the webhook URL (the format should be <span className='font-italic'>https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX)</span> and paste it into the text field above.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='card-body'>
              <button
                className='btn btn-primary'
                onClick={this.handleSubmit}
                type='submit'>
                Save changes
              </button>
            </div>
          </div>
        </form>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    projects:           state.projects,
    projectMemberships: state.projectMemberships,
    users:              state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser:                   fetchCurrentUser,
  fetchProject:                       fetchProject,
  fetchProjectMembershipsOfProject:   fetchProjectMembershipsOfProject,
  replaceProjectMembershipsOfProject: replaceProjectMembershipsOfProject,
  updateProject:                      updateProject
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
