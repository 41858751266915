import React, { Component } from 'react';
import RetrieveColumns      from '../RetrieveColumns';
import RetrieveTables       from '../RetrieveTables';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class AwsS3Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveColumnsFunc,
      resetRetrieveTablesFunc,
      retrieveColumnsFromDataSourceFunc,
      retrieveTablesFromDataSourceFunc
    } = this.props;

    const flatColumns = dataSources.columns
      .map(column => column.name);

    const flatTables = dataSources.tables
      .map(table => table.name)
      .sort();

    const retrievingTablesIsPossible =
      ![undefined, ''].includes(dataSource.password) &&
      ![undefined, ''].includes(dataSource.username);

    const retrievingColumnsIsPossible =
      ![undefined, ''].includes(dataSource.password) &&
      ![undefined, ''].includes(dataSource.username) &&
      ![undefined, ''].includes(dataSource.bucketName);

    const awsRegion = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.awsRegion !== undefined)
      ? dataSource.connectorConfig.awsRegion
      : '';

    const primaryKeyColumn = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.primaryKeyColumn !== undefined)
      ? dataSource.connectorConfig.primaryKeyColumn
      : '';

    const intervalSeconds = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.intervalSeconds !== undefined)
      ? dataSource.connectorConfig.intervalSeconds
      : '';

    const fileNameFilter = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.fileNameFilter !== undefined)
      ? dataSource.connectorConfig.fileNameFilter
      : '';

    const dataFormat = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.dataFormat !== undefined)
      ? dataSource.connectorConfig.dataFormat
      : 'csv';

    const csvDelimiterValue = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.csvDelimiterValue !== undefined)
      ? dataSource.connectorConfig.csvDelimiterValue
      : '';

    const generateAttributeNamesFromHeaderRow = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.generateAttributeNamesFromHeaderRow !== undefined)
      ? '' + dataSource.connectorConfig.generateAttributeNamesFromHeaderRow
      : 'true';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the credentials unless you want to change them.</span>)
      : (<span className='text-danger'>*</span>);

    const kafkaConnectConfig = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSource.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item' key={tab.name}>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              label='AWS region'
              name='awsRegion'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='eu-central-1'
              type='text'
              value={awsRegion} />
            <ConfigForm
              errorMessages={errorMessages}
              label='AWS access key ID'
              name='username'
              onChangeFunc={handleChangeFunc}
              isRequired={true}
              type='text'
              value={dataSource.username} />
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment>AWS secret access key{editPasswordNotice}</React.Fragment>)}
              name='password'
              onChangeFunc={handleChangeFunc}
              type='password'
              value={dataSource.password} />
            <RetrieveTables
              dataSource={dataSource}
              dataSources={dataSources}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the S3 buckets failed. Please check your connection credentials.'
              handleChangeFunc={handleChangeFunc}
              label='Bucket'
              missingOptionsErrorMessage='Please provide the AWS access key ID and the AWS secret access key.'
              resetRetrieveTablesFunc={resetRetrieveTablesFunc}
              retrieveTablesFromDataSourceFunc={retrieveTablesFromDataSourceFunc}
              retrievingTablesIsPossible={retrievingTablesIsPossible}
              tableAttributeName='bucketName'
              tables={flatTables} />
            <ConfigForm
              errorMessages={errorMessages}
              label='File name filter'
              name='fileNameFilter'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='.*'
              subLabel='Regular expression'
              value={fileNameFilter} />
            <div className='form-group'>
              <label>File format</label>
              <select
                className='custom-select'
                name='dataFormat'
                onChange={handleConfigChangeFunc}
                value={dataFormat}>
                <option value='csv'>CSV</option>
                <option value='parquet'>Parquet</option>
              </select>
            </div>
            {dataFormat === 'csv' &&
              <React.Fragment>
                <ConfigForm
                  errorMessages={errorMessages}
                  label='CSV delimiter value'
                  name='csvDelimiterValue'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder=','
                  value={csvDelimiterValue} />
                <div className='custom-control custom-switch d-flex align-items-center mb-4'>
                  <input
                    checked={[true, 'true'].includes(generateAttributeNamesFromHeaderRow)}
                    className='custom-control-input clickable'
                    id='generateAttributeNamesSwitch'
                    name='generateAttributeNamesFromHeaderRow'
                    onChange={handleConfigChangeFunc}
                    type='checkbox' />
                  <label className='custom-control-label clickable' htmlFor='generateAttributeNamesSwitch'>
                    Generate attribute names from CSV header row
                  </label>
                </div>
              </React.Fragment>
            }
            <RetrieveColumns
              columnAttributeName='primaryKeyColumn'
              columns={flatColumns}
              dataSource={dataSource}
              dataSources={dataSources}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the column names failed. Please check your connection credentials.'
              handleChangeFunc={handleConfigChangeFunc}
              isRequired={true}
              label='Primary key column'
              missingOptionsErrorMessage='Please provide the AWS access key ID and the AWS secret access key.'
              resetRetrieveColumnsFunc={resetRetrieveColumnsFunc}
              retrieveColumnsFromDataSourceFunc={retrieveColumnsFromDataSourceFunc}
              retrievingColumnsIsPossible={retrievingColumnsIsPossible}
              value={primaryKeyColumn} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Sync interval (s)'
              name='intervalSeconds'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='120'
              value={intervalSeconds} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default AwsS3Config;
