import sha1 from 'js-sha1';

class Transformer {
  static getTransformers = () => {
    return [
      {
        name: 'New attribute',
        description: 'Adds a new attribute to the data set.',
        key: 'add-column',
        hasActionValue: true,
        hidden: true,
        previewInRepository: false
      },
      {
        name: 'Cast to data type',
        key: 'cast-data-type',
        description: 'Casts all values from a certain attribute to a given data type. This transformer does not allow any filters but is applied to all records of a data source.',
        previewInRepository: true,
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          const supportedTypes = ['boolean', 'date', 'double', 'float', 'int', 'long', 'string', 'time-millis','timestamp-millis'];
          if (supportedTypes.includes(actionValue)) {
            const castType = function(castValue, toType) {
              // do not cast missing values
              if (castValue == null) {
                return null;
              }

              if (dataType === 'boolean') {
                if (toType === 'double') {
                  return castValue ? 1.0 : 0.0;
                } else if (toType === 'float') {
                  return castValue ? 1.0 : 0.0;
                } else if (toType === 'int') {
                  return castValue ? 1 : 0;
                } else if (toType === 'long') {
                  return castValue ? 1 : 0;
                } else if (toType === 'string') {
                  return castValue ? 'true' : 'false';
                } else if (toType === 'boolean') {
                  return castValue;
                } else {
                  return null;
                }
              } else if (dataType === 'date') {
                if (toType === 'long') {
                  return castValue.valueOf();
                } else if (toType === 'string') {
                  return castValue.toLocaleDateString();
                } else if (toType === 'timestamp-millis') {
                  return castValue;
                } else if (toType === 'date') {
                  return castValue;
                } else {
                  return null;
                }
              } else if (dataType === 'double') {
                if (toType === 'boolean') {
                  return castValue === 1.0;
                } else if (toType === 'float') {
                  return castValue;
                } else if (toType === 'int') {
                  return parseInt(castValue);
                } else if (toType === 'long') {
                  return parseInt(castValue);
                } else if (toType === 'string') {
                  return '' + castValue;
                } else if (toType === 'double') {
                  return castValue;
                } else {
                  return null;
                }
              } else if (dataType === 'float') {
                if (toType === 'boolean') {
                  return castValue === 1.0;
                } else if (toType === 'double') {
                  return castValue;
                } else if (toType === 'int') {
                  return parseInt(castValue);
                } else if (toType === 'long') {
                  return parseInt(castValue);
                } else if (toType === 'string') {
                  return '' + castValue;
                } else if (toType === 'float') {
                  return castValue;
                } else {
                  return null;
                }
              } else if (dataType === 'int') {
                if (toType === 'boolean') {
                  return castValue === 1;
                } else if (toType === 'double') {
                  return parseFloat(castValue);
                } else if (toType === 'float') {
                  return parseFloat(castValue);
                } else if (toType === 'long') {
                  return parseInt(castValue);
                } else if (toType === 'string') {
                  return '' + castValue;
                } else if (toType === 'int') {
                  return parseInt(castValue);
                } else {
                  return null;
                }
              } else if (dataType === 'long') {
                if (toType === 'boolean') {
                  return castValue === 1;
                } else if (toType === 'date') {
                  return new Date(castValue);
                } else if (toType === 'double') {
                  return parseFloat(castValue);
                } else if (toType === 'float') {
                  return parseFloat(castValue);
                } else if (toType === 'int') {
                  return parseInt(castValue);
                } else if (toType === 'string') {
                  return '' + castValue;
                } else if (toType === 'time-millis') {
                  return new Date(castValue);
                } else if (toType === 'timestamp-millis') {
                  return new Date(castValue);
                } else if (toType === 'long') {
                  return parseInt(castValue);
                } else {
                  return null;
                }
              } else if (dataType === 'string') {
                if (toType === 'boolean') {
                  return castValue !== undefined &&
                         ['1', 'true', 't', 'yes', 'y'].includes(
                           castValue.toLowerCase().trim());
                } else if (toType === 'date') {
                  // ensure that castValue is in correct format for a date
                  if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(castValue) === false) {
                    return null;
                  }

                  const castedValue = new Date(castValue);
                  if (isNaN(castedValue)) {
                    return null;
                  } else {
                    return castedValue;
                  }
                } else if (toType === 'double') {
                  const castedValue = parseFloat(castValue);
                  if (isNaN(castedValue)) {
                    return null;
                  } else {
                    return castedValue;
                  }
                } else if (toType === 'float') {
                  const castedValue = parseFloat(castValue);
                  if (isNaN(castedValue)) {
                    return null;
                  } else {
                    return castedValue;
                  }
                } else if (toType === 'int') {
                  const castedValue = parseInt(castValue);
                  if (isNaN(castedValue)) {
                    return null;
                  } else {
                    return castedValue;
                  }
                } else if (toType === 'long') {
                  const castedValue = parseInt(castValue);
                  if (isNaN(castedValue)) {
                    return null;
                  } else {
                    return castedValue;
                  }
                } else if (toType === 'time-millis') {
                  // ensure that castValue is in correct format for a time
                  if (/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(castValue) === false) {
                    return null;
                  }

                  let castedValue = new Date();

                  const timeUnits = castValue.split(':');
                  if (timeUnits.length > 0) {
                    castedValue.setHours(parseInt(timeUnits[0]));
                  } else {
                    return null;
                  }

                  if (timeUnits.length > 1) {
                    castedValue.setMinutes(parseInt(timeUnits[1]));
                  } else {
                    castedValue.setMinutes(0);
                  }

                  if (timeUnits.length > 2) {
                    castedValue.setSeconds(parseInt(timeUnits[2]));
                  } else {
                    castedValue.setSeconds(0);
                  }

                  if (isNaN(castedValue)) {
                    return null;
                  } else {
                    return castedValue;
                  }
                } else if (toType === 'timestamp-millis') {
                  // ensure that castValue is in correct format for a timestamp
                  if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}(T| )?[0-9]{2}:[0-9]{2}:[0-9]{2}Z?$/.test(castValue) === false) {
                    return null;
                  }

                  const castedValue = new Date(castValue);
                  if (isNaN(castedValue)) {
                    return null;
                  } else {
                    return castedValue;
                  }
                } else if (toType === 'string') {
                  return castValue;
                } else {
                  return null;
                }
              } else if (dataType === 'time-millis') {
                if (toType === 'long') {
                  return castValue.valueOf();
                } else if (toType === 'string') {
                  return castValue.toLocaleTimeString();
                } else if (toType === 'timestamp-millis') {
                  return castValue;
                } else if (toType === 'time-millis') {
                  return castValue;
                } else {
                  return null;
                }
              } else if (dataType === 'timestamp-millis') {
                if (toType === 'date') {
                  return castValue;
                } else if (toType === 'long') {
                  return castValue.valueOf();
                } else if (toType === 'string') {
                  return castValue.toLocaleString();
                } else if (toType === 'time-millis') {
                  return castValue;
                } else if (toType === 'timestamp-millis') {
                  return castValue;
                } else {
                  return null;
                }
              } else {
                // by default, cast to string
                return '' + castValue;
              }
            }

            return castType(value, actionValue);
          } else {
            return value;
          }
        },
        allowFilters: false,
        hasActionValue: true,
        actionValueName: 'New data type',
        actionValueOptions: [
          { label: '--- Please choose a new type', value: '' },
          {
            label:                  'Boolean',
            value:                  'boolean',
            supportedOriginalTypes: ['boolean', 'double', 'float', 'int', 'long', 'string']
          },
          {
            label:                  'Date',
            value:                  'date',
            supportedOriginalTypes: ['date', 'long', 'string', 'timestamp-millis']
          },
          {
            label:                  'Double',
            value:                  'double',
            supportedOriginalTypes: ['boolean', 'double', 'float', 'int', 'long', 'string']
          },
          {
            label:                  'Float',
            value:                  'float',
            supportedOriginalTypes: ['boolean', 'double', 'float', 'int', 'long', 'string']
          },
          {
            label:                  'Int',
            value:                  'int',
            supportedOriginalTypes: ['boolean', 'double', 'float', 'int', 'long', 'string']
          },
          {
            label:                  'Long',
            value:                  'long',
            supportedOriginalTypes: ['boolean', 'date', 'double', 'float', 'int', 'long', 'string', 'timestamp-millis']
          },
          {
            label:                  'String',
            value:                  'string',
            supportedOriginalTypes: ['boolean', 'date', 'double', 'float', 'int', 'long', 'string', 'time-millis', 'timestamp-millis']
          },
          {
            label:                  'Time',
            value:                  'time-millis',
            supportedOriginalTypes: ['string', 'time-millis', 'timestamp-millis']
          },
          {
            label:                  'Timestamp',
            value:                  'timestamp-millis',
            supportedOriginalTypes: ['date', 'long', 'string', 'time-millis', 'timestamp-millis']
          }
        ]
      },
      {
        name: 'Drop attribute',
        key: 'drop-column',
        allowFilters: false,
        description: 'Drops a certain attribute from all or selected records.',
        previewInRepository: false
      },
      {
        name: 'Ignore record',
        key: 'drop-record',
        allowFilters: true,
        description: 'Ignores all or selected records for further processing.',
        previewInRepository: false
      },
      {
        name: 'Rename attribute',
        key: 'rename-column',
        hasActionValue: true,
        allowFilters: false,
        description: 'Assigns a new name to a certain attribute. This transformer does not allow any filters but is applied to all records of a data source.',
        actionValueName: 'New name',
        previewInRepository: false
      },
      {
        name: 'Rename key in JSON structure',
        key: 'rename-key-json-structure',
        hasActionValue: true,
        hasActionValue2: true,
        allowFilters: true,
        description: 'Renames a given key in a JSON structure.',
        actionValueName: 'Old key name',
        actionValue2Name: 'New key name',
        defaultActionValue: '',
        defaultActionValue2: '',
        previewInRepository: false,
        restrictToDataType: ['string'],
        transformationFunc: function(record, dataType, value, actionValue, actionValue2) {
          const renameKey = (jsonNode, oldName, newName) => {
            if (jsonNode == null) {
              return jsonNode;
            } else if (Array.isArray(jsonNode)) {
              return jsonNode.map(entry => renameKey(entry, oldName, newName));
            } else if (jsonNode.constructor === ({}).constructor) {
              // rename
              if (jsonNode[oldName] !== undefined) {
                jsonNode[newName] = jsonNode[oldName];
                delete jsonNode[oldName];
              }
              // check whether recursive calls
              const nodeKeys = Object.keys(jsonNode);
              nodeKeys.forEach(nodeKey => {
                if (
                  (jsonNode[nodeKey] != null) &&
                  (
                    Array.isArray(jsonNode[nodeKey]) ||
                    (jsonNode[nodeKey].constructor === ({}).constructor)
                  )
                ) {
                  jsonNode[nodeKey] = renameKey(jsonNode[nodeKey], oldName, newName);
                }
              })

              return jsonNode;
            } else {
              return jsonNode;
            }
          }

          try {
            if (
              [undefined, ''].includes(actionValue) ||
              [undefined, ''].includes(actionValue2)
            ) {
              return value;
            }

            const parsedRecord = JSON.parse(value);
            const newRecord = renameKey(parsedRecord, actionValue, actionValue2);
            return JSON.stringify(newRecord);
          } catch (e) {
            return value;
          }
        }
      },
      {
        name: 'Replace with attribute',
        hasActionValue: true,
        key: 'replace-attribute',
        allowFilters: true,
        description: 'Replaces all or selected values of an attribute with the value of another attribute of the same data type.',
        previewInRepository: false,
        actionValueName: 'Attribute',
        useAttributeAsActionValue: true,
        transformationFunc: function(record, dataType, value, actionValue) {
          return record.values[actionValue];
        }
      },
      {
        name: 'Replace with value',
        hasActionValue: true,
        actionValueName: 'Value',
        key: 'replace-constant',
        allowFilters: true,
        description: 'Replaces all or selected values of an attribute with a constant value. The constant value must be of the same type as the attribute.',
        previewInRepository: true,
        transformationFunc: function(record, dataType, value, actionValue) {
          if ([undefined, ''].includes(actionValue)) {
            return value;
          }
          if (['int', 'long', 'double', 'float'].includes(dataType) && isNaN(actionValue)) {
            return value;
          }

          return actionValue;
        }
      },
      {
        name: 'Add attribute',
        hasActionValue: true,
        key: 'add-attribute',
        allowFilters: true,
        previewInRepository: false,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        useAttributeAsActionValue: true,
        actionValueName: 'Attribute',
        description: 'Adds a numeric attribute to another numeric attribute.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          const typedValue = (['int', 'long'].includes(dataType))
            ? parseInt(record.values[actionValue])
            : parseFloat(record.values[actionValue]);
          if (isNaN(typedValue)) {
            return value;
          }

          return value + typedValue;
        }
      },
      {
        name: 'Add value',
        hasActionValue: true,
        actionValueName: 'Value',
        key: 'add',
        allowFilters: true,
        previewInRepository: true,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        description: 'Adds a constant value to a all values of a numeric attribute. The constant value must be of the same type as the attribute.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null || actionValue == null) {
            return value;
          }

          return value + actionValue
        }
      },
      {
        name: 'Divide by attribute',
        hasActionValue: true,
        key: 'divide-attribute',
        allowFilters: true,
        previewInRepository: false,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        useAttributeAsActionValue: true,
        actionValueName: 'Attribute',
        description: 'Divides a numeric attribute by another numeric attribute.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          const typedValue = (['int', 'long'].includes(dataType))
            ? parseInt(record.values[actionValue])
            : parseFloat(record.values[actionValue]);

          if (isNaN(typedValue)) {
            return value;
          }

          const result = value / typedValue;

          if (['int', 'long'].includes(dataType)) {
            return parseInt(result);
          } else {
            return result;
          }
        }
      },
      {
        name: 'Divide by value',
        hasActionValue: true,
        actionValueName: 'Value',
        key: 'divide',
        allowFilters: true,
        previewInRepository: true,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        description: 'Divides all values of a numeric attribute by a constant value. The constant value must be of the same type as the attribute.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null || isNaN(value) || actionValue == null) {
            return value;
          }

          const result = value / actionValue;

          if (['int', 'long'].includes(dataType)) {
            return parseInt(result);
          } else {
            return result;
          }
        }
      },
      {
        name: 'Floor to nearest integer',
        key: 'floor',
        allowFilters: true,
        previewInRepository: true,
        restrictToDataType: ['float', 'double'],
        description: 'Floors a floating-point value to the nearest integer.',
        transformationFunc: function(record, dataType, value) {
          if (value == null) {
            return value;
          }

          return Math.floor(value);
        }
      },
      {
        name: 'Modulo attribute',
        hasActionValue: true,
        key: 'modulo-attribute',
        allowFilters: true,
        previewInRepository: false,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        useAttributeAsActionValue: true,
        actionValueName: 'Attribute',
        description: 'Performs the modulo operation on a numeric attribute. This transformer divides a numeric attribute by another numeric attribute and returns the remainder.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          const typedValue = (['int', 'long'].includes(dataType))
            ? parseInt(record.values[actionValue])
            : parseFloat(record.values[actionValue]);

          if (isNaN(typedValue)) {
            return value;
          }

          return value % typedValue;
        }
      },
      {
        name: 'Modulo value',
        hasActionValue: true,
        actionValueName: 'Value',
        key: 'modulo',
        allowFilters: true,
        previewInRepository: true,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        description: 'Performs the modulo operation on a numeric attribute. This transformer divides a numeric attribute by a given constant value and returns the remainder.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null || isNaN(actionValue)) {
            return value;
          }

          return value % actionValue;
        }
      },
      {
        name: 'Multiply by attribute',
        hasActionValue: true,
        key: 'multiply-attribute',
        allowFilters: true,
        previewInRepository: false,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        useAttributeAsActionValue: true,
        actionValueName: 'Attribute',
        description: 'Multiplies all values of a numeric attribute by another numeric attribute.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          const typedValue = (['int', 'long'].includes(dataType))
            ? parseInt(record.values[actionValue])
            : parseFloat(record.values[actionValue]);

          if (isNaN(typedValue)) {
            return value;
          }

          return value * typedValue;
        }
      },
      {
        name: 'Multiply by value',
        hasActionValue: true,
        actionValueName: 'Value',
        key: 'multiply',
        allowFilters: true,
        previewInRepository: true,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        description: 'Multiplies all values of a numeric attribute by a constant value. The constant value must be of the same type as the attribute.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null || isNaN(value) || actionValue == null) {
            return value;
          }

          return value * actionValue;
        }
      },
      {
        name: 'Round upwards to nearest integer',
        key: 'round-upwards-to-integer',
        allowFilters: true,
        restrictToDataType: ['float', 'double'],
        previewInRepository: true,
        description: 'Rounds a floating-point value upwards to the nearest integer.',
        transformationFunc: function(record, dataType, value) {
          if (value == null) {
            return value;
          }

          return Math.ceil(parseFloat(value));
        }
      },
      {
        name: 'Round with precision',
        key: 'round',
        hasActionValue: true,
        actionValueName: 'Precision',
        defaultActionValue: 1,
        allowFilters: true,
        previewInRepository: true,
        restrictToDataType: ['float', 'double'],
        description: 'Rounds a floating-point value with the given precision.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          const typedValue = parseInt(actionValue);
          const precision = (isNaN(typedValue) || typedValue < 0)
            ? 1
            : typedValue;

          return parseFloat(value).toFixed(precision);
        }
      },
      {
        name: 'Subtract attribute',
        hasActionValue: true,
        key: 'subtract-attribute',
        allowFilters: true,
        previewInRepository: false,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        useAttributeAsActionValue: true,
        actionValueName: 'Attribute',
        description: 'Subtracts an attribute from all values of a numeric attribute.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          const typedValue = (['int', 'long'].includes(dataType))
            ? parseInt(record.values[actionValue])
            : parseFloat(record.values[actionValue]);

          if (isNaN(typedValue)) {
            return value;
          }

          return value - typedValue;
        }
      },
      {
        name: 'Subtract value',
        hasActionValue: true,
        actionValueName: 'Value',
        key: 'subtract',
        allowFilters: true,
        previewInRepository: true,
        restrictToDataType: ['int', 'long', 'float', 'double'],
        description: 'Subtracts a constant value from all values of a numeric attribute. The constant value must be of the same type as the attribute.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null || isNaN(actionValue)) {
            return value;
          }

          return value - actionValue;
        }
      },
      {
        name: 'Append attribute',
        key: 'append-attribute',
        allowFilters: true,
        hasActionValue: true,
        restrictToDataType: ['string'],
        previewInRepository: false,
        useAttributeAsActionValue: true,
        description: 'Appends a text value with another text value.',
        actionValueName: 'Attribute',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null || record.values[actionValue] == null) {
            return value;
          }

          return value + record.values[actionValue];
        }
      },
      {
        name: 'Append value',
        key: 'append-value',
        allowFilters: true,
        hasActionValue: true,
        actionValueName: 'Value',
        restrictToDataType: ['string'],
        previewInRepository: true,
        description: 'Appends a text value with a constant value.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null || actionValue == null) {
            return value;
          }

          return value + actionValue;
        }
      },
      {
        name: 'Capitalize',
        key: 'capitalize',
        allowFilters: true,
        restrictToDataType: ['string'],
        previewInRepository: true,
        description: 'Capitalizes all words of a text value. This transformer transforms the first character of each word to an uppercase notation and the remainer of each word to a lowercase notation.',
        transformationFunc: function(record, dataType, value) {
          if (value == null) {
            return value;
          }

          return value.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
        }
      },
      {
        name: 'Hash',
        key: 'hash',
        allowFilters: true,
        restrictToDataType: ['string'],
        previewInRepository: true,
        defaultActionValue: 'sha1',
        actionValueName: 'Hash function',
        hasActionValue: true,
        actionValueOptions: [
          { label: 'SHA-1', value: 'sha1' }
        ],
        description: 'Returns the hash of a text value. This transformer supports the following hash functions: SHA-1.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          return sha1(value);
        }
      },
      {
        name: 'Prepend attribute',
        key: 'prepend-attribute',
        allowFilters: true,
        hasActionValue: true,
        actionValueName: 'Attribute',
        restrictToDataType: ['string'],
        previewInRepository: false,
        useAttributeAsActionValue: true,
        description: 'Prepends a text value with another text value.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null || record.values[actionValue] == null) {
            return value;
          }

          return record.values[actionValue] + value;
        }
      },
      {
        name: 'Prepend value',
        key: 'prepend-value',
        allowFilters: true,
        hasActionValue: true,
        actionValueName: 'Value',
        restrictToDataType: ['string'],
        previewInRepository: true,
        description: 'Prepends a text value with a constant value.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null || actionValue == null) {
            return value;
          }

          return actionValue + value;
        }
      },
      {
        name: 'Remove punctuation',
        key: 'remove-punctuation',
        allowFilters: true,
        restrictToDataType: ['string'],
        description: 'Removes the following punctuation characters from a text value: \\t, \\n, !, ", \', #, $, %, &, (, ), *, +, ,, -, ., :, ;, <, =, >, ?, @, [, ], \\, ^, _, {, |, }, ~.',
        previewInRepository: true,
        transformationFunc: function(record, dataType, value) {
          if (value == null) {
            return value;
          }

          // remove the punctuations \t\n!”#$%&()*+,-./:;<=>?@[\\]^_`{|}~
          return value.replace(/(\\t|\\n|!|"|'|#|\$|%|&|\(|\)|\*|\+|,|-|\.|\/|:|;|<|=|>|\?|@|\[|\\|\]|\^|_|`\{|\||\}|~)/g, '');
        }
      },
      {
        name: 'Tokenize',
        key: 'tokenize',
        allowFilters: false,
        restrictToDataType: ['string'],
        previewInRepository: true,
        hasActionValue: true,
        actionValueName: 'Delimiter character',
        description: 'Tokenizes a text value using a given delimiter and turns it into an array of text values, where each entry is one token from the original text value. By default, this transformer uses whitespaces as delimiter.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          const defaultValue = [undefined, ''].includes(actionValue) ? ' ' : actionValue;
          // Do not allow nested tokenization
          if (value.constructor === Array) {
            return value;
          } else {
            return value
              .split(defaultValue)
              .filter(_ => _ !== undefined && _.length > 0);
          }
        }
      },
      {
        name: 'Transform to lowercase',
        key: 'lowercase',
        allowFilters: true,
        restrictToDataType: ['string'],
        previewInRepository: true,
        description: 'Transforms all characters of a text value to a lowercase notation.',
        transformationFunc: function(record, dataType, value) {
          if (value == null) {
            return value;
          }

          return value.toLowerCase();
        }
      },
      {
        name: 'Transform to uppercase',
        key: 'uppercase',
        allowFilters: true,
        restrictToDataType: ['string'],
        previewInRepository: true,
        description: 'Transforms all characters of a text value to an uppercase notation.',
        transformationFunc: function(record, dataType, value) {
          if (value == null) {
            return value;
          }

          return value.toUpperCase();
        }
      },
      {
        name: 'Trim',
        key: 'trim',
        allowFilters: true,
        restrictToDataType: ['string'],
        previewInRepository: true,
        description: 'Trims a text value and removes all leading or trailing whitespaces.',
        transformationFunc: function(record, dataType, value) {
          if (value == null) {
            return value;
          }

          return value.trim();
        }
      },
      {
        name: 'Truncate characters',
        key: 'truncate-characters',
        allowFilters: true,
        hasActionValue: true,
        actionValueName: 'Number of characters',
        defaultActionValue: -1,
        restrictToDataType: ['string'],
        previewInRepository: true,
        description: 'Truncates a text value after the given number of characters.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          // validate user input
          const typedLimit = parseInt(actionValue);
          if (typedLimit < 0 || isNaN(typedLimit)) {
            return value;
          }

          return value.substring(0, typedLimit);
        }
      },
      {
        name: 'Truncate words',
        key: 'truncate-words',
        hasActionValue: true,
        actionValueName: 'Number of words',
        allowFilters: true,
        defaultActionValue: -1,
        restrictToDataType: ['string'],
        previewInRepository: true,
        description: 'Truncates a text value after the given number of words. This transformer is implemented as follows: First, perform a whitespace tokenization. Second, consider only the first n tokens (n equals the number of words after which the original text value should be truncated). Third, join all considered words by whitespaces.',
        transformationFunc: function(record, dataType, value, actionValue) {
          if (value == null) {
            return value;
          }

          // validate user input
          const typedLimit = parseInt(actionValue);
          if (typedLimit < 0 || isNaN(typedLimit)) {
            return value;
          }

          return value
            .split(/\s+/)
            .splice(0, typedLimit)
            .join(' ');
        }
      },
      {
        key: 'unwrap-big-query-nested-record',
        name: 'Unwrap nested record from Google BigQuery',
        description: 'Unwraps a nested record from Google Cloud BigQuery and generates more compact JSON.',
        hasActionValue: false,
        allowFilters: true,
        restrictToDataType: ['string'],
        transformationFunc: function(record, dataType, value) {
          const flattenNode = (node) => {
            if (node["v"] !== undefined) {
              if (node["v"] == null) {
                return node["v"];
              } else if (node["v"].constructor === ({}).constructor) {
                return flattenNode(node["v"]);
              } else if (Array.isArray(node["v"])) {
                return node["v"].map(entry => flattenNode(entry));
              } else {
                return node["v"];
              }
            } else if (node["f"] != undefined && Array.isArray(node["f"])) {
              const structAsArray = node["f"].map(entry => flattenNode(entry));
              const structAsStringifiedObject = JSON.stringify(Object.assign({}, structAsArray));
              return JSON.parse(structAsStringifiedObject);
            } else {
              return node;
            }
          }

          try {
            const valueWithoutNewlines = value.replace(/[\t\n\r]/gm, ' ');
            const parsedRecord = JSON.parse(valueWithoutNewlines);
            const unwrappedRecord = flattenNode(parsedRecord);
            return JSON.stringify(unwrappedRecord);
          } catch(e) {
            return value;
          }
        }
      },
      {
        key: 'user-defined-transformation',
        name: 'User-defined transformation',
        description: 'Allows to provide an user-defined transformation function written in Python.',
        hasActionValue: true,
        allowFilters: true,
        actionValueName: 'Transformation function',
        restrictToDataType: ['boolean', 'double', 'float', 'int', 'long', 'string'],
        transformationFunc: function(record, dataType, value, actionValue) {
          return value;
        }
      }
    ];
  }
}

export default Transformer;
