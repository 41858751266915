import React, { Component } from 'react';
import ConfigForm from '../../data_stores/ConfigForm';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class RestConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSink,
      errorMessages,
      handleConfigChangeFunc
    } = this.props;

    const uri = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.uri !== undefined)
      ? dataSink.connectorConfig.uri
      : '';

    const httpMethod = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.httpMethod !== undefined)
      ? dataSink.connectorConfig.httpMethod
      : 'post';

    const httpRequestHeaders = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.httpRequestHeaders !== undefined)
      ? dataSink.connectorConfig.httpRequestHeaders
      : '';

    const maxRetries = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.maxRetries !== undefined)
      ? dataSink.connectorConfig.maxRetries
      : '';

    const backoffIntervalRetries = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.backoffIntervalRetries !== undefined)
      ? dataSink.connectorConfig.backoffIntervalRetries
      : '';

    const failedRequestsPolicy = (dataSink.connectorConfig !== undefined && ![undefined, ''].includes(dataSink.connectorConfig.failedRequestsPolicy))
      ? dataSink.connectorConfig.failedRequestsPolicy
      : 'retry';

    const tokenExchangeUri = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.tokenExchangeUri !== undefined)
      ? dataSink.connectorConfig.tokenExchangeUri
      : '';

    const tokenExchangeHttpMethod = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.tokenExchangeHttpMethod !== undefined)
      ? dataSink.connectorConfig.tokenExchangeHttpMethod
      : 'post';

    const tokenExchangeHttpRequestHeaders = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.tokenExchangeHttpRequestHeaders !== undefined)
      ? dataSink.connectorConfig.tokenExchangeHttpRequestHeaders
      : '';

    const tokenExchangeHttpRequestBody = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.tokenExchangeHttpRequestBody !== undefined)
      ? dataSink.connectorConfig.tokenExchangeHttpRequestBody
      : '';

    const jsonPointerToken = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.tokenExchangeJsonPointer !== undefined)
      ? dataSink.connectorConfig.tokenExchangeJsonPointer
      : '';

    const kafkaConnectConfig = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSink.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item' key={tab.name}>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='URI'
              name='uri'
              onChangeFunc={handleConfigChangeFunc}
              subLabel='including http:// or https://'
              value={uri} />
            <div className='form-group'>
              <label>HTTP method</label>
              <select
                className='custom-select'
                name='httpMethod'
                onChange={handleConfigChangeFunc}
                value={httpMethod}>
                <option value='post'>POST</option>
                <option value='patch'>PATCH</option>
                <option value='put'>PUT</option>
                <option value='delete'>DELETE</option>
              </select>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              label='HTTP request header'
              name='httpRequestHeaders'
              onChangeFunc={handleConfigChangeFunc}
              subLabel={(<React.Fragment>pipe(|)-separated list of <i>key</i>:<i>value</i> pairs</React.Fragment>)}
              value={httpRequestHeaders} />
            <div className='form-group'>
              <label>Handling of records, where the REST endpoint returns an unsuccessful HTTP status code</label>
              <select
                className='custom-select'
                name='failedRequestsPolicy'
                onChange={handleConfigChangeFunc}
                value={failedRequestsPolicy}>
                <option value='retry'>Retry sending the record</option>
                <option value='skip'>Skip the record</option>
              </select>
            </div>
            {failedRequestsPolicy === 'retry' &&
              <React.Fragment>
                <ConfigForm
                  errorMessages={errorMessages}
                  label='Maximum number of retries'
                  name='maxRetries'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder='10'
                  value={maxRetries} />
                <ConfigForm
                  errorMessages={errorMessages}
                  label='Backoff interval for retries (ms)'
                  name='backoffIntervalRetries'
                  onChangeFunc={handleConfigChangeFunc}
                  placeholder='10000'
                  value={backoffIntervalRetries} />
              </React.Fragment>
            }
            <hr />
            <h3 className='card-title mb-3'>Token exchange</h3>
            <div className='card bg-light border rounded-0'>
              <div className='card-body'>
                <p>
                  If the API is using time-based access tokens for authentication, you can here specify a token exchange endpoint, which
                  is called for generating a new token when the API returns HTTP status code 401.
                </p>
                <p className='mb-0'>
                  You can include the retrieved token in the fields <i>URI</i> or <i>HTTP request header</i> using the variable <code>{'${token}'}</code>, e.g., <code>{'Authorization: Bearer ${token}'}</code>.
                </p>
              </div>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              label='Token exchange URI'
              name='tokenExchangeUri'
              onChangeFunc={handleConfigChangeFunc}
              subLabel='including http:// or https://'
              value={tokenExchangeUri} />
            <div className='form-group'>
              <label>Token exchange HTTP method</label>
              <select
                className='custom-select'
                name='tokenExchangeHttpMethod'
                onChange={handleConfigChangeFunc}
                value={tokenExchangeHttpMethod}>
                <option value='get'>GET</option>
                <option value='post'>POST</option>
              </select>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              label='Token exchange HTTP request headers'
              name='tokenExchangeHttpRequestHeaders'
              onChangeFunc={handleConfigChangeFunc}
              value={tokenExchangeHttpRequestHeaders} />
            {(tokenExchangeHttpMethod === 'post') &&
              <ConfigForm
                errorMessages={errorMessages}
                label='Token exchange HTTP request body'
                name='tokenExchangeHttpRequestBody'
                onChangeFunc={handleConfigChangeFunc}
                type='textarea'
                value={tokenExchangeHttpRequestBody} />
            }
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment><a href='https://tools.ietf.org/html/rfc6901'>JSON Pointer</a> to token</React.Fragment>)}
              name='tokenExchangeJsonPointer'
              onChangeFunc={handleConfigChangeFunc}
              value={jsonPointerToken} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
      </React.Fragment>
    );
  }
}

export default RestConfig;
