import React,
       { Component }            from 'react';
import { connect }              from 'react-redux';
import MainContent              from '../../components/layout/MainContent';
import Navigation               from '../../components/settings/Navigation';
import { fetchInfo } from '../../actions/info';
import { restartAllConnectors } from '../../actions/kafka_connectors';
import { fetchCurrentUser }     from '../../actions/users';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restartedConnectors: false
    };
    this.restartConnectors = this.restartConnectors.bind(this);
  }

  componentDidMount() {
    this.props.fetchInfo();
    this.props.fetchCurrentUser();
  }

  restartConnectors(event) {
    event.preventDefault();
    this.setState({
      restartedConnectors: false
    });
    this.props.restartAllConnectors().then(() => {
      this.setState({ restartedConnectors: true });
    });
  }

  render() {
    const currentUser = this.props.users.currentUser;

    if ((currentUser === undefined) || (currentUser.isSuperUser !== true)) {
      return (<div></div>);
    }

    return (
      <MainContent preTitle='Settings' title='Manage users'>
        <div className='card card-fill'>
          <div className='card-body'>
            <div className='row mb-4 mt-n3'>
              <div className='col-12'>
                <Navigation currentUser={currentUser} info={this.props.info.info} />
              </div>
            </div>
            <div className='row align-items-center mb-4'>
              <div className='col'>
                <h3 className='card-header-title'>
                  Kafka Connect
                </h3>
              </div>
            </div>
            <div>
              {this.state.restartedConnectors &&
                <div className='alert alert-success my-4 rounded-0'>
                  All connectors have been restarted.
                </div>
              }
              {this.props.kafkaConnectors.isRestartingConnectors &&
                <button className='btn btn-white'>
                  <span className='spinner-border mr-2' role='status'>
                  </span>
                  Restarting all connectors...
                </button>
              }
              {!this.props.kafkaConnectors.isRestartingConnectors &&
                <button className='btn btn-white' onClick={this.restartConnectors}>
                  Restart all connectors
                </button>
              }
            </div>
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    info:            state.info,
    kafkaConnectors: state.kafkaConnectors,
    users:           state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser:     fetchCurrentUser,
  fetchInfo:            fetchInfo,
  restartAllConnectors: restartAllConnectors
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
