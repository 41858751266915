import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { Redirect }         from 'react-router-dom';
import { fetchInfo }        from '../actions/info';
import { signUp }           from '../actions/user_sessions';
import { Eye }              from 'react-feather';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: '',
      formValues: {
        email: '',
        name:  ''
      },
      infoMessage:      '',
      signUpSuccessful: false
    };

    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchInfo();

    // do a small trick to add CSS classes to dom elements outside of
    // the application's root element
    const bodyDomElement = document.querySelector('body');
    const rootDomElement = document.querySelector('#root');
    bodyDomElement.className = 'd-flex align-items-center bg-auth border-top border-top-2 border-primary';
    rootDomElement.classList.add('container-fluid');
    rootDomElement.classList.add('pr-lg-0');

    document.title = 'Sign Up | DataCater';
  }

  componentWillUnmount() {
    // get rid of the added CSS classes
    const bodyDomElement = document.querySelector('body');
    const rootDomElement = document.querySelector('#root');
    bodyDomElement.className = '';
    rootDomElement.classList.remove('container-fluid');
  }

  handleSignUp(event) {
    event.preventDefault();
    let formErrors = '';

    if (formErrors.length === 0) {
      this.props.signUp(this.state.formValues.name, this.state.formValues.email)
        .then(() => {
          if (this.props.userSessions.signUpWasSuccessful === true) {
            // remember email address for sign in form
            localStorage.setItem('signUpEmail', this.state.formValues.email);

            this.setState({
              formErrors:       '',
              signUpSuccessful: true
            });
          } else {
            this.setState({
              formErrors:       'Could not create account.',
              infoMessage:      '',
              signUpSuccessful: false
            });
          }
        });
    }
  }

  handleChange(event) {
    let userForm = this.state.formValues;
    userForm[event.target.name] = event.target.value;
    this.setState({
      // reset errors
      formErrors: '',
      formValues: userForm
    });
  }

  render() {
    if (this.state.signUpSuccessful) {
      return (<Redirect to='/sign_in' />);
    }

    const info = this.props.info.info;

    return (
      <React.Fragment>
        <div className="row justify-content-center mr-lg-0">
          <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
            <a href='https://datacater.io'>
              <img src='/images/logo.png' alt='DataCater Logo' className='login-logo' />
            </a>
            <h1 className="display-4 text-center mb-3" style={{"line-height":"125%"}}>
              Sign up for your free 14-day trial
            </h1>
            {this.state.formErrors.length > 0 &&
              <div className='alert alert-danger mt-4 rounded-0'>
                {this.state.formErrors} Please reach out to our <a className='text-white' href='mailto:support@datacater.io'>support</a> if the issues persist.
              </div>
            }
            {this.state.infoMessage.length > 0 &&
              <div className='alert alert-success mt-4 rounded-0'>
                {this.state.infoMessage}
              </div>
            }
            <form>
              <div className="form-group">
                <label className="form-label">
                  Name
                </label>
                <div className="input-group input-group-merge">
                  <input type='text' name='name' className='form-control' placeholder='Enter your name' value={this.state.formValues.name} onChange={this.handleChange} required />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">
                  Email
                </label>
                <input type='email' name='email' className='form-control' placeholder='Enter your work email address' value={this.state.formValues.email} onChange={this.handleChange} required />
              </div>
              <div class="mb-3 font-size-sm mt-n2">
                By clicking 'Sign up' you agree with our <a href="https://datacater.io/assets/DataCater-TOS-20220316.pdf" target="_blank">terms of service</a> and <a href="https://datacater.io/privacy_policy/" target="_blank">privacy policy</a>
              </div>
              <button className='btn btn-lg w-100 btn-primary my-3' disabled={this.props.userSessions.signingUp} onClick={this.handleSignUp}>
                Sign up
              </button>
              <div className="mt-4">
                <a href="/sign_in">Sign in</a> using existing account
              </div>
              <div className="d-lg-none mt-4 text-center">
                <a href="mailto:support@datacater.io">Support</a>
                <a className="ml-4" href="https://datacater.io/imprint/">Imprint</a>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block pr-lg-0">
            <div className="bg-cover h-100 min-vh-100 mt-n1 me-n3 d-flex justify-content-center align-items-center" style={{"background-image": "url(/images/bg-overlay.jpg)"}}>
              <div className="row justify-content-center mr-lg-0">
                <div className="col-10 col-xl-8">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="font-weight-bold card-title mb-3" style={{"line-height":"125%"}}>Welcome to the real-time ETL platform for data and developer teams</h2>
                      <p className="card-text">
                        <ul className="list-unstyled mb-0">
                          <li className="d-flex align-items-center">
                            <div className="mr-4 text-primary">
                              <i className="fe fe-check" style={{"font-size": "130%"}}></i>
                            </div>
                            <p className="mb-0">
                              Get started in a few minutes
                            </p>
                          </li>
                          <li className="d-flex align-items-center mt-2">
                            <div className="mr-4 text-primary">
                              <i className="fe fe-check" style={{"font-size": "130%"}}></i>
                            </div>
                            <p className="mb-0">
                              Build two pipelines for free
                            </p>
                          </li>
                          <li className="d-flex align-items-center mt-2">
                            <div className="mr-4 text-primary">
                              <i className="fe fe-check" style={{"font-size": "130%"}}></i>
                            </div>
                            <p className="mb-0">
                              No credit card required
                            </p>
                          </li>
                          <li className="d-flex align-items-center mt-2">
                            <div className="mr-4 text-primary">
                              <i className="fe fe-check" style={{"font-size": "130%"}}></i>
                            </div>
                            <p className="mb-0">
                              Trial ends automatically
                            </p>
                          </li>
                          <li className="d-flex align-items-center mt-2">
                            <div className="mr-4 text-primary">
                              <i className="fe fe-check" style={{"font-size": "130%"}}></i>
                            </div>
                            <p className="mb-0">
                              Free support via our <a href="https://join.slack.com/t/datacater/shared_invite/zt-17cga6jg3-rGdgQZU6iX~mJGC8j~UNlw" target="_blank">community Slack</a>
                            </p>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center w-100 pb-3 d-none d-lg-block" style={{"position": "fixed", "bottom":"0"}}>
          <a className="text-white mr-4" href="mailto:support@datacater.io">Support</a>
          <a className="text-white" href="https://datacater.io/imprint/">Imprint</a>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    info:         state.info,
    userSessions: state.userSessions
  }
}

const mapDispatchToProps = {
  fetchInfo: fetchInfo,
  signUp:    signUp
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
