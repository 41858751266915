const dataSourceTypes = [
  {
    docs:  'aws-s3',
    validations: [
      {
        field:      'username',
        validation: 'presence'
      },
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'intervalSeconds',
        min:        60,
        validation: 'number'
      },
      {
        field:      'primaryKeyColumn',
        validation: 'presence'
      }
    ],
    label: 'AWS S3',
    value: 'aws-s3'
  },
  {
    docs:  'azuresql',
    validations: [
      {
        field:      'hostname',
        validation: 'presence'
      },
      {
        field:      'username',
        validation: 'presence'
      },
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'databaseName',
        validation: 'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'syncIntervalSeconds',
        min:        10,
        validation: 'number'
      }
    ],
    label: 'Azure SQL Database',
    value: 'azure-sql-database'
  },
  {
    docs:  'azureblob',
    validations: [
      {
        field:      'containerName',
        validation: 'presence'
      },
      {
        field:      'accountName',
        validation: 'presence'
      },
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      }
    ],
    label: 'Azure Blob Storage',
    value: 'azure-blob'
  },
  {
    docs:  'csv',
    label: 'CSV file',
    value: 'csv'
  },
  {
    docs:  'bigquery',
    validations: [
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'serviceAccountEmail',
        validation: 'presence'
      },
      {
        field:      'databaseName',
        validation: 'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'databaseSchemaName',
        validation: 'presence'
      },
      {
        field:      'syncIntervalMillis',
        min:        10000,
        validation: 'number'
      }
    ],
    label: 'Google Cloud BigQuery',
    value: 'google-bigquery'
  },
  {
    docs:  'google-cloud-storage',
    validations: [
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'intervalSeconds',
        min:        60,
        validation: 'number'
      },
      {
        field:      'primaryKeyColumn',
        validation: 'presence'
      }
    ],
    label: 'Google Cloud Storage',
    value: 'google-cloud-storage'
  },
  {
    docs:  'google-drive',
    validations: [
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'intervalSeconds',
        min:        60,
        validation: 'number'
      },
      {
        field:      'primaryKeyColumn',
        validation: 'presence'
      }
    ],
    label: 'Google Drive',
    value: 'google-drive'
  },
  {
    docs:  'json',
    label: 'JSON file',
    value: 'json'
  },
  {
    docs:  'mysql',
    validations: [
      {
        field:      'hostname',
        validation: 'presence'
      },
      {
        field:      'port',
        min:        1,
        validation: 'number'
      },
      {
        field:      'username',
        validation: 'presence'
      },
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'databaseName',
        validation: 'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'syncIntervalSeconds',
        min:        1,
        validateIf: {
          fieldName:   'accessMethod',
          fieldValues: ['jdbc'],
        },
        validation: 'number'
      }
    ],
    label: 'MySQL',
    value: 'mysql'
  },
  {
    docs:  'postgresql',
    validations: [
      {
        field:      'hostname',
        validation: 'presence'
      },
      {
        field:      'port',
        min:        1,
        validation: 'number'
      },
      {
        field:      'username',
        validation: 'presence'
      },
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'databaseName',
        validation: 'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'databaseSchemaName',
        validation: 'presence'
      }
    ],
    label: 'PostgreSQL',
    value: 'postgresql'
  },
  {
    docs:  'rest',
    validations: [
      {
        field:      'uri',
        validation: 'presence'
      },
      {
        field:      'recordKeyName',
        validation: 'presence'
      },
      {
        field:      'uri',
        format:     '^(http|https):\\/\\/',
        validation: 'format'
      },
      {
        field:      'tokenExchangeUri',
        format:     '^(http|https):\\/\\/',
        validation: 'format'
      },
      {
        field:         'intervalMillis',
        min:           5000,
        validateNotIf: {
          fieldName:   'syncIntervalMode',
          fieldValues: ['cron'],
        },
        validation:    'number'
      },
      {
        field:      'cronExpression',
        format:     '^[\\d\\s,-@"\\*"\\/]+$',
        validateIf: {
          fieldName:   'syncIntervalMode',
          fieldValues: ['cron'],
        },
        validation: 'format'
      },
      {
        field:      'requestTimeout',
        max:        '600',
        validation: 'number'
      },
    ],
    label: 'REST endpoint',
    value: 'rest'
  },
  {
    docs:  'rss',
    validations: [
      {
        field:      'uri',
        validation: 'presence'
      },
      {
        field:      'uri',
        format:     '^(http|https):\\/\\/',
        validation: 'format'
      },
      {
        field:      'intervalSeconds',
        min:        60,
        validation: 'number'
      },
    ],
    label: 'RSS',
    value: 'rss'
  },
  {
    docs:  'ftp',
    label: 'FTP',
    validations: [
      {
        field:      'hostname',
        validation: 'presence'
      },
      {
        field:      'port',
        min:        1,
        validation: 'number'
      },
      {
        field:      'username',
        validation: 'presence'
      },
      {
        field:        'password',
        validateOnly: 'create',
        validation:   'presence'
      },
      {
        field:      'recordKeyName',
        validation: 'presence'
      },
      {
        field:      'intervalSeconds',
        min:        10,
        validateIf: {
          fieldName:   'syncIntervalMode',
          fieldValues: [undefined, '', 'seconds'],
        },
        validation: 'number'
      }
    ],
    value: 'sftp'
  },
  {
    docs:  'typeform',
    validations: [
      {
        field:      'password',
        only:       'create',
        validation: 'presence'
      },
      {
        field:      'bucketName',
        validation: 'presence'
      },
      {
        field:      'intervalMillis',
        min:        10000,
        validation: 'number'
      },
      {
        field:      'catchupIntervalMillis',
        min:        5000,
        validation: 'number'
      },
    ],
    label: 'Typeform',
    value: 'typeform'
  },
  {
    docs:  'xml',
    label: 'XML file',
    value: 'xml'
  }
];

export default dataSourceTypes;
