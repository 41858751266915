import React, { Component }                   from 'react';
import { connect }                            from 'react-redux';
import { Redirect }                           from 'react-router-dom';
import MainContent                            from '../../components/layout/MainContent';
import ProjectMembershipAdmin                 from '../../components/projects/ProjectMembershipAdmin';
import { deepCopy }                           from '../../helpers/deepCopy';
import { isValidEmailAddress }                from '../../helpers/isValidEmailAddress';
import { addProject }                         from '../../actions/projects';
import { fetchCurrentUser }                   from '../../actions/users';
import { replaceProjectMembershipsOfProject } from '../../actions/project_memberships';

class NewProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSourceCreated: false,
      errorMessage:      '',
      newMemberEmail:    '',
      newMemberRole:     '',
      project: {
        name:        '',
        description: ''
      },
      projectMemberships: []
    };

    this.handleAddMember            = this.handleAddMember.bind(this);
    this.handleChange               = this.handleChange.bind(this);
    this.handleChangeNewMember      = this.handleChangeNewMember.bind(this);
    this.handleChangeExistingMember = this.handleChangeExistingMember.bind(this);
    this.handleSubmit               = this.handleSubmit.bind(this);
    this.removeExistingMember       = this.removeExistingMember.bind(this);
  }

  componentDidMount() {
    // add current user as default administrator of new group
    this.props.fetchCurrentUser()
      .then(() => {
        this.setState({
          projectMemberships: [
            {
              userEmail:      this.props.users.currentUser.email,
              membershipRole: 'administrator'
            }
          ]
        });
      });
  }

  handleAddMember(event) {
    event.preventDefault();
    const existingMemberEmails = this.state.projectMemberships
      .map(membership => membership.userEmail);

    if (
      // Ensure that email is not empty
      ![undefined, ''].includes(this.state.newMemberEmail) &&
      // Verify that no existing membership uses this email
      !existingMemberEmails.includes(this.state.newMemberEmail) &&
      // Verify correctness of provided email
      isValidEmailAddress(this.state.newMemberEmail) &&
      // Verify correctness of provided role
      ['viewer', 'editor', 'administrator'].includes(this.state.newMemberRole)
    ) {
      let projectMemberships = this.state.projectMemberships;
      // add member to list
      projectMemberships.push({
        userEmail:       this.state.newMemberEmail,
        membershipRole:  this.state.newMemberRole
      });
      this.setState({
        newMemberEmail:     '',
        newMemberRole:      '',
        projectMemberships: projectMemberships
      });
    }
  }

  handleChange(event) {
    let project = this.state.project;

    if (event.target.type === 'number') {
      project[event.target.name] = parseInt(event.target.value);
    } else {
      project[event.target.name] = event.target.value;
    }

    this.setState({ project: project });
  }

  handleChangeNewMember(event) {
    if (event.target.name === 'userEmail') {
      this.setState({ newMemberEmail: event.target.value });
    } else if (event.target.name === 'membershipRole') {
      this.setState({ newMemberRole: event.target.value });
    }
  }

  handleChangeExistingMember(event) {
    const newRole = event.target.value;
    const userEmail = event.target.dataset.userEmail;

    let projectMemberships = this.state.projectMemberships;
    const membershipIndex = deepCopy(projectMemberships)
      .findIndex(membership => membership.userEmail === userEmail);

    if (membershipIndex !== -1) {
      projectMemberships[membershipIndex].membershipRole = newRole;
    }

    this.setState({ projectMemberships: projectMemberships });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.project.name.length === 0) {
      this.setState({
        errorMessage: 'Please provide a name.'
      });
    } else {
      this.props.addProject(this.state.project).then((response) => {
        if (response.errorMessage !== undefined) {
          this.setState({
            errorMessage: response.errorMessage
          });
        } else {
          this.props.replaceProjectMembershipsOfProject(
            this.props.projects.project.id,
            this.state.projectMemberships
          ).then(() =>
            this.setState({
              errorMessage:       '',
              projectMemberships: this.props.projectMemberships.projectMemberships,
              projectCreated:     true
            })
          );
        }
      });
    }
  }

  removeExistingMember(userEmail) {
    let projectMemberships = deepCopy(this.state.projectMemberships)
      .filter(membership => membership.userEmail !== userEmail);

    this.setState({ projectMemberships: projectMemberships });
  }

  render() {
    if (this.state.projectCreated) {
      const project = this.props.projects.project;

      if (project !== undefined) {
        return (<Redirect to={'/projects/' + project.id} />);
      } else {
        return (<Redirect to='/projects/' />);
      }
    }

    const project = this.state.project;

    return (
      <MainContent preTitle='Projects' title='Create project' buttonLabel='Go back to projects' buttonLink='/projects'>
        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h4 className='card-header-title'>
                  New project
                </h4>
              </div>
            </div>
          </div>
          <div className='card-body'>
            {project !== undefined &&
              <React.Fragment>
                {this.state.errorMessage.length > 0 &&
                  <div className='alert alert-danger mb-4 rounded-0'>
                  {this.state.errorMessage}
                  </div>
                }
                <div className='row'>
                  <div className='col-12'>
                    <form>
                      <div className='form-group'>
                        <label htmlFor='name'>Name:</label>
                        <input
                          type='text'
                          className='form-control'
                          name='name'
                          onChange={this.handleChange}
                          value={project.name || ''} />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='description'>Description:</label>
                        <input
                          type='text'
                          className='form-control'
                          name='description'
                          onChange={this.handleChange}
                          value={project.description || ''} />
                      </div>
                      <hr className='my-4' />
                      <ProjectMembershipAdmin
                        handleAddMemberFunc={this.handleAddMember}
                        handleChangeExistingMemberFunc={this.handleChangeExistingMember}
                        handleChangeNewMemberFunc={this.handleChangeNewMember}
                        newMemberEmail={this.state.newMemberEmail}
                        newMemberRole={this.state.newMemberRole}
                        projectMemberships={this.state.projectMemberships}
                        removeExistingMemberFunc={this.removeExistingMember} />
                      <button
                        className='btn btn-primary'
                        onClick={this.handleSubmit}
                        type='submit'>
                        Save changes
                      </button>
                    </form>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    projects:           state.projects,
    projectMemberships: state.projectMemberships,
    users:              state.users
  }
}

const mapDispatchToProps = {
  addProject:                         addProject,
  fetchCurrentUser:                   fetchCurrentUser,
  replaceProjectMembershipsOfProject: replaceProjectMembershipsOfProject
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProject);
