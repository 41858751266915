const axios = require('axios');

export function resetFileUpload() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_FILE_UPLOAD'
    });
  };
}

export function resetFlatFileSampleRecords() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_FLAT_FILE_SAMPLE_RECORDS'
    });
  };
}

export function uploadFile(file, fileType, projectId) {
  const requestFileUpload = () => ({
    type: 'REQUEST_FILE_UPLOAD'
  });

  const receiveSuccessfulFileUpload = (file) => ({
    flatFile: file,
    type:     'RECEIVE_SUCCESSFUL_FILE_UPLOAD'
  });

  const receiveFailedFileUpload = () => ({
    type: 'RECEIVE_FAILED_FILE_UPLOAD'
  });

  const updateFileUploadProgress = (percentage) => ({
    percentage: percentage,
    type:       'UPDATE_FILE_UPLOAD_PROGRESS'
  });

  return function (dispatch) {
    dispatch(requestFileUpload);

    const formData = new FormData();
    formData.append('flat_file', file);

    const url = (projectId === undefined)
      ? `/api/flat_files?fileType=${fileType}`
      : `/api/flat_files?fileType=${fileType}&projectId=${projectId}`;


    return axios.request({
      cache: 'no-cache',
      credentials: 'same-origin',
      data: formData,
      headers: {
        'X-Auth-token': localStorage.getItem('userToken')
      },
      method: 'post',
      mode: 'cors',
      onUploadProgress: (progress) => {
        const percentage = (!isNaN(progress.loaded) && !isNaN(progress.total) && progress.total >= 0)
          ? parseFloat((progress.loaded / progress.total) * 100).toFixed(2)
          : undefined;
        dispatch(updateFileUploadProgress(percentage));
      },
      url: url
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('userToken');
        window.location = '/sign_in';
      } else if (response.status === 201) {
        return response.data;
      } else {
        dispatch(receiveFailedFileUpload());
      }
    }).then((json) => {
      dispatch(receiveSuccessfulFileUpload(json));
    });
  };
}

export function ingestFileIntoPipeline(pipelineId, file) {
  const requestFileUpload = () => ({
    type: 'REQUEST_FILE_UPLOAD'
  });

  const receiveSuccessfulFileUpload = (file) => ({
    flatFile: file,
    type:     'RECEIVE_SUCCESSFUL_FILE_UPLOAD'
  });

  const receiveFailedFileUpload = () => ({
    type: 'RECEIVE_FAILED_FILE_UPLOAD'
  });

  const updateFileUploadProgress = (percentage) => ({
    percentage: percentage,
    type:       'UPDATE_FILE_UPLOAD_PROGRESS'
  });

  return function (dispatch) {
    dispatch(requestFileUpload);

    const formData = new FormData();
    formData.append('flat_file', file);

    return axios.request({
      cache: 'no-cache',
      credentials: 'same-origin',
      data: formData,
      headers: {
        'X-Auth-token': localStorage.getItem('userToken')
      },
      method: 'post',
      mode: 'cors',
      onUploadProgress: (progress) => {
        const percentage = (!isNaN(progress.loaded) && !isNaN(progress.total) && progress.total >= 0)
          ? parseFloat((progress.loaded / progress.total) * 100).toFixed(2)
          : undefined;
        dispatch(updateFileUploadProgress(percentage));
      },
      url: '/api/pipelines/' + pipelineId + '/ingest'
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('userToken');
        window.location = '/sign_in';
      } else if (response.status === 201) {
        return response.data;
      } else {
        dispatch(receiveFailedFileUpload());
      }
    }).then((json) => {
      dispatch(receiveSuccessfulFileUpload(json));
    });
  };
}

export function getSampleRecordsOfFlatFile(flatFile, dataSourceProfileId) {
  const requestFileSampleRecords = () => ({
    type: 'REQUEST_FILE_SAMPLE_RECORDS'
  });

  const receiveFileSampleRecords = (sampleRecords) => ({
    sampleRecords: sampleRecords,
    type:          'RECEIVE_FILE_SAMPLE_RECORDS'
  });

  return function (dispatch) {
    dispatch(requestFileSampleRecords);

    let requestUri = '/api/flat_files/' + flatFile.id + '/sample_records';
    if (dataSourceProfileId !== undefined) {
      requestUri += '?dataSourceProfileId=' + dataSourceProfileId;
    }

    return fetch(
      requestUri,
      {
        headers: {
          'X-Auth-token': localStorage.getItem('userToken')
        }
      }
    ).then(
      response => {
        if (response.status === 401) {
          localStorage.removeItem('userToken');
          window.location = '/sign_in';
        } else {
          return response.json();
        }
      }
    ).then((json) => dispatch(receiveFileSampleRecords(json)));
  };
}

export function fetchFlatFileOfDataSource(id) {
  const requestFlatFile = () => ({
    type: 'REQUEST_FLAT_FILE'
  });

  const receivedFlatFile = response => ({
    flatFile: response,
    type:     'RECEIVE_FLAT_FILE'
  });

  return function (dispatch) {
    dispatch(requestFlatFile());

    return fetch('/api/data_sources/' + id + '/flat_file',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedFlatFile(json));
      });
  };
}
