import React, { Component } from 'react';
import RetrieveTables       from '../RetrieveTables';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class MySqlConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSink,
      dataSinks,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveTablesFunc
    } = this.props;

    const flatTables = dataSinks.tables
      .map(table => table.name)
      .sort();

    const retrievingTablesIsPossible =
      ![undefined, ''].includes(dataSink.hostname) &&
      ![undefined, ''].includes(dataSink.port) &&
      ![undefined, ''].includes(dataSink.username) &&
      ![undefined, ''].includes(dataSink.password) &&
      ![undefined, ''].includes(dataSink.databaseName);

    const serverTimezone = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.serverTimezone !== undefined)
      ? dataSink.connectorConfig.serverTimezone
      : '';

    const sslMode = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.sslMode !== undefined)
      ? dataSink.connectorConfig.sslMode
      : 'no-ssl';

    const insertMode = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.insertMode !== undefined)
      ? dataSink.connectorConfig.insertMode
      : 'upsert';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the password unless you want to change it.</span>)
      : (<span className='text-danger'>*</span>);

    const kafkaConnectConfig = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSink.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item' key={tab.name}>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Hostname or IP'
              name='hostname'
              onChangeFunc={handleChangeFunc}
              value={dataSink.hostname} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Port'
              name='port'
              onChangeFunc={handleChangeFunc}
              value={dataSink.port} />
            <div className='form-group'>
              <label>SSL</label>
              <select
                className='custom-select'
                name='sslMode'
                onChange={handleConfigChangeFunc}
                value={sslMode}>
                <option value='no-ssl'>Do not use SSL</option>
                <option value='use-ssl-no-verification'>Use SSL</option>
              </select>
            </div>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Username'
              name='username'
              onChangeFunc={handleChangeFunc}
              value={dataSink.username} />
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment>Password{editPasswordNotice}</React.Fragment>)}
              name='password'
              onChangeFunc={handleChangeFunc}
              type='password'
              value={dataSink.password} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Server timezone'
              name='serverTimezone'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='UTC'
              value={serverTimezone} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Database name'
              name='databaseName'
              onChangeFunc={handleChangeFunc}
              value={dataSink.databaseName} />
            <RetrieveTables
              dataSink={dataSink}
              dataSinks={dataSinks}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the table names from the MySQL database failed. Please check your connection credentials.'
              handleChangeFunc={handleChangeFunc}
              missingOptionsErrorMessage='Please provide the hostname, the port, the username, the password, and the name of the MySQL database.'
              resetRetrieveTablesFunc={resetRetrieveTablesFunc}
              retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSinkFunc}
              retrievingTablesIsPossible={retrievingTablesIsPossible}
              tableAttributeName='bucketName'
              tables={flatTables} />
            <div className='form-group'>
              <label>Insert mode</label>
              <select
                className='custom-select'
                name='insertMode'
                onChange={handleConfigChangeFunc}
                value={insertMode}>
                <option value='insert'>Insert</option>
                <option value='upsert'>Upsert</option>
              </select>
            </div>
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSinks={dataSinks}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default MySqlConfig;
