import React, { Component }        from 'react';
import { connect }                 from 'react-redux';
import { Redirect }                from 'react-router-dom';
import { Book }                    from 'react-feather';
import MainContent                 from '../../components/layout/MainContent';
import ApacheSolrConfig            from '../../components/data_sinks/configs/ApacheSolrConfig';
import AxSemanticsConfig           from '../../components/data_sinks/configs/AxSemanticsConfig';
import BigQueryConfig              from '../../components/data_sinks/configs/BigQueryConfig';
import ElasticsearchConfig         from '../../components/data_sinks/configs/ElasticsearchConfig';
import HubSpotConfig               from '../../components/data_sinks/configs/HubSpotConfig';
import MySqlConfig                 from '../../components/data_sinks/configs/MySqlConfig';
import PostgreSqlConfig            from '../../components/data_sinks/configs/PostgreSqlConfig';
import PxaProductManagerConfig     from '../../components/data_sinks/configs/PxaProductManagerConfig';
import RedshiftConfig              from '../../components/data_sinks/configs/RedshiftConfig';
import RestConfig                  from '../../components/data_sinks/configs/RestConfig';
import SnowflakeConfig             from '../../components/data_sinks/configs/SnowflakeConfig';
import DataSinkLogo                from '../../components/data_sinks/DataSinkLogo';
import ConfigForm                  from '../../components/data_stores/ConfigForm';
import dataSinkTypes               from '../../data_stores/data_sink_types';
import { deepCopy }                from '../../helpers/deepCopy';
import { validateConnectorConfig } from '../../helpers/validateConnectorConfig';
import {
  fetchDataSink,
  resetRetrieveTables,
  resetTestConnection,
  retrieveTablesFromDataSink,
  testConnection,
  updateDataSink
} from '../../actions/data_sinks';
import { fetchCurrentUser } from '../../actions/users';

class EditDataSink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatingDataSinkFailed: false,
      dataSinkUpdated:        false,
      errorMessages:          {}
    };

    this.getDatabaseNameLabel = this.getDatabaseNameLabel.bind(this);
    this.getBucketNameLabel   = this.getBucketNameLabel.bind(this);
    this.showBucketNameField  = this.showBucketNameField.bind(this);
    this.handleUpdateDataSink = this.handleUpdateDataSink.bind(this);
    this.handleChange         = this.handleChange.bind(this);
    this.handleConfigChange   = this.handleConfigChange.bind(this);
    this.handleTestConnection = this.handleTestConnection.bind(this);
  }

  componentDidMount() {
    this.props.fetchCurrentUser();
    this.props.fetchDataSink(this.getDataSinkId())
      .then(() => {
        let dataSink = this.props.dataSinks.dataSink;

        if (dataSink.sinkType === 'google-bigquery') {
          if (dataSink.connectorConfig === undefined) {
            dataSink.connectorConfig = {};
          }

          if (dataSink.connectorConfig.partitioningMethod === undefined) {
            dataSink.connectorConfig.partitioningMethod = 'ingestion-time-daily';
          }
        }

        this.setState({ dataSink: dataSink })
      });
  }

  getDatabaseNameLabel() {
    if (this.state.dataSink.sinkType === 'elasticsearch') {
      return 'Index name';
    } else if (this.state.dataSink.sinkType === 'solr') {
      return 'Core name';
    } else {
      return 'Database name';
    }
  }

  getBucketNameLabel() {
    if (this.props.dataSinks.dataSink.sinkType === 'mongodb') {
      return 'Collection name';
    } else {
      return 'Table name';
    }
  }

  showBucketNameField() {
    return !['solr', 'elasticsearch'].includes(this.props.dataSinks.dataSink.sinkType);
  }

  getDataSinkId() {
    return parseInt(this.props.match.params.id);
  }

  handleUpdateDataSink(event) {
    event.preventDefault();
    const dataSink = this.state.dataSink;

    const selectedSinkType = dataSinkTypes
      .find(sinkType => sinkType.value === dataSink.sinkType);
    const validations = (selectedSinkType !== undefined)
      ? (selectedSinkType.validations || []).filter(validation => [undefined, 'edit'].includes(validation.validateOnly))
      : [];
    const errorMessages = validateConnectorConfig(
      dataSink,
      validations
    );

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        updatingDataSinkFailed: true,
        errorMessages:          errorMessages
      });
      window.scrollTo(0, 0);
    } else {
      this.props.updateDataSink(dataSink)
        .then(() => this.setState({ dataSinkUpdated: true }));
    }
  }

  handleChange(event) {
    let dataSink = this.state.dataSink;
    let errorMessages = this.state.errorMessages;

    dataSink[event.target.name] = (event.target.type === 'checkbox')
      ? event.target.checked
      : event.target.value;

    // reset error message
    errorMessages[event.target.name] = undefined;

    if (
      dataSink.sinkType === 'google-bigquery' &&
      event.target.name === 'password'
    ) {
      try {
        // try to parse project name from credentials
        const credentials = JSON.parse(event.target.value);
        if (credentials !== undefined && credentials.project_id !== undefined) {
          dataSink.databaseName = credentials.project_id;
        }
      } catch (e) {
        // do not change the project id if parsing the credentials failed
      }
    }

    // For Google BigQuery:
    // Try to automatically fill in partitioningMethod and partitioningColumn
    // if the table name has been changed
    if (
      dataSink.sinkType === 'google-bigquery' &&
      event.target.name === 'databaseSchemaName'
    ) {
      const tableName = event.target.value;
      const table = this.props.dataSinks.tables.find(table => table.name === tableName);

      if (table != null) {
        if (dataSink.connectorConfig === undefined) {
          dataSink.connectorConfig = {};
        }

        dataSink.connectorConfig.partitioningMethod = table.partitioningMethod;

        if (dataSink.connectorConfig.partitioningMethod === 'timestamp-column') {
          dataSink.connectorConfig.partitioningColumn = table.partitioningColumn;
        }
      }
    }

    this.props.resetTestConnection();

    // find currently selected sink type
    const selectedSinkType = dataSinkTypes
      .find(sinkType => sinkType.value === dataSink.sinkType);

    // validate and fill error message
    if ((selectedSinkType !== undefined) && (selectedSinkType.validations !== undefined)) {
      const relevantValidations = selectedSinkType.validations
        .filter(validation => [undefined, 'edit'].includes(validation.validateOnly))
        .filter(validation => validation.field === event.target.name);
      const validationResult = validateConnectorConfig(
        dataSink,
        relevantValidations
      );
      errorMessages[event.target.name] = validationResult[event.target.name];
    }

    this.setState({
      updatingDataSinkFailed: false,
      errorMessages:          errorMessages,
      dataSink:               dataSink
    });
  }

  handleConfigChange(event) {
    let dataSink = this.state.dataSink;
    let errorMessages = this.state.errorMessages;

    if (dataSink.connectorConfig === undefined) {
      dataSink.connectorConfig = {};
    }

    dataSink.connectorConfig[event.target.name] = (event.target.type === 'checkbox')
      ? '' + event.target.checked
      : event.target.value;

    // reset error message
    errorMessages[event.target.name] = undefined;

    this.props.resetTestConnection();

    // find currently selected sink type
    const selectedSinkType = dataSinkTypes
      .find(sinkType => sinkType.value === dataSink.sinkType);

    // validate and fill error message
    if ((selectedSinkType !== undefined) && (selectedSinkType.validations !== undefined)) {
      const relevantValidations = selectedSinkType.validations
        .filter(validation => [undefined, 'edit'].includes(validation.validateOnly))
        .filter(validation => validation.field === event.target.name);
      const validationResult = validateConnectorConfig(
        dataSink,
        relevantValidations
      );
      errorMessages[event.target.name] = validationResult[event.target.name];
    }

    this.setState({
      updatingDataSinkFailed: false,
      dataSink:               dataSink,
      errorMessages:          errorMessages
    });
  }

  handleTestConnection() {
    // the backend always requires a data sink name, although it is not relevant
    // in this case. TODO: fix this in the future, such that we can test a connection
    // without requiring the user to enter a data sink name
    this.props.testConnection(
      Object.assign(deepCopy(this.state.dataSink), { name: 'viktoria' }));
  }

  render() {
    const dataSinkUri = '/data_sinks/' + this.getDataSinkId();
    if (this.state.dataSinkUpdated) {
      let redirectTo = dataSinkUri;
      if (this.state.dataSink.connectionType === 'transit-agent') {
        redirectTo += '/connection';
      }
      return (<Redirect to={redirectTo} />);
    }
    const dataSink = this.state.dataSink;
    if (dataSink === undefined) {
      return (<div></div>);
    }

    const sinkTypes = deepCopy(dataSinkTypes);
    const selectedSinkType = sinkTypes.find(sinkType => sinkType.value === dataSink.sinkType);

    const currentUser = this.props.users.currentUser;
    const userEmail =
      (
        ![currentUser, dataSink].includes(undefined) &&
        (dataSink.userId === currentUser.id)
      )
        ? currentUser.email
        : undefined;

    const project =
      (
        ![currentUser, dataSink].includes(undefined) &&
        (dataSink.userId === undefined) &&
        (dataSink.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === dataSink.projectId)
        : undefined;

    const projectMembership = (project !== undefined)
      ? currentUser.projectMemberships.find(_ => _.projectId === project.id)
      : undefined;

    const canEditDataSink = (
      (projectMembership === undefined) ||
      ['editor', 'administrator'].includes(projectMembership.membershipRole)
    );

    if (!canEditDataSink) {
      return (<div></div>);
    }

    return (
      <MainContent preTitle='Data Sinks' title='Edit data sink' buttonLabel='Go back to data sink' buttonLink={dataSinkUri}>
        <form>
          <div className='card'>
            <div className='card-header'>
              <div className='row align-items-center'>
                <div className='col-auto sink-type-logos'>
                  <div className='logo-wrap'>
                    <DataSinkLogo dataSink={dataSink} />
                  </div>
                </div>
                <div className='col'>
                  <h4 className='card-header-title'>
                    {userEmail !== undefined &&
                      <React.Fragment>
                        <span className='text-gray'>
                          {userEmail}
                        </span>
                        <span className='text-gray mx-2'>
                          /
                        </span>
                      </React.Fragment>
                    }
                    {project !== undefined &&
                      <React.Fragment>
                        <a href={`/projects/${project.id}`} className='text-gray'>
                          {project.name}
                        </a>
                        <span className='text-gray mx-2'>
                          /
                        </span>
                      </React.Fragment>
                    }
                    {dataSink.name}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {dataSink.sinkType !== undefined &&
            <React.Fragment>
              {this.state.updatingDataSinkFailed &&
                <div className='alert alert-danger fade show rounded-0' role='alert'>
                  The data sink configuration is invalid, please check the form fields.
                </div>
              }
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>General settings</h4>
                </div>
                <div className='card-body border-bottom'>
                  <ConfigForm
                    errorMessages={this.state.errorMessages}
                    isRequired={true}
                    label='Name'
                    name='name'
                    onChangeFunc={this.handleChange}
                    value={dataSink.name} />
                  <div className='mt-4 mb-2'>
                    <div className='custom-control custom-switch d-flex align-items-center'>
                      <input
                        checked={dataSink.autoRestartFailedConnector}
                        className='custom-control-input clickable'
                        id='customSwitch1'
                        name='autoRestartFailedConnector'
                        onChange={this.handleChange}
                        type='checkbox' />
                      <label className='custom-control-label clickable' htmlFor='customSwitch1'>
                        Automatically restart failed connector
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      {selectedSinkType !== undefined &&
                        <h4 className='card-header-title'>{selectedSinkType.label}-specific settings</h4>
                      }
                      {selectedSinkType === undefined &&
                        <h4 className='card-header-title'>Connector-specific settings</h4>
                      }
                    </div>
                    {(selectedSinkType !== undefined) && (selectedSinkType.docs !== undefined) &&
                      <div className='col-auto'>
                        <a href={`https://datacater.io/docs/sink_connectors/${selectedSinkType.docs}/`} target='_blank' className='btn btn-white' rel='noopener noreferrer'>
                          <Book className='feather-icon mr-1' />
                          Documentation
                        </a>
                      </div>
                    }
                  </div>
                </div>
                <div className='card-body'>
                  {dataSink.sinkType === 'redshift' &&
                    <RedshiftConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'solr' &&
                    <ApacheSolrConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSink.sinkType === 'ax-semantics' &&
                    <AxSemanticsConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange} />
                  }
                  {dataSink.sinkType === 'elasticsearch' &&
                    <ElasticsearchConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSink.sinkType === 'google-bigquery' &&
                    <BigQueryConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'hubspot' &&
                    <HubSpotConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSink.sinkType === 'mysql' &&
                    <MySqlConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'postgresql' &&
                    <PostgreSqlConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'rest' &&
                    <RestConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange} />
                  }
                  {dataSink.sinkType === 'snowflake' &&
                    <SnowflakeConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSinkFunc={this.props.retrieveTablesFromDataSink} />
                  }
                  {dataSink.sinkType === 'typo3-pxa-product-manager' &&
                    <PxaProductManagerConfig
                      dataSink={dataSink}
                      dataSinks={this.props.dataSinks}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange} />
                  }
                </div>
              </div>
              <div className='card'>
                <div className='card-body'>
                  {!this.props.dataSinks.updatingDataSink &&
                    <button
                      className='btn btn-primary'
                      onClick={this.handleUpdateDataSink}
                      type='submit'>
                      Update data sink
                    </button>
                  }
                  {this.props.dataSinks.updatingDataSink &&
                    <button
                      className='btn btn-primary'
                      disabled={true}
                      type='submit'>
                      <span className='spinner-border mr-2' role='status'>
                      </span>
                      Updating...
                    </button>
                  }
                </div>
              </div>
            </React.Fragment>
          }
        </form>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    dataSinks: state.dataSinks,
    users:     state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser:           fetchCurrentUser,
  fetchDataSink:              fetchDataSink,
  retrieveTablesFromDataSink: retrieveTablesFromDataSink,
  resetRetrieveTables:        resetRetrieveTables,
  resetTestConnection:        resetTestConnection,
  testConnection:             testConnection,
  updateDataSink:             updateDataSink
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDataSink);
