import React, { Component }    from 'react';
import { Check, Key, XCircle } from 'react-feather';
import DataSourceLogo          from '../../../../data_sources/DataSourceLogo';

class ManageJoinedDataSourceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resettingJoinedDataSource: false
    };

    this.resetJoinedDataSource = this.resetJoinedDataSource.bind(this);
    this.getConfigurationErrors = this.getConfigurationErrors.bind(this);
  }

  resetJoinedDataSource(event) {
    this.setState({ resettingJoinedDataSource: true });

    this.props.resetJoinedDataSourceFunc(event)
      .then(() => this.setState({ resettingJoinedDataSource: false }));
  }

  getConfigurationErrors() {
    let errors = [];

    const dataSource = this.props.dataSource;
    const joinConfig = this.props.pipeline.joinConfig;

    if (
      (joinConfig === undefined) ||
      ([undefined, ''].includes(joinConfig.dataSourceJoinAttributeId))
    ) {
      errors = [
        'Please choose the attribute from the data source #' +
        dataSource.id +
        ': ' +
        dataSource.name +
        '.'
      ];
    }

    return errors;
  }

  render() {
    const {
      dataSource,
      dataSourceProfiles,
      handleJoinConfigChangeFunc,
      joinedDataSource,
      pipeline
    } = this.props;

    const dataSourceProfile = dataSourceProfiles.dataSourceProfile;
    const joinedDataSourceProfile = dataSourceProfiles.joinedDataSourceProfile;

    const compareAttributes = (a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    };

    // restrict joins to double, float, integer, long and string
    const dataTypesAllowedInJoin = ['double', 'float', 'int', 'long', 'string'];
    // hide attributes created by DataCater for flat file sources
    const internalAttributes = ['__datacater_auto_id', '__datacater_file_id'];

    const dataSourceProfileAttributes = dataSourceProfile
      .attributes
      .filter(attribute => dataTypesAllowedInJoin.includes(attribute.dataType))
      .filter(attribute => !internalAttributes.includes(attribute.name))
      .sort(compareAttributes);

    const joinConfig = pipeline.joinConfig || {};

    const dataSourceJoinAttributeId = (joinConfig.dataSourceJoinAttributeId !== undefined)
      ? joinConfig.dataSourceJoinAttributeId
      : '';

    const joinedDataSourceJoinAttributeId = (joinConfig.joinedDataSourceJoinAttributeId !== undefined)
      ? joinConfig.joinedDataSourceJoinAttributeId
      : undefined;

    const joinedDataSourceJoinAttribute = joinedDataSourceProfile
      .attributes
      .filter(attribute => dataTypesAllowedInJoin.includes(attribute.dataType))
      .find(attribute => parseInt(attribute.id) === parseInt(joinedDataSourceJoinAttributeId));

    const leftOuterJoinClassNames = ([undefined, '', 'left-outer-join'].includes(joinConfig.joinOperator))
      ? 'col p-4 border-right clickable datacater-pipeline-designer-active-option'
      : 'col p-4 border-right hoverable clickable';

    const innerJoinClassNames = (joinConfig.joinOperator === 'inner-join')
      ? 'col p-4 clickable datacater-pipeline-designer-active-option'
      : 'col p-4 hoverable clickable';

    const configurationErrors = this.getConfigurationErrors();

    let dataSourceAttributeClassNames = 'custom-select mt-2';

    if ([undefined, ''].includes(joinConfig.dataSourceJoinAttributeId)) {
      dataSourceAttributeClassNames += ' is-invalid';
    }

    return (
      <React.Fragment>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-header-title'>Manage joined data source</h4>
          </div>
          <div className='card-body'>
            <div className='row align-items-center'>
              <div className='col-auto sink-type-logos pl-0'>
                <div className='logo-wrap'>
                  <DataSourceLogo dataSource={joinedDataSource} />
                </div>
              </div>
              <div className='col'>
                <h4 className='mb-0'>
                  #{joinedDataSource.id}: {joinedDataSource.name}
                </h4>
              </div>
              <div className='col-auto'>
                {this.state.resettingJoinedDataSource !== true &&
                  <a
                    href='/'
                    className='btn btn-white btn-sm'
                    onClick={this.resetJoinedDataSource}>
                    Reset join operator
                  </a>
                }
                {this.state.resettingJoinedDataSource === true &&
                  <button
                    className='btn btn-white btn-sm d-flex align-items-center'
                    disabled={true}>
                    <span className='spinner-border mr-2' role='status'>
                    </span>
                    Resetting join operator...
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-body p-0'>
            <div className='row m-0'>
              <div
                className={leftOuterJoinClassNames}
                onClick={(event) => handleJoinConfigChangeFunc('joinOperator', 'left-outer-join')}>
                <div className='row align-items-center'>
                  {!leftOuterJoinClassNames.includes('active') &&
                    <div className='col-auto'>
                    </div>
                  }
                  {leftOuterJoinClassNames.includes('active') &&
                    <div className='col-auto'>
                      <div className='avatar avatar-sm'>
                        <div className='avatar-title font-size-lg bg-primary-soft rounded-circle text-primary'>
                          <Check className='feather-icon' />
                        </div>
                      </div>
                    </div>
                  }
									<div className='col'>
										<h4>LEFT OUTER JOIN</h4>
                    <p className='mb-0 text-muted'>
											The LEFT OUTER JOIN joins the records of <strong>#{dataSource.id}: {dataSource.name}</strong> with
											all matching records from <strong>#{joinedDataSource.id}: {joinedDataSource.name}</strong> and publishes the results
											to the pipeline. If no matching record can be found, the join operator sets all attributes of <strong>#{joinedDataSource.id}: {joinedDataSource.name}</strong> to NULL.
										</p>
                  </div>
                </div>
              </div>
              <div
                className={innerJoinClassNames}
                onClick={(event) => handleJoinConfigChangeFunc('joinOperator', 'inner-join')}>
                <div className='row align-items-center'>
                  {!innerJoinClassNames.includes('active') &&
                    <div className='col-auto'>
                    </div>
                  }
                  {innerJoinClassNames.includes('active') &&
                    <div className='col-auto'>
                      <div className='avatar avatar-sm'>
                        <div className='avatar-title font-size-lg bg-primary-soft rounded-circle text-primary'>
                          <Check className='feather-icon' />
                        </div>
                      </div>
                    </div>
                  }
									<div className='col'>
										<h4>INNER JOIN</h4>
                    <p className='mb-0 text-muted'>
											The INNER JOIN joins the records of <strong>#{dataSource.id}: {dataSource.name}</strong> with
											all matching records from <strong>#{joinedDataSource.id}: {joinedDataSource.name}</strong> and publishes the results
                      to the pipeline. If no matching record can be found, the join operator does not publish any result to the pipeline
                      and skips the record of <strong>#{dataSource.id}: {dataSource.name}</strong> for processing.
										</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-header'>
            <div className='row'>
              <div className='col'>
                <h4 className='mb-0'>Data source</h4>
              </div>
              <div className='col-auto'>
              </div>
              <div className='col text-right'>
                <h4 className='mb-0'>Joining data source</h4>
              </div>
            </div>
          </div>
          <div className='card-body'>
						<div className='row align-items-center'>
							<div className='col'>
								<div className='row m-0 align-items-center datacater-joined-data-source'>
									<div className='col-auto source-type-logos pl-0'>
										<div className='logo-wrap'>
                      <DataSourceLogo dataSource={dataSource} />
										</div>
								  </div>
									<div className='col'>
										<h4 className='mb-0'>
											#{dataSource.id}: {dataSource.name}
										</h4>
								  </div>
								</div>
								<select
									className={dataSourceAttributeClassNames}
									name='dataSourceJoinAttributeId'
                  onChange={(event) => handleJoinConfigChangeFunc(event.target.name, event.target.value)}
									value={dataSourceJoinAttributeId}>
                  <option value=''>--- Please choose an attribute</option>
                  {dataSourceProfileAttributes.map((attribute, index) => (
                    <option key={index} value={attribute.id}>{attribute.name}</option>
                  ))}
								</select>
							</div>
							<div className='col-auto'>
                <div className='datacater-joined-data-source'>
                </div>
                <div className='mt-2'>
                  =
                </div>
							</div>
							<div className='col'>
								<div className='row m-0 align-items-center datacater-joined-data-source'>
									<div className='col-auto source-type-logos pl-0'>
										<div className='logo-wrap'>
                      <DataSourceLogo dataSource={joinedDataSource} />
										</div>
								  </div>
									<div className='col'>
										<h4 className='mb-0'>
											#{joinedDataSource.id}: {joinedDataSource.name}
										</h4>
								  </div>
								</div>
                <div className='mt-2 d-flex align-items-center'>
                  {joinedDataSourceJoinAttribute !== undefined &&
                    <span>
                      {joinedDataSourceJoinAttribute.name}
                      <Key className='feather-icon ml-2' />
                    </span>
                  }
                </div>
							</div>
						</div>
					</div>
				</div>
        {configurationErrors.length === 0 &&
          <div className='card bg-primary text-white rounded-0'>
            <div className='card-body d-flex align-items-center'>
              <Check className='feather-icon mr-3' />
              The join operation is correctly configured and will be triggered by changes captured from
              the data source <strong className='ml-1'>#{dataSource.id}: {dataSource.name}</strong>.
            </div>
          </div>
        }
        {configurationErrors.length > 0 &&
          <div className='card text-bg-danger text-white rounded-0'>
            <div className='card-body d-flex align-items-center'>
              <XCircle className='feather-icon mr-3' />
              The join operation is not yet configured correctly and will be ignored when executing the pipeline deployment.
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default ManageJoinedDataSourceForm;
