export function fetchProjects(isAdmin = false) {
  const requestProjects = () => ({
    type: 'REQUEST_PROJECTS'
  });

  const receivedProjects = response => ({
    type:     'RECEIVE_PROJECTS',
    projects: response
  });

  return function (dispatch) {
    dispatch(requestProjects());

    const apiUrl = (isAdmin === true)
      ? '/api/admin/projects'
      : '/api/projects';

    return fetch(apiUrl,
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedProjects(json));
      });
  };
}

export function fetchProject(id, isAdmin = false) {
  const requestProject = () => ({
    type: 'REQUEST_PROJECT'
  });

  const receivedProject = response => ({
    type:    'RECEIVE_PROJECT',
    project: response
  });

  return function (dispatch) {
    dispatch(requestProject());

    const apiUrl = (isAdmin === true)
      ? '/api/admin/projects/'
      : '/api/projects/';

    return fetch((apiUrl + id),
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedProject(json));
      });
  };
}

export function fetchStreamsOfProject(id) {
  const requestProjectStreams = () => ({
    type: 'REQUEST_PROJECT_STREAMS'
  });

  const receivedProjectStreams = response => ({
    streams: response,
    type:    'RECEIVE_PROJECT_STREAMS'
  });

  return function (dispatch) {
    dispatch(requestProjectStreams());

    return fetch('/api/projects/' + id + '/streams',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedProjectStreams(json));
      });
  };
}

export function fetchDataSinksOfProject(id) {
  const requestProjectDataSinks = () => ({
    type: 'REQUEST_PROJECT_DATA_SINKS'
  });

  const receivedProjectDataSinks = response => ({
    dataSinks: response,
    type:      'RECEIVE_PROJECT_DATA_SINKS'
  });

  return function (dispatch) {
    dispatch(requestProjectDataSinks());

    return fetch('/api/projects/' + id + '/data_sinks',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedProjectDataSinks(json));
      });
  };
}

export function fetchDataSourcesOfProject(id) {
  const requestProjectDataSources = () => ({
    type: 'REQUEST_PROJECT_DATA_SOURCES'
  });

  const receivedProjectDataSources = response => ({
    dataSources: response,
    type:        'RECEIVE_PROJECT_DATA_SOURCES'
  });

  return function (dispatch) {
    dispatch(requestProjectDataSources());

    return fetch('/api/projects/' + id + '/data_sources',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedProjectDataSources(json));
      });
  };
}

export function fetchPipelinesOfProject(id) {
  const requestProjectPipelines = () => ({
    type: 'REQUEST_PROJECT_PIPELINES'
  });

  const receivedProjectPipelines = response => ({
    pipelines: response,
    type:      'RECEIVE_PROJECT_PIPELINES'
  });

  return function (dispatch) {
    dispatch(requestProjectPipelines());

    return fetch('/api/projects/' + id + '/pipelines',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedProjectPipelines(json));
      });
  };
}

export function addProject(project) {
  const requestAddProject = () => ({
    type: 'REQUEST_ADD_PROJECT'
  });

  const receivedAddProject = response => ({
    project: response,
    type:    'RECEIVE_ADD_PROJECT'
  });

  return function (dispatch) {
    dispatch(requestAddProject());

    return fetch(
        '/api/projects',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(project),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((response) => {
        dispatch(receivedAddProject(response));
        return response;
      });
  };
}

export function updateProject(project, isAdmin = false) {
  const requestUpdateProject = () => ({
    type: 'REQUEST_UPDATE_PROJECT'
  });
  const receivedUpdateProject = () => ({
    type: 'RECEIVE_UPDATE_PROJECT',
  });

  return function (dispatch) {
    dispatch(requestUpdateProject());

    // remove timestamps
    let cleanProject = project;
    delete cleanProject.createdAt;
    delete cleanProject.updatedAt;

    const apiUrl = (isAdmin === true)
      ? '/api/admin/projects/'
      : '/api/projects/';

    return fetch(
        (apiUrl + project.id),
        {
          method:      'PUT',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(cleanProject),
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((response) => {
        dispatch(receivedUpdateProject());
        return response;
      });
  };
}

export function deleteProject(id, isAdmin = false) {
  const requestDeleteProject = () => ({
    type: 'REQUEST_DELETE_PROJECT'
  });

  const receivedDeleteProject = () => ({
    type: 'RECEIVE_DELETE_PROJECT'
  });

  return function (dispatch) {
    dispatch(requestDeleteProject());

    const apiUrl = (isAdmin === true)
      ? '/api/admin/projects/'
      : '/api/projects/';

    return fetch(
        (apiUrl + id),
        {
          method:      'DELETE',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'X-Auth-token': localStorage.getItem('userToken')
          }
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.text();
          }
        },
        error => console.log('An error occurred.', error)
      ).then(() => {
        dispatch(receivedDeleteProject());
      });
  };
}
