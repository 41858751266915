import React,
       { Component } from 'react';
import { connect }   from 'react-redux';
import MainContent   from '../../components/layout/MainContent';
import Navigation    from '../../components/settings/Navigation';
import { fetchInfo } from '../../actions/info';
import {
  fetchCurrentUser,
  updateCurrentUser
} from '../../actions/users';

class UserAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoMessage:  '',
      errorMessage: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateAccount = this.handleUpdateAccount.bind(this);
  }

  // TODO: Move in common util class
  validateEmail(email) {
    var re = /^(([^<>()\[\]\.,;:\s@\']+(\.[^<>()\[\]\.,;:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,;:\s@\']+\.)+[^<>()[\]\.,;:\s@\']{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  componentDidMount() {
    this.props.fetchInfo();
    this.props.fetchCurrentUser()
      .then(() => this.setState({ user: this.props.users.currentUser }));
  }

  handleChange(event) {
    let user = this.state.user;
    user[event.target.name] = event.target.value;
    this.setState({ user: user });
  }

  handleUpdateAccount(event) {
    event.preventDefault();

    if (this.state.user.name.length === 0) {
      this.setState({
        errorMessage: 'Please provide a name.',
        infoMessage:  ''
      });
    } else if (this.state.user.email.length === 0) {
      this.setState({
        errorMessage: 'Please provide an email.',
        infoMessage:  ''
      });
    } else if (!this.validateEmail(this.state.user.email)) {
      this.setState({
        errorMessage: 'Please provide a valid email.',
        infoMessage:  ''
      });
    } else {
      this.props.updateCurrentUser(this.state.user).then((response) => {
        if (response.errorMessage !== undefined) {
          this.setState({
            errorMessage: response.errorMessage,
            infoMessage:  ''
          });
        } else {
          this.setState({
            errorMessage: '',
            infoMessage:  'Account information has been updated successfully.',
            user:         this.props.users.currentUser
          });
        }
      });
    }
  }

  render() {
    const currentUser = this.props.users.currentUser;

    return (
      <MainContent preTitle='Settings' title='Manage account'>
        <div className='card card-fill'>
          <div className='card-body'>
            <div className='row mb-4 mt-n3'>
              <div className='col-12'>
                <Navigation currentUser={currentUser} info={this.props.info.info} />
              </div>
            </div>
            {this.state.user !== undefined &&
              <React.Fragment>
                <h3 className='card-header-title mb-4'>Manage account</h3>
                {this.state.infoMessage.length > 0 &&
                  <div className='alert alert-success my-4 rounded-0'>
                  {this.state.infoMessage}
                  </div>
                }
                {this.state.errorMessage.length > 0 &&
                  <div className='alert alert-danger my-4 rounded-0'>
                  {this.state.errorMessage}
                  </div>
                }
                <form>
                  <div className='form-group'>
                    <label htmlFor='name'>Name:</label>
                    <input
                      type='text'
                      className='form-control'
                      name='name'
                      onChange={this.handleChange}
                      value={this.state.user.name} />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='name'>Email:</label>
                    <input
                      type='email'
                      className='form-control'
                      name='email'
                      onChange={this.handleChange}
                      value={this.state.user.email} />
                  </div>
                  <button
                    className='btn btn-primary'
                    onClick={this.handleUpdateAccount}
                    type='submit'>
                    Save changes
                  </button>
                </form>
              </React.Fragment>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    info:  state.info,
    users: state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser:  fetchCurrentUser,
  fetchInfo:         fetchInfo,
  updateCurrentUser: updateCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);
