export function daysInTrialLeft(trialEndsAt) {
  const trialEndsAtSplit = trialEndsAt.split('-');
  const today = Date.now();
  const trialEnd = new Date();

  trialEnd.setYear(parseInt(trialEndsAtSplit[0]));
  trialEnd.setMonth(parseInt(trialEndsAtSplit[1]) - 1);
  trialEnd.setDate(parseInt(trialEndsAtSplit[2]));
  trialEnd.setHours(23);
  trialEnd.setMinutes(59);
  trialEnd.setSeconds(59);

  // determine number of days left for trial
  return Math.floor((trialEnd - today) / (1000*60*60*24));
}
