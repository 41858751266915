import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  AlertCircle,
  Check,
  Code,
  Database,
  Filter as FilterIcon,
  GitMerge,
  Target,
  XCircle,
  X
} from 'react-feather';
import Filter             from '../../../../pipelines/Filter';
import DataSourceListItem from '../../../data_sources/DataSourceListItem';
import DataSinkListItem   from '../../../data_sinks/DataSinkListItem';
import StatusResponse     from '../../../kafka_connectors/StatusResponse';
import FlatFileDataSource from './instructions/FlatFileDataSource';
import { formatInteger }  from '../../../../helpers/formatInteger';

class InstructionsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reloadPipelineDesigner:            false,
      recreatedSourceConnector:          false,
      restartedSinkConnector:            false,
      restartedSourceConnector:          false,
      showFlatFileList:                  false,
      showJoinedSourceConnectorResponse: false,
      showSinkConnectorResponse:         false,
      showSourceConnectorResponse:       false,
    };

    this.moveToDataSink                      = this.moveToDataSink.bind(this);
    this.moveToJoinPage                      = this.moveToJoinPage.bind(this);
    this.moveToPipelineFilters               = this.moveToPipelineFilters.bind(this);
    this.moveToPipelineSteps                 = this.moveToPipelineSteps.bind(this);
    this.moveToPipelineStep                  = this.moveToPipelineStep.bind(this);
    this.moveToSampleData                    = this.moveToSampleData.bind(this);
    this.renderPipelineFilter                = this.renderPipelineFilter.bind(this);
    this.ingestData                          = this.ingestData.bind(this);
    this.startExportPipeline                 = this.startExportPipeline.bind(this);
    this.fetchDataStoreInformation           = this.fetchDataStoreInformation.bind(this);
    this.toggleFlatFileList                  = this.toggleFlatFileList.bind(this);
    this.recreateSourceConnector             = this.recreateSourceConnector.bind(this);
    this.restartSinkConnector                = this.restartSinkConnector.bind(this);
    this.restartSourceConnector              = this.restartSourceConnector.bind(this);
    this.hideSourceRecreate                  = this.hideSourceRecreate.bind(this);
    this.hideSourceRestart                   = this.hideSourceRestart.bind(this);
    this.hideSinkRestart                     = this.hideSinkRestart.bind(this);
    this.toggleSinkConnectorResponse         = this.toggleSinkConnectorResponse.bind(this);
    this.toggleSourceConnectorResponse       = this.toggleSourceConnectorResponse.bind(this);
    this.toggleJoinedSourceConnectorResponse = this.toggleJoinedSourceConnectorResponse.bind(this);
  }

  componentDidMount() {
    this.props.resetFileUploadFunc();

    const pipeline = this.props.pipeline;
    if (pipeline !== undefined) {
      this.props.fetchFlatFilesOfPipelineFunc(pipeline.id);

      if (
        pipeline.dataSinkId !== undefined ||
        pipeline.dataSourceId !== undefined ||
        pipeline.joinedDataSourceId !== undefined
      ) {
        this.fetchDataStoreInformation();

        clearInterval(this.fetchDataStoreInformationInterval);

        this.fetchDataStoreInformationInterval =
          setInterval(this.fetchDataStoreInformation, 60000);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.fetchDataStoreInformationInterval);
  }

  moveToDataSink(event) {
    this.props.moveToPageFunc('data-sink', undefined);
  }

  moveToJoinPage(event) {
    this.props.moveToPageFunc('join', undefined);
  }

  moveToPipelineFilters(event) {
    if (document.querySelector('.loading-pipeline-designer-wrapper') != null) {
      document.querySelector('.loading-pipeline-designer-wrapper').classList.add('d-block');
    }

    setTimeout(
      () => {
        this.props.moveToPageFunc('steps', 0);
      },
      100
    );
  }

  moveToPipelineSteps(event) {
    if (document.querySelector('.loading-pipeline-designer-wrapper') != null) {
      document.querySelector('.loading-pipeline-designer-wrapper').classList.add('d-block');
    }

    setTimeout(
      () => {
        if (this.props.pipeline.pipelineSteps.length === 0) {
          this.props.addPipelineStepFunc(event, false);
        } else {
          const sortPositions = this.props.pipeline.pipelineSteps
            .map(step => step.sortPosition);
          const minSortPosition = Math.min.apply(Math, sortPositions);
          this.props.moveToPageFunc('steps', minSortPosition);
        }
      },
      100);
  }

  moveToPipelineStep(sortPosition) {
    if (document.querySelector('.loading-pipeline-designer-wrapper') != null) {
      document.querySelector('.loading-pipeline-designer-wrapper').classList.add('d-block');
    }

    setTimeout(
      () => {
        this.props.moveToPageFunc('steps', sortPosition);
      },
      100);
  }

  moveToSampleData(event) {
    if (document.querySelector('.loading-pipeline-designer-wrapper') != null) {
      document.querySelector('.loading-pipeline-designer-wrapper').classList.add('d-block');
    }

    setTimeout(
      () => {
        this.props.moveToPageFunc('steps', undefined);
      },
      100);
  }

  renderPipelineFilter(pipelineFilter, index) {
    const attribute = this.props.attributes
      .find(attr => parseInt(attr.id) === parseInt(pipelineFilter.attributeId));

    const filter = Filter.getFilters()
      .find(filter => filter.key === pipelineFilter.assertionFilter);

    return (
      <div className='list-group-item' key={index}>
        <div className='row'>
          <div className='col-auto'>
            <div className='avatar avatar-sm'>
              <div className='avatar-title font-size-lg bg-primary-soft rounded-circle text-primary'>
                <FilterIcon className='feather-icon' />
              </div>
            </div>
          </div>
          <div className='col'>
            <h5 className='mb-1'>
              {attribute.name}
            </h5>
            <p className='text-muted mb-0'>
              Require {filter.name}
            </p>
          </div>
        </div>
      </div>
    );
  }

  ingestData(files) {
    const file     = files[0];
    const pipeline = this.props.pipelines.pipeline;

    if (file !== undefined && pipeline !== undefined) {
      this.props.ingestFileIntoPipeline(pipeline.id, file)
        .then(() => this.props.fetchFlatFilesOfPipeline(pipeline.id));
    }
  }

  startExportPipeline() {
    const pipeline = this.props.pipelines.pipeline;
    const dataSink = this.props.dataSinks.dataSinks.find(function(el) {
      return (el.id === parseInt(pipeline.dataSinkId));
    });

    if (dataSink != null) {
      this.props.exportPipelineFunc(pipeline, dataSink);
    }
  }

  fetchDataStoreInformation() {
    const pipeline = this.props.pipelines.pipeline;

    if (pipeline !== undefined) {
      if (pipeline.dataSinkId !== undefined) {
        this.props.getNumberOfRecordsInDataSinkFunc(
          pipeline.dataSinkId,
          pipeline.id);
      }

      if (pipeline.joinedDataSourceId !== undefined) {
        this.props.getNumberOfRecordsInJoinedDataSourceFunc(
          pipeline.joinedDataSourceId,
          pipeline.id);
      }

      if (pipeline.dataSourceId !== undefined) {
        this.props.getNumberOfRecordsInDataSourceFunc(
          pipeline.dataSourceId,
          pipeline.id);
      }
    }
  }

  toggleFlatFileList(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showFlatFileList: !this.state.showFlatFileList
    });
  }

  restartSinkConnector(event) {
    this.setState({
      restartedSinkConnector: false
    });

    this.props.restartSinkConnectorFunc(event)
      .then(() => this.setState({ restartedSinkConnector: true }));
  }

  recreateSourceConnector(event) {
    this.setState({
      restartedSourceConnector: false,
      recreatedSourceConnector: false
    });

    this.props.recreateSourceConnectorFunc(event)
      .then(() => this.setState({ recreatedSourceConnector: true }));
  }

  restartSourceConnector(event) {
    this.setState({
      restartedSourceConnector: false
    });

    this.props.restartSourceConnectorFunc(event)
      .then(() => this.setState({ restartedSourceConnector: true }));
  }

  hideSourceRecreate() {
    this.setState({
      recreatedSourceConnector: false
    });
  }

  hideSourceRestart() {
    this.setState({
      restartedSourceConnector: false
    });
  }

  hideSinkRestart() {
    this.setState({
      restartedSinkConnector: false
    });
  }

  toggleSinkConnectorResponse() {
    this.setState({
      showSinkConnectorResponse: !this.state.showSinkConnectorResponse
    });
  }

  toggleSourceConnectorResponse() {
    this.setState({
      showSourceConnectorResponse: !this.state.showSourceConnectorResponse
    });
  }

  toggleJoinedSourceConnectorResponse() {
    this.setState({
      showJoinedSourceConnectorResponse: !this.state.showJoinedSourceConnectorResponse
    });
  }

  render() {
    const {
      canEditPipeline,
      dataSink,
      dataSource,
      dataSources,
      failedLoadingSampleRecords,
      pipeline,
      pipelines
    } = this.props;

    const flatFileSinkTypes = ['csv', 'json', 'xml'];

    const pipelineFilters = pipeline.pipelineAssertions
      .filter(assertion => ![undefined, ''].includes(assertion.assertionFilter));
    const pipelineSteps = pipeline.pipelineSteps;

    const isFlatFileDataSource =
      dataSource !== undefined &&
      ['csv', 'json', 'xml'].includes(dataSource.sourceType);

    const joinedDataSource = dataSources.dataSources
      .find(ds => parseInt(ds.id) === parseInt(pipeline.joinedDataSourceId));

    const isJoining =
      pipeline.joinedDataSourceId !== undefined &&
      pipeline.joinedDataSourceProfileId !== undefined &&
      joinedDataSource !== undefined;

    const sourceConnectorHasFailed = (
      pipelines.kafkaSourceConnector !== undefined &&
      pipelines.kafkaSourceConnector.connectorStatus === 'failed' &&
      pipelines.kafkaSourceConnector.statusResponse !== undefined
    );

    return (
      <div className='container mt-4'>
        <div className='row'>
          <div className='col'>
            <div className='pipeline-instructions-page'>
              {dataSource !== undefined && isFlatFileDataSource &&
                <FlatFileDataSource
                  canEditPipeline={canEditPipeline}
                  dataSource={dataSource}
                  dataSources={dataSources}
                  failedLoadingSampleRecords={failedLoadingSampleRecords}
                  flatFiles={this.props.flatFiles}
                  ingestDataFunc={this.props.ingestDataFunc}
                  moveToJoinPageFunc={this.moveToJoinPage}
                  moveToSampleDataFunc={this.moveToSampleData}
                  pipeline={pipeline}
                  pipelines={pipelines}
                  resetFileUploadFunc={this.props.resetFileUploadFunc} />
              }
              {dataSource !== undefined && !isFlatFileDataSource &&
                <div className='card mb-4'>
                  <div className='card-header' style={{ height: '50px' }}>
                    <h4 className='card-header-title d-flex align-items-center'>
                      <Database className='feather-icon mr-2' />
                      Data source
                    </h4>
                    {pipeline.dataSourceStreamId !== undefined &&
                      <a className='btn btn-white btn-sm' href={`/streams/${pipeline.dataSourceStreamId}`}>View stream</a>
                    }
                    {pipeline.joinedDataSourceStreamId !== undefined &&
                      <a className='btn btn-white btn-sm' href={`/streams/${pipeline.joinedDataSourceStreamId}`}>View join stream</a>
                    }
                    {canEditPipeline && (pipeline.kafkaSourceConnectorId !== undefined) &&
                      <button
                        className='btn btn-white btn-sm ml-2'
                        disabled={pipelines.restartingKafkaSourceConnector}
                        onClick={this.restartSourceConnector}>
                        {!pipelines.restartingKafkaSourceConnector && 'Restart connector'}
                        {pipelines.restartingKafkaSourceConnector && 'Restarting connector...'}
                      </button>
                    }
                    {canEditPipeline &&
                      <button
                        className='btn btn-white btn-sm ml-2'
                        onClick={this.moveToJoinPage}>
                        Manage join
                      </button>
                    }
                  </div>
                  {canEditPipeline && (pipeline.kafkaSourceConnectorId === undefined) && (pipelines.kafkaSourceConnector === undefined) &&
                    <div
                      className='card-body text-bg-danger p-3 pl-4 d-flex align-items-center clickable'
                      onClick={this.recreateSourceConnector}>
                      <AlertCircle className='feather-icon mr-2' />
                      We couldn't initialize the source connector. Please click here to try recreating it.
                    </div>
                  }
                  {!canEditPipeline && (pipeline.kafkaSourceConnectorId === undefined) && (pipelines.kafkaSourceConnector === undefined) &&
                    <div className='card-body text-bg-danger p-3 pl-4 d-flex align-items-center'>
                      <AlertCircle className='feather-icon mr-2' />
                      We couldn't initialize the source connector. Please try recreating it.
                    </div>
                  }
                  {pipelines.kafkaSourceConnector !== undefined && pipelines.kafkaSourceConnector.connectorStatus === 'failed' && pipelines.kafkaSourceConnector.statusResponse === undefined &&
                    <div className='card-body text-bg-danger p-3 pl-4 d-flex align-items-center'>
                      <AlertCircle className='feather-icon mr-2' />
                      The connection to the connector cluster has failed.
                    </div>
                  }
                  {failedLoadingSampleRecords === true &&
                    <div className='card-body text-bg-danger p-3 pl-4 d-flex align-items-center'>
                      <AlertCircle className='feather-icon mr-2' />
                      We couldn't extract sample records from the data source. Please check the health of the data source and consider recreating both the data source and the pipeline.
                    </div>
                  }
                  {sourceConnectorHasFailed &&
                    <React.Fragment>
                      <div
                        className='card-body text-bg-danger p-3 pl-4 d-flex align-items-center clickable'
                        onClick={this.toggleSourceConnectorResponse}>
                        <AlertCircle className='feather-icon mr-2' />
                        The connector of the data source has failed. Please try restarting the connector.
                      </div>
                      {this.state.showSourceConnectorResponse &&
                        <div className='card-body border-bottom p-0'>
                          <StatusResponse
                            fetchingKafkaConnector={pipelines.fetchingKafkaSourceConnector}
                            kafkaConnector={pipelines.kafkaSourceConnector}
                            kafkaConnectorClassName='highlight json text-danger p-4' />
                        </div>
                      }
                    </React.Fragment>
                  }
                  {(pipeline.joinedDataSourceId !== undefined) && (pipelines.kafkaJoinedSourceConnector !== undefined) && (pipelines.kafkaJoinedSourceConnector.connectorStatus === 'failed') && (pipelines.kafkaJoinedSourceConnector.statusResponse !== undefined) &&
                    <React.Fragment>
                      <div
                        className='card-body text-bg-danger p-3 pl-4 d-flex align-items-center clickable'
                        onClick={this.toggleJoinedSourceConnectorResponse}>
                        <AlertCircle className='feather-icon mr-2' />
                        The connector of the joining data source has failed. Please try restarting the connector.
                      </div>
                      {this.state.showJoinedSourceConnectorResponse &&
                        <div className='card-body border-bottom p-0'>
                          <StatusResponse
                            fetchingKafkaConnector={pipelines.fetchingKafkaJoinedSourceConnector}
                            kafkaConnector={pipelines.kafkaJoinedSourceConnector}
                            kafkaConnectorClassName='highlight json text-danger p-4' />
                        </div>
                      }
                    </React.Fragment>
                  }
                  {this.state.restartedSourceConnector && (pipelines.kafkaSourceConnector !== undefined) && (pipelines.kafkaSourceConnector.connectorStatus === 'running') && (pipelines.kafkaJoinedSourceConnector === undefined || pipelines.kafkaJoinedSourceConnector.connectorStatus === 'running') &&
                    <div className='card-body text-bg-success p-3 pl-4'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <Check className='feather-icon mr-2' />
                          {pipelines.kafkaJoinedSourceConnector !== undefined && 'The data source and joining data source connectors have been restarted.'}
                          {pipelines.kafkaJoinedSourceConnector === undefined && 'The data source connector has been restarted.'}
                        </div>
                        <div className='col-auto'>
                          <X
                            className='feather-icon mr-2 clickable'
                            onClick={this.hideSourceRestart} />
                        </div>
                      </div>
                    </div>
                  }
                  <div className='card-body'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <ul className='list-group list-group-flush list my-n4'>
                          <DataSourceListItem
                            dataSource={dataSource}
                            history={this.props.history}
                            numberOfRecords={dataSources.numberOfRecords}
                            showNumberOfRecords={true} />
                        </ul>
                      </div>
                      {isJoining &&
                        <React.Fragment>
                          <div className='col-auto px-2'>
                            <GitMerge className='feather-icon' />
                          </div>
                          <div className='col'>
                            <ul className='list-group list-group-flush list my-n4'>
                              <DataSourceListItem
                                dataSource={joinedDataSource}
                                history={this.props.history}
                                numberOfRecords={dataSources.numberOfRecordsJoined}
                                showNumberOfRecords={true} />
                            </ul>
                          </div>
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </div>
              }
              <div className='card mb-4'>
                <div className='card-header' style={{ height: '50px' }}>
                  <h4 className='card-header-title d-flex align-items-center'>
                    <FilterIcon className='feather-icon mr-2' />
                    Filter
                  </h4>
                  <button
                    className='btn btn-white btn-sm'
                    onClick={this.moveToPipelineFilters}>
                    Manage filters
                  </button>
                </div>
                <div className='card-body'>
                  {pipelineFilters.length === 0 &&
                    <p className='mb-0'>
                      No filters defined.
                    </p>
                  }
                  {pipelineFilters.length > 0 &&
                    <div className='list-group list-group-flush my-n2'>
                      {pipelineFilters.map((pipelineFilter, index) => this.renderPipelineFilter(pipelineFilter, index))}
                    </div>
                  }
                </div>
              </div>
              <div className='card mb-4'>
                <div className='card-header' style={{ height: '50px' }}>
                  <h4 className='card-header-title d-flex align-items-center'>
                    <Code className='feather-icon mr-2' />
                    Transform
                  </h4>
                  <button
                    className='btn btn-white btn-sm'
                    onClick={this.moveToPipelineSteps}>
                    Manage transformations
                  </button>
                </div>
                <div className='card-body'>
                  {(pipelineSteps.length === 0) &&
                    <p className='mb-0'>
                      No transformations defined.
                    </p>
                  }
                  {(pipelineSteps.length > 0) &&
                    <div className='list-group list-group-flush list-group-activity my-n3'>
                      {pipeline.pipelineSteps.map((pipelineStep, index) => (
                        <div className='list-group-item' key={index}>
                          <div className='row'>
                            <div className='col-auto'>
                              <div className='avatar avatar-sm'>
                                <div className='avatar-title font-size-lg bg-primary-soft rounded-circle text-primary'>
                                  <Code className='feather-icon' />
                                </div>
                              </div>
                            </div>
                            <div className='col d-flex align-items-center'>
                              <Link
                                onClick={() => this.moveToPipelineStep(pipelineStep.sortPosition)}
                                to={'/pipelines/' + pipeline.id + '/edit'}>
                                <h4 className='mb-0'>
                                  {pipelineStep.name || 'Untitled step'}
                                </h4>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              </div>
              {(dataSink === undefined) &&
                <div
                  className='card card-error clickable'
                  onClick={this.moveToDataSink}>
                  <div className='card-header' style={{ height: '50px' }}>
                    <h4 className='card-header-title d-flex align-items-center'>
                      <Target className='feather-icon mr-2' />
                      Data sink
                    </h4>
                    <button
                      className='btn btn-white btn-sm'
                      onClick={this.moveToDataSink}>
                      Assign data sink
                    </button>
                  </div>
                  <div className='card-body clickable'>
                    <div className='row align-items-center'>
                      <div className='col-auto d-flex align-items-center'>
                        <XCircle className='feather-icon text-danger' />
                      </div>
                      <div className='col text-danger'>
                        Please assign a data sink.
                      </div>
                    </div>
                  </div>
                </div>
              }
              {(dataSink !== undefined) &&
                <div className='card'>
                  <div className='card-header' style={{ height: '50px' }}>
                    <h4 className='card-header-title d-flex align-items-center'>
                      <Target className='feather-icon mr-2' />
                      Data sink
                    </h4>
                    {pipeline.dataSinkStreamId !== undefined &&
                      <a className='btn btn-white btn-sm' href={`/streams/${pipeline.dataSinkStreamId}`}>View stream</a>
                    }
                    {canEditPipeline && (pipeline.kafkaSinkConnectorId !== undefined) &&
                      <button
                        className='btn btn-white btn-sm ml-2'
                        disabled={pipelines.restartingKafkaSinkConnector}
                        onClick={this.restartSinkConnector}>
                        {!pipelines.restartingKafkaSinkConnector && 'Restart connector'}
                        {pipelines.restartingKafkaSinkConnector && 'Restarting connector...'}
                      </button>
                    }
                  </div>
                  {(pipelines.kafkaSinkConnector !== undefined) && (pipelines.kafkaSinkConnector.connectorStatus === 'failed') && (pipelines.kafkaSinkConnector.statusResponse !== undefined) &&
                    <React.Fragment>
                      <div
                        className='card-body text-bg-danger p-3 pl-4 d-flex align-items-center clickable'
                        onClick={this.toggleSinkConnectorResponse}>
                        <AlertCircle className='feather-icon mr-2' />
                        The connector of the data sink has failed. Please try restarting the connector or reassigning the data sink.
                      </div>
                      {this.state.showSinkConnectorResponse &&
                        <div className='card-body border-bottom p-0'>
                          <StatusResponse
                            fetchingKafkaConnector={pipelines.fetchingKafkaSinkConnector}
                            kafkaConnector={pipelines.kafkaSinkConnector}
                            kafkaConnectorClassName='highlight json text-danger p-4' />
                        </div>
                      }
                    </React.Fragment>
                  }
                  {(pipelines.kafkaSinkConnector !== undefined) && (pipelines.kafkaSinkConnector.connectorStatus === 'failed') && (pipelines.kafkaSinkConnector.statusResponse === undefined) &&
                    <div className='card-body text-bg-danger p-3 pl-4 d-flex align-items-center'>
                      <AlertCircle className='feather-icon mr-2' />
                      The connection to Kafka has failed.
                    </div>
                  }
                  {this.state.restartedSinkConnector && (pipelines.kafkaSinkConnector !== undefined) && (pipelines.kafkaSinkConnector.connectorStatus === 'running') &&
                    <div className='card-body text-bg-success p-3 pl-4'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <Check className='feather-icon mr-2' />
                          The data sink connector has been restarted.
                        </div>
                        <div className='col-auto'>
                          <X
                            className='feather-icon mr-2 clickable'
                            onClick={this.hideSinkRestart} />
                        </div>
                      </div>
                    </div>
                  }
                  {!flatFileSinkTypes.includes(dataSink.sinkType) &&
                    <div className='card-body'>
                      <ul className='list-group list-group-flush list my-n4'>
                        <DataSinkListItem
                          dataSink={dataSink}
                          history={this.props.history}
                          numberOfRecords={this.props.dataSinks.numberOfRecords}
                          showNumberOfRecords={true} />
                      </ul>
                    </div>
                  }
                  {flatFileSinkTypes.includes(dataSink.sinkType) &&
                    <React.Fragment>
                      <div className='card-body py-3'>
                        <ul className='list-group list-group-flush list my-n4'>
                          <li className='list-group-item' key={dataSink.id}>
                            <div className='row align-items-center py-2'>
                              <div className='col-auto sink-type-logos'>
                                <div className='logo-wrap'>
                                  <img
                                    alt={dataSink.sinkType}
                                    src={'/images/' + dataSink.sinkType + '-logo.png'} />
                                </div>
                              </div>
                              <div className='col pl-2'>
                                <span>
                                  {(this.props.pipelines.exportingPipeline === false) &&
                                    <button
                                      className='btn btn-white btn-sm'
                                      onClick={this.startExportPipeline}>
                                      Download
                                    </button>
                                  }
                                  {(this.props.pipelines.exportingPipeline === true) &&
                                    <button
                                      className='btn btn-white btn-sm'
                                      disabled={true}>
                                      <span className='spinner-border mr-2 float-left mt-1' role='status'></span>
                                      Downloading (this may take a while)...
                                    </button>
                                  }
                                </span>
                              </div>
                              {(this.props.dataSinks.numberOfRecords !== undefined) &&
                                <div className='col-auto'>
                                  {formatInteger(this.props.dataSinks.numberOfRecords)} records
                                </div>
                              }
                            </div>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstructionsPage;
