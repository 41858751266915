export function signIn(email, password) {
  const requestSignIn = () => ({
    type: 'REQUEST_SIGN_IN'
  });

  const receivedSignIn = response => ({
    type: 'RECEIVE_SIGN_IN',
    user: response
  });

  const failedSignIn = () => ({
    type: 'FAILURE_SIGN_IN'
  });

  return function (dispatch) {
    dispatch(requestSignIn());

    return fetch(
        '/api/user_sessions',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            email:    email,
            password: password
          })
        }
      )
      .then(response => response.json())
      .catch(error => dispatch(failedSignIn()))
      .then((json) => {
        localStorage.setItem(
          'maxNumberOfPipelines',
          json.maxNumberOfPipelines);
        localStorage.setItem(
          'maxNumberOfPipelineSteps',
          json.maxNumberOfPipelineSteps);
        localStorage.setItem('userToken', json.token);
        dispatch(receivedSignIn(json));
      });
  };
}

export function signUp(name, email) {
  const requestSignUp = () => ({
    type: 'REQUEST_SIGN_UP'
  });

  const receivedSignUp = response => ({
    type: 'RECEIVE_SIGN_UP',
    user: response
  });

  const failedSignUp = () => ({
    type: 'FAILURE_SIGN_UP'
  });

  return function (dispatch) {
    dispatch(requestSignUp());

    return fetch(
        '/api/user_sessions/sign_up',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            name:  name
          })
        }
      )
      .then(response => {
        if (response.status >= 300) {
          return response.text()
            .then(error => dispatch(failedSignUp()));
        } else {
          return response.json()
            .then(json => dispatch(receivedSignUp(json)));
        }
      })
      .catch(error => dispatch(failedSignUp()));
  };
}

export function forgotPassword(email) {
  const requestForgotPassword = () => ({
    type: 'REQUEST_FORGOT_PASSWORD'
  });

  const receivedForgotPassword = () => ({
    type: 'RECEIVE_FORGOT_PASSWORD'
  });

  return function (dispatch) {
    dispatch(requestForgotPassword());

    return fetch(
        '/api/user_sessions/forgot_password',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            email:    email
          })
        }
      )
      .then(() => {
        dispatch(receivedForgotPassword());
      });
  };
}

export function signOut() {
  ['maxNumberOfPipelines', 'maxNumberOfPipelineSteps', 'userToken']
    .forEach(entry => localStorage.removeItem(entry));

  return function (dispatch) {
    dispatch(() => ({ type: 'SIGN_OUT' }));
  };
}
