import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import { Check, Search, X } from 'react-feather';
import DataSourceLogo       from '../../data_sources/DataSourceLogo';

class GeneralForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: ''
    };

    this.selectDataSource  = this.selectDataSource.bind(this);
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
    this.renderDataSource  = this.renderDataSource.bind(this);
  }

  updateSearchQuery(event) {
    this.setState({
      searchQuery: event.target.value
    });
  }

  selectDataSource(event, dataSourceId) {
    event.preventDefault();
    this.props.handleStartProfilingFunc(dataSourceId);
  }

  renderDataSource(dataSource) {
    const currentUser = this.props.currentUser;
    const userEmail =
      (
        ![currentUser, dataSource].includes(undefined) &&
        (dataSource.userId === currentUser.id)
      )
        ? currentUser.email
        : undefined;

    const project =
      (
        ![currentUser, dataSource].includes(undefined) &&
        (dataSource.userId === undefined) &&
        (dataSource.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === dataSource.projectId)
        : undefined;

    return (
      <li
        className='list-group-item hoverable clickable p-3 px-4'
        data-data-source-id={dataSource.id}
        key={dataSource.id}
        onClick={(event) => this.selectDataSource(event, dataSource.id)}>
        <div className='row align-items-center'>
          <div className='col-auto'>
            <div className='logo-wrap'>
              <DataSourceLogo dataSource={dataSource} />
            </div>
          </div>
          {userEmail !== undefined &&
            <div className='col-auto pr-0'>
              <span className='text-gray'>
                {userEmail}
              </span>
              <span className='text-gray ml-2'>
                /
              </span>
            </div>
          }
          {project !== undefined &&
            <div className='col-auto pr-0'>
              <span className='text-gray'>
                {project.name}
              </span>
              <span className='text-gray ml-2'>
                /
              </span>
            </div>
          }
          <div className='col pl-2'>
            <span data-data-source-id={dataSource.id}>
              #{dataSource.id}: {dataSource.name}
            </span>
          </div>
          <div className='col-auto'>
            <Link
              className='btn btn-white btn-sm'
              to='/pipelines/new'
              onClick={(event) => this.selectDataSource(event, dataSource.id)}>
              Choose
            </Link>
          </div>
        </div>
      </li>
    );
  }

  render() {
    const {
      createPipelineStatus,
      dataSourceParamIsSet,
      errorMessage,
      pipeline
    } = this.props;

    const searchTokens = this.state.searchQuery.toLowerCase().trim().split(' ');
    const dataSources = this.props.dataSources
      .filter(dataSource => dataSource.isTemporary !== true);
    const filteredDataSources = dataSources
      .filter(dataSource => searchTokens
                              .map(_ => dataSource.name.toLowerCase().includes(_))
                              .filter(_ => _)
                              .length === searchTokens.length)
      .sort((a, b) => b.id - a.id);
    const consumedDataSource = this.props.dataSources
      .find(dataSource => dataSource.id === this.props.pipeline.dataSourceId);

    return (
      <div className='card'>
        {!dataSourceParamIsSet &&
          <div className='card-header'>
            {createPipelineStatus === undefined &&
              <h4 className='card-header-title'>Choose a data source</h4>
            }
            {createPipelineStatus !== undefined &&
              <h4 className='card-header-title'>Creating pipeline...</h4>
            }
          </div>
        }
        {!dataSourceParamIsSet && createPipelineStatus === undefined &&
          <div className='card-header'>
            <form>
              <div className='input-group input-group-flush input-group-merge'>
                <input
                  type='search'
                  className='form-control form-control-prepended search'
                  onChange={this.updateSearchQuery}
                  placeholder='Search data sources'
                  value={this.state.searchQuery} />
                <div className='input-group-prepend'>
                  <div className='input-group-text'>
                    <Search className='feather-icon' />
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
        {(this.props.profilingFailed === true) &&
          <div className='card-header text-bg-danger'>
            <span className='d-flex align-items-center'>
              <X className='feather-icon mr-3' />
              Creating a pipeline for the data source #{consumedDataSource.id}: {consumedDataSource.name} failed.
              Please try again.
            </span>
          </div>
        }
        {(errorMessage !== undefined) &&
          <div className='card-header text-bg-danger'>
            <span className='d-flex align-items-center'>
              <X className='feather-icon mr-3' />
              {errorMessage}
            </span>
          </div>
        }
        <div className='card-body border-bottom p-0'>
          {(createPipelineStatus !== undefined) && (this.props.profilingFailed !== true) && (errorMessage === undefined) &&
            <div className='p-4'>
              <div className='d-flex align-items-center mb-4'>
                <span style={{ width: '40px'}}>
                  <Check className='feather-icon text-primary font-weight-bold' />
                </span>
                <span className='ml-4'>
                  Create source stream
                </span>
              </div>
              <div className='d-flex align-items-center mb-4'>
                <span style={{ width: '40px'}}>
                  <Check className='feather-icon text-primary font-weight-bold' />
                </span>
                <span className='ml-4'>
                  Create source connector
                </span>
              </div>
              {createPipelineStatus === 'profile_data' &&
                <div className='d-flex align-items-center'>
                  <span style={{ width: '40px'}}>
                    <span style={{ 'font-size': '0.5rem', height: '1.5rem', width: '1.5rem' }} className='spinner-border text-primary' role='status'>
                        <span className='sr-only'>Loading...</span>
                    </span>
                  </span>
                  <span className='ml-4'>
                    Profile data from source stream
                  </span>
                </div>
              }
              {createPipelineStatus === 'profiled_data' &&
                <div className='d-flex align-items-center'>
                  <span style={{ width: '40px'}}>
                    <Check className='feather-icon text-primary font-weight-bold' />
                  </span>
                  <span className='ml-4'>
                    Profile data from source stream
                  </span>
                </div>
              }
            </div>
          }
          {createPipelineStatus === undefined && filteredDataSources.length > 0 &&
            <ul className='list-group list-group-flush source-type-logos py-0'>
              {filteredDataSources.map((dataSource, index) => this.renderDataSource(dataSource))}
            </ul>
          }
          {createPipelineStatus === undefined && filteredDataSources.length === 0 && dataSources.length > 0 &&
            <div className='p-4'>
              No matching data source was found. Please refine your search query.
            </div>
          }
          {createPipelineStatus === undefined && filteredDataSources.length === 0 && dataSources.length === 0 &&
            <div className='p-4'>
              No data sources available.
            </div>
          }
        </div>
      </div>
    );
  }
}

export default GeneralForm;
