import React,
       { Component } from 'react';

class AttributeStats extends Component {
  formatNumber(value) {
    return parseFloat(value).toLocaleString();
  }

  renderPercentageStat(value, count, percentage) {
    return (
      <li className={'stat-percentage stat-percentage-' + percentage}>
        <span className='label'>{value}</span>
        <span className='value'>{this.formatNumber(count)} ({percentage} %)</span>
      </li>
    );
  }

  render() {
    const profile   = this.props.attributeProfile;
    if (profile === undefined) {
      return (
        <ul></ul>
      );
    }

    const distinctValuesRatio = (profile.totalValues > 0)
    ? ((profile.distinctValues / profile.totalValues) * 100).toFixed(0)
    : undefined;
    const missingValuesRatio = (profile.totalValues > 0)
      ? ((profile.missingValues / profile.totalValues) * 100).toFixed(0)
      : undefined;

    return (
      <ul>
        {this.renderPercentageStat('Distinct Values:', profile.distinctValues, distinctValuesRatio)}
        {this.renderPercentageStat('Missing Values:', profile.missingValues, missingValuesRatio)}
        {profile.minimumValue !== undefined &&
          <li>
            <span className='label'>Minimum:</span>
            <span className='value'>{this.formatNumber(profile.minimumValue)}</span>
          </li>
        }
        {profile.maximumValue !== undefined &&
          <li>
            <span className='label'>Maximum:</span>
            <span className='value'>{this.formatNumber(profile.maximumValue)}</span>
          </li>
        }
        {profile.meanValue !== undefined &&
          <li>
            <span className='label'>Mean:</span>
            <span className='value'>{this.formatNumber(profile.meanValue)}</span>
          </li>
        }
        {profile.medianValue !== undefined &&
          <li>
            <span className='label'>Median:</span>
            <span className='value'>{this.formatNumber(profile.medianValue)}</span>
          </li>
        }
        {profile.standardDeviation !== undefined &&
          <li>
            <span className='label'>Standard Deviation:</span>
            <span className='value'>{this.formatNumber(profile.standardDeviation)}</span>
          </li>
        }
      </ul>
    );
  }
}

export default AttributeStats;
