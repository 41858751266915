import React, { Component } from 'react';

class AttributeFilterValue extends Component {
  getExampleValue(dataType) {
    const exampleValues = {
      boolean:            'true',
      int:                1,
      long:               1,
      float:              1.0,
      double:             1.0,
      decimal:            1.0,
      string:             'foo',
      uuid:               '087fd5e7-24fa-4e7b-83ac-0fc642979d4b',
      bytes:              '"\u00FF"',
      date:               '2019-10-24',
      'time-millis':      '13:15:00',
      'time-micros':      '13:15:00',
      'timestamp-millis': '2019-10-24 13:15:00',
      'timestamp-micros': '2019-10-24 13:15:00',
      'null':             'null'
    };

    if (exampleValues[dataType] !== undefined) {
      return 'e.g., ' + exampleValues[this.props.dataType];
    } else {
      return '';
    }
  }

  render() {
    const canEditPipeline = this.props.canEditPipeline;

    return (
      <input
        type='text'
        className='form-control mb-2'
        data-attribute-id={this.props.attributeId}
        data-sort-position={this.props.sortPosition}
        disabled={!canEditPipeline}
        name={this.props.name}
        onChange={this.props.handleChangeFunc}
        placeholder={this.getExampleValue(this.props.dataType)}
        value={this.props.value} />
    );
  }
}

export default AttributeFilterValue;
