import React, { Component }        from 'react';
import { X }                       from 'react-feather';
import RefineDataSourceProfileForm from '../../data_sources/RefineDataSourceProfileForm';
import UploadFlatFile              from '../../flat_files/UploadFlatFile';

class JSONConfig extends Component {
  render() {
    const {
      changedAttributeNames,
      createPipelineStatus,
      dataSourceProfile,
      dataSourceProfiles,
      disableParserConfig,
      flatFiles,
      handleChangeFunc,
      handleFileUploadFunc,
      profilingDataSource,
      profilingFailed,
      refineProfileOfFlatFile,
      reprofileDataSourceProfileFunc,
      resetFileUploadFunc,
      selectAttributeAsPrimaryKeyFunc,
      uploadFailed
    } = this.props;

    if (
      refineProfileOfFlatFile &&
      dataSourceProfile !== undefined &&
      uploadFailed !== true
    ) {
      return (
        <div className='datacater-refine-csv-flat-file m-n4'>
          <RefineDataSourceProfileForm
            changedAttributeNames={changedAttributeNames}
            createPipelineStatus={createPipelineStatus}
            dataSourceProfile={dataSourceProfile}
            disableParserConfig={disableParserConfig}
            fileType='JSON'
            finalizeProfileFunc={undefined}
            formLabel='Preview'
            handleChangeFunc={handleChangeFunc}
            handleAttributeChangeFunc={undefined}
            handleKeyChangeFunc={undefined}
            isProfilingDataSourceProfile={dataSourceProfiles.profilingDataSourceProfile}
            profilingFailed={profilingFailed}
            reprofileDataSourceProfileFunc={reprofileDataSourceProfileFunc}
            sampleRecords={flatFiles.sampleRecords}
            selectAttributeAsPrimaryKeyFunc={selectAttributeAsPrimaryKeyFunc} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <h4 className='mb-4'>Upload JSON file</h4>
        {!profilingDataSource && uploadFailed === true &&
          <div className='card-header text-bg-danger mx-n4'>
            <span className='d-flex align-items-center'>
              <X className='feather-icon mr-3' />
              {dataSourceProfile !== undefined && dataSourceProfile.profilingFailureMessage !== undefined &&
                'Processing your file failed: ' + dataSourceProfile.profilingFailureMessage + '.'
              }
              {(dataSourceProfile === undefined || dataSourceProfile.profilingFailureMessage === undefined) &&
                'Processing your file failed.'
              }
            </span>
          </div>
        }
        <div className='mx-n4 mb-n4'>
          <UploadFlatFile
            fileType='JSON'
            flatFiles={flatFiles}
            handleFileUploadFunc={handleFileUploadFunc}
            profilingDataSource={profilingDataSource}
            profilingFailed={profilingFailed}
            resetFileUploadFunc={resetFileUploadFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default JSONConfig;
