import React,
       { Component }      from 'react';
import { connect }        from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Lock }           from 'react-feather';
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import MainContent      from '../../components/layout/MainContent';
import PipelineListItem from '../../components/pipelines/PipelineListItem';
import DataSourceLogo   from '../../components/data_sources/DataSourceLogo';
import {
  deleteDataSource,
  fetchDataSource,
  fetchDataSources,
  transferDataSourceToProject
} from '../../actions/data_sources';
import { fetchDataSinks } from '../../actions/data_sinks';
import { fetchPipelines } from '../../actions/pipelines';
import { fetchCurrentUser } from '../../actions/users';

class ShowDataSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSourceDeleted: false,
      errorMessage:      '',
      showDeleteModal:   false,
      showTransferForm:  false,
      showTransferModal: false,
      transferProjectId: undefined
    };

    this.handleDeleteDataSource   = this.handleDeleteDataSource.bind(this);
    this.toggleDeleteModal        = this.toggleDeleteModal.bind(this);
    this.toggleTransferModal      = this.toggleTransferModal.bind(this);
    this.enableTransferForm       = this.enableTransferForm.bind(this);
    this.changeProjectForTransfer = this.changeProjectForTransfer.bind(this);
    this.handleTransferDataSource = this.handleTransferDataSource.bind(this);
  }

  componentDidMount() {
    this.props.fetchCurrentUser();
    this.props.fetchDataSource(this.getDataSourceId());
    this.props.fetchPipelines();
    this.props.fetchDataSources();
    this.props.fetchDataSinks();
  }

  componentWillUnmount() {
  }

  getDataSourceId() {
    return parseInt(this.props.match.params.id);
  }

  handleDeleteDataSource(event) {
    event.preventDefault();

    this.props.deleteDataSource(this.getDataSourceId())
      .then(() => {
        this.setState({ dataSourceDeleted: true })
      });
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  toggleTransferModal() {
    if (
      // ignore valid project id if hiding transfer modal
      this.state.showTransferModal ||
      // validate valid project id if showing transfer modal
      !isNaN(parseInt(this.state.transferProjectId))
    ) {
      this.setState({
        showTransferModal: !this.state.showTransferModal
      });
    }
  }

  enableTransferForm() {
    this.setState({
      showTransferForm: true
    });
  }

  changeProjectForTransfer(event) {
    this.setState({
      transferProjectId: event.target.value
    });
  }

  handleTransferDataSource() {
    this.props.transferDataSourceToProject(
      this.getDataSourceId(),
      this.state.transferProjectId
    );
  }

  render() {
    const dataSource = this.props.dataSources.dataSource;

    if (this.state.dataSourceDeleted) {
      if ((dataSource !== undefined) && (dataSource.projectId !== undefined)) {
        return (<Redirect to={`/projects/${dataSource.projectId}`} />);
      } else {
        return (<Redirect to='/data_sources' />);
      }
    }

    if (dataSource === undefined) {
      return (<div></div>);
    }

    const consumingPipelines = this.props.pipelines.pipelines
      .filter(pipeline => [pipeline.dataSourceId, pipeline.joinedDataSourceId].includes(dataSource.id))
      .sort((a, b) => (a.id - b.id));

    const currentUser = this.props.users.currentUser;
    const userEmail =
      (
        ![currentUser, dataSource].includes(undefined) &&
        (dataSource.userId === currentUser.id)
      )
        ? currentUser.email
        : undefined;

    const project =
      (
        ![currentUser, dataSource].includes(undefined) &&
        (dataSource.userId === undefined) &&
        (dataSource.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === dataSource.projectId)
        : undefined;

    const projectMembership = (project !== undefined)
      ? currentUser.projectMemberships.find(_ => _.projectId === project.id)
      : undefined;

    const canEditDataSource = (
      (projectMembership === undefined) ||
      ['editor', 'administrator'].includes(projectMembership.membershipRole)
    );

    const canDeleteDataSource = (
      (projectMembership === undefined) ||
      ['administrator'].includes(projectMembership.membershipRole)
    );

    return (
      <MainContent preTitle='Data Sources' title={'#' + dataSource.id + ': ' + dataSource.name} buttonLabel='Go back to data sources' buttonLink='/data_sources'>
        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col-auto source-type-logos'>
                <div className='logo-wrap'>
                  <DataSourceLogo dataSource={dataSource} />
                </div>
              </div>
              <div className='col'>
                <h4 className='card-header-title'>
                  {userEmail !== undefined &&
                    <React.Fragment>
                      <span className='text-gray'>
                        {userEmail}
                      </span>
                      <span className='text-gray mx-2'>
                        /
                      </span>
                    </React.Fragment>
                  }
                  {project !== undefined &&
                    <React.Fragment>
                      <a href={`/projects/${project.id}`} className='text-gray'>
                        {project.name}
                      </a>
                      <span className='text-gray mx-2'>
                        /
                      </span>
                    </React.Fragment>
                  }
                  {dataSource.name}
                </h4>
              </div>
              <div className='col-auto'>
                {canEditDataSource &&
                  <Link className='btn btn-primary' to={'/data_sources/' + dataSource.id + '/edit'}>
                    Edit
                  </Link>
                }
                {canDeleteDataSource && (consumingPipelines.length === 0) &&
                  <React.Fragment>
                    <button
                      className='btn btn-white delete-btn ml-2'
                      onClick={this.toggleDeleteModal}>
                      Delete
                    </button>
                    <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
                      <Modal.Header closeButton>
                        <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Deleting a data source is irreversible. Do you want to proceed?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="white" onClick={this.toggleDeleteModal}>
                          Cancel
                        </Button>
                        <Button variant="danger" onClick={this.handleDeleteDataSource}>
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </React.Fragment>
                }
                {canDeleteDataSource && (consumingPipelines.length > 0) &&
                  <OverlayTrigger
                      placement='bottom'
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => (<Tooltip {...props}>Please delete all consuming pipelines before deleting this data source.</Tooltip>)}>
                    <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                      <button
                        className='btn btn-white delete-btn ml-2'
                        disabled={true}
                        style={{ pointerEvents : 'none' }}>
                        Delete
                      </button>
                    </div>
                  </OverlayTrigger>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-header-title'>Consuming pipelines</h4>
            {consumingPipelines.length > 0 &&
              <a className="btn btn-white btn-sm" href={`/pipelines/new?dataSourceId=${dataSource.id}`}>Create new pipeline</a>
            }
          </div>
          <div className='card-body'>
            {consumingPipelines.length > 0 &&
              <ul className='list-group list-group-flush list my-n4'>
                {consumingPipelines.map(pipeline => (
                  <PipelineListItem
                    currentUser={this.props.users.currentUser}
                    dataSinks={this.props.dataSinks.dataSinks}
                    dataSources={this.props.dataSources.dataSources}
                    history={this.props.history}
                    key={pipeline.id}
                    pipeline={pipeline} />
                ))}
              </ul>
            }
            {consumingPipelines.length === 0 &&
              <div className='text-center'>
                <p>No pipeline consumes this data source.</p>
                <a href={`/pipelines/new?dataSourceId=${dataSource.id}`} className='btn btn-primary'>Create new pipeline</a>
              </div>
            }
          </div>
        </div>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-header-title'>
              <Lock className='feather-icon mr-2' />
              Access
            </h4>
          </div>
          {dataSource.projectId === undefined && currentUser !== undefined &&
            <div className='card-body'>
              {!this.state.showTransferForm &&
                <React.Fragment>
                  <p>
                    You are the only user that can access this data source.
                  </p>
                  <button className='btn btn-outline-primary' onClick={this.enableTransferForm}>
                    Transfer data source to project
                  </button>
                </React.Fragment>
              }
              {this.state.showTransferForm &&
                <React.Fragment>
                  <div className='form-group'>
                    <label>
                    Transfer this data source to the following project:
                    </label>
                    <select
                      className='custom-select'
                      onChange={this.changeProjectForTransfer}
                      value={this.state.transferProjectId}>
                      <option value=''>-- Please choose a project</option>
                      {currentUser.projects.map(project => (
                        <option value={project.id}>#{project.id}: {project.name}</option>
                      ))}
                    </select>
                  </div>
                  <button
                    className='btn btn-primary'
                    disabled={(this.state.showTransferModal || (this.state.transferProjectId === undefined))}
                    onClick={this.toggleTransferModal}>
                    Transfer to project
                  </button>
                  <Modal show={this.state.showTransferModal} onHide={this.toggleTransferModal}>
                    <Modal.Header closeButton>
                      <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Transferring a data source to a project is irreversible.
                      </p>
                      <p className='mb-0'>
                        Do you want to proceed?
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="white" onClick={this.toggleTransferModal}>
                        Cancel
                      </Button>
                      <Button variant="danger" onClick={this.handleTransferDataSource}>
                        Transfer
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </React.Fragment>
              }
            </div>
          }
          {dataSource.projectId !== undefined && project !== undefined &&
            <div className='card-body'>
              All members of the project <a href={`/projects/${project.id}`}>#{project.id}: {project.name}</a> can access this data source.
            </div>
          }
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    dataSinks:   state.dataSinks,
    dataSources: state.dataSources,
    pipelines:   state.pipelines,
    users:       state.users
  }
}

const mapDispatchToProps = {
  deleteDataSource:            deleteDataSource,
  fetchCurrentUser:            fetchCurrentUser,
  fetchDataSource:             fetchDataSource,
  fetchDataSources:            fetchDataSources,
  fetchDataSinks:              fetchDataSinks,
  fetchPipelines:              fetchPipelines,
  transferDataSourceToProject: transferDataSourceToProject
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowDataSource);
