import React, { Component } from 'react';
import CreateEvent          from './CreateEvent';
import DeleteEvent          from './DeleteEvent';
import HaltEvent            from './HaltEvent';
import StartEvent           from './StartEvent';

class Activity extends Component {
  render() {
    if (this.props.activity === undefined) {
      return (<React.Fragment></React.Fragment>);
    }

    if (this.props.activity.actionType === 'create') {
      return (
        <CreateEvent activity={this.props.activity} />
      );
    } else if (this.props.activity.actionType === 'delete') {
      return (
        <DeleteEvent activity={this.props.activity} />
      );
    } else if (this.props.activity.actionType === 'started') {
      return (
        <StartEvent activity={this.props.activity} />
      );
    } else if (this.props.activity.actionType === 'halted') {
      return (
        <HaltEvent activity={this.props.activity} />
      );
    } else {
      return (<React.Fragment></React.Fragment>);
    }
  }
}

export default Activity;
