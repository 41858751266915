import React,
{ Component } from 'react';
import {
  Link,
  Redirect
} from 'react-router-dom';
import { signOut } from '../../actions/user_sessions';
import {
  Book,
  Code,
  Database,
  Mail,
  LogOut,
  Settings,
  Target,
  Wind
} from 'react-feather';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  renderNavItem(label, href, icon) {
    let classNames = 'nav-link d-flex align-items-center';
    if (
      (window.location.pathname !== undefined) &&
      window.location.pathname.includes(href) &&
      // e.g. do not render projects as active, when visiting admin area for projects
      !window.location.pathname.includes('admin')
    ) {
      classNames += ' active';
    }

    return (
      <li className='nav-item'>
        <Link className={classNames} to={href} role='button'>
          {icon}
          {label}
        </Link>
      </li>
    );
  }

  handleSignOut(event) {
    event.preventDefault();
    signOut();
    this.setState({ signedOut: true });
  }

  render() {
    if (this.state.signedOut === true) {
      return (<Redirect to='/sign_in' />);
    }

    return (
      <nav className='navbar navbar-expand-lg navbar-light' id='topnav'>
        <div className='container'>
          <button className='navbar-toggler me-auto' type='button' data-toggle='collapse' data-target='#navbar' aria-controls='navbar' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <a className='navbar-brand ml-3' href='/'>
            <img src='/images/logo.png' className='navbar-brand-img' alt='DataCater logo' style={{ 'maxHeight': '2rem' }} />
          </a>
          <div className='navbar-collapse ml-2 collapse' id='navbar' style={{ 'flexBasis': '100%' }}>
            <ul className='navbar-nav me-lg-auto'>
              {this.renderNavItem('Pipelines', '/pipelines', (<Code className='feather-icon mr-2' />))}
              {this.renderNavItem('Streams', '/streams', (<Wind className='feather-icon mr-2' />))}
              {this.renderNavItem('Data Sources', '/data_sources', (<Database className='feather-icon mr-2' />))}
              {this.renderNavItem('Data Sinks', '/data_sinks', (<Target className='feather-icon mr-2' />))}
            </ul>
            <div className='mt-auto'></div>
          </div>
          <div className='navbar-user'>
            <div className='dropdown'>
              <div className='clickable dropdown-toggle mr-3' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                Account
              </div>
              <div className='dropdown-menu dropdown-menu-right'>
                <a href='/settings/user/' className='dropdown-item'><Settings className='feather-icon' />  <span className='ml-1'>Settings</span></a>
                <a href='mailto:support@datacater.io' className='dropdown-item'><Mail className='feather-icon' /> <span className='ml-1'>Support</span></a>
                <a href='https://datacater.io/docs/' className='dropdown-item' target='_blank'>
                  <Book className='feather-icon' />
                  <span className='ml-1'>Docs</span>
                  {!['latest', 'unstable'].includes(process.env.REACT_APP_VERSION) &&
                    <span className='ml-2 badge badge-pill bg-primary-soft align-self-center'>{process.env.REACT_APP_VERSION}</span>
                  }
                </a>
                <a href='/sign_out/' className='dropdown-item' onClick={this.handleSignOut}><LogOut className='feather-icon' /> <span className='ml-1'>Logout</span></a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
