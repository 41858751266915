export function fetchApiCredentials() {
  const requestApiCredentials = () => ({
    type: 'REQUEST_API_CREDENTIALS'
  });

  const receivedApiCredentials = response => ({
    type:           'RECEIVE_API_CREDENTIALS',
    apiCredentials: response
  });

  return function (dispatch) {
    dispatch(requestApiCredentials());

    return fetch('/api/api_credentials',
                 { headers: { 'X-Auth-token': localStorage.getItem('userToken') }})
      .then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      )
      .then((json) => {
        dispatch(receivedApiCredentials(json));
      });
  };
}

export function addApiCredential(apiCredential) {
  const requestAddApiCredential = () => ({
    type: 'REQUEST_ADD_API_CREDENTIAL'
  });

  const receivedAddApiCredential = response => ({
    type:          'RECEIVE_ADD_API_CREDENTIAL',
    apiCredential: response
  });

  return function (dispatch) {
    dispatch(requestAddApiCredential());

    return fetch(
        '/api/api_credentials',
        {
          method:      'POST',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'Content-type': 'application/json',
            'X-Auth-token': localStorage.getItem('userToken')
          },
          body: JSON.stringify(apiCredential)
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.json();
          }
        },
        error => console.log('An error occurred.', error)
      ).then((json) => {
        dispatch(receivedAddApiCredential(json));
      });
  };
}

export function deleteApiCredential(id) {
  const requestDeleteApiCredential = () => ({
    type: 'REQUEST_DELETE_API_CREDENTIAL'
  });

  const receivedDeleteApiCredential = () => ({
    type: 'RECEIVE_DELETE_API_CREDENTIAL'
  });

  return function (dispatch) {
    dispatch(requestDeleteApiCredential());

    return fetch(
        '/api/api_credentials/' + id,
        {
          method:      'DELETE',
          credentials: 'same-origin',
          mode:        'cors',
          cache:       'no-cache',
          headers: {
            'X-Auth-token': localStorage.getItem('userToken')
          }
        }
      ).then(
        response => {
          if (response.status === 401) {
            localStorage.removeItem('userToken');
            window.location = '/sign_in';
          } else {
            return response.text();
          }
        },
        error => console.log('An error occurred.', error)
      ).then(() => {
        dispatch(receivedDeleteApiCredential());
      });
  };
}
