import React,
  { Component }          from 'react';
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch
}                        from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary'
import { PrivateRoute }  from '../components/PrivateRoute';
import MainLayout        from '../components/layout/MainLayout';
import ListDataSinks     from './data_sinks/ListDataSinks';
import NewDataSink       from './data_sinks/NewDataSink';
import ShowDataSink      from './data_sinks/ShowDataSink';
import EditDataSink      from './data_sinks/EditDataSink';
import ListDataSources   from './data_sources/ListDataSources';
import NewDataSource     from './data_sources/NewDataSource';
import ShowDataSource    from './data_sources/ShowDataSource';
import EditDataSource    from './data_sources/EditDataSource';
import Home              from './Home';
import Login             from './Login';
import SignUp            from './SignUp';
import ListProjects      from './projects/ListProjects';
import NewProject        from './projects/NewProject';
import EditProject       from './projects/EditProject';
import ShowProject       from './projects/ShowProject';
import ListPipelines     from './pipelines/ListPipelines';
import NewPipeline       from './pipelines/NewPipeline';
import EditPipeline      from './pipelines/EditPipeline';
import ListStreams       from './streams/ListStreams';
import ShowStream        from './streams/ShowStream';
import UserAccount       from './settings/UserAccount';
import ChangePassword    from './settings/ChangePassword';
import API               from './settings/API';
import NewAPI            from './settings/NewAPI';
import Admin             from './admin/Admin';
import UserAdmin         from './admin/UserAdmin';
import EditUser          from './admin/EditUser';
import NewUser           from './admin/NewUser';
import ProjectAdmin      from './admin/ProjectAdmin';
import EditProjectAdmin  from './admin/EditProject';
import NewProjectAdmin   from './admin/NewProject';
import Catalog           from './catalog/Catalog';
import NotFoundError     from './NotFoundError';
import FatalError        from './FatalError';

class DataCaterApp extends Component {
  render() {
    return (
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={FatalError}
          onReset={() => {
            // reset the state of the app so the error doesn't happen again
          }}
        >
        <MainLayout>
          <Switch>
            <Route exact path='/' render={() => (
              <Redirect to='/home' />
            )}/>

            <PrivateRoute exact path='/home' component={Home} />
            <Route exact path='/sign_in' component={Login} />
            <Route exact path='/sign_up' component={SignUp} />

            <PrivateRoute exact path='/data_sinks' component={ListDataSinks} />
            <PrivateRoute exact path='/data_sinks/new' component={NewDataSink} />
            <PrivateRoute exact path='/data_sinks/:id' component={ShowDataSink} />
            <PrivateRoute exact path='/data_sinks/:id/edit' component={EditDataSink} />

            <PrivateRoute exact path='/data_sources' component={ListDataSources} />
            <PrivateRoute exact path='/data_sources/new' component={NewDataSource} />
            <PrivateRoute exact path='/data_sources/:id' component={ShowDataSource} />
            <PrivateRoute exact path='/data_sources/:id/edit' component={EditDataSource} />

            <PrivateRoute exact path='/pipelines' component={ListPipelines} />
            <PrivateRoute exact path='/pipelines/new' component={NewPipeline} />
            <PrivateRoute exact path='/pipelines/:id/edit' component={EditPipeline} />

            <PrivateRoute exact path='/projects' component={ListProjects} />
            <PrivateRoute exact path='/projects/new' component={NewProject} />
            <PrivateRoute exact path='/projects/:id' component={ShowProject} />
            <PrivateRoute exact path='/projects/:id/edit' component={EditProject} />

            <PrivateRoute exact path='/streams' component={ListStreams} />
            <PrivateRoute exact path='/streams/:id' component={ShowStream} />

            <PrivateRoute exact path='/repository' component={Catalog} />

            <PrivateRoute exact path='/settings/user' component={UserAccount} />
            <PrivateRoute exact path='/settings/password' component={ChangePassword} />
            <PrivateRoute exact path='/settings/api' component={API} />
            <PrivateRoute exact path='/settings/api/new' component={NewAPI} />

            <PrivateRoute exact path='/admin' component={Admin} />
            <PrivateRoute exact path='/admin/users' component={UserAdmin} />
            <PrivateRoute exact path='/admin/users/new' component={NewUser} />
            <PrivateRoute exact path='/admin/users/:id/edit' component={EditUser} />
            <PrivateRoute exact path='/admin/projects' component={ProjectAdmin} />
            <PrivateRoute exact path='/admin/projects/new' component={NewProjectAdmin} />
            <PrivateRoute exact path='/admin/projects/:id/edit' component={EditProjectAdmin} />

            <Route exact path='/404' component={NotFoundError} />
            <Redirect to='/404' />
          </Switch>
        </MainLayout>
        </ErrorBoundary>
      </BrowserRouter>
    );
  }
}

export default DataCaterApp;
