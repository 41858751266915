import React, { Component } from 'react';
import RetrieveColumns      from '../RetrieveColumns';
import RetrieveTables       from '../RetrieveTables';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class BigQueryConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSource,
      dataSources,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc,
      resetRetrieveColumnsFunc,
      resetRetrieveTablesFunc,
      retrieveColumnsFromDataSourceFunc,
      retrieveTablesFromDataSourceFunc
    } = this.props;

    const flatColumns = dataSources.columns
      .map(column => column.name)
      .sort();

    const flatTables = dataSources.tables
      .map(table => table.name)
      .sort();

    const retrievingTablesIsPossible =
      ![undefined, ''].includes(dataSource.password) &&
      ![undefined, ''].includes(dataSource.databaseName) &&
      ![undefined, ''].includes(dataSource.bucketName);

    const retrievingColumnsIsPossible =
      ![undefined, ''].includes(dataSource.password) &&
      ![undefined, ''].includes(dataSource.databaseName) &&
      ![undefined, ''].includes(dataSource.bucketName) &&
      ![undefined, ''].includes(dataSource.databaseSchemaName);

    const serviceAccountEmail = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.serviceAccountEmail !== undefined)
      ? dataSource.connectorConfig.serviceAccountEmail
      : '';

    const primaryKeyColumn = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.primaryKeyColumn !== undefined)
      ? dataSource.connectorConfig.primaryKeyColumn
      : '';

    const timestampColumn = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.timestampColumn !== undefined)
      ? dataSource.connectorConfig.timestampColumn
      : '';

    const syncIntervalMillis = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.syncIntervalMillis !== undefined)
      ? dataSource.connectorConfig.syncIntervalMillis
      : '';

    const cdcMode = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.cdcMode !== undefined)
      ? dataSource.connectorConfig.cdcMode
      : 'bulk';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the credentials unless you want to change them.</span>)
      : (<span className='text-danger'>*</span>);

    const kafkaConnectConfig = (dataSource.connectorConfig !== undefined && dataSource.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSource.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Change Data Capture', key: 'cdc' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item'>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              label={(<React.Fragment>Google Cloud credentials (JSON){editPasswordNotice}</React.Fragment>)}
              name='password'
              onChangeFunc={handleChangeFunc}
              type='password'
              value={dataSource.password} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Service account e-mail address'
              name='serviceAccountEmail'
              onChangeFunc={handleConfigChangeFunc}
              value={serviceAccountEmail} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Project name'
              name='databaseName'
              onChangeFunc={handleChangeFunc}
              value={dataSource.databaseName} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Dataset name'
              name='bucketName'
              onChangeFunc={handleChangeFunc}
              value={dataSource.bucketName} />
            <RetrieveTables
              dataSource={dataSource}
              dataSources={dataSources}
              errorMessages={errorMessages}
              failedFetchErrorMessage='Fetching the table names from the BigQuery dataset failed. Please check your connection credentials.'
              handleChangeFunc={handleChangeFunc}
              missingOptionsErrorMessage='Please provide your Google Cloud credentials, the name of the BigQuery project, and the name of the BigQuery dataset.'
              resetRetrieveTablesFunc={resetRetrieveTablesFunc}
              retrieveTablesFromDataSourceFunc={retrieveTablesFromDataSourceFunc}
              retrievingTablesIsPossible={retrievingTablesIsPossible}
              tableAttributeName='databaseSchemaName'
              tables={flatTables} />
            <RetrieveColumns
              columnAttributeName='primaryKeyColumn'
              columns={flatColumns}
              dataSource={dataSource}
              dataSources={dataSources}
              failedFetchErrorMessage='Fetching the column names from the BigQuery dataset failed. Please check your connection credentials.'
              handleChangeFunc={handleConfigChangeFunc}
              label='Primary key column'
              missingOptionsErrorMessage='Please provide your Google Cloud credentials, the name of the BigQuery project, the name of the BigQuery dataset, and the name of the BigQuery table.'
              resetRetrieveColumnsFunc={resetRetrieveColumnsFunc}
              retrieveColumnsFromDataSourceFunc={retrieveColumnsFromDataSourceFunc}
              retrievingColumnsIsPossible={retrievingColumnsIsPossible}
              value={primaryKeyColumn} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Sync interval (ms)'
              name='syncIntervalMillis'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='3600000'
              value={syncIntervalMillis} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'cdc') &&
          <React.Fragment>
            <div className='form-group'>
              <label>Change Data Capture mode</label>
              <select
                className='custom-select'
                name='cdcMode'
                onChange={handleConfigChangeFunc}
                value={cdcMode}>
                <option value='bulk'>BULK: Fetch all data at each sync</option>
                <option value='incrementing'>INCREMENTING: Use the primary key column to fetch data that have been inserted since the last sync</option>
                <option value='timestamp'>TIMESTAMP: Use the timestamp column to fetch data that have been inserted or updated since the last sync</option>
                <option value='timestamp+incrementing'>TIMESTAMP/INCREMENTING: Use the timestamp column and the primary key column to fetch data that have been inserted or updated since the last sync</option>
              </select>
            </div>
            {['timestamp', 'timestamp+incrementing'].includes(cdcMode) &&
              <RetrieveColumns
                columnAttributeName='timestampColumn'
                columns={flatColumns}
                dataSource={dataSource}
                dataSources={dataSources}
                failedFetchErrorMessage='Fetching the column names from the BigQuery dataset failed. Please check your connection credentials.'
                handleChangeFunc={handleConfigChangeFunc}
                label='Timestamp column'
                missingOptionsErrorMessage='Please provide your Google Cloud credentials, the name of the BigQuery project, the name of the BigQuery dataset, and the name of the BigQuery table.'
                resetRetrieveColumnsFunc={resetRetrieveColumnsFunc}
                retrieveColumnsFromDataSourceFunc={retrieveColumnsFromDataSourceFunc}
                retrievingColumnsIsPossible={retrievingColumnsIsPossible}
                value={timestampColumn} />
            }
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSources={dataSources}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default BigQueryConfig;
