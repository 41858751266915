import React,
       { Component } from 'react';
import { Link }      from 'react-router-dom';
import { Clock,
         Code,
         Database,
         Target }    from 'react-feather';

class DeleteEvent extends Component {
  humanizeItemType(activity) {
    return activity.itemType
      .replace('_', ' ')
      .split(' ')
      .map(_ => _.charAt(0).toUpperCase() + _.substr(1).toLowerCase())
      .join(' ');
  }

  getItemLink(activity, name) {
    let url = '/' + activity.itemType + 's/' + activity.itemId;

    if (activity.itemType === 'pipeline') {
      url += '/edit';
    }

    return (
      <Link to={url}>{name}</Link>
    );
  }

  getUserName(activity) {
    if (activity.userName !== undefined && activity.userName.length > 0) {
      return activity.userName;
    } else {
      return 'An user';
    }
  }

  getTimeDifference(activity) {
    const eventTime = new Date(activity.createdAt);
    const now       = new Date();
    const diffMs    = now - eventTime;

    const diffDays = Math.floor(diffMs / 86400000); // days
     if (diffDays >= 1) {
      return diffDays + 'd ago';
    }

    const diffHours = Math.floor((diffMs % 86400000) / 3600000); // hours
    if (diffHours >= 1) {
      return diffHours + 'h ago';
    }

    const diffMinutes = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffMinutes >= 1) {
      return diffMinutes + 'm ago';
    }

    return 'a moment ago';
  }

  render() {
    const activity = this.props.activity;

    if (this.props.activity === undefined) {
      return (<div />);
    }

    let itemName = activity.itemName || '';

    if (itemName === '' && activity.itemType === 'pipeline') {
      itemName = 'Untitled pipeline';
    }

    // show icon depending on item type
    let icon = undefined;
    if (activity.itemType === 'pipeline') {
      icon = (<Code className='feather-icon' />);
    } else if (activity.itemType === 'data_source') {
      icon = (<Database className='feather-icon' />);
    } else if (activity.itemType === 'data_sink') {
      icon = (<Target className='feather-icon' />);
    }

    return (
      <div className='list-group-item'>
        <div className='row'>
          <div className='col-auto'>
            <div className='avatar avatar-sm'>
              <div className='avatar-title font-size-lg bg-primary-soft rounded-circle text-primary'>
                {icon}
              </div>
            </div>
          </div>
          <div className='col ml-n2'>
            <h5 className='mb-1'>
              {this.getUserName(activity)}
            </h5>
            <p className='small mb-0'>
              Deleted the {activity.itemType.replace('_', ' ')} {this.getItemLink(activity, itemName)}.
            </p>
            <small className='text-muted d-flex align-items-center pt-1'>
              <Clock className='feather-icon' /> {this.getTimeDifference(activity)}
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteEvent;
