import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import { ArrowLeft, Trash2 }   from 'react-feather';
import MainContent             from '../../components/layout/MainContent';
import ProjectMembershipAdmin  from '../../components/projects/ProjectMembershipAdmin';
import Navigation              from '../../components/settings/Navigation';
import { deepCopy }            from '../../helpers/deepCopy';
import { isValidEmailAddress } from '../../helpers/isValidEmailAddress';
import { fetchCurrentUser }    from '../../actions/users';
import { fetchInfo }           from '../../actions/info';
import {
  deleteProject,
  fetchProject,
  updateProject
} from '../../actions/projects';
import {
  fetchProjectMembershipsOfProject,
  replaceProjectMembershipsOfProject
} from '../../actions/project_memberships';

class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage:       '',
      newMemberEmail:     '',
      newMemberRole:      '',
      showDeleteModal:    false,
      project:            {},
      projectMemberships: []
    };

    this.handleAddMember            = this.handleAddMember.bind(this);
    this.handleChange               = this.handleChange.bind(this);
    this.handleChangeNewMember      = this.handleChangeNewMember.bind(this);
    this.handleChangeExistingMember = this.handleChangeExistingMember.bind(this);
    this.handleSubmit               = this.handleSubmit.bind(this);
    this.removeExistingMember       = this.removeExistingMember.bind(this);
    this.removeProject              = this.removeProject.bind(this);
    this.toggleDeleteModal          = this.toggleDeleteModal.bind(this);
  }

  componentDidMount() {
    this.props.fetchInfo();
    this.props.fetchCurrentUser();
    this.props.fetchProject(this.getProjectId(), true)
      .then(() => this.setState({ project: this.props.projects.project }));
    this.props.fetchProjectMembershipsOfProject(this.getProjectId())
      .then(() => this.setState({ projectMemberships: this.props.projectMemberships.projectMemberships }));
  }

  handleAddMember(event) {
    event.preventDefault();
    const existingMemberEmails = this.state.projectMemberships
      .map(membership => membership.userEmail);

    if (
      // Ensure that email is not empty
      ![undefined, ''].includes(this.state.newMemberEmail) &&
      // Verify that no existing membership uses this email
      !existingMemberEmails.includes(this.state.newMemberEmail) &&
      // Verify correctness of provided email
      isValidEmailAddress(this.state.newMemberEmail) &&
      // Verify correctness of provided role
      ['viewer', 'editor', 'administrator'].includes(this.state.newMemberRole)
    ) {
      let projectMemberships = this.state.projectMemberships;
      // add member to list
      projectMemberships.push({
        userEmail:       this.state.newMemberEmail,
        membershipRole:  this.state.newMemberRole
      });
      this.setState({
        newMemberEmail:     '',
        newMemberRole:      '',
        projectMemberships: projectMemberships
      });
    }
  }

  handleChange(event) {
    let project = this.state.project;

    if (event.target.type === 'number') {
      project[event.target.name] = parseInt(event.target.value);
    } else {
      project[event.target.name] = event.target.value;
    }

    this.setState({ project: project });
  }

  handleChangeNewMember(event) {
    if (event.target.name === 'userEmail') {
      this.setState({ newMemberEmail: event.target.value });
    } else if (event.target.name === 'membershipRole') {
      this.setState({ newMemberRole: event.target.value });
    }
  }

  handleChangeExistingMember(event) {
    const attributeName = event.target.name;
    const userEmail = event.target.dataset.userEmail;

    let projectMemberships = this.state.projectMemberships;
    const membershipIndex = deepCopy(projectMemberships)
      .findIndex(membership => membership.userEmail === userEmail);

    if (membershipIndex !== -1) {
      if (attributeName === 'enableNotifications') {
        const toggledNotifications = (projectMemberships[membershipIndex].enableNotifications === false)
          ? true
          : false;
        projectMemberships[membershipIndex].enableNotifications = toggledNotifications;
      } else {
        projectMemberships[membershipIndex].membershipRole = event.target.value;
      }
    }

    this.setState({ projectMemberships: projectMemberships });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.project.name.length === 0) {
      this.setState({
        errorMessage: 'Please provide a name.'
      });
    } else {
      this.props.updateProject(this.state.project, true).then((response) => {
        if (response.errorMessage !== undefined) {
          this.setState({
            errorMessage: response.errorMessage
          });
        } else {
          this.props.replaceProjectMembershipsOfProject(
            this.getProjectId(),
            this.state.projectMemberships
          ).then(() =>
            this.setState({
              errorMessage:       '',
              projectMemberships: this.props.projectMemberships.projectMemberships,
              projectUpdated:     true
            })
          );
        }
      });
    }
  }

  removeExistingMember(userEmail) {
    let projectMemberships = deepCopy(this.state.projectMemberships)
      .filter(membership => membership.userEmail !== userEmail);

    this.setState({ projectMemberships: projectMemberships });
  }

  getProjectId() {
    return parseInt(this.props.match.params.id);
  }

  removeProject() {
    this.props.deleteProject(this.getProjectId(), true)
      .then((response) => this.setState({ projectUpdated: true }));
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  render() {
    const currentUser = this.props.users.currentUser;

    if ((currentUser === undefined) || (currentUser.isSuperUser !== true)) {
      return (<div></div>);
    }

    if (this.state.projectUpdated) {
      return (<Redirect to='/admin/projects' />);
    }

    const project = this.state.project;

    return (
      <MainContent preTitle='Settings' title='Manage projects'>
        <div className='card card-fill'>
          <div className='card-body'>
            <div className='row mb-4 mt-n3'>
              <div className='col-12'>
                <Navigation currentUser={currentUser} info={this.props.info.info} />
              </div>
            </div>
            {project !== undefined &&
              <React.Fragment>
                <div className='row align-items-center mt-n2'>
                  <div className='col overflow-hidden'>
                    <h3 className='card-header-title'>Edit project #{project.id}: {project.name}</h3>
                  </div>
                  <div className='col-auto'>
                    <button
                      className='btn btn-white delete-btn d-flex align-items-center'
                      onClick={this.toggleDeleteModal}>
                      <Trash2 className='feather-icon mr-2' />Delete project
                    </button>
                    <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
                      <Modal.Header closeButton>
                        <Modal.Title className='mb-0'>Danger Zone</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          Deleting a project is irreversible and will automatically remove all ressources (e.g., data sources, data sinks, pipelines) assigned to the project.
                        </p>
                        <p className='mb-0'>
                          Do you want to proceed?
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="white" onClick={this.toggleDeleteModal}>
                          Cancel
                        </Button>
                        <Button variant="danger" onClick={this.removeProject}>
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className='col-auto'>
                    <a className='btn btn-white d-flex align-items-center' href='/admin/projects'>
                      <ArrowLeft className='feather-icon' />
                      Go back to projects
                    </a>
                  </div>
                </div>
                <hr className='mb-4' />
                {this.state.errorMessage.length > 0 &&
                  <div className='alert alert-danger mb-4 rounded-0'>
                  {this.state.errorMessage}
                  </div>
                }
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='mb-4'>General information</h4>
                    <form>
                      <div className='form-group'>
                        <label htmlFor='name'>Name:</label>
                        <input
                          type='text'
                          className='form-control'
                          name='name'
                          onChange={this.handleChange}
                          value={project.name || ''} />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='description'>Description:</label>
                        <input
                          type='text'
                          className='form-control'
                          name='description'
                          onChange={this.handleChange}
                          value={project.description || ''} />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='maxNumberOfPipelines'>Allowed number of pipelines (leave empty for unlimited):</label>
                        <input
                          type='number'
                          className='form-control'
                          name='maxNumberOfPipelines'
                          onChange={this.handleChange}
                          value={project.maxNumberOfPipelines || ''} />
                      </div>
                      <hr className='my-4' />
                      <h4 className='my-4'>Manage project members</h4>
                      <ProjectMembershipAdmin
                        handleAddMemberFunc={this.handleAddMember}
                        handleChangeExistingMemberFunc={this.handleChangeExistingMember}
                        handleChangeNewMemberFunc={this.handleChangeNewMember}
                        newMemberEmail={this.state.newMemberEmail}
                        newMemberRole={this.state.newMemberRole}
                        projectMemberships={this.state.projectMemberships}
                        removeExistingMemberFunc={this.removeExistingMember} />
                      <button
                        className='btn btn-primary'
                        onClick={this.handleSubmit}
                        type='submit'>
                        Save changes
                      </button>
                    </form>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    info:               state.info,
    projects:           state.projects,
    projectMemberships: state.projectMemberships,
    users:              state.users
  }
}

const mapDispatchToProps = {
  deleteProject:                      deleteProject,
  fetchCurrentUser:                   fetchCurrentUser,
  fetchInfo:                          fetchInfo,
  fetchProject:                       fetchProject,
  fetchProjectMembershipsOfProject:   fetchProjectMembershipsOfProject,
  replaceProjectMembershipsOfProject: replaceProjectMembershipsOfProject,
  updateProject:                      updateProject
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
