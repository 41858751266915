import React, { Component } from 'react';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-json";

class SnowflakeConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'general'
    };
  }

  render() {
    const {
      dataSink,
      dataSinks,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc
    } = this.props;

    const snowflakeUrl = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.snowflakeUrl !== undefined)
      ? dataSink.connectorConfig.snowflakeUrl
      : '';

    const privateKey = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.privateKey !== undefined)
      ? dataSink.connectorConfig.privateKey
      : '';

    const schemaName = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.schemaName !== undefined)
      ? dataSink.connectorConfig.schemaName
      : '';

    const kafkaConnectConfig = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.kafkaConnectConfig !== undefined)
      ? dataSink.connectorConfig.kafkaConnectConfig
      : '{}';

    const tabs = [
      { name: 'General', key: 'general' },
      { name: 'Advanced', key: 'advanced' },
    ];

    return (
      <React.Fragment>
        <ul className='nav nav-pills mb-4'>
          {tabs.map(tab => (
            <li className='nav-item' key={tab.name}>
              {(this.state.currentTab === tab.key) &&
                <a href='#' className='nav-link active'>
                  {tab.name}
                </a>
              }
              {(this.state.currentTab !== tab.key) &&
                <a href='#' className='nav-link' onClick={(e) => {e.preventDefault();this.setState({ currentTab: tab.key });}}>
                  {tab.name}
                </a>
              }
            </li>
          ))}
        </ul>
        {(this.state.currentTab === 'general') &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Snowflake account URL'
              name='snowflakeUrl'
              onChangeFunc={handleConfigChangeFunc}
              subLabel={(<React.Fragment>Typically in the format <span className='font-italic'>https://[account_name].[region].snowflakecomputing.com</span>.</React.Fragment>)}
              value={snowflakeUrl} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Username'
              name='username'
              onChangeFunc={handleChangeFunc}
              value={dataSink.username} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Private key'
              name='privateKey'
              onChangeFunc={handleConfigChangeFunc}
              value={privateKey} />
            <ConfigForm
              errorMessages={errorMessages}
              isRequired={true}
              label='Database name'
              name='databaseName'
              onChangeFunc={handleChangeFunc}
              value={dataSink.databaseName} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Schema name'
              name='schemaName'
              onChangeFunc={handleConfigChangeFunc}
              placeholder='public'
              value={schemaName} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Table name'
              name='bucketName'
              onChangeFunc={handleConfigChangeFunc}
              subLabel={(<React.Fragment>Leave empty to use <i>datacater_pipeline_[pipeline_id]</i> as table name.</React.Fragment>)}
              value={dataSink.bucketName} />
          </React.Fragment>
        }
        {(this.state.currentTab === 'advanced') &&
          <div className="mb-4">
            <label>Overwrite Kafka Connect configuration<span className="ml-2 text-gray font-size-sm"><span>Please provide the configuration as a JSON object, e.g., <code>{'{ "configuration.name": "value" }'}</code></span></span></label>
            <AceEditor
                placeholder=''
                mode='json'
                theme='xcode'
                className=''
                onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
                onChange={(value, event) => {
                  handleConfigChangeFunc({
                    "target": {
                      "name": "kafkaConnectConfig",
                      "value": value
                    }
                  });
                }}
                fontSize={13}
                height='200px'
                width='100%'
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={kafkaConnectConfig}
                tabSize={2} />
          </div>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSinks={dataSinks}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default SnowflakeConfig;
