import React, { Component } from 'react';
import { connect }          from 'react-redux';
import MainContent          from '../../components/layout/MainContent';
import PipelineListItem     from '../../components/pipelines/PipelineListItem';
import { fetchPipelines }   from '../../actions/pipelines';
import { fetchDataSources } from '../../actions/data_sources';
import { fetchDataSinks }   from '../../actions/data_sinks';
import { fetchCurrentUser } from '../../actions/users';
import { Search }           from 'react-feather';

class ListPipelines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: ''
    };
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
  }

  componentDidMount() {
    this.props.fetchCurrentUser();
    this.props.fetchPipelines();
    this.props.fetchDataSources();
    this.props.fetchDataSinks();
  }

  updateSearchQuery(event) {
    this.setState({
      searchQuery: event.target.value
    });
  }

  render() {
    const history = this.props.history;
    const searchTokens = this.state.searchQuery.toLowerCase().trim().split(' ');
    const pipelines = this.props.pipelines.pipelines
      .map(pipeline => Object.assign({}, { name: 'Untitled Pipeline' }, pipeline))
      .filter(pipeline => searchTokens
                            .map(_ => pipeline.name.toLowerCase().includes(_))
                            .filter(_ => _)
                            .length === searchTokens.length)
      .sort(function(a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

    return (
      <MainContent preTitle='Pipelines' title='List pipelines' buttonLabel='Create pipeline' buttonLink='/pipelines/new'>
        {!this.props.pipelines.fetchingPipelines && this.props.pipelines.pipelines.length === 0 &&
          <div className='text-center mt-4'>
            <img src='/images/illustrations/void.svg' alt='No pipeline available' className='img-fluid mb-4' style={{'maxWidth': '272px'}} />
            <p className='mb-0'>
              No pipeline available.
            </p>
          </div>
        }
        {(this.props.pipelines.fetchingPipelines || (!this.props.pipelines.fetchingPipelines && this.props.pipelines.pipelines.length > 0)) &&
          <div className='card'>
            <div className='card-header'>
              <form>
                <div className='input-group input-group-flush input-group-merge'>
                  <input
                    type='search'
                    className='form-control form-control-prepended search'
                    onChange={this.updateSearchQuery}
                    placeholder='Search pipelines'
                    value={this.state.searchQuery} />
                  <div className='input-group-prepend'>
                    <div className='input-group-text'>
                      <Search className='feather-icon' />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className='card-body'>
              {pipelines.length > 0 &&
                <ul className='list-group list-group-flush list my-n4'>
                  {pipelines.map(pipeline => (
                    <PipelineListItem
                       currentUser={this.props.users.currentUser}
                       dataSinks={this.props.dataSinks.dataSinks}
                       dataSources={this.props.dataSources.dataSources}
                       history={history}
                       key={pipeline.id}
                       pipeline={pipeline} />
                  ))}
                </ul>
              }
              {!this.props.pipelines.fetchingPipelines && pipelines.length === 0 && this.props.pipelines.pipelines.length > 0 &&
                <div className='text-center'>
                  <img src='/images/illustrations/no_search_result.svg' alt='No pipeline available' className='img-fluid mb-4' style={{'maxWidth': '272px'}} />
                  <p className='mb-0'>
                    No matching pipeline was found. Please refine your search query.
                  </p>
                </div>
              }
            </div>
          </div>
        }
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    dataSinks:   state.dataSinks,
    dataSources: state.dataSources,
    pipelines:   state.pipelines,
    users:       state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser: fetchCurrentUser,
  fetchDataSinks:   fetchDataSinks,
  fetchDataSources: fetchDataSources,
  fetchPipelines:   fetchPipelines
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPipelines);
