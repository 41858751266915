import React, { Component } from 'react';

class ChangesStatus extends Component {
  render() {
    if (this.props.updatingPipeline ||
        this.props.unpersistedChanges) {
      return (<React.Fragment>Saving...</React.Fragment>);
    } else {
      return (<React.Fragment></React.Fragment>);
    }
  }
}

export default ChangesStatus;
