import React, { Component }        from 'react';
import { humanizeDataType }        from '../../../../../../helpers/humanizeDataType';
import AttributeTransformationList from '../../../pipeline_step/AttributeTransformationList';
import Config                      from './Config';

class Attribute extends Component {
  getTransformersForAttribute(pipelineAttribute, attribute, dataType, fullDataType) {
    return this.props.transformers
      // do not allow to apply UDFs to lists
      .filter(transformer =>
        (transformer.key !== 'user-defined-transformation') ||
        (!fullDataType.includes('array_'))
      )
      // select only transformation functions that can be applied to
      // the given data type
      .filter(transformer =>
        (transformer.restrictToDataType === undefined) ||
        (transformer.restrictToDataType.includes(dataType)) ||
        (
          (pipelineAttribute.transformationAction !== undefined) &&
          (pipelineAttribute.transformationAction.length > 0) &&
          (transformer.key === pipelineAttribute.transformationAction)
        )
      )
      // do not allow to define user-defined transformations
      // for date, time or timestamp
      .filter(transformer =>
        (!['date', 'time-millis', 'timestamp-millis'].includes(dataType)) ||
        (transformer.key !== 'user-defined-transformation')
      );
  }

  render() {
    const {
      attributes,
      canEditPipeline,
      editColumn,
      handleChangeFunc,
      pipelineStep,
      sortPosition
    } = this.props;

    const attribute = attributes
      .find(entry => parseInt(entry.id) === editColumn.attributeId);
    const pipelineAttribute = pipelineStep.attributes
      .find(attribute => attribute.transformAttributeId === editColumn.attributeId);

    const dataType = attribute.dataType;
    const flattenedDataType = dataType.replace('array_', '');

    const transformers = this.getTransformersForAttribute(
      pipelineAttribute,
      attribute,
      flattenedDataType,
      dataType);

    // find the assigned transformation
    const transformer = transformers
      .find(transformer => transformer.key === pipelineAttribute.transformationAction);

    return (
      <React.Fragment>
        <div className='row border-bottom py-4'>
          <div className='col'>
            <h3 className='mb-0 text-nowrap'>
              {attribute.name}
            </h3>
          </div>
          <div className='col-auto'>
            <h3 className='mb-0'>
              {humanizeDataType(dataType)}
            </h3>
          </div>
        </div>
        {(transformer == null) &&
          <AttributeTransformationList
            attribute={attribute}
            handleChangeFunc={handleChangeFunc}
            pipelineStep={pipelineStep}
            transformersForAttribute={transformers}
          />
        }
        {(transformer != null) &&
          <Config
            attribute={attribute}
            attributes={attributes}
            canEditPipeline={canEditPipeline}
            dataType={dataType}
            dataSourceMappings={this.props.dataSourceMappings}
            editColumn={editColumn}
            flattenedDataType={flattenedDataType}
            handleChangeFunc={handleChangeFunc}
            pipelineAttribute={pipelineAttribute}
            pipelineStep={pipelineStep}
            sortPosition={sortPosition}
            transformationState={this.props.transformationState}
            transformer={transformer}
            />
        }
      </React.Fragment>
    );
  }
}

export default Attribute;
