import React, {Component} from 'react';
import Attribute          from './Attribute';
import {Button}           from 'react-bootstrap';

class Edit extends Component {
  render () {
    return (
      <React.Fragment>
        <Attribute
          attributes={this.props.attributes}
          canEditPipeline={this.props.canEditPipeline}
          dataSourceMappings={this.props.dataSourceMappings}
          editColumn={this.props.editColumn}
          handleChangeFunc={this.props.handleChangeFunc}
          pipelineStep={this.props.pipelineStep}
          sortPosition={this.props.sortPosition}
          transformationState={this.props.transformationState}
          transformers={this.props.transformers} />

        <div className='datacater-context-bar-button-group border-top d-flex align-items-center bg-white mx-n4 px-4 datacater-context-bar-fixed-element'>
          <Button
            className='w-100'
            onClick={this.props.hideContextBarFunc}
            variant='white'>
            Close sidebar
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default Edit;
