import React, { Component } from 'react';

class DataSinkLogo extends Component {
  render() {
    const {
      dataSink,
    } = this.props;

    const sinksWithoutLogo = ['rest'];

    const value = dataSink.sinkType || dataSink.value;

    if (sinksWithoutLogo.includes(value)) {
      return (
        <div className='avatar w-100 h-100'>
          <div className='avatar-title font-size-sm bg-primary-soft rounded-circle text-black font-weight-bold'>
            {value.toUpperCase()}
          </div>
        </div>
      )
    } else {
      return (
        <img
          src={'/images/' + value + '-logo.png'}
          alt={value + ' Logo'} />
      );
    }
  }
}

export default DataSinkLogo;
