import React, { Component }       from 'react';
import { connect }                from 'react-redux';
import {
  AlertTriangle,
  RefreshCcw,
  PlayCircle, Code
} from 'react-feather';
import { Modal }                  from 'react-bootstrap';
import CodeView                   from '../../components/pipelines/designer/codeview/CodeView';
import Transformer                from '../../pipelines/Transformer';
import ChangesStatus              from '../../components/pipelines/form/ChangesStatus';
import DataSinkPage               from '../../components/pipelines/designer/pages/DataSinkPage';
import DeploymentsPage            from '../../components/pipelines/designer/pages/DeploymentsPage';
import InstructionsPage           from '../../components/pipelines/designer/pages/InstructionsPage';
import SettingsPage               from '../../components/pipelines/designer/pages/SettingsPage';
import JoinPage                   from '../../components/pipelines/designer/pages/JoinPage';
import StepsPage                  from '../../components/pipelines/designer/pages/StepsPage';
import PipelineNameForm           from '../../components/pipelines/form/PipelineNameForm';
import PipelineDesignerNavigation from '../../components/pipelines/form/PipelineDesignerNavigation';
import { deepCopy }               from '../../helpers/deepCopy';
import {
  fetchDataSinks,
  getNumberOfRecordsInDataSink
} from '../../actions/data_sinks';
import {
  fetchDataSources,
  getNumberOfRecordsInDataSource,
  getNumberOfRecordsInJoinedDataSource,
} from '../../actions/data_sources';
import {
  ingestFileIntoPipeline,
  resetFileUpload
} from '../../actions/flat_files';
import {
  compileExecutable,
  deleteExecutable,
  deletePipeline,
  duplicatePipeline,
  exportPipeline,
  fetchExecutables,
  fetchFlatFilesOfPipeline,
  fetchMetricsOfPipeline,
  fetchPipeline,
  fetchYamlOfPipeline,
  fetchPipelineExecutableLogs,
  fetchSinkConnectorOfPipeline,
  fetchSourceConnectorOfPipeline,
  fetchJoinedSourceConnectorOfPipeline,
  haltExecutable,
  recreateSourceConnectorOfPipeline,
  resetPipeline,
  resetErrorMessage,
  resetOffsetOfPipeline,
  resetOffsetOfSinkConnectorOfPipeline,
  restartSinkConnectorOfPipeline,
  restartSourceConnectorOfPipeline,
  restartJoinedSourceConnectorOfPipeline,
  startExecutable,
  transferPipelineToProject,
  updateDataSinkOfPipeline,
  updateExecutableName,
  updateJoinedDataSourceOfPipeline,
  updatePipeline
} from '../../actions/pipelines';
import {
  addDataSourceProfileAttribute,
  deleteDataSourceProfileAttribute,
  fetchDataSourceProfile,
  fetchJoinedDataSourceProfile,
  fetchJoinedSampleRecords,
  fetchSampleRecords,
  resetJoinedDataSourceProfile,
} from '../../actions/data_source_profiles';
import {
  createDataSinkProfile,
  addDataSinkProfileAttribute,
  deleteDataSinkProfileAttribute,
  updateDataSinkProfileAttribute,
  fetchDataSinkProfile,
  resetDataSinkProfile
} from '../../actions/data_sink_profiles';
import {
  addDataSink,
  deleteDataSink
} from '../../actions/data_sinks';
import {
  applyPipelineToSampleRecords,
  applyTransformationToCachedSampleRecords,
  profileSampleRecords,
  resetSampleRecordsCache
} from '../../actions/pipeline_designer';
import {
  executeTransformation,
  resetTransformationError
} from '../../actions/transformations';
import {
  fetchCurrentUser
} from '../../actions/users';

class EditPipeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // storing, whether a new column has been added, in the state
      // is a bit nasty but will speed up the componentDidMount function
      addedColumn: false,
      editColumn: undefined,
      contextBarActive: false,
      currentPage: 'instructions',
      currentStep: undefined,
      dataSinkProfileAttributes: [],
      errorMessage: '',
      loadingSampleRecords: true,
      pipeline: {
        name: '',
        description: '',
        dataSourceId: undefined,
        pipelineSteps: [],
        pipelineAssertions: []
      },
      codeView: false,
      pipelineUpdated: false,
      pipelineUpdatedAt: undefined,
      unpersistedChanges: false
    };

    this.monitorChanges = this.monitorChanges.bind(this);
    this.updateSampleRecords = this.updateSampleRecords.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleJoinConfigChange = this.handleJoinConfigChange.bind(this);
    this.selectDataSink = this.selectDataSink.bind(this);
    this.resetDataSink = this.resetDataSink.bind(this);
    this.selectJoinedDataSource = this.selectJoinedDataSource.bind(this);
    this.resetJoinedDataSource = this.resetJoinedDataSource.bind(this);
    this.editColumn = this.editColumn.bind(this);
    this.handleAssertionChange = this.handleAssertionChange.bind(this);
    this.handlePipelineStepChange = this.handlePipelineStepChange.bind(this);
    this.delayedReloadSampleRecords = this.delayedReloadSampleRecords.bind(this);
    this.handleDataSinkProfileChange = this.handleDataSinkProfileChange.bind(this);
    this.handleMultipleDataSinkProfileChanges = this.handleMultipleDataSinkProfileChanges.bind(this);
    this.handleStartProfilingOfSink = this.handleStartProfilingOfSink.bind(this);
    this.addPipelineStep = this.addPipelineStep.bind(this);
    this.removePipelineStep = this.removePipelineStep.bind(this);
    this.movePipelineStep = this.movePipelineStep.bind(this);
    this.moveToStep = this.moveToStep.bind(this);
    this.moveToPage = this.moveToPage.bind(this);
    this.addColumn = this.addColumn.bind(this);
    this.showContextBar = this.showContextBar.bind(this);
    this.hideContextBar = this.hideContextBar.bind(this);
    this.removeColumn = this.removeColumn.bind(this);
    this.simulateImpactOnAttributes = this.simulateImpactOnAttributes.bind(this);
    this.toggleDataSinkDialog = this.toggleDataSinkDialog.bind(this);
    this.addDataSinkAttribute = this.addDataSinkAttribute.bind(this);
    this.deleteDataSinkAttribute = this.deleteDataSinkAttribute.bind(this);
    this.ingestData = this.ingestData.bind(this);
    this.resetPipeline = this.resetPipeline.bind(this);
    this.compileExecutable = this.compileExecutable.bind(this);
    this.deleteExecutable = this.deleteExecutable.bind(this);
    this.haltExecutable = this.haltExecutable.bind(this);
    this.startExecutable = this.startExecutable.bind(this);
    this.areAnyExecutablesCompiling = this.areAnyExecutablesCompiling.bind(this);
    this.fetchExecutables = this.fetchExecutables.bind(this);
    this.fetchExecutableLogs = this.fetchExecutableLogs.bind(this);
    this.toggleLogsOfRunningExecutable = this.toggleLogsOfRunningExecutable.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.checkDataSourceHealth = this.checkDataSourceHealth.bind(this);
    this.checkDataSinkHealth = this.checkDataSinkHealth.bind(this);
    this.recreateSourceConnector = this.recreateSourceConnector.bind(this);
    this.restartSourceConnector = this.restartSourceConnector.bind(this);
    this.restartSinkConnector = this.restartSinkConnector.bind(this);
    this.updatePipelineExecutableName = this.updatePipelineExecutableName.bind(this);
    this.handleTransferPipelineToProject = this.handleTransferPipelineToProject.bind(this);
    this.fetchMetrics = this.fetchMetrics.bind(this);
    this.toggleCodeView = this.toggleCodeView.bind(this);
  }

  componentDidMount() {
    this.props.fetchCurrentUser();
    this.props.resetSampleRecordsCache();
    this.props.fetchPipeline(this.getPipelineId())
        .then(() => {
          const pipeline = this.props.pipelines.pipeline;

          // fetch profile of data source
          this.props.fetchDataSourceProfile(pipeline.dataSourceProfileId);

          // fetch profile of joined data source, if present
          if (pipeline.joinedDataSourceProfileId !== undefined) {
            this.props.fetchJoinedDataSourceProfile(
                pipeline.joinedDataSourceProfileId);
          } else {
            this.props.resetJoinedDataSourceProfile();
          }

          // TODO: Make this configurable in the UI
          const sampleRecordsCount = 500;

          // fetch sample records from data source
          this.props.fetchSampleRecords(pipeline.dataSourceProfileId, sampleRecordsCount)
              .then(() => {
                if (pipeline.joinedDataSourceProfileId !== undefined) {
                  this.props.fetchJoinedSampleRecords(pipeline.joinedDataSourceProfileId, sampleRecordsCount)
                      .then(() => {
                        this.updateSampleRecords(
                            pipeline,
                            undefined,
                            false);

                        this.setState({
                          loadingSampleRecords: false,
                          pipeline: pipeline,
                          pipelineUpdatedAt: pipeline.updatedAt,
                          unpersistedChanges: false
                        });
                      });
                } else {
                  this.updateSampleRecords(
                      pipeline,
                      undefined,
                      false);

                  this.setState({
                    loadingSampleRecords: false,
                    pipeline: pipeline,
                    pipelineUpdatedAt: pipeline.updatedAt,
                    unpersistedChanges: false
                  });
                }
              });

          // fetch profile of data sink, if pipeline has a data sink assigned
          if (pipeline.dataSinkProfileId !== undefined) {
            this.props.fetchDataSinkProfile(pipeline.dataSinkProfileId)
                .then(() => this.setState({
                  dataSinkProfileAttributes: this.props.dataSinkProfiles.dataSinkProfile.attributes,
                  unpersistedChanges: false
                }));
          }

          // fetch deployments of pipeline
          this.props.fetchExecutables(pipeline.id).then(() => {
            if (this.areAnyExecutablesCompiling()) {
              clearInterval(this.fetchExecutablesIntervalId);
              this.fetchExecutablesIntervalId = setInterval(this.fetchExecutables, 500);
            }
          });

          // fetch Kafka source connector
          if (pipeline.kafkaSourceConnectorId !== undefined) {
            this.props.fetchSourceConnectorOfPipeline(pipeline.id);
          }

          // fetch Kafka joined source connector
          if (pipeline.kafkaJoinedSourceConnectorId !== undefined) {
            this.props.fetchJoinedSourceConnectorOfPipeline(pipeline.id);
          }

          // fetch Kafka sink connector
          if (pipeline.kafkaSinkConnectorId !== undefined) {
            this.props.fetchSinkConnectorOfPipeline(pipeline.id);
          }
        });

    this.props.fetchDataSinks();
    this.props.fetchDataSources();

    // initialize monitoring of pipeline changes
    if (this.monitorChangesIntervalId != null) {
      clearInterval(this.monitorChangesIntervalId);
    }

    this.monitorChangesIntervalId = setInterval(this.monitorChanges, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.fetchExecutableLogsIntervalId);
    clearInterval(this.fetchPipelineMetrics);
    clearInterval(this.monitorChangesIntervalId);
    this.monitorChanges();
  }

  getPipelineId() {
    return parseInt(this.props.match.params.id);
  }

  monitorChanges() {
    if (this.state.unpersistedChanges) {
      return this.props.updatePipeline(
          this.transformPipelineStepsAndAssertions(this.state.pipeline),
          this.state.pipelineUpdatedAt
      ).then((lastUpdatedAt) => {
        this.setState({
          pipelineUpdatedAt: lastUpdatedAt,
          unpersistedChanges: false
        });
      });
    } else {
      return Promise.resolve(undefined)
    }
  }

  updateSampleRecords(pipeline, currentStep, useCache, attributeId) {
    const domElement = document.querySelector('.datacater-grid-container');
    if (domElement != null) {
      domElement.style.opacity = 0.2;
    }

    clearTimeout(this.updateSampleRecordsTimeout);
    this.updateSampleRecordsTimeout = setTimeout(() => {
      let updatedSampleRecords = undefined;

      const attributes = this.props.dataSourceProfiles.dataSourceProfile !== undefined
          ? deepCopy(this.props.dataSourceProfiles.dataSourceProfile.attributes)
          : [];

      if (this.props.dataSourceProfiles.joinedDataSourceProfile !== undefined) {
        this.props.dataSourceProfiles
            .joinedDataSourceProfile
            .attributes
            .forEach(attribute => attributes.push(deepCopy(attribute)));
      }


      // consider all attributes
      if (isNaN(attributeId)) {
        updatedSampleRecords =
            this.props.applyPipelineToSampleRecords(
                this.props.executeTransformation,
                this.props.pipelineDesigner,
                this.props.dataSourceProfiles.sampleRecords,
                this.props.dataSourceProfiles.joinedSampleRecords,
                attributes,
                this.props.dataSourceProfiles.dataSourceProfile.id,
                pipeline,
                currentStep,
                useCache
            );
      } else { // consider only provided attribute
        updatedSampleRecords =
            this.props.applyTransformationToCachedSampleRecords(
                this.props.executeTransformation,
                this.props.pipelineDesigner,
                attributes,
                pipeline,
                currentStep,
                attributeId
            );
      }

      updatedSampleRecords
          .then(() => {
            // update profile (most frequent values and statistics)
            this.props.profileSampleRecords(
                this.props.pipelineDesigner.sampleRecords,
                attributes,
                pipeline,
                currentStep
            );

            // remove opacity from data table, most frequent values and statistics
            if (domElement != null) {
              domElement.style.opacity = 1;
            }

            // hide loader
            if (document.querySelector('.loading-pipeline-designer-wrapper') != null) {
              document.querySelector('.loading-pipeline-designer-wrapper').classList.remove('d-block');
            }
          });
    }, 50);
  }

  handleChange(event) {
    let pipeline = deepCopy(this.state.pipeline);
    pipeline[event.target.name] = event.target.value;
    this.setState({
      addedColumn: false,
      pipeline: pipeline,
      unpersistedChanges: true
    });
  }

  handleJoinConfigChange(property, value) {
    let pipeline = deepCopy(this.state.pipeline);

    if (pipeline.joinConfig === undefined) {
      pipeline.joinConfig = {};
    }

    pipeline.joinConfig[property] = value;

    this.setState({
      addedColumn: false,
      pipeline: pipeline,
      unpersistedChanges: true
    });
  }

  selectDataSink(dataSinkId, dataSinkType) {
    const setDataSinkId = function (that, dataSinkId) {
      let pipeline = deepCopy(that.state.pipeline);

      that.props.updateDataSinkOfPipeline(pipeline, dataSinkId)
          .then(() => {
            that.setState({
              addedColumn: false,
              contextBarActive: false,
              editColumn: undefined,
              pipeline: that.props.pipelines.pipeline,
              unpersistedChanges: false
            });

            that.handleStartProfilingOfSink(dataSinkId);

            // give sink connector 2 seconds to start up
            setTimeout(() => {
              that.props.fetchSinkConnectorOfPipeline(pipeline.id);
            }, 2000);
          });
    }

    // if the data sink id is undefined, we will create a new flat file data sink
    // and assign it to the pipeline
    if (dataSinkId === undefined) {
      this.props.addDataSink(
          {
            name: dataSinkType.toUpperCase() + ' file',
            projectId: this.state.pipeline.projectId,
            sinkType: dataSinkType,
            userId: this.state.pipeline.userId
          }
      ).then(() =>
          setDataSinkId(this, this.props.dataSinks.dataSink.id)
      );
    } else {
      setDataSinkId(this, dataSinkId)
    }
  }

  resetDataSink(event) {
    event.preventDefault();
    let pipeline = deepCopy(this.state.pipeline);

    // if the data sink is a flat file, delete it in the backend
    // because we will never need it again
    const dataSink = this.props.dataSinks.dataSinks
        .find(dataSink => dataSink.id === parseInt(pipeline.dataSinkId));
    if (dataSink !== undefined && ['csv', 'json', 'xml'].includes(dataSink.sinkType)) {
      this.props.deleteDataSink(dataSink.id);
    }

    this.props.updateDataSinkOfPipeline(pipeline, undefined)
        .then(() =>
            this.setState({
              addedColumn: false,
              contextBarActive: false,
              editColumn: undefined,
              pipeline: this.props.pipelines.pipeline,
              unpersistedChanges: false
            }));
  }

  selectJoinedDataSource(dataSourceId) {
    const pipeline = deepCopy(this.state.pipeline);
    this.props.updateJoinedDataSourceOfPipeline(pipeline, dataSourceId)
        .then(() => {
          const updatedPipeline = this.props.pipelines.pipeline;

          this.setState({
            addedColumn: false,
            contextBarActive: false,
            editColumn: undefined,
            pipeline: updatedPipeline,
            unpersistedChanges: false
          });


          if (updatedPipeline.joinedDataSourceProfileId !== undefined) {
            this.props.fetchJoinedSourceConnectorOfPipeline(
                updatedPipeline.id
            );

            this.props.fetchJoinedDataSourceProfile(
                updatedPipeline.joinedDataSourceProfileId
            )
                .then(() => {
                  // TODO: Make sample records count configurable
                  const sampleRecordsCount = 500;

                  this.props.fetchJoinedSampleRecords(
                      updatedPipeline.joinedDataSourceProfileId,
                      sampleRecordsCount
                  )
                      .then(() =>
                          this.updateSampleRecords(
                              updatedPipeline,
                              undefined,
                              false
                          )
                      );
                });
          }
        });
  }

  resetJoinedDataSource(event) {
    event.preventDefault();
    const pipeline = deepCopy(this.state.pipeline);

    return this.props.updateJoinedDataSourceOfPipeline(pipeline, undefined)
        .then(() =>
            this.setState({
              addedColumn: false,
              contextBarActive: false,
              editColumn: undefined,
              pipeline: this.props.pipelines.pipeline,
              unpersistedChanges: false
            })
        );
  }

  /**
   * handle assertion change
   * @param event
   * @param attributeId
   * @param property
   * @param value
   */
  handleAssertionChange(event, attributeId, property, value) {
    let pipeline = deepCopy(this.state.pipeline);
    let type = '';
    let editColumn = undefined;

    if (attributeId === undefined && property === undefined) {
      attributeId = parseInt(event.target.name.split('-')[1]);
      property = event.target.name.split('-')[0];
      value = event.target.value;
      type = event.target.type;
    }

    const assertionIndex = pipeline.pipelineAssertions.findIndex(
        assertion => parseInt(assertion.attributeId) === parseInt(attributeId));

    if (assertionIndex >= 0) {
      let contextBarActive = true;
      editColumn = this.state.editColumn;

      // Value for an assertion filter was set or changed
      if (editColumn !== undefined && editColumn.hasOwnProperty('assertion')) {
        editColumn.assertion.assertionValue = value;
      }

      pipeline.pipelineAssertions[assertionIndex][property] = value;

      // assertion filter was set or reset
      if (property === 'assertionFilter') {
        pipeline.pipelineAssertions[assertionIndex]['assertionValue'] = '';

        editColumn = this.createEditColumnAssertionObject(attributeId);
        editColumn.assertion.assertionFilter = value;
      }

      this.delayedReloadSampleRecords(pipeline, type);

      this.setState({
        contextBarActive: contextBarActive,
        editColumn: editColumn,
        addedColumn: false,
        pipeline: pipeline,
        unpersistedChanges: true
      });
    }
  }

  /**
   * Create editColumn object for assertion by attributeId
   * @param attributeId
   * @returns {{attributeId: *, assertion: T, type: string}}
   */
  createEditColumnAssertionObject(attributeId) {
    const pipeline = deepCopy(this.state.pipeline);
    const assertion = pipeline.pipelineAssertions
        .find(assertion => parseInt(assertion.attributeId) === parseInt(attributeId));

    return {
      type: 'assertion',
      attributeId: attributeId,
      assertion: assertion
    };
  }

  /**
   * Create editColumn object for transformation by attributeId
   * @param attributeId, sortPosition
   * @returns {{attribute: *, attributeId: *, sortPosition: Int, stepIndex: Int, type: string}}
   */
  createEditColumnTransformationObject(attributeId, sortPosition, stepIndex) {
    const pipeline = deepCopy(this.state.pipeline);

    const attribute = pipeline.pipelineSteps[stepIndex].attributes
        .find(attribute => parseInt(attribute.transformAttributeId) === attributeId);

    return {
      attribute: attribute,
      attributeId: attributeId,
      sortPosition: sortPosition,
      stepIndex: stepIndex,
      type: 'transformation'
    };
  }

  /**
   * Handle pipeline step transformation change
   * @param event
   */
  handlePipelineStepChange(event) {
    //event.preventDefault();

    let pipeline = deepCopy(this.state.pipeline);
    let sortPosition, stepIndex;

    let editColumn = this.state.editColumn === undefined
        ? undefined
        : deepCopy(this.state.editColumn);

    if (event.target.name === 'name') {
      sortPosition = parseInt(event.target.dataset.sortPosition);
      stepIndex = pipeline.pipelineSteps
          .findIndex(step => step.sortPosition === sortPosition);
      pipeline.pipelineSteps[stepIndex].name = event.target.value;
    } else {
      const dataset = deepCopy(event.target.dataset);

      if (dataset.attributeId !== undefined && dataset.sortPosition !== undefined) {
        const property = dataset.property || event.target.name;
        const propertyValue = dataset.value || event.target.value;
        const attributeId = parseInt(dataset.attributeId);
        // only used for user-defined transformations;
        // used to indicate whether to apply the UDF
        const applyUdf = dataset.applyUdf;

        sortPosition = parseInt(dataset.sortPosition);

        const pipelineStepIndex = pipeline.pipelineSteps
            .findIndex(step => step.sortPosition === sortPosition);
        const pipelineStep = pipeline.pipelineSteps[pipelineStepIndex];

        const pipelineStepAttribute = pipelineStep.attributes
            .find(attribute => parseInt(attribute.transformAttributeId) === attributeId);

        // set transform or filter
        if (property !== '') {
          pipelineStepAttribute[property] = propertyValue;
        } else {
          // remove transformation
          pipelineStepAttribute['transformationAction'] = '';
          pipelineStepAttribute['actionValue'] = '';
          pipelineStepAttribute['actionValue2'] = '';
          pipelineStepAttribute['transformationFilter'] = '';
          pipelineStepAttribute['filterValue'] = '';
        }

        // reset action value when changing transformation action
        if (property === 'transformationAction') {
          pipelineStepAttribute['actionValue'] = '';
          pipelineStepAttribute['actionValue2'] = '';
          pipelineStepAttribute['transformationFilter'] = '';

          // set identity function as default value when applying UDF
          if (propertyValue === 'user-defined-transformation') {
            pipelineStepAttribute['actionValue'] = 'def transform(value, row):\n  return value';
          }

          editColumn = this.createEditColumnTransformationObject(
              attributeId,
              sortPosition,
              pipelineStepIndex);
        }

        // reset filter value when changing transformation filter
        if (property === 'transformationFilter' || property === 'transformationAction') {
          pipelineStepAttribute['filterValue'] = '';
        }

        if (pipelineStepAttribute.transformationAction !== 'user-defined-transformation' || applyUdf) {
          if ([undefined, '', 'drop-record'].includes(pipelineStepAttribute.transformationAction)) {
            this.delayedReloadSampleRecords(pipeline, event.target.type);
          } else {
            this.delayedReloadSampleRecords(pipeline, event.target.type, attributeId);
          }
        }
      }
    }

    this.setState({
      addedColumn: false,
      editColumn: editColumn,
      pipeline: pipeline,
      unpersistedChanges: true
    });
  }

  delayedReloadSampleRecords(pipeline, eventType, attributeId) {
    const usedAttributeId = (this.state.currentStep === this.props.pipelineDesigner.cachedStep + 1)
        ? attributeId
        : undefined;
    if (eventType === 'text') {
      let domElements = [
        document.querySelector('.datacater-grid-container')
      ].filter(_ => _ != null);
      domElements.forEach(function (el) {
        el.style.opacity = 0.2;
      });
      clearTimeout(this.bounceTimeout);
      this.bounceTimeout = setTimeout(() => {
        // opacity will be reset by the following function call
        this.updateSampleRecords(
            pipeline,
            this.state.currentStep,
            true,
            usedAttributeId);
      }, 333);
    } else {
      this.updateSampleRecords(
          pipeline,
          this.state.currentStep,
          true,
          usedAttributeId);
    }
  }

  handleDataSinkProfileChange(event) {
    event.preventDefault();

    let attributes = this.state.dataSinkProfileAttributes;

    const attributeId = parseInt(event.target.dataset.attributeId);
    const attributeIndex = attributes.findIndex(_ => _.id === attributeId);

    if (event.target.value === 'fill-with-processing-time') {
      attributes[attributeIndex].dataSourceProfileAttributeId = undefined;
      attributes[attributeIndex].fillWithProcessingTime = true;
    } else {
      const newValue = parseInt(event.target.value) > 0
          ? parseInt(event.target.value)
          : undefined;

      attributes[attributeIndex].dataSourceProfileAttributeId = newValue;
      attributes[attributeIndex].fillWithProcessingTime = false;
    }

    this.props.updateDataSinkProfileAttribute(
        this.props.dataSinkProfiles.dataSinkProfile.id,
        attributes[attributeIndex]
    ).then(() =>
        this.setState({
          addedColumn: false,
          dataSinkProfileAttributes: attributes
        }));
  }

  handleMultipleDataSinkProfileChanges(updatedAttributes) {
    updatedAttributes.forEach((attribute) =>
        this.props.updateDataSinkProfileAttribute(
            this.props.dataSinkProfiles.dataSinkProfile.id,
            attribute
        )
    );

    const attributes = this.state.dataSinkProfileAttributes
        .map(attribute => {
          const updatedAttribute = updatedAttributes
              .find(a => parseInt(a.id) === parseInt(attribute.id));

          if (updatedAttribute === undefined) {
            return attribute;
          } else {
            return updatedAttribute;
          }
        });

    this.setState({
      addedColumn: false,
      dataSinkProfileAttributes: attributes
    });
  }

  waitForProfilingOfSink() {
    // TODO: Handle failure
    if (this.props.dataSinkProfiles.dataSinkProfile != null) {
      if (this.props.dataSinkProfiles.dataSinkProfile.profilingStatus !== 'profiling') {
        clearInterval(this.profilingSinkIntervalId);
        let pipeline = deepCopy(this.state.pipeline);
        pipeline.dataSinkProfileId = this.props.dataSinkProfiles.dataSinkProfile.id;
        this.setState({
          addedColumn: false,
          dataSinkProfileAttributes: this.props.dataSinkProfiles.dataSinkProfile.attributes,
          pipeline: pipeline,
          unpersistedChanges: true
        });
      } else {
        this.props.fetchDataSinkProfile(this.props.dataSinkProfiles.dataSinkProfile.id);
      }
    }
  }

  handleStartProfilingOfSink(dataSink) {
    const dataSinkId = parseInt(dataSink);

    if (!isNaN(dataSinkId) && dataSinkId > 0) {
      this.props.createDataSinkProfile(dataSinkId);
      // clear old interval
      if (this.profilingSinkIntervalId != null) {
        clearInterval(this.profilingSinkIntervalId);
      }
      this.profilingSinkIntervalId = setInterval(
          this.waitForProfilingOfSink.bind(this), 500);
    } else {
      this.props.resetDataSinkProfile();
      this.setState({dataSinkProfileAttributes: []});
    }
  }

  addPipelineStep(event, useCache) {
    event.preventDefault();

    let pipeline = deepCopy(this.state.pipeline);
    let maxSortPosition = 0;

    pipeline.pipelineSteps.forEach(function (step) {
      if (step.sortPosition > maxSortPosition) {
        maxSortPosition = step.sortPosition;
      }
    });

    const sortPosition = maxSortPosition + 1;

    // initialize first pipeline step with attributes from data source profile
    let attributes = this.props.dataSourceProfiles.dataSourceProfile
        .attributes
        .map(attribute => {
          return {
            transformAttributeId: parseInt(attribute.id),
            transformationAction: '',
            transformationFilter: '',
            actionValue: '',
            filterValue: '',
            isKey: attribute.isKey,
            dataType: attribute.dataType
          };
        });

    // if joined data source is present, add its attributes as well
    if (
        pipeline.joinedDataSourceId !== undefined &&
        this.props.dataSourceProfiles.joinedDataSourceProfile !== undefined
    ) {
      this.props.dataSourceProfiles.joinedDataSourceProfile
          .attributes
          .forEach(attribute =>
              attributes.push({
                transformAttributeId: parseInt(attribute.id),
                transformationAction: '',
                transformationFilter: '',
                actionValue: '',
                filterValue: '',
                isKey: false,
                dataType: attribute.dataType
              })
          );
    }

    // copy from previous step for all successive pipeline steps
    if (maxSortPosition > 0) {
      attributes = pipeline.pipelineSteps
          .find(_ => _.sortPosition === maxSortPosition)
          .attributes
          .map(attribute => {
            return Object.assign(
                {},
                attribute,
                {
                  transformationAction: '',
                  transformationFilter: '',
                  actionValue: '',
                  filterValue: '',
                }
            );
          });
    }

    pipeline.pipelineSteps.push({
      id: sortPosition,
      name: '',
      sortPosition: sortPosition,
      transformationType: 'transform',
      attributes: attributes
    });

    this.updateSampleRecords(
        pipeline,
        sortPosition,
        useCache !== false
    );

    this.setState({
      addedColumn: false,
      contextBarActive: false,
      currentPage: 'steps',
      currentStep: sortPosition,
      editColumn: undefined,
      pipeline: pipeline,
      unpersistedChanges: true
    });
  }

  removePipelineStep(sortPosition) {
    let pipeline = deepCopy(this.state.pipeline);
    const stepIndex = pipeline.pipelineSteps.findIndex(function (el) {
      return (el.sortPosition === sortPosition);
    });
    const deletedPosition = pipeline.pipelineSteps[stepIndex].sortPosition;

    // Check whether the to-be-remove pipeline step introduced any new column
    // If yes, then remove it from all successive pipeline steps and also delete it from the backend
    const introducedAttributes = pipeline
        .pipelineSteps[stepIndex]
        .attributes
        .filter(_ => _.transformationAction === 'add-column')
        .map(_ => _.transformAttributeId);
    // delete the attributes from the successive pipeline steps
    if (introducedAttributes.length > 0) {
      pipeline.pipelineSteps = pipeline.pipelineSteps.map(function (step) {
        step.attributes = step.attributes.filter(_ => !introducedAttributes.includes(_.transformAttributeId));
        return step;
      });
    }

    pipeline.pipelineSteps.splice(stepIndex, 1);
    pipeline.pipelineSteps = pipeline.pipelineSteps.map(function (el) {
      const overwrittenPosition = {
        sortPosition: (el.sortPosition > deletedPosition) ? (el.sortPosition - 1) : el.sortPosition
      };
      return Object.assign(el, overwrittenPosition);
    });

    let currentStep = this.state.currentStep;
    let currentPage = 'steps';
    if (pipeline.pipelineSteps.length === 0) {
      currentStep = undefined;
      currentPage = 'instructions';
    } else if (deletedPosition < currentStep) {
      currentStep--;
    } else {
      currentStep = pipeline.pipelineSteps.length;
    }

    this.updateSampleRecords(pipeline, currentStep, true);
    this.setState({
      addedColumn: false,
      currentPage: currentPage,
      currentStep: currentStep,
      contextBarActive: false,
      editColumn: undefined,
      pipeline: pipeline,
      unpersistedChanges: true
    });
  }

  movePipelineStep(fromPosition, toPosition) {
    let pipeline = deepCopy(this.state.pipeline);
    let pipelineSteps = pipeline.pipelineSteps;
    const movingStepIndex = pipelineSteps.findIndex(function (el) {
      return (el.sortPosition === fromPosition);
    });
    if (fromPosition > toPosition) {
      pipelineSteps = pipelineSteps.map(function (step) {
        if (step.sortPosition >= toPosition && step.sortPosition < fromPosition) {
          step.sortPosition++;
        }
        return step;
      });
      pipelineSteps[movingStepIndex].sortPosition = toPosition;
    } else if (fromPosition < toPosition) {
      pipelineSteps = pipelineSteps.map(function (step) {
        if (step.sortPosition > fromPosition && step.sortPosition < toPosition) {
          step.sortPosition--;
        }
        return step;
      });
      pipelineSteps[movingStepIndex].sortPosition = toPosition - 1;
    }
    pipeline.pipelineSteps = pipelineSteps;

    const newCurrentStep = pipelineSteps[movingStepIndex].sortPosition;
    this.updateSampleRecords(pipeline, newCurrentStep, true);
    this.setState({
      addedColumn: false,
      currentStep: newCurrentStep,
      contextBarActive: false,
      editColumn: undefined,
      pipeline: pipeline,
      unpersistedChanges: true
    });
  }

  moveToStep(event, newStep) {
    event.preventDefault();
    // block until style has been changed
    this.updateSampleRecords(this.state.pipeline, newStep, true);
    this.setState({
      addedColumn: false,
      codeView: false,
      currentPage: 'steps',
      currentStep: newStep,
      contextBarActive: false,
      editColumn: undefined
    });
  }

  moveToPage(newPage, newStep) {
    window.scrollTo(0, 0);

    const pipelineId = this.getPipelineId();

    this.props.fetchPipeline(pipelineId)
        .then(() => {
          this.props.fetchExecutables(pipelineId).then(() => {
            if (this.areAnyExecutablesCompiling()) {
              clearInterval(this.fetchExecutablesIntervalId);
              this.fetchExecutablesIntervalId = setInterval(this.fetchExecutables, 500);
            }
          });

          if (newPage === 'steps') {
            this.updateSampleRecords(this.state.pipeline, newStep, false);
          }

          // reset interval for fetching metrics
          clearInterval(this.fetchPipelineMetrics);

          // fetch metrics of pipeline every 10 seconds when staying on deployments page
          if (newPage === 'deployments') {
            this.fetchMetrics();
            this.fetchPipelineMetrics = setInterval(this.fetchMetrics, 10000);
          }

          // only show the pipeline logs
          // - when staying on the deployment page and
          // - when logs are still enabled
          const showPipelineLogs =
              (newPage === 'deployments') &&
              this.state.showPipelineLogs;

          if (!showPipelineLogs) {
            clearInterval(this.fetchExecutableLogsIntervalId);
          }

          this.setState({
            addedColumn: false,
            codeView: false,
            currentPage: newPage,
            currentStep: newStep,
            contextBarActive: false,
            editColumn: undefined,
            showPipelineLogs: showPipelineLogs
          });
        });
  }

  simulateImpactOnAttributes(currentStep, instantDeletions) {
    const pipeline = this.state.pipeline;

    let attributes = deepCopy(this.props.dataSourceProfiles.dataSourceProfile.attributes);

    // check whether we need to add profile of joined data source
    if (
        pipeline.joinedDataSourceId !== undefined &&
        pipeline.joinedDataSourceProfileId !== undefined &&
        this.props.dataSourceProfiles.joinedDataSourceProfile !== undefined
    ) {
      attributes = attributes.concat(deepCopy(this.props.dataSourceProfiles.joinedDataSourceProfile.attributes));
    }

    // add prefixes to name, if present
    attributes = attributes.map(attribute => {
      const name = [undefined, ''].includes(attribute.namePrefix)
          ? attribute.name
          : attribute.namePrefix + attribute.name;
      return Object.assign({}, attribute, {name: name});
    });

    if (currentStep === undefined) {
      return attributes;
    }

    for (let i = 0; i < currentStep; i++) {
      pipeline
          .pipelineSteps.find(el => el.sortPosition === i + 1)
          .attributes.filter(attribute => attribute.transformationAction !== undefined && attribute.transformationAction.length > 0)
          .forEach(function (attribute) {
            const transformAction = attribute.transformationAction;
            const attributeIndex = attributes.findIndex(attr => parseInt(attr.id) === attribute.transformAttributeId);
            if (attributeIndex < 0) {
              if (transformAction === 'add-column') {
                const columnName = (attribute.actionValue !== undefined && attribute.actionValue.length > 0)
                    ? attribute.actionValue
                    : 'attribute_' + attribute.transformAttributeId;
                attributes.push({
                  id: attribute.transformAttributeId + '',
                  name: columnName,
                  dataType: attribute.filterValue,
                  isKey: false,
                  isVirtual: true
                });
              } else {
                // do nothing, because the attribute has not been found
              }
            } else if (transformAction === 'drop-column' &&
                // only show for successive steps
                (instantDeletions || (i + 1 < currentStep)) &&
                // show only if attribute drop is applied to all records
                (attribute.transformationFilter === undefined || attribute.transformationFilter.length === 0)) {
              attributes.splice(attributeIndex, 1);
            } else if (transformAction === 'rename-column') {
              if (attribute.actionValue !== undefined && attribute.actionValue.trim().length > 0) {
                if (i + 1 === currentStep) {
                  attributes[attributeIndex].oldName = attributes[attributeIndex].name;
                }
                attributes[attributeIndex].name = attribute.actionValue;
              }
            } else if (transformAction === 'cast-data-type' &&
                ![undefined, ''].includes(attribute.actionValue)) {
              if (i + 1 === currentStep) {
                attributes[attributeIndex].oldDataType = attributes[attributeIndex].dataType;
              }
              if (attributes[attributeIndex].dataType.includes('array_')) {
                attributes[attributeIndex].dataType = 'array_' + attribute.actionValue;
              } else {
                attributes[attributeIndex].dataType = attribute.actionValue;
              }
            } else if (transformAction === 'tokenize') {
              attributes[attributeIndex].dataType = 'array_string';
            }
          });
    }

    return attributes;
  }

  addColumn(event) {
    event.preventDefault();
    let pipeline = deepCopy(this.state.pipeline);
    const stepIndex = pipeline.pipelineSteps.findIndex(_ => _.sortPosition === this.state.currentStep);
    if (stepIndex >= 0) {
      // add new virtual attribute to data source profile
      const that = this;
      this.props.addDataSourceProfileAttribute(this.props.dataSourceProfiles.dataSourceProfile.id).then(function () {
        const virtualAttribute = that.props.dataSourceProfiles.dataSourceProfileAttribute;
        if (virtualAttribute != null) {
          pipeline.pipelineSteps[stepIndex].attributes.push({
            transformAttributeId: virtualAttribute.id,
            transformationAction: 'add-column',
            transformationFilter: '',
            actionValue: '',
            filterValue: 'string',
            isKey: false
          });
          // Add column to all successive pipeline steps
          pipeline.pipelineSteps = pipeline.pipelineSteps.map(function (pipelineStep) {
            if (pipelineStep.sortPosition > that.state.currentStep) {
              pipelineStep.attributes.push({
                transformAttributeId: virtualAttribute.id,
                transformationAction: '',
                transformationFilter: '',
                actionValue: '',
                filterValue: '',
                isKey: false
              });
            }
            return pipelineStep;
          });
          that.updateSampleRecords(pipeline, that.state.currentStep, true);
          that.setState({
            addedColumn: true,
            contextBarActive: false,
            editColumn: undefined,
            pipeline: pipeline,
            unpersistedChanges: true
          });
        }
      });
    }
  }

  /**
   * Method editColumn gets triggered when edit the transformation and filter of a sample data column
   * it shows and hides the context bar and sets the editColumn state object
   *
   * @param attributeId
   * @param sortPosition
   */
  editColumn(attributeId, sortPosition, type) {
    const pipeline = deepCopy(this.state.pipeline);
    let editColumn = undefined;

    if (
        (this.state.editColumn !== undefined) &&
        (attributeId === this.state.editColumn.attributeId)
    ) {
      // hide context bar if we click the edit button of an active attribute again
      this.hideContextBar();
    } else {
      // show context bar when editing a transformation or filter
      this.showContextBar();

      if (
          (type === 'transformation') &&
          (attributeId !== undefined) &&
          (sortPosition !== undefined)
      ) {
        // reset possible error message of UDF
        this.props.resetTransformationError();

        const stepIndex = pipeline.pipelineSteps
            .findIndex(step => step.sortPosition === sortPosition);
        editColumn = this.createEditColumnTransformationObject(
            attributeId,
            sortPosition,
            stepIndex);
      } else if (
          (type === 'assertion') &&
          (attributeId !== undefined)
      ) {
        editColumn = this.createEditColumnAssertionObject(attributeId);
      }
    }

    this.setState({
      editColumn: editColumn
    });
  }

  /**
   * Sets the contextBarActive flag which shows the context bar
   */
  showContextBar() {
    this.setState({
      contextBarActive: true
    });
  }

  /**
   * Resets the contextBarActive flag which hides the context bar
   */
  hideContextBar() {
    this.setState({
      contextBarActive: false,
      editColumn: undefined
    });
  }

  removeColumn(event) {
    event.preventDefault();

    const attributeId = parseInt(event.target.dataset.attributeId);

    // remove column from all pipeline steps
    let pipeline = deepCopy(this.state.pipeline);
    pipeline.pipelineSteps = pipeline.pipelineSteps.map(function (step) {
      step.attributes = step.attributes.filter(_ => _.transformAttributeId !== attributeId);
      return step;
    });

    this.updateSampleRecords(pipeline, this.state.currentStep, true);

    this.setState({
      addedColumn: false,
      contextBarActive: false,
      editColumn: undefined,
      pipeline: pipeline,
      unpersistedChanges: true
    });
  }

  toggleDataSinkDialog(event) {
    event.preventDefault();
    this.setState({
      addedColumn: false,
      currentPage: 'data-sink',
      contextBarActive: false,
      editColumn: undefined
    });
  }

  addDataSinkAttribute(attribute) {
    this.props.addDataSinkProfileAttribute(
        this.props.dataSinkProfiles.dataSinkProfile.id,
        attribute
    ).then(() => {
      let dataSinkProfileAttributes = this.state.dataSinkProfileAttributes;
      dataSinkProfileAttributes.push(this.props.dataSinkProfiles.dataSinkProfileAttribute);
      this.setState({
        dataSinkProfileAttributes: dataSinkProfileAttributes
      });
    });
  }

  fetchYamlRepresentation() {
    const pipeline = this.props.pipelines.pipeline;

    this.props.fetchYamlOfPipeline(pipeline.id);
  }

  toggleCodeView() {
    const toggle = !this.state.codeView;
    if (toggle) {
      this.monitorChanges().then(
          result => {
            this.fetchYamlRepresentation();

            this.setState({
              addedColumn:      false,
              codeView:         toggle,
              contextBarActive: false,
              editColumn:       undefined
            });
          }
      )
    } else {
      this.setState({
        addedColumn:      false,
        codeView:         toggle,
        contextBarActive: false,
        editColumn:       undefined
      });
    }
  }



  deleteDataSinkAttribute (attributeId) {
    this.props.deleteDataSinkProfileAttribute(
      this.props.dataSinkProfiles.dataSinkProfile.id,
      attributeId
    ).then(() => {
      let dataSinkProfileAttributes = this.state.dataSinkProfileAttributes;
      const attrIndex = dataSinkProfileAttributes.findIndex(_ => _.id === attributeId);
      if (attrIndex >= 0) {
        dataSinkProfileAttributes.splice(attrIndex, 1);
      }
      this.setState({
        dataSinkProfileAttributes: dataSinkProfileAttributes
      });
    });
  }

  ingestData(files) {
    const file     = files[0];
    const pipeline = this.props.pipelines.pipeline;

    if (file !== undefined && pipeline !== undefined) {
      this.props.ingestFileIntoPipeline(pipeline.id, file)
        .then(() => this.props.fetchFlatFilesOfPipeline(pipeline.id));
    }
  }

  resetPipeline(event) {
    this.props.resetPipeline(this.getPipelineId());
  }

  compileExecutable(event) {
    event.preventDefault();

    this.props.compileExecutable(this.getPipelineId())
      .then(() =>  {
        if (this.areAnyExecutablesCompiling()) {
          this.fetchExecutablesIntervalId = setInterval(this.fetchExecutables, 500);
        }
      });
  }

  deleteExecutable(event, pipelineExecutableId) {
    event.preventDefault();

    this.props.deleteExecutable(this.getPipelineId(), pipelineExecutableId)
      .then(() => this.props.fetchExecutables(this.getPipelineId()));
  }

  startExecutable(event, pipelineExecutableId) {
    event.preventDefault();

    const pipelineId = this.getPipelineId();
    // check whether any other pipeline executable is running
    const isAnyExecutableRunning = this.props.pipelines.pipelineExecutables
      .filter(_ => _.startedAt !== undefined && _.stoppedAt === undefined)
      .length > 0;

    if (isAnyExecutableRunning) {
      const runningExecutableId = this.props.pipelines.pipelineExecutables
        .filter(_ => _.startedAt !== undefined && _.stoppedAt === undefined)
        .map(_ => _.id)[0];
      this.setState({
        errorMessage: 'Please first stop the currently executing deployment (#' +
                      runningExecutableId +
                      ') before starting another one.'
      });
      // scroll to top
      window.scrollTo(0, 0);

      return Promise.resolve(undefined);
    } else {
      return this.props.startExecutable(pipelineId, pipelineExecutableId)
        .then(() => {
          if (!this.props.pipelines.startingPipelineExecutableFailed) {
            this.props.fetchExecutables(pipelineId);

            let pipeline = deepCopy(this.state.pipeline);
            pipeline.isRunning = true;

            this.setState({
              errorMessage: '',
              pipeline: pipeline
            });
          }
        });
    }
  }

  haltExecutable(event, pipelineExecutableId) {
    event.preventDefault();
    const pipelineId = this.getPipelineId();
    return this.props.haltExecutable(pipelineId, pipelineExecutableId)
      .then(() => {
        if (!this.props.pipelines.stoppingPipelineExecutableFailed) {
          this.props.fetchExecutables(pipelineId);
          this.setState({
            errorMessage:     '',
            showPipelineLogs: false
          });
        }
      });
  }

  areAnyExecutablesCompiling() {
    const isCompiling = this.props.pipelines.pipelineExecutables
      .filter(_ => _.compiledAt === undefined && _.buildFailed !== true)
      .length > 0;

    return isCompiling;
  }

  fetchExecutables() {
    if (!this.areAnyExecutablesCompiling()) {
      clearInterval(this.fetchExecutablesIntervalId);
    } else {
      this.props.fetchExecutables(this.getPipelineId());
    }
  }

  fetchExecutableLogs() {
    const pipelineId        = this.getPipelineId();
    const runningExecutable = this.props.pipelines.pipelineExecutables
      .find(_ => _.startedAt !== undefined && _.stoppedAt === undefined);

    if (runningExecutable !== undefined) {
      this.props.fetchPipelineExecutableLogs(pipelineId, runningExecutable.id).then(() => {
        // if fetching logs failed, stop the automated polling of the backend
        if (this.props.pipelines.fetchingPipelineLogsFailed) {
          clearInterval(this.fetchExecutableLogsIntervalId);
        }
      });
    }
  }

  toggleLogsOfRunningExecutable(event) {
    event.preventDefault();

    if (!this.state.showPipelineLogs) {
      this.fetchExecutableLogs();
      this.fetchExecutableLogsIntervalId = setInterval(this.fetchExecutableLogs, 2000);
    } else {
      clearInterval(this.fetchExecutableLogsIntervalId);
    }

    this.setState({
      showPipelineLogs: !this.state.showPipelineLogs
    });
  }

  handleDelete(event) {
    event.preventDefault();
    this.props.deletePipeline(this.getPipelineId())
      .then(() => {
        if (this.state.pipeline.projectId !== undefined) {
          this.props.history.push(`/projects/${this.state.pipeline.projectId}`);
        } else {
          this.props.history.push('/pipelines');
        }
      });
  }

  transformPipelineStepsAndAssertions (pipeline) {
    pipeline = deepCopy(pipeline);
    let newPipelineSteps = [];
    // transform pipeline steps
    pipeline.pipelineSteps.forEach(function (pipelineStep) {
      if (pipelineStep.attributes.length === 0) {
        let newStep = deepCopy(pipelineStep);
        delete newStep.attributes;
        newPipelineSteps.push(newStep);
      } else {
        pipelineStep.attributes.forEach(function (attribute) {
          let newStep = deepCopy(pipelineStep);
          delete newStep.attributes;

          newStep.transformAttributeId = attribute.transformAttributeId;
          newStep.transformationAction = attribute.transformationAction;
          newStep.transformationFilter = attribute.transformationFilter;
          newStep.actionValue          = attribute.actionValue;
          newStep.actionValue2         = attribute.actionValue2;
          newStep.filterValue          = attribute.filterValue;

          newPipelineSteps.push(newStep);
        });
      }
    });
    pipeline.pipelineSteps = newPipelineSteps;
    // transform pipeline assertions
    pipeline.pipelineAssertions = pipeline.pipelineAssertions.map(function (assertion) {
      return {
        attributeId:     assertion.attributeId,
        assertionFilter: assertion.assertionFilter,
        assertionValue:  assertion.assertionValue
      };
    });

    return pipeline;
  }

  checkDataSourceHealth(event) {
    event.preventDefault();

    const pipeline = this.props.pipelines.pipeline;

    if (pipeline.kafkaSourceConnectorId !== undefined) {
      return this.props.fetchSourceConnectorOfPipeline(pipeline.id)
        .then(() => {
          if (pipeline.kafkaJoinedSourceConnectorId !== undefined) {
            return this.props.fetchJoinedSourceConnectorOfPipeline(pipeline.id);
          } else {
            return Promise.resolve(undefined);
          }
        });
    } else {
      return Promise.resolve(undefined);
    }
  }

  checkDataSinkHealth(event) {
    event.preventDefault();

    const pipeline = this.props.pipelines.pipeline;

    if (pipeline.kafkaSinkConnectorId !== undefined) {
      return this.props.fetchSinkConnectorOfPipeline(pipeline.id);
    } else {
      return Promise.resolve(undefined);
    }
  }

  recreateSourceConnector() {
    const pipeline = this.props.pipelines.pipeline;

    return this.props.recreateSourceConnectorOfPipeline(pipeline.id);
  }

  restartSourceConnector() {
    const pipeline = this.props.pipelines.pipeline;

    if (pipeline.kafkaSourceConnectorId !== undefined) {
      return this.props.restartSourceConnectorOfPipeline(pipeline.id)
        .then(() => {
          if (pipeline.kafkaJoinedSourceConnectorId !== undefined) {
            return this.props.restartJoinedSourceConnectorOfPipeline(pipeline.id);
          } else {
            Promise.resolve(undefined);
          }
        });
    } else {
      return Promise.resolve(undefined);
    }
  }

  restartSinkConnector() {
    const pipeline = this.props.pipelines.pipeline;

    if (pipeline.kafkaSinkConnectorId !== undefined) {
      return this.props.restartSinkConnectorOfPipeline(
        pipeline.id);
    } else {
      return Promise.resolve(undefined);
    }
  }

  updatePipelineExecutableName(pipelineId, executableId, newName) {
    this.props.updateExecutableName(
      pipelineId,
      executableId,
      newName
    ).then(() => this.props.fetchExecutables(pipelineId));
  }

  handleTransferPipelineToProject(pipelineId, projectId) {
    this.props.transferPipelineToProject(pipelineId, projectId)
      .then(() => {
        if (this.props.pipelines.errorMessage === undefined) {
          this.setState({ pipeline: this.props.pipelines.pipeline });
        }
      });
  }

  fetchMetrics() {
    this.props.fetchMetricsOfPipeline(this.getPipelineId());
  }

  render() {
    const transformers = Transformer.getTransformers()
      .sort((a, b) => (a.name > b.name) - (a.name < b.name));

    const pipeline = this.state.pipeline;
    const dataSourceProfile = this.props.dataSourceProfiles.dataSourceProfile;

    const currentUser = this.props.users.currentUser;
    const project =
      (
        ![currentUser, pipeline].includes(undefined) &&
        (pipeline.userId === undefined) &&
        (pipeline.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === pipeline.projectId)
        : undefined;

    const projectMembership = (project !== undefined)
      ? currentUser.projectMemberships.find(_ => _.projectId === project.id)
      : undefined;

    const canEditPipeline = (
      (projectMembership === undefined) ||
      ['editor', 'administrator'].includes(projectMembership.membershipRole)
    );

    const canDeletePipeline = (
      (projectMembership === undefined) ||
      ['administrator'].includes(projectMembership.membershipRole)
    );

    if (
      [pipeline, dataSourceProfile].includes(undefined) ||
      this.state.loadingSampleRecords
    ) {
      return (
        <React.Fragment>
          <div className='loading-pipeline-designer-wrapper'>
            <div className='loading-pipeline-designer'>
              <div className='loader-text'>
                <div className='spinner-border text-success' role='status'>
                    <span className='sr-only'>Loading...</span>
                </div>
                <div className='loader-label'>
                  Loading pipeline designer
                </div>
              </div>
            </div>
          </div>
          <div className='main-content'>
            <div className='create-pipeline-form pipeline-designer pipeline-designer-page'>
              <div className='top-row row align-items-center'>
                <div className='col overflow-hidden text-nowrap'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <h4 className='d-inline-block m-0 mr-2'>&nbsp;</h4>
                    </div>
                  </div>
                </div>
              </div>
              <PipelineDesignerNavigation
                addPipelineStepFunc={this.addPipelineStep}
                currentPage={this.state.currentPage}
                currentStep={this.state.currentStep}
                history={this.props.history}
                moveToPageFunc={this.moveToPage}
                moveToStepFunc={this.moveToStep}
                pipeline={pipeline}
                toggleDataSinkDialogFunc={this.toggleDataSinkDialog} />
            </div>
          </div>
        </React.Fragment>
      );
    }

    const dataSource = this.props.dataSources.dataSources
      .find(el => el.id === parseInt(pipeline.dataSourceId));
    const dataSink = this.props.dataSinks.dataSinks
      .find(el => el.id === parseInt(pipeline.dataSinkId));

    const sampleRecords = this.props.pipelineDesigner.sampleRecords;

    const attributes = ['data-sink', 'deployments'].includes(this.state.currentPage)
      ? this.simulateImpactOnAttributes(pipeline.pipelineSteps.length, true)
      : this.simulateImpactOnAttributes(this.state.currentStep);

    let classNames = 'create-pipeline-form pipeline-designer';

    if (this.state.currentPage !== 'steps') {
      classNames += ' pipeline-designer-page';
    }

    if (this.state.contextBarActive && this.state.currentStep !== undefined) {
      classNames += ' datacater-context-bar-active';
    }

    if (this.state.currentPage === 'steps' && this.state.currentStep > 0) {
      classNames += ' pipeline-designer-transformations';
    }

    if (
      pipeline.joinedDataSourceId !== undefined &&
      pipeline.joinedDataSourceProfileId !== undefined &&
      this.props.dataSourceProfiles.joinedDataSourceProfile !== undefined
    ) {
      classNames += ' datacater-pipeline-with-join';
    }

    const pipelineHasActiveDeployment =
      this.props.pipelines.pipelineExecutables
        .filter(_ => _.startedAt !== undefined && _.stoppedAt === undefined)
        .length > 0;

    return (
      <React.Fragment>
        {this.props.pipelines.updateConflict === true &&
          <Modal show={true}>
            <Modal.Header>
              <Modal.Title className='mb-0 d-flex align-items-center'>
                <AlertTriangle className='feather-icon mr-2' />
                Write conflict detected
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                We detected a write conflict most likely caused by someone else
                editing the pipeline simultaneously.
              </p>
              <p className='mb-0'>
                Please reload the pipeline designer to resolve the conflict and start over again.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <a className='btn btn-primary d-flex align-items-center' href={`/pipelines/${pipeline.id}/edit`}>
                <RefreshCcw className='feather-icon mr-2' />
                Reload pipeline designer
              </a>
            </Modal.Footer>
          </Modal>
        }
        <div className='loading-pipeline-designer-wrapper d-none'>
          <div className='loading-pipeline-designer'>
            <div className='loader-text'>
              <div className='spinner-border text-success' role='status'>
                  <span className='sr-only'>Loading...</span>
              </div>
              <div className='loader-label'>
                Loading pipeline designer
              </div>
            </div>
          </div>
        </div>
        <div className='main-content'>
          <div className={classNames}>
            <div className='top-row row align-items-center'>
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col overflow-hidden text-nowrap'>
                    <PipelineNameForm
                      canEditPipeline={canEditPipeline}
                      currentUser={currentUser}
                      handleChangeFunc={this.handleChange}
                      pipeline={this.state.pipeline}
                      project={project} />
                  </div>
                  <div className='col-auto'>
                    <ChangesStatus
                      moveToPageFunc={this.moveToPage}
                      pipelineId={this.state.pipeline.id}
                      unpersistedChanges={this.state.unpersistedChanges}
                      updatingPipeline={this.props.pipelines.updatingPipeline}/>
                  </div>
                  <div className='col-auto'>
                    {!this.state.codeView &&
                      <button
                          className='btn btn-white mr-3'
                          onClick={(() => {this.toggleCodeView()})}>
                        <Code className="feather-icon mr-2" />
                        YAML
                      </button>
                    }
                    {this.state.codeView &&
                      <button
                          className='btn btn-primary mr-3'
                          onClick={(() => {this.toggleCodeView()})}>
                        <Code className="feather-icon mr-2" />
                        YAML
                      </button>
                    }
                    {pipelineHasActiveDeployment &&
                      <button
                        className='btn btn-primary mr-3'
                        onClick={() => { this.moveToPage('deployments') }}>
                        <PlayCircle className='feather-icon mr-2' />
                        Active deployment
                      </button>
                    }
                    {!pipelineHasActiveDeployment &&
                        <button
                          className='btn btn-white mr-3'
                          onClick={() => { this.moveToPage('deployments') }}>
                        <PlayCircle className='feather-icon mr-2' />
                        No active deployment
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
            <PipelineDesignerNavigation
              addPipelineStepFunc={this.addPipelineStep}
              canEditPipeline={canEditPipeline}
              codeView={this.state.codeView}
              currentPage={this.state.currentPage}
              currentStep={this.state.currentStep}
              history={this.props.history}
              moveToPageFunc={this.moveToPage}
              moveToStepFunc={this.moveToStep}
              pipeline={pipeline}
              toggleDataSinkDialogFunc={this.toggleDataSinkDialog} />
            {this.state.codeView &&
              <CodeView
                  code={this.props.pipelines.yamlRepresentation}
                  yamlError={this.props.pipelines.yamlError}
              />
            }
            {!this.state.codeView && (this.state.currentPage === 'instructions') &&
              <InstructionsPage
                addPipelineStepFunc={this.addPipelineStep}
                attributes={attributes}
                canEditPipeline={canEditPipeline}
                checkDataSourceHealthFunc={this.checkDataSourceHealth}
                checkDataSinkHealthFunc={this.checkDataSinkHealth}
                dataSink={dataSink}
                dataSinks={this.props.dataSinks}
                dataSource={dataSource}
                dataSources={this.props.dataSources}
                exportPipelineFunc={this.props.exportPipeline}
                failedLoadingSampleRecords={this.props.dataSourceProfiles.failedLoadingSampleRecords}
                fetchFlatFilesOfPipelineFunc={this.props.fetchFlatFilesOfPipeline}
                flatFiles={this.props.flatFiles}
                getNumberOfRecordsInDataSinkFunc={this.props.getNumberOfRecordsInDataSink}
                getNumberOfRecordsInDataSourceFunc={this.props.getNumberOfRecordsInDataSource}
                getNumberOfRecordsInJoinedDataSourceFunc={this.props.getNumberOfRecordsInJoinedDataSource}
                history={this.props.history}
                ingestDataFunc={this.ingestData}
                moveToPageFunc={this.moveToPage}
                moveToStepFunc={this.moveToStep}
                pipelineHasActiveDeployment={pipelineHasActiveDeployment}
                pipeline={this.state.pipeline}
                pipelines={this.props.pipelines}
                resetErrorMessageFunc={this.props.resetErrorMessage}
                resetFileUploadFunc={this.props.resetFileUpload}
                restartSourceConnectorFunc={this.restartSourceConnector}
                recreateSourceConnectorFunc={this.recreateSourceConnector}
                restartSinkConnectorFunc={this.restartSinkConnector}
                />
            }
            {!this.state.codeView && canEditPipeline && (this.state.currentPage === 'join') &&
              <JoinPage
                currentUser={currentUser}
                dataSource={dataSource}
                dataSources={this.props.dataSources.dataSources}
                dataSourceProfiles={this.props.dataSourceProfiles}
                handleJoinConfigChangeFunc={this.handleJoinConfigChange}
                pipeline={this.state.pipeline}
                pipelines={this.props.pipelines}
                resetJoinedDataSourceFunc={this.resetJoinedDataSource}
                selectJoinedDataSourceFunc={this.selectJoinedDataSource}
                />
            }
            {!this.state.codeView && (this.state.currentPage === 'deployments') &&
              <DeploymentsPage
                areAnyExecutablesCompilingFunc={this.areAnyExecutablesCompiling}
                attributes={attributes}
                canEditPipeline={canEditPipeline}
                compileExecutableFunc={this.compileExecutable}
                dataSink={dataSink}
                deleteExecutableFunc={this.deleteExecutable}
                errorMessage={this.state.errorMessage}
                fetchExecutablesFunc={this.props.fetchExecutables}
                haltExecutableFunc={this.haltExecutable}
                pipeline={this.state.pipeline}
                pipelines={this.props.pipelines}
                pipelineExecutables={this.props.pipelines.pipelineExecutables}
                pipelineHasActiveDeployment={pipelineHasActiveDeployment}
                resetOffsetOfPipelineFunc={this.props.resetOffsetOfPipeline}
                resetOffsetOfSinkConnectorFunc={this.props.resetOffsetOfSinkConnectorOfPipeline}
                resetPipelineFunc={this.resetPipeline}
                showPipelineLogs={this.state.showPipelineLogs}
                startExecutableFunc={this.startExecutable}
                toggleLogsOfRunningExecutableFunc={this.toggleLogsOfRunningExecutable}
                updateExecutableNameFunc={this.updatePipelineExecutableName}
              />
            }
            {(!this.state.codeView && canEditPipeline && this.state.currentPage === 'settings') &&
              <SettingsPage
                canEditPipeline={canEditPipeline}
                canDeletePipeline={canDeletePipeline}
                currentUser={currentUser}
                duplicatePipelineFunc={this.props.duplicatePipeline}
                errorMessage={this.state.errorMessage}
                handleDeleteFunc={this.handleDelete}
                pipeline={this.state.pipeline}
                pipelines={this.props.pipelines}
                pipelineHasActiveDeployment={pipelineHasActiveDeployment}
                project={project}
                transferPipelineFunc={this.handleTransferPipelineToProject}
              />
            }
            {!this.state.codeView && (this.state.currentPage === 'steps') &&
              <StepsPage
                addColumnFunc={this.addColumn}
                addedColumn={this.state.addedColumn}
                addPipelineStepFunc={this.addPipelineStep}
                attributes={attributes}
                attributeProfiles={this.props.pipelineDesigner.attributeProfiles}
                contextBarActive={this.state.contextBarActive}
                canEditPipeline={canEditPipeline}
                currentStep={this.state.currentStep}
                dataSource={dataSource}
                dataSources={this.props.dataSources.dataSources}
                dataSourceProfile={this.props.dataSourceProfiles.dataSourceProfile}
                editColumn={this.state.editColumn}
                editColumnFunc={this.editColumn}
                errorMessage={this.state.errorMessage}
                handleChangeFunc={this.handleChange}
                handleAssertionChangeFunc={this.handleAssertionChange}
                handlePipelineStepChangeFunc={this.handlePipelineStepChange}
                hideContextBarFunc={this.hideContextBar}
                history={this.props.history}
                joinedDataSourceProfile={this.props.dataSourceProfiles.joinedDataSourceProfile}
                movePipelineStepFunc={this.movePipelineStep}
                removePipelineStepFunc={this.removePipelineStep}
                moveToStepFunc={this.moveToStep}
                originalRecordsSize={this.props.dataSourceProfiles.sampleRecords.length}
                pipeline={pipeline}
                removeColumnFunc={this.removeColumn}
                sampleRecords={sampleRecords}
                transformationState={this.props.transformations}
                transformers={transformers}
                unpersistedChanges={this.state.unpersistedChanges}
                updatingPipeline={this.props.pipelines.updatingPipeline} />
            }
            {!this.state.codeView && (this.state.currentPage === 'data-sink') &&
              <DataSinkPage
                addDataSinkAttributeFunc={this.addDataSinkAttribute}
                attributes={attributes}
                canEditPipeline={canEditPipeline}
                currentUser={currentUser}
                dataSink={dataSink}
                dataSinks={this.props.dataSinks.dataSinks}
                dataSinkId={pipeline.dataSinkId}
                dataSinkProfileAttributes={this.state.dataSinkProfileAttributes}
                dataSinkProfile={this.props.dataSinkProfiles.dataSinkProfile}
                deleteDataSinkAttributeFunc={this.deleteDataSinkAttribute}
                handleChangeFunc={this.handleChange}
                handleDataSinkProfileChangeFunc={this.handleDataSinkProfileChange}
                handleMultipleDataSinkProfileChangesFunc={this.handleMultipleDataSinkProfileChanges}
                resetDataSinkFunc={this.resetDataSink}
                pipeline={pipeline}
                pipelineHasActiveDeployment={pipelineHasActiveDeployment}
                selectDataSinkFunc={this.selectDataSink}
                toggleDataSinkDialogFunc={this.toggleDataSinkDialog}/>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    dataSinks:          state.dataSinks,
    dataSinkProfiles:   state.dataSinkProfiles,
    dataSources:        state.dataSources,
    dataSourceProfiles: state.dataSourceProfiles,
    flatFiles:          state.flatFiles,
    pipelines:          state.pipelines,
    pipelineDesigner:   state.pipelineDesigner,
    transformations:    state.transformations,
    users:              state.users
  }
}

const mapDispatchToProps = {
  addDataSink:                              addDataSink,
  addDataSinkProfileAttribute:              addDataSinkProfileAttribute,
  addDataSourceProfileAttribute:            addDataSourceProfileAttribute,
  applyPipelineToSampleRecords:             applyPipelineToSampleRecords,
  applyTransformationToCachedSampleRecords: applyTransformationToCachedSampleRecords,
  compileExecutable:                        compileExecutable,
  createDataSinkProfile:                    createDataSinkProfile,
  deleteDataSink:                           deleteDataSink,
  deleteDataSinkProfileAttribute:           deleteDataSinkProfileAttribute,
  deleteDataSourceProfileAttribute:         deleteDataSourceProfileAttribute,
  deleteExecutable:                         deleteExecutable,
  deletePipeline:                           deletePipeline,
  duplicatePipeline:                        duplicatePipeline,
  executeTransformation:                    executeTransformation,
  exportPipeline:                           exportPipeline,
  fetchCurrentUser:                         fetchCurrentUser,
  fetchDataSinks:                           fetchDataSinks,
  fetchDataSinkProfile:                     fetchDataSinkProfile,
  fetchDataSources:                         fetchDataSources,
  fetchDataSourceProfile:                   fetchDataSourceProfile,
  fetchExecutables:                         fetchExecutables,
  fetchFlatFilesOfPipeline:                 fetchFlatFilesOfPipeline,
  fetchMetricsOfPipeline:                   fetchMetricsOfPipeline,
  fetchYamlOfPipeline:                      fetchYamlOfPipeline,
  fetchJoinedDataSourceProfile:             fetchJoinedDataSourceProfile,
  fetchJoinedSourceConnectorOfPipeline:     fetchJoinedSourceConnectorOfPipeline,
  fetchJoinedSampleRecords:                 fetchJoinedSampleRecords,
  fetchSampleRecords:                       fetchSampleRecords,
  fetchSinkConnectorOfPipeline:             fetchSinkConnectorOfPipeline,
  fetchSourceConnectorOfPipeline:           fetchSourceConnectorOfPipeline,
  fetchPipeline:                            fetchPipeline,
  fetchPipelineExecutableLogs:              fetchPipelineExecutableLogs,
  getNumberOfRecordsInDataSink:             getNumberOfRecordsInDataSink,
  getNumberOfRecordsInDataSource:           getNumberOfRecordsInDataSource,
  getNumberOfRecordsInJoinedDataSource:     getNumberOfRecordsInJoinedDataSource,
  haltExecutable:                           haltExecutable,
  ingestFileIntoPipeline:                   ingestFileIntoPipeline,
  profileSampleRecords:                     profileSampleRecords,
  resetDataSinkProfile:                     resetDataSinkProfile,
  resetErrorMessage:                        resetErrorMessage,
  resetFileUpload:                          resetFileUpload,
  resetJoinedDataSourceProfile:             resetJoinedDataSourceProfile,
  resetOffsetOfSinkConnectorOfPipeline:     resetOffsetOfSinkConnectorOfPipeline,
  resetOffsetOfPipeline:                    resetOffsetOfPipeline,
  resetPipeline:                            resetPipeline,
  resetSampleRecordsCache:                  resetSampleRecordsCache,
  resetTransformationError:                 resetTransformationError,
  restartJoinedSourceConnectorOfPipeline:   restartJoinedSourceConnectorOfPipeline,
  restartSinkConnectorOfPipeline:           restartSinkConnectorOfPipeline,
  restartSourceConnectorOfPipeline:         restartSourceConnectorOfPipeline,
  recreateSourceConnectorOfPipeline:        recreateSourceConnectorOfPipeline,
  startExecutable:                          startExecutable,
  transferPipelineToProject:                transferPipelineToProject,
  updateDataSinkOfPipeline:                 updateDataSinkOfPipeline,
  updateDataSinkProfileAttribute:           updateDataSinkProfileAttribute,
  updateExecutableName:                     updateExecutableName,
  updateJoinedDataSourceOfPipeline:         updateJoinedDataSourceOfPipeline,
  updatePipeline:                           updatePipeline
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPipeline);
