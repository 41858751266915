import React, { Component } from 'react';
import { Check,
         X,
         Zap } from 'react-feather';

class TestConnectionButton extends Component {
  constructor(props) {
    super(props);
    this.handleTestConnection = this.handleTestConnection.bind(this);
  }

  handleTestConnection(event) {
    event.preventDefault();
    this.props.handleTestConnectionFunc();
  }

  render() {
    const dataSinks = this.props.dataSinks;
    let icon        = (<Zap className='feather-icon mt-n1 mr-2' />);
    let label       = 'Test connection';
    let className   = 'test-connection';

    if (dataSinks.testingConnection) {
      label = 'Establishing connection...';
    } else if (dataSinks.connectionWorking === true) {
      label     = 'Connection test was successful';
      className = 'btn-success';
      icon      = (<Check className='feather-icon mt-n1 mr-2' />);
    } else if (dataSinks.connectionWorking === false) {
      label     = 'Connection test failed (Retry)';
      className = 'btn-danger';
      icon      = (<X className='feather-icon mt-n1 mr-2' />);
    }

    return (
      <React.Fragment>
        <a href='/data_sinks/new'
           className={'btn btn-white ' + className}
           onClick={this.handleTestConnection}>
          {icon}
          {label}
        </a>
        {dataSinks.connectionTestError !== undefined && dataSinks.connectionWorking === false &&
          <div className='alert bg-danger-soft rounded-0 mt-4 mb-0'>
            <p className='font-weight-bold mb-0'>Connection failure:</p>
            <code className='text-black'>
              {dataSinks.connectionTestError}
            </code>
          </div>
        }
        </React.Fragment>
    );
  }
}

export default TestConnectionButton;
