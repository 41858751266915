import React, { Component } from 'react';
import { ArrowLeft, Plus } from 'react-feather';

class MainContent extends Component {
  render() {
    return (
      <div className='main-content'>
        <div className='header'>
          <div className='container'>
            <div className='header-body'>
              <div className='row align-items-end'>
                <div className='col'>
                  <h6 className='header-pretitle'>
                    {this.props.preTitle}
                  </h6>
                  <h1 className='header-title'>
                    {this.props.title}
                  </h1>
                </div>
                {this.props.buttonLabel !== undefined &&
                  <div className='col-auto'>
                    {this.props.buttonLabel.includes('Go back') &&
                      <a href={this.props.buttonLink} className='btn btn-outline-primary btn-lift d-flex align-items-center'>
                        <ArrowLeft className='feather-icon' />
                        {this.props.buttonLabel}
                      </a>
                    }
                    {this.props.buttonLabel.includes('Create') &&
                      <a href={this.props.buttonLink} className='btn btn-primary btn-lift d-flex align-items-center'>
                        <Plus className='feather-icon' />
                        {this.props.buttonLabel}
                      </a>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainContent;
