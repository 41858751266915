import React, { Component }       from 'react';
import { BarChart, Plus }         from 'react-feather';
import PipelineDesignerDataSource from '../../form/PipelineDesignerDataSource';
import PipelineDesignerFilters    from '../../form/PipelineDesignerFilters';
import { blurOnEnterKeyDown }     from '../../../../helpers/blurOnEnterKeyDown';
import { formatInteger }          from '../../../../helpers/formatInteger';
import '../../../../scss/grid/toolbar.scss';

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingName: false
    };

    this.toggleEditingName = this.toggleEditingName.bind(this);
  }

  toggleEditingName(event) {
    this.setState({
      editingName: !this.state.editingName
    });
  }

  getPipelineStepForAttribute(attribute) {
    return this.props.assertions.find(
      assertion => assertion.attributeId === attribute.id);
  }

  render() {
    const {
      assertions,
      canEditPipeline,
      currentStep,
      dataSource,
      joinedDataSource,
      originalRecordsSize,
      pipelineStep,
      sampleRecordsSize
    } = this.props;

    const name = (
      (pipelineStep !== undefined) &&
      (pipelineStep.name !== undefined) &&
      (pipelineStep.name.length > 0)
    )
      ? pipelineStep.name
      : 'Untitled Step';

    let statisticsClassNames = 'btn btn-sm btn-white ml-2';
    if (this.props.showStatistics) {
      statisticsClassNames += ' active';
    }

    return (
      <React.Fragment>
        <div className='datacater-grid-toolbar row align-items-center m-0'>
          {currentStep === undefined &&
            <div className='col d-flex align-items-center overflow-hidden text-nowrap'>
              <PipelineDesignerDataSource
                dataSource={dataSource}
                joinedDataSource={joinedDataSource} />
            </div>
          }
          {currentStep === 0 &&
            <div className='col d-flex align-items-center overflow-hidden text-nowrap pl-0'>
              <PipelineDesignerFilters
                assertions={assertions}
                originalRecordsSize={originalRecordsSize}
                sampleRecordsSize={sampleRecordsSize} />
            </div>
          }
          {currentStep > 0 &&
            <div className='col d-flex align-items-center overflow-hidden text-nowrap p-0'>
              <h6 className='header-pretitle d-inline-block mb-0 mr-2'>
                Step #{pipelineStep.sortPosition}
              </h6>
              {!canEditPipeline &&
                <h4 className='d-inline-block mb-0 datacater-grid-toolbar-pipeline-name'>
                  {name}
                </h4>
              }
              {canEditPipeline && !this.state.editingName &&
                <h4
                  className='d-inline-block mb-0 datacater-grid-toolbar-pipeline-name'
                  onClick={this.toggleEditingName}>
                  {name}
                </h4>
              }
              {canEditPipeline && this.state.editingName &&
                <input
                  autoFocus
                  type='text'
                  name='name'
                  data-sort-position={pipelineStep.sortPosition}
                  onChange={this.props.handlePipelineStepChangeFunc}
                  onBlur={this.toggleEditingName}
                  onKeyDown={blurOnEnterKeyDown}
                  className='form-control form-control-sm name-textfield d-inline'
                  placeholder='Name the transformation step'
                  value={pipelineStep.name} />
              }
            </div>
          }
          <div className='toolbox col-auto d-flex align-items-center'>
            <a href='/' className={statisticsClassNames} onClick={this.props.toggleStatisticsFunc}>
              <BarChart className='feather-icon mt-n1 ml-n1'/>
              Statistics
            </a>
            {canEditPipeline && (currentStep > 0) &&
              <a className='btn btn-sm btn-white ml-2' href='/' onClick={this.props.addColumnFunc}>
                <Plus className='feather-icon mt-n1 ml-n1'/>
                New attribute
              </a>
            }
          </div>
          <div className='toolbox col-auto d-flex align-items-center'>
            <span className='datacater-sample-records'>
              {formatInteger(this.props.sampleRecordsSize)} sample records
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Toolbar;
