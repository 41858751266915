import React, { Component } from 'react';
import ConfigForm           from '../../data_stores/ConfigForm';
import TestConnectionButton from '../TestConnectionButton';

class HubSpotConfig extends Component {
  render() {
    const {
      dataSink,
      dataSinks,
      errorMessages,
      handleChangeFunc,
      handleConfigChangeFunc,
      handleTestConnectionFunc
    } = this.props;

    const crmObjectType = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.crmObjectType !== undefined)
      ? dataSink.connectorConfig.crmObjectType
      : 'contacts';

    const attributeNameDeduplication = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.attributeNameDeduplication !== undefined)
      ? dataSink.connectorConfig.attributeNameDeduplication
      : '';

    const associatedCrmObjectType = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.associatedCrmObjectType !== undefined)
      ? dataSink.connectorConfig.associatedCrmObjectType
      : '';

    let availableObjectTypesForAssociation = undefined;
    if (crmObjectType === 'contacts') {
      availableObjectTypesForAssociation = ['Company'];
    } else if (crmObjectType === 'companies') {
      availableObjectTypesForAssociation = ['Contact'];
    } else if (crmObjectType === 'deals') {
      availableObjectTypesForAssociation = ['Contact', 'Company'];
    } else if (crmObjectType === 'custom_object') {
      availableObjectTypesForAssociation = ['Contact', 'Company', 'Deal'];
    }

    const localAttributeAssociation = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.localAttributeAssociation !== undefined)
      ? dataSink.connectorConfig.localAttributeAssociation
      : '';

    const foreignAttributeAssociation = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.foreignAttributeAssociation !== undefined)
      ? dataSink.connectorConfig.foreignAttributeAssociation
      : '';

    const customObjectTypeId = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.customObjectTypeId !== undefined)
      ? dataSink.connectorConfig.customObjectTypeId
      : '';

    const customObjectName = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.customObjectName !== undefined)
      ? dataSink.connectorConfig.customObjectName
      : '';

    const authenticationMethod = (dataSink.connectorConfig !== undefined && dataSink.connectorConfig.authenticationMethod !== undefined)
      ? dataSink.connectorConfig.authenticationMethod
      : 'apiKey';

    const editPasswordNotice = window.location.href.includes('edit')
      ? (<span className='ml-1 text-gray font-size-sm'>You don't need to enter the API key unless you want to change it.</span>)
      : (<span className='text-danger'>*</span>);

    return (
      <React.Fragment>
        <div className='form-group'>
          <label>Authentication method</label>
          <select
            className='custom-select'
            name='authenticationMethod'
            onChange={handleConfigChangeFunc}
            value={authenticationMethod}>
            <option value='apiKey'>API Key</option>
            <option value='privateApp'>Private App</option>
          </select>
        </div>
        {authenticationMethod === 'apiKey' &&
          <ConfigForm
            errorMessages={errorMessages}
            label={(<React.Fragment>API key{editPasswordNotice}</React.Fragment>)}
            name='password'
            onChangeFunc={handleChangeFunc}
            value={dataSink.password} />
        }
        {authenticationMethod === 'privateApp' &&
          <ConfigForm
            errorMessages={errorMessages}
            label={(<React.Fragment>Access token{editPasswordNotice}</React.Fragment>)}
            name='password'
            onChangeFunc={handleChangeFunc}
            value={dataSink.password} />
        }
        <div className='form-group'>
          <label>HubSpot CRM object</label>
          <select
            className='custom-select'
            name='crmObjectType'
            onChange={handleConfigChangeFunc}
            value={crmObjectType}>
            <option value='companies'>Companies</option>
            <option value='contacts'>Contacts</option>
            <option value='custom_object'>Custom Object</option>
            <option value='deals'>Deals</option>
            <option value='products'>Products</option>
          </select>
        </div>
        {crmObjectType === 'custom_object' &&
          <React.Fragment>
            <ConfigForm
              errorMessages={errorMessages}
              label='Custom object type ID'
              name='customObjectTypeId'
              onChangeFunc={handleConfigChangeFunc}
              value={customObjectTypeId} />
            <ConfigForm
              errorMessages={errorMessages}
              label='Custom object name'
              name='customObjectName'
              onChangeFunc={handleConfigChangeFunc}
              value={customObjectName} />
          </React.Fragment>
        }
        <ConfigForm
          errorMessages={errorMessages}
          label='Attribute used for deduplication'
          name='attributeNameDeduplication'
          onChangeFunc={handleConfigChangeFunc}
          value={attributeNameDeduplication} />
        {availableObjectTypesForAssociation !== undefined &&
          <React.Fragment>
            <div className='form-group'>
              <label>Associated HubSpot CRM object</label>
              <select
                className='custom-select'
                name='associatedCrmObjectType'
                onChange={handleConfigChangeFunc}
                value={associatedCrmObjectType}>
                <option value=''>--- No association</option>
                {availableObjectTypesForAssociation.map((associatedType, idx) => (
                  <option value={associatedType.lowercase}>{associatedType}</option>
                ))}
              </select>
            </div>
            {availableObjectTypesForAssociation.includes(associatedCrmObjectType) &&
              <React.Fragment>
                <ConfigForm
                  errorMessages={errorMessages}
                  label={`Local attribute from ${crmObjectType} to use for association`}
                  name='localAttributeAssociation'
                  onChangeFunc={handleConfigChangeFunc}
                  value={localAttributeAssociation} />
                <ConfigForm
                  errorMessages={errorMessages}
                  label={`Foreign attribute from ${associatedCrmObjectType.toLowerCase()}s to use for association`}
                  name='foreignAttributeAssociation'
                  onChangeFunc={handleConfigChangeFunc}
                  value={foreignAttributeAssociation} />
              </React.Fragment>
            }
          </React.Fragment>
        }
        <div className='mb-2'>
          <TestConnectionButton
            dataSinks={dataSinks}
            handleTestConnectionFunc={handleTestConnectionFunc} />
        </div>
      </React.Fragment>
    );
  }
}

export default HubSpotConfig;
