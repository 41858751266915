import React,
       { Component }   from 'react';
import BaseTable,
       { AutoResizer } from 'react-base-table';
import TableHeaderCell from './TableHeaderCell';
import { isString }    from '../../../helpers/isString';

class Grid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfiguration: false
    };

    this.getColumns          = this.getColumns.bind(this);
    this.getData             = this.getData.bind(this);
    this.toggleConfiguration = this.toggleConfiguration.bind(this);
  }

  getColumns() {
    // get attributes sorted by their appearance in source file
    const attributes = this.props.dataSourceProfile.attributes
      .filter(attribute => !['__datacater_auto_id', '__datacater_file_id', '__datacater_file_name'].includes(attribute.name))
      .sort((a, b) => parseInt(a.id) - parseInt(b.id));

    let columnsConfig = [...attributes];
    let columns = [];

    // add # row number column
    columnsConfig.unshift({id: 0, name: '#'})
    columnsConfig.map((attribute, idx) => columns.push({
      attribute:      attribute,
      attributeIndex: idx,
      cellRenderer:   function({cellData, columns, column, columnIndex, rowData, rowIndex, container, isScrolling}) {
        if (!column.isRowNumber && column.attribute !== undefined) {
          const rawValue = rowData[column.attribute.id];
          let cellContent = (<React.Fragment></React.Fragment>);

          const renderBoolean = function(value) {
            if (value === true) {
              return 'true';
            } else if (value === false) {
              return 'false';
            } else {
              return '';
            }
          }

          const renderString = function(value) {
            if (!isString(value)) {
              return value;
            }

            const tokens = value.split(' ');

            if (tokens.length === 0) {
              return value;
            } else {
              return (
                <React.Fragment>
                  {tokens.map((entry, index) => (
                    <React.Fragment key={index}>
                      {index > 0 &&
                        <span className='text-black-50'>·</span>
                      }
                      {entry}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            }
          }

          if (rawValue == null) {
            cellContent = (<React.Fragment></React.Fragment>);
          } else if (attribute.dataType === 'boolean') {
            if (rawValue.constructor === Array) {
              cellContent = (
                <React.Fragment>
                  <span className='text-black-50'>(</span>
                  {rawValue.map((entry, index) => (
                    <React.Fragment key={index}>
                      {index > 0 &&
                        <span className='text-black-50'>,</span>
                      }
                      {renderBoolean(entry)}
                    </React.Fragment>
                  ))}
                  <span className='text-black-50'>)</span>
                </React.Fragment>
              );
            } else {
              cellContent = renderBoolean(rawValue);
            }
          } else {
            if (rawValue.constructor === Array) {
              cellContent = (
                <React.Fragment>
                  <span className='text-black-50'>(</span>
                  {rawValue.map((entry, index) => (
                    <React.Fragment key={index}>
                      {index > 0 &&
                        <span className='text-black-50'>,</span>
                      }
                      {renderString(entry)}
                    </React.Fragment>
                  ))}
                  <span className='text-black-50'>)</span>
                </React.Fragment>
              );
            } else {
              cellContent = renderString(rawValue);
            }
          }

          return (
            <div className='sample-cell w-100 text-nowrap'>
              {cellContent}
            </div>
          );
        } else {
          return cellData;
        }
      },
      createPipelineStatus:            this.props.createPipelineStatus,
      dataGetter:                      ({rowData, rowIndex}) => attribute.id !== 0 ? rowData[attribute.id] : rowIndex + 1,
      frozen:                          attribute.id === 0 ? 'left' : false,
      handleAttributeChangeFunc:       this.props.handleAttributeChangeFunc,
      selectAttributeAsPrimaryKeyFunc: this.props.selectAttributeAsPrimaryKeyFunc,
      isRowNumber:                     attribute.id === 0,
      key:                             parseInt(attribute.id),
      resizable:                       true,
      title:                           attribute.name,
      width:                           attribute.id === 0 ? 50 : 300
    }));

    return columns;
  }

  getData() {
    // we have to make sure that the samples get an id property. this is needed for row hover / selection
    const sampleRecords = this.props.sampleRecords.map(function(sample, index) {
      return Object.assign({}, sample, { id: index + 1 });
    });

    return sampleRecords;
  }

  toggleConfiguration() {
    this.setState({
      showConfiguration: !this.state.showConfiguration
    });
  }

  render() {
    return (
      <AutoResizer>
        {({width, height}) => (
          <BaseTable
            classPrefix='datacater-grid'
            columns={this.getColumns()}
            components={{TableHeaderCell}}
            data={this.getData()}
            fixed
            headerHeight={40}
            height={height}
            rowHeight={24}
            width={width}
          />
        )}
      </AutoResizer>
    );
  }
}

export default Grid;
