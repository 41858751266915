import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { Redirect }         from 'react-router-dom';
import { ArrowLeft, Copy }  from 'react-feather';
import MainContent          from '../../components/layout/MainContent';
import Navigation           from '../../components/settings/Navigation';
import { fetchInfo }        from '../../actions/info';
import { fetchCurrentUser } from '../../actions/users';
import { addApiCredential } from '../../actions/api_credentials';

class NewAPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCredential: {
        name: '',
      },
      apiCredentialCreated: false,
      errorMessage: ''
    };
    this.handleChange         = this.handleChange.bind(this);
    this.handleSubmit         = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchInfo();
    this.props.fetchCurrentUser();
  }

  handleChange(event) {
    let apiCredential = this.state.apiCredential;

    apiCredential[event.target.name] = event.target.value;

    this.setState({
      apiCredential: apiCredential,
      errorMessage:  ''
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.apiCredential.name.length === 0) {
      this.setState({
        errorMessage: 'Please provide a name.'
      });
    } else {
      this.props.addApiCredential(this.state.apiCredential).then(() => {
        this.setState({
          apiCredential: {
            name: ''
          },
          errorMessage: '',
          apiCredentialCreated: true
        });
      });
    }
  }

  render() {
    const currentUser = this.props.users.currentUser;

    if (currentUser === undefined) {
      return (<div></div>);
    }

    const apiCredential = this.state.apiCredential;

    return (
      <MainContent preTitle='Settings' title='Create user'>
        <div className='card card-fill'>
          <div className='card-body'>
            <div className='row mb-4 mt-n3'>
              <div className='col-12'>
                <Navigation currentUser={currentUser} info={this.props.info.info} />
              </div>
            </div>
            {currentUser !== undefined &&
              <React.Fragment>
                <div className='row align-items-center mt-n2'>
                  <div className='col'>
                    <h3 className='card-header-title'>Create API integration</h3>
                  </div>
                  <div className='col-auto'>
                    <a className='btn btn-white d-flex align-items-center' href='/settings/api'>
                      <ArrowLeft className='feather-icon' />
                      Go back
                    </a>
                  </div>
                </div>
                <hr className='mb-4' />
                {this.state.errorMessage.length > 0 &&
                  <div className='alert alert-danger mb-4 rounded-0'>
                  {this.state.errorMessage}
                  </div>
                }
                {this.state.apiCredentialCreated &&
                  <React.Fragment>
                    <div className='alert alert-success mb-4 rounded-0'>
                      You have successfully created a new integration.<br /> Please use the following credentials for authentication (note that we will never show the client secret again):
                    </div>
                    <ul className='list-group list-group-flush list'>
                      <li className='list-group-item py-2'>
                        <div className='row align-items-center py-2'>
                          <div className='col-3'>
                            Client id:
                          </div>
                          <div className='col font-weight-bold' style={{"font-family": "'Roboto Mono', monospace"}}>
                            {this.props.apiCredentials.apiCredential.clientId}
                            <Copy className='feather-icon clickable ml-2' title='Copy to clipboard' onClick={() => {navigator.clipboard.writeText(this.props.apiCredentials.apiCredential.clientId)}} />
                          </div>
                        </div>
                      </li>
                      <li className='list-group-item py-2'>
                        <div className='row align-items-center py-2'>
                          <div className='col-3'>
                            Client secret:
                          </div>
                          <div className='col font-weight-bold' style={{"font-family": "'Roboto Mono', monospace"}}>
                            {this.props.apiCredentials.apiCredential.clientSecret}
                            <Copy className='feather-icon clickable ml-2' title='Copy to clipboard' onClick={() => {navigator.clipboard.writeText(this.props.apiCredentials.apiCredential.clientSecret)}} />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </React.Fragment>
                }
                {!this.state.apiCredentialCreated &&
                  <form>
                    <div className='form-group'>
                      <label htmlFor='name'>Name:</label>
                      <input
                        type='text'
                        className='form-control'
                        name='name'
                        onChange={this.handleChange}
                        value={apiCredential.name} />
                    </div>
                    <button
                      className='btn btn-primary'
                      onClick={this.handleSubmit}
                      type='submit'>
                      Create integration
                    </button>
                  </form>
                }
              </React.Fragment>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    apiCredentials: state.apiCredentials,
    info:           state.info,
    users:          state.users
  }
}

const mapDispatchToProps = {
  addApiCredential: addApiCredential,
  fetchCurrentUser: fetchCurrentUser,
  fetchInfo:        fetchInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAPI);
