import React, { Component }              from 'react';
import Grid                              from '../pipelines/designer/Grid';
import Toolbar                           from '../pipelines/designer/grid/Toolbar';
import PipelineDesignerPipelineStepsList from '../pipelines/form/PipelineDesignerPipelineStepsList';

class Samples extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatistics: false
    };
    this.toggleStatistics = this.toggleStatistics.bind(this);
  }

  toggleStatistics(event) {
    event.preventDefault();
    this.setState({
      showStatistics: !this.state.showStatistics
    });
  }

  render() {
    const {
      canEditPipeline,
      dataSourceMappings,
      dataSourceProfile
    } = this.props;

    // push primary keys to the left
    const attributes = this.props.attributes
      .filter(attribute => !['__datacater_auto_id', '__datacater_file_id'].includes(attribute.name))
      .sort(function(a, b) {
        // move data source to the left, joined data source to the right
        if (a.dataSourceProfileId !== b.dataSourceProfileId) {
          if (parseInt(a.dataSourceProfileId) === parseInt(dataSourceProfile.id)) {
            return -1;
          } else if (parseInt(b.dataSourceProfileId) === parseInt(dataSourceProfile.id)) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return (parseInt(a.id) > parseInt(b.id)) ? 1 : -1;
        }
      });

    if (attributes.length === 0) {
      return (<div/>);
    }

    const pipelineStep = (this.props.currentStep > 0)
      ? this.props.pipelineSteps.find(step => step.sortPosition === this.props.currentStep)
      : undefined;
    const sampleRecords = this.props.sampleRecords;

    // add row numbers
    const calculatedWidth = (attributes.length * 300) + 210;
    const tableWidth = window.innerWidth;
    const cellWidth = (calculatedWidth >= window.innerWidth)
      ? 300
      : ((window.innerWidth - 210) / attributes.length);

    const introducedAttributes = (pipelineStep !== undefined)
      ? pipelineStep.attributes.filter(_ => _.transformationAction === 'add-column').map(_ => _.transformAttributeId)
      : [];

    const sampleRecordsSize = (sampleRecords !== undefined)
      ? sampleRecords
          .filter(record => (record != null) && (record.isDropped !== true))
          .length
      : 0;

    return (
      <React.Fragment>
        {this.props.currentStep > 0 &&
          <PipelineDesignerPipelineStepsList
            addPipelineStepFunc={this.props.addPipelineStepFunc}
            canEditPipeline={canEditPipeline}
            currentStep={this.props.currentStep}
            movePipelineStepFunc={this.props.movePipelineStepFunc}
            moveToStepFunc={this.props.moveToStepFunc}
            pipelineSteps={this.props.pipelineSteps}
            removePipelineStepFunc={this.props.removePipelineStepFunc} />
        }
        <div className='datacater-grid'>
          <Toolbar
            addColumnFunc={this.props.addColumnFunc}
            assertions={this.props.assertions}
            attributes={attributes}
            canEditPipeline={canEditPipeline}
            cellWidth={cellWidth}
            currentStep={this.props.currentStep}
            dataSource={this.props.dataSource}
            editColumnAttribute={this.props.editColumnAttribute}
            editColumnFunc={this.props.editColumnFunc}
            handlePipelineStepChangeFunc={this.props.handlePipelineStepChangeFunc}
            joinedDataSource={this.props.joinedDataSource}
            originalRecordsSize={this.props.originalRecordsSize}
            pipelineStep={pipelineStep}
            removePipelineStepFunc={this.props.removePipelineStepFunc}
            sampleRecordsSize={sampleRecordsSize}
            showStatistics={this.state.showStatistics}
            tableWidth={tableWidth}
            toggleStatisticsFunc={this.toggleStatistics}
            transformers={this.props.transformers} />
          <Grid
            addedColumn={this.props.addedColumn}
            assertions={this.props.assertions}
            attributes={attributes}
            attributeProfiles={this.props.attributeProfiles}
            canEditPipeline={canEditPipeline}
            cellWidth={cellWidth}
            currentStep={this.props.currentStep}
            dataSourceMappings={dataSourceMappings}
            dataSourceProfile={dataSourceProfile}
            editColumnAttribute={this.props.editColumnAttribute}
            editColumnFunc={this.props.editColumnFunc}
            handleAssertionChangeFunc={this.props.handleAssertionChangeFunc}
            handlePipelineStepChangeFunc={this.props.handlePipelineStepChangeFunc}
            introducedAttributes={introducedAttributes}
            pipelineStep={pipelineStep}
            pipelineSteps={this.props.pipelineSteps}
            removeColumnFunc={this.props.removeColumnFunc}
            sampleRecords={sampleRecords}
            showStatistics={this.state.showStatistics}
            tableWidth={tableWidth}
            transformers={this.props.transformers}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Samples;
