import React, { Component } from 'react';
import { AlertCircle }      from 'react-feather';
import CsvConfig            from './previews/CsvConfig';
import JsonConfig           from './previews/JsonConfig';
import XmlConfig            from './previews/XmlConfig';
import Grid                 from './previews/Grid';

class RefineDataSourceProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfiguration: false
    };

    this.toggleConfiguration = this.toggleConfiguration.bind(this);
  }

  toggleConfiguration(event) {
    event.preventDefault();

    this.setState({
      showConfiguration: !this.state.showConfiguration
    });
  }

  render() {
    const {
      changedAttributeNames,
      createPipelineStatus,
      dataSourceProfile,
      disableParserConfig,
      fileType,
      formLabel,
      handleAttributeChangeFunc,
      handleChangeFunc,
      handleConfigChangeFunc,
      profilingFailed,
      reprofileDataSourceProfileFunc,
      sampleRecords,
      selectAttributeAsPrimaryKeyFunc
    } = this.props;

    if (dataSourceProfile === undefined || dataSourceProfile.attributes === undefined) {
      return (<React.Fragment />);
    }

    return (
      <React.Fragment>
        <div className='card-header'>
          <h4 className='card-header-title'>{formLabel}</h4>
          <button
            className='btn btn-white'
            onClick={this.toggleConfiguration}>
            {this.state.showConfiguration && 'Hide settings'}
            {!this.state.showConfiguration && 'Parser settings'}
          </button>
        </div>
        {this.state.showConfiguration &&
          <div className='card-body py-3 pt-4 border-bottom'>
            {fileType === 'CSV' &&
              <CsvConfig
                changedAttributeNames={changedAttributeNames}
                createPipelineStatus={createPipelineStatus}
                dataSourceProfile={dataSourceProfile}
                disableParserConfig={disableParserConfig}
                handleChangeFunc={handleChangeFunc}
                reprofileDataSourceProfileFunc={reprofileDataSourceProfileFunc} />
            }
            {fileType === 'JSON' &&
              <JsonConfig
                changedAttributeNames={changedAttributeNames}
                createPipelineStatus={createPipelineStatus}
                dataSourceProfile={dataSourceProfile}
                disableParserConfig={disableParserConfig}
                handleChangeFunc={handleChangeFunc}
                reprofileDataSourceProfileFunc={reprofileDataSourceProfileFunc} />
            }
            {fileType === 'XML' &&
              <XmlConfig
                changedAttributeNames={changedAttributeNames}
                createPipelineStatus={createPipelineStatus}
                dataSourceProfile={dataSourceProfile}
                disableParserConfig={disableParserConfig}
                handleChangeFunc={handleChangeFunc}
                handleConfigChangeFunc={handleConfigChangeFunc}
                reprofileDataSourceProfileFunc={reprofileDataSourceProfileFunc} />
            }
          </div>
        }
        {profilingFailed === true &&
          <div className='card-body alert alert-danger rounded-0 border-0 mb-0'>
            <AlertCircle className='feather-icon mr-3' />
            {[undefined, ''].includes(dataSourceProfile.profilingFailureMessage) &&
              'We were not able to profile the uploaded file. Please consider adjusting the parser settings.'
            }
            {![undefined, ''].includes(dataSourceProfile.profilingFailureMessage) &&
              'Profiling failed: ' + dataSourceProfile.profilingFailureMessage
            }
          </div>
        }
        {profilingFailed !== true &&
          <div className='card-body datacater-grid-container p-0 w-100 datacater-flat-file-preview-container'>
            <Grid
              createPipelineStatus={createPipelineStatus}
              dataSourceProfile={dataSourceProfile}
              handleAttributeChangeFunc={handleAttributeChangeFunc}
              sampleRecords={sampleRecords}
              selectAttributeAsPrimaryKeyFunc={selectAttributeAsPrimaryKeyFunc} />
          </div>
        }
      </React.Fragment>
    );
  }
}

export default RefineDataSourceProfileForm;
