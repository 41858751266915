import React,
       { Component } from 'react';
import { ListGroup } from 'react-bootstrap';

class AttributeTransformationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: ''
    };

    this.updateSearchQuery = this.updateSearchQuery.bind(this);
  }

  updateSearchQuery(event) {
    this.setState({
      searchQuery: event.target.value
    });
  }

  render() {
    const {
      attribute,
      handleChangeFunc,
      pipelineStep,
      transformersForAttribute
    } = this.props;

    const searchTokens = this.state.searchQuery.toLowerCase().trim().split(' ');
    const transformers = transformersForAttribute
      .filter(transformer => transformer.hidden !== true)
      .filter(transformer => searchTokens
        .map(token =>
          transformer.name.toLowerCase().includes(token) ||
          transformer.description.toLowerCase().includes(token))
        .filter(_ => _)
        .length === searchTokens.length);

    return (
      <React.Fragment>
        <div className='border-bottom border-light datacater-context-bar-fixed-element mx-n4 px-4 datacater-context-bar-search-field'>
          <div className='input-group input-group-flush input-group-merge'>
            <input
              type='search'
              className='form-control form-control-prepended search'
              onChange={this.updateSearchQuery}
              placeholder='Search transformation functions'
              value={this.state.searchQuery}
            />
            <div className='input-group-prepend'>
              <div className='input-group-text'>
                <span className='fe fe-search'></span>
              </div>
            </div>
          </div>
        </div>
        {transformers.length > 0 &&
          <div className='datacater-popover-pipeline-filter-list pt-2 list-group-flush list mx-n4 datacater-context-bar-flex-list'>
            {transformers.map((transformer, index) => (
              <ListGroup.Item
                className='px-5 pt-3 pb-0 border-0 font-size-sm'
                key={index}
                action
                onClick={handleChangeFunc}
                data-attribute-id={attribute.id}
                data-property='transformationAction'
                data-sort-position={pipelineStep.sortPosition}
                data-value={transformer.key}>
                <div
                  className='row align-items-center justify-content-center border-bottom pb-3'
                  data-attribute-id={attribute.id}
                  data-property='transformationAction'
                  data-sort-position={pipelineStep.sortPosition}
                  data-value={transformer.key}>
                  <div
                    className='col pl-0 pr-3'
                    data-attribute-id={attribute.id}
                    data-property='transformationAction'
                    data-sort-position={pipelineStep.sortPosition}
                    data-value={transformer.key}>
                    <div
                      className='font-weight-bold mb-1'
                      data-attribute-id={attribute.id}
                      data-property='transformationAction'
                      data-sort-position={pipelineStep.sortPosition}
                      data-value={transformer.key}>
                      {transformer.name}
                    </div>
                    <div
                      data-attribute-id={attribute.id}
                      data-property='transformationAction'
                      data-sort-position={pipelineStep.sortPosition}
                      data-value={transformer.key}>
                      {transformer.description}
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </div>
        }
        {transformers.length === 0 &&
          <h4 className='pt-4 mb-0 text-center text-black datacater-context-bar-flex-list'>
            No transformation function found.
          </h4>
        }
      </React.Fragment>
    );
  }
}

export default AttributeTransformationList;
