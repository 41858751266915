import React, { Component } from 'react';
import Dropzone             from 'react-dropzone';
import { ProgressBar }      from 'react-bootstrap';

class UploadFlatFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive:    false,
      isUploading: false
    };

    this.onDrop      = this.onDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
  }

  onDrop(files) {
    this.props.resetFileUploadFunc();

    if (files.length > 0) {
      const fileSize = files[0].size;
      const fileSizeLimit = 250 * 1024 * 1024;

      // check whether file size exceeds limit
      if (fileSize > fileSizeLimit) {
        this.setState({
          isActive: false
        });
      } else {
        this.setState({
          fileName:    files[0].name,
          fileSize:    files[0].size,
          isActive:    false,
          isUploading: true
        });
        this.props.handleFileUploadFunc(files);
      }
    } else {
      this.setState({
        isActive: false
      });
    }
  }

  onDragEnter() {
    this.setState({
      isActive: true
    });
  }

  onDragLeave() {
    this.setState({
      isActive: false
    });
  }

  fileSizeInMb(fileSize) {
    if (fileSize !== undefined && !isNaN(fileSize)) {
      return (fileSize / 1024 / 1024).toFixed(2) + 'MB';
    }
  }

  render () {
    const {
      fileType,
      flatFiles,
      profilingDataSource
    } = this.props;

    let classNames = 'flat-file-drop-zone';

    const isUploading =
      flatFiles.isUploadingFiles ||
      this.state.isUploading;

    // show spinner after uploading until flat file has been profiled
    if (isUploading && !this.props.profilingFailed && !profilingDataSource) {
      return (
        <div className='flat-file-drop-zone'>
          <div className='d-flex align-items-center justify-content-center drop-zone-wrapper'>
            <div className='m-0 w-50 text-center'>
              {this.state.fileName !== undefined &&
                <span>Uploading {this.state.fileName}</span>
              }
              {this.state.fileSize !== undefined &&
                <span className='ml-2'>({this.fileSizeInMb(this.state.fileSize)})</span>
              }
              <ProgressBar className='w-100 mt-4' now={flatFiles.uploadProgress} label={`${flatFiles.uploadProgress}%`} />
            </div>
          </div>
        </div>
      );
    }

    const dropZoneContent = (
      <p className='m-0 text-center'>
        Drop single {fileType} file here to start upload or <a href='/pipelines/new' onClick={(e) => e.preventDefault()}>click here</a> to select (File size limit: 250MB)
      </p>
    );

    if (this.state.isActive) {
      classNames += ' active-flat-file-drop-zone';
    }

    return (
      <Dropzone
        multiple={false}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className={classNames}>
            <div {...getRootProps()} className='d-flex align-items-center justify-content-center drop-zone-wrapper'>
              <input {...getInputProps()} />
              {dropZoneContent}
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default UploadFlatFile;
