const flatFiles = (state, action) => {
  const initialState = {
    isRetrievingFlatFile:      false,
    isRetrievingSampleRecords: false,
    isUploadingFiles:          false,
    fileUploadSucceeded:       undefined,
    flatFile:                  undefined,
    sampleRecords:             [],
    uploadProgress:            undefined
  };

  switch (action.type) {
    case 'REQUEST_FLAT_FILE':
      return {
        ...state,
        isRetrievingFlatFile: true
      };
    case 'RECEIVE_FLAT_FILE':
      return {
        ...state,
        flatFile:             action.flatFile,
        isRetrievingFlatFile: false
      };
    case 'RESET_FILE_UPLOAD':
      return {
        ...state,
        isUploadingFiles:    false,
        fileUploadSucceeded: undefined,
        flatFile:            undefined,
      };
    case 'REQUEST_FILE_UPLOAD':
      return {
        ...state,
        isUploadingFiles:    true,
        fileUploadSucceeded: undefined,
        flatFile:            undefined
      };
    case 'RECEIVE_SUCCESSFUL_FILE_UPLOAD':
      return {
        ...state,
        isUploadingFiles:    false,
        fileUploadSucceeded: true,
        flatFile:            action.flatFile
      };
    case 'RECEIVE_FAILED_FILE_UPLOAD':
      return {
        ...state,
        isUploadingFiles:    false,
        fileUploadSucceeded: false
      };
    case 'REQUEST_FILE_SAMPLE_RECORDS':
      return {
        ...state,
        isRetrievingSampleRecords: true
      };
    case 'RECEIVE_FILE_SAMPLE_RECORDS':
      return {
        ...state,
        isRetrievingSampleRecords: false,
        sampleRecords:             action.sampleRecords
      };
    case 'RESET_FLAT_FILE_SAMPLE_RECORDS':
      return {
        ...state,
        isRetrievingSampleRecords: false,
        sampleRecords:             []
      };
    case 'UPDATE_FILE_UPLOAD_PROGRESS':
      return {
        ...state,
        uploadProgress: action.percentage
      };
    default:
      return (state || initialState);
  }
};

export default flatFiles;
