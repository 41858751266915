import React, { Component } from 'react';
import {
  Filter as FilterIcon,
  HelpCircle
} from 'react-feather';
import {
  Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { humanizeDataType }   from '../../../../../../helpers/humanizeDataType';
import AttributeAssertionList from '../../../assertion/AttributeAssertionList';
import AttributeFilterValue   from '../AttributeFilterValue';
import Filter                 from '../../../../../../pipelines/Filter';

class Attribute extends Component {
  render() {
    const {
      assertion,
      attribute,
      canEditPipeline,
      handleChangeFunc
    } = this.props;

    const filters = Filter.getFilters()
      .filter(filter =>
        (filter.restrictToDataType === undefined) ||
        filter.restrictToDataType.includes(attribute.dataType)
      );

    const filter = (assertion.assertionFilter === '')
      ? undefined
      : filters.find(filter => (filter.key === assertion.assertionFilter));

    const filterIsDefined = ![undefined, ''].includes(assertion.assertionFilter);

    return (
      <React.Fragment>
        <div className='row border-bottom py-4'>
          <div className='col'>
            <h3 className='mb-0 text-nowrap'>
              {attribute.name}
            </h3>
          </div>
          <div className='col-auto'>
            <h3 className='mb-0'>
              {humanizeDataType(attribute.dataType)}
            </h3>
          </div>
        </div>

        {!filterIsDefined &&
          <AttributeAssertionList
            assertion={assertion}
            attribute={attribute}
            filters={filters}
            handleChangeFunc={handleChangeFunc}
          />
        }

        {filterIsDefined &&
          <React.Fragment>
            <div className='datacater-context-bar-fixed-double-element border-bottom'>
              <div className='datacater-context-bar-fixed-element w-100'>
                <div className='row w-100 m-0 align-items-center'>
                  <div className='col p-0'>
                    <h3 className='mb-0 text-nowrap'>
                      <FilterIcon className='feather-icon mr-2 mt-n1'/>
                      Require {filter.name}
                    </h3>
                  </div>
                  <div className='col-auto p-0'>
                    <OverlayTrigger
                      placement='bottom'
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => <Tooltip {...props}>{filter.description}</Tooltip>}>
                    <HelpCircle className='feather-icon' />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className='datacater-context-bar-fixed-element w-100 datacater-context-bar-search-field'>
                <Button
                  onClick={(event) => {
                    this.props.handleChangeFunc(event, this.props.attribute.id, 'assertionFilter', undefined)
                  }}
                  className='delete-btn w-100'
                  disabled={!canEditPipeline}
                  variant='link'>
                  Reset filter
                </Button>
              </div>
            </div>
            <div className='form-group mb-0 py-4 datacater-context-bar-function-config'>
              {filter.hasFilterValue &&
                <React.Fragment>
                  <label>{filter.filterValueName}:</label>
                  <AttributeFilterValue
                    canEditPipeline={canEditPipeline}
                    dataType={attribute.dataType}
                    filter={filter}
                    handleChangeFunc={handleChangeFunc}
                    name={'assertionValue-' + assertion.attributeId}
                    value={assertion.assertionValue}/>
                </React.Fragment>
              }
            </div>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default Attribute;
