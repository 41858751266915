import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  HelpCircle
} from 'react-feather';
import DataSinkLogo from '../data_sinks/DataSinkLogo';
import DataSourceLogo from '../data_sources/DataSourceLogo';

class PipelineListItem extends Component {
  formatSeconds(rawSeconds) {
    const minutes = parseInt(rawSeconds/60);
    const seconds = rawSeconds % 60;
    let formattedText = '';
    if (minutes > 0) {
      formattedText += minutes + 'm';
      if (seconds > 0) {
        formattedText += ' ';
      }
    }
    if (seconds > 0) {
      formattedText += seconds + 's';
    }

    return formattedText;
  }

  // TODO: move to common route helper class
  getPathToPipeline(pipeline) {
    return (pipeline.id != null) ? ('/pipelines/' + pipeline.id + '/edit') : '/pipelines';
  }

  // TODO: move to common route helper class
  getPathToDataSource(dataSource) {
    return (dataSource.id != null) ? ('/data_sources/' + dataSource.id) : '/data_sources';
  }

  // TODO: move to common route helper class
  getPathToDataSink(dataSink) {
    return (dataSink.id != null) ? ('/data_sinks/' + dataSink.id) : '/data_sinks';
  }

  render() {
    const {
      currentUser,
      pipeline
    } = this.props;
    const dataSource = this.props.dataSources.find(el => el.id === pipeline.dataSourceId);
    const dataSink = this.props.dataSinks.find(el => el.id === pipeline.dataSinkId);
    const pipelineName = ([undefined, ''].includes(pipeline.name)) ? 'Untitled Pipeline' : pipeline.name;

    const connectionStatusClassName = (pipeline.isRunning === true)
      ? 'text-success'
      : '';

    const project =
      (
        ![currentUser, dataSource].includes(undefined) &&
        (pipeline.userId === undefined) &&
        (pipeline.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === pipeline.projectId)
        : undefined;

    return (
      <li className='list-group-item py-2' key={pipeline.id}>
        <div className='row align-items-center py-2'>
          <div className='col-auto'>
            <div className='row align-items-center p-0'>
              <div className='col-auto'>
                <span className={connectionStatusClassName}>●</span>
              </div>
              {dataSource !== undefined &&
                <React.Fragment>
                  <div className='col-auto source-type-logos'>
                    <div className='logo-wrap'>
                      <DataSourceLogo dataSource={dataSource} />
                    </div>
                  </div>
                  <div className='col-auto p-0'>
                    <ArrowRight className='feather-icon' />
                  </div>
                  <div className='col-auto sink-type-logos'>
                    <div className='logo-wrap'>
                      {dataSink !== undefined &&
                        <DataSinkLogo dataSink={dataSink} />
                      }
                      {dataSink === undefined &&
                        <div className='avatar w-100 h-100'>
                          <div className='avatar-title font-size-lg bg-primary-soft rounded-circle text-primary'>
                            <HelpCircle className='feather-icon' />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
          <div className='col ml-2 overflow-hidden text-nowrap'>
            <h4 className='mb-0 name'>
              {project !== undefined &&
                <React.Fragment>
                  <a href={`/projects/${project.id}`} className='text-gray d-none d-lg-inline'>
                    {project.name}
                  </a>
                  <span className='text-gray mx-2 d-none d-lg-inline'>
                    /
                  </span>
                </React.Fragment>
              }
              <Link to={this.getPathToPipeline(pipeline)}>
                #{pipeline.id}: {pipelineName}
              </Link>
            </h4>
          </div>
          <div className='col-auto col-show-btn d-none d-lg-flex'>
            <Link to={this.getPathToPipeline(pipeline)} className='btn btn-white btn-sm'>
              Show
            </Link>
          </div>
        </div>
      </li>
    );
  }
}

export default PipelineListItem;
