const info = (state, action) => {
  const initialState = {
    fetchingInfo: false,
    info:         {}
  };

  switch (action.type) {
    case 'REQUEST_INFO':
      return {
        ...state,
        fetchingInfo: true,
        info:         {}
      };
    case 'RECEIVE_INFO':
      return {
        ...state,
        fetchingInfo: false,
        info:         action.info
      };
    default:
      return (state || initialState);
  }
};

export default info;
