import React, { Component } from 'react';

class ExecutableLogs extends Component {
  formatLogDate(logDate) {
    // parse date
    const utcDate = new Date(Date.parse(logDate));
    // try to format the date nicely; if not possible, show raw date string
    return isNaN(utcDate) ? logDate : utcDate.toLocaleString();
  }

  render() {
    const logs = this.props.logs;

    if (logs == null) {
      return (
        <li className='list-group-item justify-content-between lh-condensed'>
          <code className='highlight html bg-dark hljs xml p-3 executable-logs'>
            ERROR: Cannot access the logs of the deployment. Try to close and re-open the logs.
          </code>
        </li>
      );
    }

    if ([undefined, null, ''].includes(logs.logMessage)) {
      return (
        <li className='list-group-item justify-content-between lh-condensed'>
          <code className='highlight html bg-dark hljs xml p-3 executable-logs'>
            Please wait for a moment, we are starting the deployment...
          </code>
        </li>
      );
    }

    return (
      <li className='list-group-item justify-content-between lh-condensed'>
        <code className='highlight html bg-dark hljs xml p-3 executable-logs'>
          {logs.logMessage.split('\n').map((logMessage, idx) => (
            <React.Fragment>
              {(idx > 0) && (logMessage.length > 0) && <br />}
              <span class='hljs-string mr-2'>{this.formatLogDate(logMessage.split(' ')[0])}</span>
              {logMessage.split(' ').slice(1).join(' ')}
            </React.Fragment>
          ))}
        </code>
      </li>
    );
  }
}

export default ExecutableLogs;
