import React, { Component }      from 'react';
import { connect }               from 'react-redux';
import { Redirect }              from 'react-router-dom';
import { Book }                  from 'react-feather';
import MainContent               from '../../components/layout/MainContent';
import SourceTypeItem            from '../../components/data_sources/SourceTypeItem';
import ADSBHubConfig             from '../../components/data_sources/configs/ADSBHubConfig';
import AwsS3Config               from '../../components/data_sources/configs/AwsS3Config';
import AzureSqlDatabaseConfig    from '../../components/data_sources/configs/AzureSqlDatabaseConfig';
import AzureBlobStorageConfig    from '../../components/data_sources/configs/AzureBlobStorageConfig';
import BigQueryConfig            from '../../components/data_sources/configs/BigQueryConfig';
import CSVConfig                 from '../../components/data_sources/configs/CSVConfig';
import GoogleCloudStorageConfig  from '../../components/data_sources/configs/GoogleCloudStorageConfig';
import GoogleDriveConfig         from '../../components/data_sources/configs/GoogleDriveConfig';
import JSONConfig                from '../../components/data_sources/configs/JSONConfig';
import XMLConfig                 from '../../components/data_sources/configs/XMLConfig';
import MySQLCDCConfig            from '../../components/data_sources/configs/MySQLCDCConfig';
import OpenSkyConfig             from '../../components/data_sources/configs/OpenSkyConfig';
import PostgreSQLCDCConfig       from '../../components/data_sources/configs/PostgreSQLCDCConfig';
import RestConfig                from '../../components/data_sources/configs/RestConfig';
import RssConfig                 from '../../components/data_sources/configs/RssConfig';
import SftpConfig                from '../../components/data_sources/configs/SftpConfig';
import TypeformConfig            from '../../components/data_sources/configs/TypeformConfig';
import ConfigForm                from '../../components/data_stores/ConfigForm';
import dataSourceTypes           from '../../data_stores/data_source_types';
import { deepCopy }              from '../../helpers/deepCopy';
import { parseQueryString }      from '../../helpers/parseQueryString';
import { validateConnectorConfig } from '../../helpers/validateConnectorConfig';
import {
  getSampleRecordsOfFlatFile,
  resetFileUpload,
  resetFlatFileSampleRecords,
  uploadFile
} from '../../actions/flat_files';
import {
  addDataSource,
  persistDataSource,
  resetRetrieveColumns,
  resetRetrieveTables,
  resetTestConnection,
  retrieveColumnsFromDataSource,
  retrieveTablesFromDataSource,
  testConnection
} from '../../actions/data_sources';
import {
  createDataSourceProfileForFile,
  fetchDataSourceProfile,
  replaceDataSourceProfileAttributes,
  resetDataSourceProfile,
  updateAndReprofileDataSourceProfile
} from '../../actions/data_source_profiles';
import { fetchProject } from '../../actions/projects';
import { fetchCurrentUser } from '../../actions/users';

class NewDataSource extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creatingDataSourceFailed: false,
      dataSourceCreated:        false,
      errorMessages:            {},
      newDataSource: {
        autoRestartFailedConnector: true,
        connectionType:             'direct-connection',
        sourceType:                 undefined,
        hostname:                   '',
        username:                   '',
        password:                   '',
        port:                       '',
        databaseName:               '',
        bucketName:                 '',
        databaseSchemaName:         '',
        name:                       '',
      },
      profilingDataSource: false,
      profilingFailed:     false,
      uploadFailed:        false
    };

    this.getBucketNameLabel               = this.getBucketNameLabel.bind(this);
    this.showBucketNameField              = this.showBucketNameField.bind(this);
    this.handleCreateDataSource           = this.handleCreateDataSource.bind(this);
    this.handleChange                     = this.handleChange.bind(this);
    this.handleConfigChange               = this.handleConfigChange.bind(this);
    this.handleFileUpload                 = this.handleFileUpload.bind(this);
    this.changeSourceType                 = this.changeSourceType.bind(this);
    this.handleTestConnection             = this.handleTestConnection.bind(this);
    this.updateDataSourceProfile          = this.updateDataSourceProfile.bind(this);
    this.updateDataSourceProfileAttribute = this.updateDataSourceProfileAttribute.bind(this);
    this.updateDataSourceProfileConfig    = this.updateDataSourceProfileConfig.bind(this);
    this.selectAttributeAsPrimaryKey      = this.selectAttributeAsPrimaryKey.bind(this);
    this.reprofileDataSourceProfile       = this.reprofileDataSourceProfile.bind(this);
  }

  componentDidMount() {
    this.props.resetRetrieveColumns();
    this.props.resetRetrieveTables();
    this.props.fetchCurrentUser();

    const params = parseQueryString(this.props.location.search);
    if (params['projectId'] !== undefined) {
      this.props.fetchProject(params['projectId'])
        .then(() => {
          if (this.props.projects.project !== undefined) {
            let dataSource = this.state.newDataSource;
            dataSource['projectId'] = this.props.projects.project.id;
            this.setState({ newDataSource: dataSource });
          }
        });
    }
  }

  getBucketNameLabel() {
    if (this.state.newDataSource.sourceType === 'mongodb') {
      return 'Collection name';
    } else {
      return 'Table name';
    }
  }

  showBucketNameField() {
    return true;
  }

  handleCreateDataSource(event) {
    event.preventDefault();
    const newDataSource = this.state.newDataSource;

    const selectedSourceType = dataSourceTypes
      .find(sourceType => sourceType.value === newDataSource.sourceType);
    const validations = (selectedSourceType !== undefined)
      ? (selectedSourceType.validations || []).filter(validation => [undefined, 'create'].includes(validation.validateOnly))
      : [];
    const errorMessages = validateConnectorConfig(
      newDataSource,
      validations
    );

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        creatingDataSourceFailed: true,
        errorMessages:            errorMessages
      });
      window.scrollTo(0, 0);
    } else {
      // if flat file, then make temporary data source permanent
      if (this.state.refineProfileOfFlatFile === true) {
        const dataSourceProfile = this.props.dataSourceProfiles.dataSourceProfile;
        if (dataSourceProfile.profilingStatus.includes('profiled') &&
            dataSourceProfile.dataSourceId !== undefined) {
          // update names of attributes
          this.props.replaceDataSourceProfileAttributes(
            this.state.dataSourceProfile.id,
            this.state.dataSourceProfile.attributes
          ).then(() =>
            // persist data source such that it shows up on /data_sources
            this.props.persistDataSource(
              dataSourceProfile.dataSourceId,
              newDataSource
            ).then(() =>
              this.setState({
                dataSourceCreated: true,
                errorMessage:      ''
              })
            )
          );
        } else {
          this.setState({ errorMessage: 'Failed creating the data source.' });
          window.scrollTo(0, 0);
        }
      } else {
        this.props.addDataSource(newDataSource)
          .then(() => this.setState({
            dataSourceCreated: true,
            errorMessage:      ''
          }));
      }
    }
  }

  getDefaultPort(sourceType) {
    switch (sourceType) {
      case 'postgresql':
        return 5432;
      case 'mysql':
        return 3306;
      case 'mongodb':
        return 27017;
      default:
        return undefined;
    }
  }

  handleChange(event) {
    let newDataSource = this.state.newDataSource;
    let errorMessages = this.state.errorMessages;

    newDataSource[event.target.name] = (event.target.type === 'checkbox')
      ? event.target.checked
      : event.target.value;

    // reset error message
    errorMessages[event.target.name] = undefined;

    if (
      (event.target.name === 'connectionType') &&
      (event.target.value === 'direct-connection') &&
      [undefined, ''].includes(newDataSource.port)
    ) {
      newDataSource.port = this.getDefaultPort(newDataSource.sourceType);
    }

    if (
      (newDataSource.sourceType === 'google-bigquery') &&
      (event.target.name === 'password')
    ) {
      try {
        // try to parse project name and service account from credentials
        const credentials = JSON.parse(event.target.value);

        if ((credentials !== undefined) && (credentials.project_id !== undefined)) {
          newDataSource.databaseName = credentials.project_id;
        }

        if ((credentials !== undefined) && (credentials.client_email !== undefined)) {
          if (newDataSource.connectorConfig === undefined) {
            newDataSource.connectorConfig = {};
          }

          newDataSource.connectorConfig.serviceAccountEmail = credentials.client_email;
        }
      } catch (e) {
        // do not change the project id if parsing the credentials failed
      }
    }

    if (
      (newDataSource.sourceType === 'google-cloud-storage') &&
      (event.target.name === 'password')
    ) {
      try {
        // try to parse project name and service account from credentials
        const credentials = JSON.parse(event.target.value);

        if ((credentials !== undefined) && (credentials.project_id !== undefined)) {
          newDataSource.databaseName = credentials.project_id;
        }
      } catch (e) {
        // do not change the project id if parsing the credentials failed
      }
    }

    this.props.resetTestConnection();

    // find currently selected source type
    const selectedSourceType = dataSourceTypes
      .find(sourceType => sourceType.value === newDataSource.sourceType);

    // validate and fill error message
    if ((selectedSourceType !== undefined) && (selectedSourceType.validations !== undefined)) {
      const relevantValidations = selectedSourceType.validations
        .filter(validation => [undefined, 'create'].includes(validation.validateOnly))
        .filter(validation => validation.field === event.target.name);
      const validationResult = validateConnectorConfig(
        newDataSource,
        relevantValidations
      );
      errorMessages[event.target.name] = validationResult[event.target.name];
    }

    this.setState({
      creatingDataSourceFailed: false,
      errorMessages:            errorMessages,
      newDataSource:            newDataSource
    });
  }

  handleConfigChange(event) {
    let dataSource = this.state.newDataSource;
    let errorMessages = this.state.errorMessages;

    if (dataSource.connectorConfig === undefined) {
      dataSource.connectorConfig = {};
    }

    // reset error message
    errorMessages[event.target.name] = undefined;

    // update data source config
    dataSource.connectorConfig[event.target.name] = (event.target.type === 'checkbox')
      ? '' + event.target.checked
      : event.target.value;

    // reset test connection button
    this.props.resetTestConnection();

    // find currently selected source type
    const selectedSourceType = dataSourceTypes
      .find(sourceType => sourceType.value === dataSource.sourceType);

    // validate and fill error message
    if ((selectedSourceType !== undefined) && (selectedSourceType.validations !== undefined)) {
      const relevantValidations = selectedSourceType.validations
        .filter(validation => [undefined, 'create'].includes(validation.validateOnly))
        .filter(validation => validation.field === event.target.name);
      const validationResult = validateConnectorConfig(
        dataSource,
        relevantValidations
      );
      errorMessages[event.target.name] = validationResult[event.target.name];
    }

    this.setState({
      creatingDataSourceFailed: false,
      errorMessages:            errorMessages,
      newDataSource:            dataSource
    });
  }

  handleFileUpload(files) {
    const file = files[0];

    this.props.resetDataSourceProfile();
    this.props.resetFlatFileSampleRecords();

    if (file !== undefined) {
      this.setState({
        profilingDataSource: false,
        profilingFailed:     false,
        uploadFailed:        false
      });

      const projectId = (this.props.projects.project !== undefined)
        ? this.props.projects.project.id
        : undefined;

      this.props.uploadFile(
        file,
        this.state.newDataSource.sourceType,
        projectId
      ).then(() => {
        if (this.props.flatFiles.fileUploadSucceeded === true) {
          this.setState({
            profilingFailed:         false,
            refineProfileOfFlatFile: true,
            uploadFailed:            false
          });

          this.props.createDataSourceProfileForFile(this.props.flatFiles.flatFile);

          // clear old interval
          if (this.profilingIntervalId != null) {
            clearInterval(this.profilingIntervalId);
          }
          this.profilingIntervalId = setInterval(this.waitForProfiling.bind(this), 500);
        } else {
          this.setState({
            profilingDataSource: false,
            profilingFailed:     true,
            uploadFailed:        true
          });
        }
      });
    }
  }

  waitForProfiling() {
    const dataSourceProfile = this.props.dataSourceProfiles.dataSourceProfile;
    if (dataSourceProfile != null) {
      if (
        dataSourceProfile.profilingStatus !== undefined &&
        dataSourceProfile.profilingStatus.includes('profiled')
      ) {
        clearInterval(this.profilingIntervalId);

        this.setState({
          dataSourceProfile: dataSourceProfile
        });

        // retrieve sample records from the flat file based on the new data source profile
        this.props.getSampleRecordsOfFlatFile(this.props.flatFiles.flatFile);
      } else if (
        dataSourceProfile.profilingStatus !== undefined &&
        dataSourceProfile.profilingStatus.includes('failed')
      ) {
        clearInterval(this.profilingIntervalId);

        // handle error
        this.setState({
          dataSourceProfile: dataSourceProfile,
          profilingFailed: true
        });
      } else {
        this.props.fetchDataSourceProfile(dataSourceProfile.id);
      }
    }
  }

  changeSourceType(event, sourceType) {
    event.preventDefault();

    let newDataSource = this.state.newDataSource;
    const oldSourceType = newDataSource.sourceType;
    newDataSource.sourceType = sourceType;

    if (
      this.getDefaultPort(oldSourceType) === undefined ||
      this.getDefaultPort(oldSourceType) === parseInt(newDataSource.port) ||
      newDataSource.port.length === 0
    ) {
      newDataSource.port = this.getDefaultPort(newDataSource.sourceType);
    }

    if (newDataSource.sourceType === 'postgresql') {
      newDataSource.databaseSchemaName = 'public';
    } else {
      newDataSource.databaseSchemaName = '';
    }

    this.props.resetTestConnection();

    this.setState({ newDataSource: newDataSource });
  }

  handleTestConnection() {
    // the backend always requires a data source name, although it is not relevant
    // in this case. TODO: fix this in the future, such that we can test a connection
    // without requiring the user to enter a data source name
    this.props.testConnection(
      Object.assign(deepCopy(this.state.newDataSource), { name: 'viktoria' }));
  }

  updateDataSourceProfile(event) {
    const property = event.target.name;
    const value    = event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value;

    let dataSourceProfile = deepCopy(this.state.dataSourceProfile);

    if (dataSourceProfile !== undefined) {
      dataSourceProfile[property] = value;
      this.setState({
        dataSourceProfile: dataSourceProfile
      });
    }
  }

  updateDataSourceProfileAttribute(event) {
    const attributeIdx = event.target.dataset.attributePos;
    const property     = event.target.name;
    const value        = event.target.value;

    let dataSourceProfile = deepCopy(this.state.dataSourceProfile);

    if (!isNaN(attributeIdx) && dataSourceProfile !== undefined) {
      dataSourceProfile.attributes[attributeIdx][property] = value;
      this.setState({
        attributeValidation:   undefined,
        changedAttributeNames: true,
        dataSourceProfile:     dataSourceProfile
      });
    }
  }

  updateDataSourceProfileConfig(event) {
    const property = event.target.name;
    const value    = event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value;

    let dataSourceProfile = deepCopy(this.state.dataSourceProfile);

    if (dataSourceProfile !== undefined) {
      if (dataSourceProfile.profilingConfig === undefined) {
        dataSourceProfile.profilingConfig = {};
      }

      dataSourceProfile.profilingConfig[property] = value;

      this.setState({
        dataSourceProfile: dataSourceProfile
      });
    }
  }

  selectAttributeAsPrimaryKey(attributePos) {
    let dataSourceProfile = deepCopy(this.state.dataSourceProfile);

    if (
      (dataSourceProfile !== undefined) &&
      (dataSourceProfile.attributes[attributePos] !== undefined)
    ) {
      // reset key
      dataSourceProfile.attributes = dataSourceProfile.attributes
        .map(attribute => Object.assign({}, attribute, { isKey: false }));

      // assign new key
      dataSourceProfile.attributes[attributePos]['isKey'] = true;

      // update profile
      this.setState({
        attributeValidation:   undefined,
        changedAttributeNames: true,
        dataSourceProfile:     dataSourceProfile
      });
    }
  }

  reprofileDataSourceProfile() {
    return this.props.updateAndReprofileDataSourceProfile(this.state.dataSourceProfile)
      .then(() => {
        const dataSourceProfile = this.props.dataSourceProfiles.dataSourceProfile;
        const profilingFailed =
          (dataSourceProfile !== undefined) &&
          (dataSourceProfile.profilingStatus === 'failed');

        if (!profilingFailed) {
          // update sample records based on new data source profile
          this.props.getSampleRecordsOfFlatFile(this.props.flatFiles.flatFile);
        }

        this.setState({
          changedAttributeNames: false,
          dataSourceProfile:     this.props.dataSourceProfiles.dataSourceProfile,
          profilingFailed:       profilingFailed,
          uploadFailed:          false
        });
      });
  }

  render() {
    const project = this.props.projects.project;
    const currentUser = this.props.users.currentUser;

    if (this.state.dataSourceCreated) {
      // if we create a flat file data source,
      // we must get the data source id from the data source profile
      const createdDataSourceId = this.state.refineProfileOfFlatFile
        ? this.props.dataSourceProfiles.dataSourceProfile.dataSourceId
        : this.props.dataSources.dataSource.id;

      let redirectTo = '/data_sources';

      if (createdDataSourceId !== undefined) {
        redirectTo += '/' + createdDataSourceId;
      }

      return (<Redirect to={redirectTo} />);
    }

    const contentTitle = (project === undefined)
      ? 'Create data source'
      : `Create data source for project #${project.id}: ${project.name}`;

    const contentButtonLabel = (project === undefined)
      ? 'Go back to data sources'
      : 'Go back to project';

    const contentButtonLink = (project === undefined)
      ? '/data_sources'
      : `/projects/${project.id}`;

    const dataSource = this.state.newDataSource;

    const coreSourceTypes = deepCopy(dataSourceTypes);

    // add custom connectors, if configured
    if (
      (currentUser !== undefined) &&
      (currentUser.allowConnectors !== undefined) &&
      currentUser.allowConnectors.includes('adsbhub')
    ) {
      coreSourceTypes.push({
        label: 'ADS-B Hub',
        value: 'adsbhub'
      });
    }
    if (
      (currentUser !== undefined) &&
      (currentUser.allowConnectors !== undefined) &&
      currentUser.allowConnectors.includes('opensky')
    ) {
      coreSourceTypes.push({
        label: 'OpenSky',
        value: 'opensky'
      });
    }

    const sourceTypes = coreSourceTypes
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
      .map(function(sourceType) {
        return Object.assign(
          {},
          sourceType,
          {
            isSelected: (dataSource.sourceType === sourceType.value)
          }
        );
      });
    const selectedSourceType = sourceTypes.find(sourceType => sourceType.isSelected);

    // Indicate status of creating a pipeline
    let createPipelineStatus = undefined;
    if (this.props.dataSources.creatingDataSource) {
      createPipelineStatus = '';
    }

    return (
      <MainContent preTitle='Data Sources' title={contentTitle} buttonLabel={contentButtonLabel} buttonLink={contentButtonLink}>
        <form>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>
                Select data source type
              </h4>
            </div>
            <div className='card-body py-0'>
              <ul className='list-group list-group-flush list list-group-lg mx-n4 source-type-logos'>
                {selectedSourceType === undefined && sourceTypes.map((sourceType, index) => (
                  <SourceTypeItem
                    changeSourceTypeFunc={this.changeSourceType}
                    dataSource={sourceType}
                    key={index} />
                ))}
                {selectedSourceType !== undefined &&
                  <SourceTypeItem
                    changeSourceTypeFunc={this.changeSourceType}
                    dataSource={selectedSourceType} />
                }
              </ul>
            </div>
          </div>
          {dataSource.sourceType !== undefined &&
            <React.Fragment>
              {this.state.creatingDataSourceFailed &&
                <div className='alert alert-danger fade show rounded-0' role='alert'>
                  The data source configuration is invalid, please check the form fields.
                </div>
              }
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>General settings</h4>
                </div>
                <div className='card-body'>
                  <ConfigForm
                    errorMessages={this.state.errorMessages}
                    isRequired={true}
                    label='Name'
                    name='name'
                    onChangeFunc={this.handleChange}
                    value={dataSource.name} />
                  <div className='mt-4 mb-2'>
                    <div className='custom-control custom-switch d-flex align-items-center'>
                      <input
                        checked={dataSource.autoRestartFailedConnector}
                        className='custom-control-input clickable'
                        id='customSwitch1'
                        name='autoRestartFailedConnector'
                        onChange={this.handleChange}
                        type='checkbox' />
                      <label className='custom-control-label clickable' htmlFor='customSwitch1'>
                        Automatically restart failed connector
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <h4 className='card-header-title'>{selectedSourceType.label}-specific settings</h4>
                    </div>
                    {selectedSourceType.docs !== undefined &&
                      <div className='col-auto'>
                        <a href={`https://datacater.io/docs/source_connectors/${selectedSourceType.docs}/`} target='_blank' className='btn btn-white' rel='noopener noreferrer'>
                          <Book className='feather-icon mr-1' />
                          Documentation
                        </a>
                      </div>
                    }
                  </div>
                </div>
                <div className='card-body border-bottom'>
                  {dataSource.sourceType === 'aws-s3' &&
                    <AwsS3Config
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'azure-sql-database' &&
                    <AzureSqlDatabaseConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'azure-blob' &&
                    <AzureBlobStorageConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'adsbhub' &&
                    <ADSBHubConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSource.sourceType === 'csv' &&
                    <CSVConfig
                      changedAttributeNames={this.state.changedAttributeNames}
                      createPipelineStatus={createPipelineStatus}
                      dataSource={dataSource}
                      dataSourceProfile={this.state.dataSourceProfile}
                      dataSourceProfiles={this.props.dataSourceProfiles}
                      dataSources={this.props.dataSources}
                      flatFiles={this.props.flatFiles}
                      handleAttributeChangeFunc={this.updateDataSourceProfileAttribute}
                      handleChangeFunc={this.updateDataSourceProfile}
                      handleFileUploadFunc={this.handleFileUpload}
                      profilingDataSource={this.state.profilingDataSource}
                      profilingFailed={this.state.profilingFailed}
                      reprofileDataSourceProfileFunc={this.reprofileDataSourceProfile}
                      refineProfileOfFlatFile={this.state.refineProfileOfFlatFile}
                      resetFileUploadFunc={this.props.resetFileUpload}
                      selectAttributeAsPrimaryKeyFunc={this.selectAttributeAsPrimaryKey}
                      uploadFailed={this.state.uploadFailed} />
                  }
                  {dataSource.sourceType === 'google-bigquery' &&
                    <BigQueryConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'google-cloud-storage' &&
                    <GoogleCloudStorageConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'google-drive' &&
                    <GoogleDriveConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'json' &&
                    <JSONConfig
                      changedAttributeNames={this.state.changedAttributeNames}
                      createPipelineStatus={createPipelineStatus}
                      dataSource={dataSource}
                      dataSourceProfile={this.state.dataSourceProfile}
                      dataSourceProfiles={this.props.dataSourceProfiles}
                      dataSources={this.props.dataSources}
                      flatFiles={this.props.flatFiles}
                      handleAttributeChangeFunc={this.updateDataSourceProfileAttribute}
                      handleChangeFunc={this.updateDataSourceProfile}
                      handleFileUploadFunc={this.handleFileUpload}
                      profilingDataSource={this.state.profilingDataSource}
                      profilingFailed={this.state.profilingFailed}
                      reprofileDataSourceProfileFunc={this.reprofileDataSourceProfile}
                      refineProfileOfFlatFile={this.state.refineProfileOfFlatFile}
                      resetFileUploadFunc={this.props.resetFileUpload}
                      selectAttributeAsPrimaryKeyFunc={this.selectAttributeAsPrimaryKey}
                      uploadFailed={this.state.uploadFailed} />
                  }
                  {dataSource.sourceType === 'mysql' &&
                    <MySQLCDCConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'opensky' &&
                    <OpenSkyConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSource.sourceType === 'postgresql' &&
                    <PostgreSQLCDCConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'rest' &&
                    <RestConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource} />
                  }
                  {dataSource.sourceType === 'rss' &&
                    <RssConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection} />
                  }
                  {dataSource.sourceType === 'sftp' &&
                    <SftpConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveColumnsFunc={this.props.resetRetrieveColumns}
                      retrieveColumnsFromDataSourceFunc={this.props.retrieveColumnsFromDataSource} />
                  }
                  {dataSource.sourceType === 'typeform' &&
                    <TypeformConfig
                      dataSource={dataSource}
                      dataSources={this.props.dataSources}
                      errorMessages={this.state.errorMessages}
                      handleChangeFunc={this.handleChange}
                      handleConfigChangeFunc={this.handleConfigChange}
                      handleTestConnectionFunc={this.handleTestConnection}
                      resetRetrieveTablesFunc={this.props.resetRetrieveTables}
                      retrieveTablesFromDataSourceFunc={this.props.retrieveTablesFromDataSource} />
                  }
                  {dataSource.sourceType === 'xml' &&
                    <XMLConfig
                      changedAttributeNames={this.state.changedAttributeNames}
                      createPipelineStatus={createPipelineStatus}
                      dataSource={dataSource}
                      dataSourceProfile={this.state.dataSourceProfile}
                      dataSourceProfiles={this.props.dataSourceProfiles}
                      dataSources={this.props.dataSources}
                      flatFiles={this.props.flatFiles}
                      handleAttributeChangeFunc={this.updateDataSourceProfileAttribute}
                      handleChangeFunc={this.updateDataSourceProfile}
                      handleConfigChangeFunc={this.updateDataSourceProfileConfig}
                      handleFileUploadFunc={this.handleFileUpload}
                      profilingDataSource={this.state.profilingDataSource}
                      profilingFailed={this.state.profilingFailed}
                      reprofileDataSourceProfileFunc={this.reprofileDataSourceProfile}
                      refineProfileOfFlatFile={this.state.refineProfileOfFlatFile}
                      resetFileUploadFunc={this.props.resetFileUpload}
                      selectAttributeAsPrimaryKeyFunc={this.selectAttributeAsPrimaryKey}
                      uploadFailed={this.state.uploadFailed} />
                  }
                </div>
              </div>
              <div className='card'>
                <div className='card-body'>
                  {!this.props.dataSources.creatingDataSource &&
                    <button
                      className='btn btn-primary'
                      onClick={this.handleCreateDataSource}
                      type='submit'>
                      Create data source
                    </button>
                  }
                  {this.props.dataSources.creatingDataSource &&
                    <button
                      className='btn btn-primary'
                      disabled={true}
                      type='submit'>
                      <span className='spinner-border mr-2' role='status'>
                      </span>
                      Creating...
                    </button>
                  }
                </div>
              </div>
            </React.Fragment>
          }
        </form>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    dataSources:        state.dataSources,
    dataSourceProfiles: state.dataSourceProfiles,
    flatFiles:          state.flatFiles,
    projects:           state.projects,
    users:              state.users
  }
}

const mapDispatchToProps = {
  addDataSource:                       addDataSource,
  createDataSourceProfileForFile:      createDataSourceProfileForFile,
  fetchCurrentUser:                    fetchCurrentUser,
  fetchDataSourceProfile:              fetchDataSourceProfile,
  fetchProject:                        fetchProject,
  getSampleRecordsOfFlatFile:          getSampleRecordsOfFlatFile,
  persistDataSource:                   persistDataSource,
  replaceDataSourceProfileAttributes:  replaceDataSourceProfileAttributes,
  resetDataSourceProfile:              resetDataSourceProfile,
  resetFileUpload:                     resetFileUpload,
  resetFlatFileSampleRecords:          resetFlatFileSampleRecords,
  resetRetrieveColumns:                resetRetrieveColumns,
  resetRetrieveTables:                 resetRetrieveTables,
  resetTestConnection:                 resetTestConnection,
  retrieveColumnsFromDataSource:       retrieveColumnsFromDataSource,
  retrieveTablesFromDataSource:        retrieveTablesFromDataSource,
  testConnection:                      testConnection,
  updateAndReprofileDataSourceProfile: updateAndReprofileDataSourceProfile,
  uploadFile:                          uploadFile
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDataSource);
