import React,
       { Component }      from 'react';
import { connect }        from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { Check }            from 'react-feather';
import MainContent          from '../../components/layout/MainContent';
import PipelineListItem     from '../../components/pipelines/PipelineListItem';
import { fetchStream }      from '../../actions/streams';
import { fetchDataSources } from '../../actions/data_sources';
import { fetchDataSinks }   from '../../actions/data_sinks';
import { fetchPipelines }   from '../../actions/pipelines';
import { fetchCurrentUser } from '../../actions/users';

class ShowStream extends Component {
  componentDidMount() {
    this.props.fetchCurrentUser();
    this.props.fetchStream(this.getStreamId());
    this.props.fetchPipelines();
    this.props.fetchDataSources();
    this.props.fetchDataSinks();
  }

  getStreamId() {
    return parseInt(this.props.match.params.id);
  }

  render() {
    const stream = this.props.streams.stream;

    if (stream === undefined) {
      return (<div></div>);
    }

    const consumingPipelines = this.props.pipelines.pipelines
      .filter(pipeline => (pipeline.dataSourceStreamId === stream.id) || (pipeline.joinedDataSourceStreamId === stream.id))
      .sort((a, b) => (a.id - b.id));

    const publishingPipelines = this.props.pipelines.pipelines
      .filter(pipeline => pipeline.dataSinkStreamId === stream.id)
      .sort((a, b) => (a.id - b.id));

    const currentUser = this.props.users.currentUser;
    const userEmail =
      (
        ![currentUser, stream].includes(undefined) &&
        (stream.userId === currentUser.id)
      )
        ? currentUser.email
        : undefined;

    const project =
      (
        ![currentUser, stream].includes(undefined) &&
        (stream.userId === undefined) &&
        (stream.projectId !== undefined)
      )
        ? currentUser.projects.find(_ => _.id === stream.projectId)
        : undefined;

    const projectMembership = (project !== undefined)
      ? currentUser.projectMemberships.find(_ => _.projectId === project.id)
      : undefined;

    const sortedConfigNames = Object.keys(stream.spec).sort();

    return (
      <MainContent preTitle='streams' title={'#' + stream.id + ': ' + stream.name} buttonLabel='Go back to streams' buttonLink='/streams'>
        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h4 className='card-header-title'>
                    {userEmail !== undefined &&
                      <React.Fragment>
                        <span className='text-gray'>
                          {userEmail}
                        </span>
                        <span className='text-gray mx-2'>
                          /
                        </span>
                      </React.Fragment>
                    }
                    {project !== undefined &&
                      <React.Fragment>
                        <a href={`/projects/${project.id}`} className='text-gray'>
                          {project.name}
                        </a>
                        <span className='text-gray mx-2'>
                          /
                        </span>
                      </React.Fragment>
                    }
                    {stream.name}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className='alert alert-primary align-items-center d-flex' role='alert'>
          <Check className='feather-icon mr-2' /> Managed by DataCater
        </div>
        {publishingPipelines.length > 0 &&
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>Publishing pipelines</h4>
            </div>
            <div className='card-body'>
              <ul className='list-group list-group-flush list my-n4'>
                {publishingPipelines.map(pipeline => (
                  <PipelineListItem
                    currentUser={this.props.users.currentUser}
                    streams={[stream]}
                    dataSources={this.props.dataSources.dataSources}
                    dataSinks={this.props.dataSinks.dataSinks}
                    history={this.props.history}
                    key={pipeline.id}
                    pipeline={pipeline} />
                ))}
              </ul>
            </div>
          </div>
        }
        {consumingPipelines.length > 0 &&
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>Consuming pipelines</h4>
            </div>
            <div className='card-body'>
              <ul className='list-group list-group-flush list my-n4'>
                {consumingPipelines.map(pipeline => (
                  <PipelineListItem
                    currentUser={this.props.users.currentUser}
                    streams={[stream]}
                    dataSources={this.props.dataSources.dataSources}
                    dataSinks={this.props.dataSinks.dataSinks}
                    history={this.props.history}
                    key={pipeline.id}
                    pipeline={pipeline} />
                ))}
              </ul>
            </div>
          </div>
        }
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-header-title'>Spec</h4>
          </div>
          {sortedConfigNames.length === 0 &&
            <div className='card-body'>
              We could not load the stream spec.
            </div>
          }
          {sortedConfigNames.length > 0 &&
            <div className='table-responsive'>
              <table className='table table-sm table-nowrap card-table'>
                <thead>
                  <tr>
                    <th width='50%'>Name</th>
                    <th width='50%'>Value</th>
                  </tr>
                </thead>
                <tbody className='fs-base'>
                  {sortedConfigNames.map(configName => (
                    <tr key={configName}>
                      <td>{configName}</td>
                      <td>{stream.spec[configName]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </div>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-header-title'>Access</h4>
          </div>
          {stream.projectId === undefined && currentUser !== undefined &&
            <div className='card-body'>
              You are the only user that can access this stream.
            </div>
          }
          {(stream.projectId !== undefined) && (project !== undefined) &&
            <div className='card-body'>
              All members of the project <a href={`/projects/${project.id}`}>#{project.id}: {project.name}</a> can access this stream.
            </div>
          }
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    streams:     state.streams,
    dataSources: state.dataSources,
    dataSinks:   state.dataSinks,
    pipelines:   state.pipelines,
    users:       state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser: fetchCurrentUser,
  fetchStream:      fetchStream,
  fetchDataSources: fetchDataSources,
  fetchDataSinks:   fetchDataSinks,
  fetchPipelines:   fetchPipelines,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowStream);
