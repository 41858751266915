import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import MainContent from '../../components/layout/MainContent';
import Navigation from '../../components/settings/Navigation';
import { fetchInfo } from '../../actions/info';
import {
  fetchCurrentUser,
  fetchUser,
  updateUser
} from '../../actions/users';
import { humanizeFormattedTimestamp } from '../../helpers/humanizeFormattedTimestamp';

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user:         {},
      errorMessage: ''
    };
    this.handleChange         = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmit         = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchInfo();
    this.props.fetchCurrentUser();
    this.props.fetchUser(this.getUserId())
      .then(() => this.setState({ user: this.props.users.user }));
  }

  // TODO: Move in common util class
  validateEmail(email) {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  handleChange(event) {
    let user = this.state.user;

    if (event.target.type === 'number') {
      user[event.target.name] = parseInt(event.target.value);
    } else {
      user[event.target.name] = event.target.value;
    }

    this.setState({ user: user });
  }

  handleCheckboxChange(event) {
    let user = this.state.user;
    user[event.target.name] = event.target.checked;
    this.setState({ user: user });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.user.name.length === 0) {
      this.setState({
        errorMessage: 'Please provide a name.'
      });
    } else if (this.state.user.email.length === 0) {
      this.setState({
        errorMessage: 'Please provide an email.'
      });
    } else if (!this.validateEmail(this.state.user.email)) {
      this.setState({
        errorMessage: 'Please provide a valid email.'
      });
    } else {
      this.props.updateUser(this.state.user).then((response) => {
        if (response.errorMessage !== undefined) {
          this.setState({
            errorMessage: response.errorMessage
          });
        } else {
          this.setState({
            errorMessage: '',
            userUpdated:  true
          });
        }
      });
    }
  }

  getUserId() {
    return parseInt(this.props.match.params.id);
  }

  render() {
    const currentUser = this.props.users.currentUser;

    if ((currentUser === undefined) || (currentUser.isSuperUser !== true)) {
      return (<div></div>);
    }

    if (this.state.userUpdated) {
      return (<Redirect to='/admin/users' />);
    }

    const user = this.state.user;

    return (
      <MainContent preTitle='Settings' title='Manage users'>
        <div className='card card-fill'>
          <div className='card-body'>
            <div className='row mb-4 mt-n3'>
              <div className='col-12'>
                <Navigation currentUser={currentUser} info={this.props.info.info} />
              </div>
            </div>
            {user !== undefined &&
              <React.Fragment>
                <div className='row align-items-center mt-n2'>
                  <div className='col'>
                    <h3 className='card-header-title'>Edit user #{user.id}: {user.name}</h3>
                  </div>
                  <div className='col-auto'>
                    <a className='btn btn-white d-flex align-items-center' href='/admin/users'>
                      <ArrowLeft className='feather-icon' />
                      Go back to users
                    </a>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-12 col-lg-4 col-xl'>
                    <div class="card">
                      <div class="card-body">
                        <div class="row align-items-center gx-0">
                          <div class="col">
                            <h6 class="text-uppercase text-muted mb-2">
                              Sign up at
                            </h6>
                            <span class="h2 mb-0">
                              {humanizeFormattedTimestamp(user.createdAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-4 col-xl'>
                    <div class="card">
                      <div class="card-body">
                        <div class="row align-items-center gx-0">
                          <div class="col">
                            <h6 class="text-uppercase text-muted mb-2">
                              First sign in at
                            </h6>
                            <span class="h2 mb-0">
                              {humanizeFormattedTimestamp(user.firstSignInAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-4 col-xl'>
                    <div class="card">
                      <div class="card-body">
                        <div class="row align-items-center gx-0">
                          <div class="col">
                            <h6 class="text-uppercase text-muted mb-2">
                              Last sign in at
                            </h6>
                            <span class="h2 mb-0">
                              {humanizeFormattedTimestamp(user.lastSignInAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='mb-4' />
                {this.state.errorMessage.length > 0 &&
                  <div className='alert alert-danger mb-4 rounded-0'>
                  {this.state.errorMessage}
                  </div>
                }
                <form>
                  <div className='form-group'>
                    <label htmlFor='name'>Name:</label>
                    <input
                      type='text'
                      className='form-control'
                      name='name'
                      onChange={this.handleChange}
                      value={user.name || ''} />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='email'>Email:</label>
                    <input
                      type='email'
                      className='form-control'
                      name='email'
                      onChange={this.handleChange}
                      value={user.email || ''} />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='maxNumberOfPipelines'>Allowed number of pipelines (leave empty for unlimited):</label>
                    <input
                      type='text'
                      className='form-control'
                      name='maxNumberOfPipelines'
                      onChange={this.handleChange}
                      value={user.maxNumberOfPipelines || ''} />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='maxNumberOfPipelines'>Trial ends at (leave empty for regular, non-trial user):</label>
                    <input
                      type='date'
                      className='form-control'
                      name='trialEndsAt'
                      placeholder='YYYY-mm-dd'
                      onChange={this.handleChange}
                      value={user.trialEndsAt || ''} />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='allowConnectors'>Allowed custom connectors (separated by commas):</label>
                    <input
                      type='text'
                      className='form-control'
                      name='allowConnectors'
                      onChange={this.handleChange}
                      value={user.allowConnectors || ''} />
                  </div>
                  <div className='form-group mb-3'>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='isActivated'
                        onClick={this.handleCheckboxChange}
                        defaultChecked={this.state.user.isActivated} />
                      <label htmlFor='isActivated'>Activated?</label>
                    </div>
                  </div>
                  <div className='form-group mb-3'>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='isSuperUser'
                        onClick={this.handleCheckboxChange}
                        defaultChecked={this.state.user.isSuperUser} />
                      <label htmlFor='isSuperUser'>Superuser?</label>
                    </div>
                  </div>
                  <button
                    className='btn btn-primary'
                    onClick={this.handleSubmit}
                    type='submit'>
                    Save changes
                  </button>
                </form>
              </React.Fragment>
            }
          </div>
        </div>
      </MainContent>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    info:  state.info,
    users: state.users
  }
}

const mapDispatchToProps = {
  fetchCurrentUser: fetchCurrentUser,
  fetchInfo:        fetchInfo,
  fetchUser:        fetchUser,
  updateUser:       updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
