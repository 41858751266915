import React,
       { Component }     from 'react';
import AceEditor         from 'react-ace';
import { Button }        from 'react-bootstrap';
import { Play } from 'react-feather';

import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/theme-xcode';

import '../../../../../../scss/code-editor.scss'

class CodeEditor extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleApply  = this.handleApply.bind(this);
  }

  handleChange(value, event) {
    // manually create change event
    this.props.handleChangeFunc({
      target: {
        dataset: {
          applyUdf:     false,
          attributeId:  this.props.attributeId,
          property:     'actionValue',
          sortPosition: this.props.sortPosition,
          value:        value
        }
      }
    });
  }

  handleApply(event) {
    event.preventDefault();

    // manually create change event
    this.props.handleChangeFunc({
      target: {
        dataset: {
          applyUdf:     true,
          attributeId:  this.props.attributeId,
          property:     'actionValue',
          sortPosition: this.props.sortPosition,
          value:        this.props.value
      }
        }
    });
  }

  render() {
    const {
      executionError,
      isExecutingTransformation
    } = this.props.transformationState;

    const canEditPipeline = this.props.canEditPipeline;
    let code = this.props.value;

    return (
      <div className='datacater-code-editor border-left border-right border-top border-bottom border-dark'>
        <div className='row py-2 px-3 border-bottom border-dark'>
          <div className='col d-flex align-items-center font-weight-bold'>
            <img className='mr-3' src='/images/python.png' style={{height: '1.5rem'}} alt='Python' />
            Python
          </div>
          <div className='col-auto'>
            <Button
              className='d-flex align-items-center border-dark my-2'
              disabled={!canEditPipeline || isExecutingTransformation}
              onClick={this.handleApply}
              size='sm'
              variant='white'>
              <Play className='feather-icon mr-2' />
              {!isExecutingTransformation && 'Run'}
              {isExecutingTransformation && 'Running...'}
            </Button>
          </div>
        </div>
        {executionError !== undefined &&
          <div className='text-bg-danger font-size-sm p-3'>
            {executionError}
          </div>
        }
        <AceEditor
          placeholder=''
          mode='python'
          theme='xcode'
          className=''
          readOnly={!canEditPipeline}
          onLoad={(editor) => { editor.renderer.setPadding(10); editor.renderer.setScrollMargin(10); }}
          onChange={this.handleChange}
          fontSize={13}
          height='300px'
          width='100%'
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={code}
          enableLiveAutocompletion={false}
          tabSize={2} />
      </div>
    );
  }
}

export default CodeEditor;
