class Filter {
  static getFilters = () => {
    return [
      {
        name: 'values that equal value',
        description: 'Selects values that equal a constant value.',
        key: 'equal',
        hasFilterValue: true,
        filterValueName: 'Value',
        restrictToDataType: ['boolean', 'int', 'long', 'float', 'double', 'string',
                             'date', 'time-micros', 'time-millis',
                             'timestamp-micros', 'timestamp-millis'],
        filterFunc: function(element, dataType, value) {
          if (['time-micros', 'time-millis'].includes(dataType)) {
            return element.toLocaleTimeString() === value.toLocaleTimeString();
          } else if (['timestamp-micros', 'timestamp-millis'].includes(dataType)) {
            return element.toLocaleString() === value.toLocaleString();
          } else if (dataType === 'date') {
            return element.toLocaleDateString() === value.toLocaleDateString();
          }

          return element === value;
        }
      },
      {
        name: 'values that are greater than value',
        description: 'Selects values that are greater than a constant value.',
        key: 'greater-than',
        hasFilterValue: true,
        filterValueName: 'Value',
        restrictToDataType: ['int', 'long', 'float', 'double', 'date',
                             'time-micros', 'time-millis',
                             'timestamp-micros', 'timestamp-millis'],
        filterFunc: function(element, dataType, value) {
          if (value == null) {
            return false;
          }

          if (['time-micros', 'time-millis'].includes(dataType)) {
            return element.toLocaleTimeString() > value.toLocaleTimeString();
          }

          return element > value;
        }
      },
      {
        name: 'values that are less than value',
        description: 'Selects values that are less than a constant value.',
        key: 'less-than',
        hasFilterValue: true,
        filterValueName: 'Value',
        restrictToDataType: ['int', 'long', 'float', 'double', 'date',
                             'time-micros', 'time-millis',
                             'timestamp-micros', 'timestamp-millis'],
        filterFunc: function(element, dataType, value) {
          if (value == null) {
            return false;
          }

          if (['time-micros', 'time-millis'].includes(dataType)) {
            return element.toLocaleTimeString() < value.toLocaleTimeString();
          }

          return element < value;
        }
      },
      {
        name: 'missing values',
        description: 'Selects values that are missing.',
        key: 'empty',
        filterFunc: function(element, dataType) {
          return [undefined, null, ''].includes(element);
        }
      },
      {
        name: 'present values',
        description: 'Selects values that are present.',
        key: 'not-empty',
        filterFunc: function(element, dataType) {
          return ![undefined, null, ''].includes(element);
        }
      },
      {
        name: 'values that do not equal value',
        description: 'Selects values that do not equal a constant value.',
        key: 'not-equal',
        hasFilterValue: true,
        filterValueName: 'Value',
        restrictToDataType: ['boolean', 'int', 'long', 'float', 'double', 'string',
                             'date', 'time-micros', 'time-millis',
                             'timestamp-micros', 'timestamp-millis'],
        filterFunc: function(element, dataType, value) {
          if (['time-micros', 'time-millis'].includes(dataType)) {
            return element.toLocaleTimeString() !== value.toLocaleTimeString();
          } else if (['timestamp-micros', 'timestamp-millis'].includes(dataType)) {
            return element.toLocaleString() !== value.toLocaleString();
          } else if (dataType === 'date') {
            return element.toLocaleDateString() !== value.toLocaleDateString();
          }

          return element !== value;
        }
      },
      {
        name: 'values that contain substring',
        description: 'Selects text values that contain a substring.',
        key: 'contain',
        hasFilterValue: true,
        filterValueName: 'Substring',
        restrictToDataType: ['string'],
        filterFunc: function(element, dataType, value) {
          if (value == null) {
            return true;
          } else if (element == null) {
            return false;
          } else if (element.constructor === Array) {
            const matchingElements = element
              .filter(item => item !== undefined && item.includes(value));
            return element.length === matchingElements.length;
          } else {
            return element.includes(value);
          }
        }
      },
      {
        name: 'values that end with substring',
        description: 'Selects text values that end with a substring.',
        key: 'end-with',
        hasFilterValue: true,
        filterValueName: 'Substring',
        restrictToDataType: ['string'],
        filterFunc: function(element, dataType, value) {
          if (element == null) {
            return false;
          } else if (element.constructor === Array) {
            const matchingElements = element
              .filter(item => item !== undefined && item.endsWith(value));
            return element.length === matchingElements.length;
          } else {
            return element.endsWith(value);
          }
        }
      },
      {
        name: 'values that match regular expression',
        description: 'Selects text values that match a regular expression.',
        key: 'reg-exp',
        hasFilterValue: true,
        filterValueName: 'Regular expression',
        restrictToDataType: ['string'],
        filterFunc: function(element, dataType, value) {
          let regularExpression;

          // try to catch SyntaxError
          try {
            regularExpression = new RegExp(value);
          }
          catch (error) {
            return false;
          }

          if (element == null) {
            return false;
          } else if (element.constructor === Array) {
            const matchingElements = element
              .filter(item => item !== undefined && item.match(regularExpression) != null);
            return element.length === matchingElements.length;
          } else {
            return element.match(regularExpression) != null;
          }
        }
      },
      {
        name: 'values that start with substring',
        description: 'Selects text values that start with a substring.',
        key: 'start-with',
        hasFilterValue: true,
        filterValueName: 'Substring',
        restrictToDataType: ['string'],
        filterFunc: function(element, dataType, value) {
          if (element == null) {
            return false;
          } else if (element.constructor === Array) {
            const matchingElements = element
              .filter(item => item !== undefined && item.startsWith(value));
            return element.length === matchingElements.length;
          } else {
            return element.startsWith(value);
          }
        }
      }
    ];
  }
}

export default Filter;
