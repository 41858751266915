import React, { Component } from 'react';
import Samples              from '../../../data_profiler/Samples';
import ContextSidebar       from '../../../layout/ContextSidebar';
import EditTransformation   from '../context_bar/pipeline_step/transformation/Edit';
import EditAssertion        from '../context_bar/pipeline_step/assertion/Edit';

class StepsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draggingItem:      undefined,
      draggingName:      undefined,
      draggingShortName: undefined,
      draggingOverItem:  undefined
    };
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.renderTransformationStepHeader = this.renderTransformationStepHeader.bind(this);
  }

  onDragStart(event) {
    // we need to set transferred data for Firefox, uargs!
    event.dataTransfer.setData('Text', this.id);
    this.setState({
      draggingItem:      parseInt(event.target.dataset.sortPosition),
      draggingName:      event.target.dataset.name,
      draggingShortName: event.target.dataset.shortName
    });
  }

  onDragEnd(event) {
    event.preventDefault();
    this.setState({
      draggingItem:      undefined,
      draggingName:      undefined,
      draggingShortName: undefined
    });
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.state.draggingOverItem === undefined) {
      this.setState({draggingOverItem: parseInt(event.target.dataset.sortPosition)});
    }
  }

  onDragLeave(event) {
    event.preventDefault();
    this.setState({draggingOverItem: undefined});
  }

  onDrop(event) {
    event.preventDefault();
    event.stopPropagation(); // stops the browser from redirecting.

    if (
      (this.state.draggingItem !== undefined) &&
      (this.state.draggingItem !== this.state.draggingOverItem) &&
      (this.state.draggingItem !== (this.state.draggingOverItem + 1))
    ) {
      this.props.movePipelineStepFunc(
        this.state.draggingItem,
        this.state.draggingOverItem + 1
      );
      this.setState({
        draggingItem:     undefined,
        draggingOverItem: undefined
      });
    }
  }

  componentDidUpdate() {
    // scroll to the right end of the steps list
    if (document.getElementById('steps-list') != null &&
      this.props.currentStep === this.props.pipeline.pipelineSteps.length &&
      this.state.draggingItem === undefined) {
      document.getElementById('steps-list').scrollLeft = document.getElementById('steps-list').scrollWidth + 200;
    }
  }

  renderTransformationStepHeader() {
    const pipelineSteps = this.props.pipeline.pipelineSteps.length;
    if (pipelineSteps === 0) {
      return 'Transformation Steps';
    } else if (pipelineSteps === 1) {
      return '1 Transformation Step';
    } else if (pipelineSteps > 1) {
      return pipelineSteps + ' Transformation Steps';
    }
  }

  render() {
    const {
      canEditPipeline,
      dataSource,
      dataSources,
      dataSourceProfile,
      joinedDataSourceProfile,
      pipeline
    } = this.props;

    let editColumnAttribute;
    const pipelineSteps = this.props.pipeline.pipelineSteps
      .sort(function(a, b) {
        return a.sortPosition - b.sortPosition;
      });

    const pipelineStep = (this.props.currentStep > 0)
      ? pipelineSteps.find(step => step.sortPosition === this.props.currentStep)
      : undefined;

    if (this.props.editColumn !== undefined) {
      editColumnAttribute = this.props.attributes
        .find(attribute => parseInt(attribute.id) === parseInt(this.props.editColumn.attributeId))
    }

    const dataSourceMappings = {};

    if (dataSourceProfile !== undefined) {
      dataSourceMappings[dataSourceProfile.id] = {
        dataSourceId:   dataSourceProfile.dataSourceId,
        dataSourceType: dataSource.sourceType
      }
    }

    const joinedDataSource = dataSources
      .find(ds => parseInt(ds.id) === parseInt(pipeline.joinedDataSourceId));

    if ((joinedDataSourceProfile !== undefined) && (joinedDataSource !== undefined)) {
      dataSourceMappings[joinedDataSourceProfile.id] = {
        dataSourceId:   joinedDataSourceProfile.dataSourceId,
        dataSourceType: joinedDataSource.sourceType
      }
    }

    return (
      <React.Fragment>
        <Samples
          addColumnFunc={this.props.addColumnFunc}
          addedColumn={this.props.addedColumn}
          addPipelineStepFunc={this.props.addPipelineStepFunc}
          assertions={this.props.pipeline.pipelineAssertions}
          attributes={this.props.attributes}
          attributeProfiles={this.props.attributeProfiles}
          canEditPipeline={canEditPipeline}
          currentStep={this.props.currentStep}
          dataSource={dataSource}
          dataSourceMappings={dataSourceMappings}
          dataSourceProfile={dataSourceProfile}
          editColumnAttribute={editColumnAttribute}
          editColumnFunc={this.props.editColumnFunc}
          handleAssertionChangeFunc={this.props.handleAssertionChangeFunc}
          handlePipelineStepChangeFunc={this.props.handlePipelineStepChangeFunc}
          joinedDataSource={joinedDataSource}
          joinedDataSourceProfile={this.props.joinedDataSourceProfile}
          movePipelineStepFunc={this.props.movePipelineStepFunc}
          moveToStepFunc={this.props.moveToStepFunc}
          originalRecordsSize={this.props.originalRecordsSize}
          pipelineSteps={this.props.pipeline.pipelineSteps}
          removeColumnFunc={this.props.removeColumnFunc}
          removePipelineStepFunc={this.props.removePipelineStepFunc}
          sampleRecords={this.props.sampleRecords}
          transformers={this.props.transformers} />
        {this.props.contextBarActive && (this.props.currentStep !== undefined) &&
          <ContextSidebar>
            {(this.props.editColumn !== undefined) && (this.props.editColumn.type === 'transformation') && (this.props.currentStep > 0) &&
            <EditTransformation
              attributeId={this.props.editColumn.attributeId}
              attributes={this.props.attributes}
              canEditPipeline={canEditPipeline}
              dataSourceMappings={dataSourceMappings}
              editColumn={this.props.editColumn}
              handleChangeFunc={this.props.handlePipelineStepChangeFunc}
              hideContextBarFunc={this.props.hideContextBarFunc}
              pipelineStep={pipelineStep}
              sortPosition={pipelineStep.sortPosition}
              transformationState={this.props.transformationState}
              transformers={this.props.transformers}
            />
            }
            {(this.props.editColumn !== undefined) && (this.props.editColumn.type === 'assertion') &&
            <EditAssertion
              assertion={this.props.editColumn.assertion}
              attributeId={this.props.editColumn.attributeId}
              attribute={editColumnAttribute}
              attributes={this.props.attributes}
              canEditPipeline={canEditPipeline}
              editColumn={this.props.editColumn}
              handleChangeFunc={this.props.handleAssertionChangeFunc}
              hideContextBarFunc={this.props.hideContextBarFunc}
            />
            }
          </ContextSidebar>
        }
      </React.Fragment>
    );
  }
}

export default StepsPage;
